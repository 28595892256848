import { Alert, Button, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import DepartmentInline from "@/common/components/Entity/Department/DepartmentInline";
import GeneralAddressDisplay from "@/common/components/Entity/General/Display/GeneralAddressDisplay";
import LocationMenu from "@/common/components/Entity/Location/LocationMenu";
import LocationsDeleteModal from "@/common/components/Entity/Location/LocationsDeleteModal";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { EntityHelper } from "@/common/helpers/entity";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  LocationGetPaginatedDto,
} from "@/core/api/generated";

enum BulkActionFlags {
  Delete = "Delete",
}
const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
  create: true,
};
interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function LocationsPage({ displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<LocationGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.Location,
    },
  });

  const paginatedLocationsRequest = useApiRequest(
    apiClient.locationsApi.apiV1LocationsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      locationGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedLocations = paginatedLocationsRequest.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Location)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedLocationsRequest.handleEntityChanged(data);
    },
  });

  return (
    <ViewLayout
      displayProps={displayProps}
      header={
        <>
          <SimpleViewPageHeader
            title={undefined}
            primaryActions={
              displayProps?.create && (
                <AuthorizedElement permissions={[AppPermission.LocationManage]}>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<AppIcon of='add' />}
                    component={RouterLink}
                    to={ROUTE_PATH.LOCATION_CREATE}
                  >
                    Create new location
                  </Button>
                </AuthorizedElement>
              )
            }
          />

          <Alert severity='info' sx={{ mb: 2 }}>
            Locations are one of the levels of the company structure hierarchy. They can have
            specific address or describe an area, and can belong to either specific department or
            the company.
          </Alert>
        </>
      }
    >
      <Stack direction='column' spacing={1}>
        <DataTabular
          columns={[
            {
              field: "name",
              title: "Name",
              flex: 1,
              renderCell: (item) => <>{item.name}</>,
            },
            {
              field: "address",
              title: "Address",
              flex: 2,
              renderCell: (item) => (
                <AppTypography ellipsing={{ enabled: true }} component='div'>
                  <GeneralAddressDisplay address={item.address} direction='row' />
                </AppTypography>
              ),
            },
            {
              field: "department",
              title: "Department",
              flex: 1,
              renderCell: (item) => <DepartmentInline entity={item.department} />,
            },
          ]}
          isLoading={paginatedLocationsRequest.isLoading}
          rows={paginatedLocations?.items}
          getRowId={(item) => item.id!}
          rowTo={(item) => ROUTE_PATH.LOCATION_VIEW(item.id)}
          renderRowAction={({ item }) => (
            <LocationMenu
              onDelete={() => paginatedLocationsRequest.refetch()}
              onUpdate={() => paginatedLocationsRequest.refetch()}
              entity={item}
            />
          )}
          statePersistence={commonRequestParams.dataTabularProps.statePersistence}
          pagination={commonRequestParams.dataTabularProps.pagination}
          sort={commonRequestParams.dataTabularProps.sort}
          quickFilter={commonRequestParams.dataTabularProps.quickFilter}
          filters={commonRequestParams.dataTabularProps.filters}
          bulkActions={{
            enabled: true,
            definition: BulkActionFlags,
            actionTriggers: ({ currentAction, startAction, selectedIds }) => (
              <>
                <AppIconButton
                  tooltipProps={{ title: "Delete" }}
                  onClick={() => startAction(BulkActionFlags.Delete)}
                >
                  <AppIcon of='delete' />
                </AppIconButton>
              </>
            ),
            actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => (
              <>
                <LocationsDeleteModal
                  entities={EntityHelper.filterEntitiesByIds(
                    paginatedLocations?.items || [],
                    selectedIds as string[],
                  )}
                  open={currentAction === BulkActionFlags.Delete}
                  onClose={() => cancelAction()}
                  onDelete={() => {
                    completeAction();
                    paginatedLocationsRequest.refetch();
                  }}
                />
              </>
            ),
          }}
        />
      </Stack>
    </ViewLayout>
  );
}
