import { Button, IconButton, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";

import { ChatDto, ChatParticipantDto, ProposalStatus } from "@/core/api/generated";
import * as chatHistorySlice from "@/store/communication/chatHistorySlice";

import { useChatCurrentParticipant } from "@/common/hooks/communication/useChatCurrentParticipant";
import { useAppDispatch, useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import AppIcon from "../../Icons/AppIcon";
import ChatHistoryItemTypeIcon from "../../Icons/ChatHistoryItemTypeIcon";
import RespondOnNegotiationProposalModal from "../Negotiation/NegotiationProposal/RespondOnNegotiationProposalModal";
import ChatPinnedItemsModal from "./ChatPinnedItemsModal";

export interface OwnProps {
  chat: ChatDto;
  participants?: ChatParticipantDto[];
}

type Props = OwnProps;

function ChatPinnedItems({ chat, participants }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();

  const currentParticipant = useChatCurrentParticipant(chat.id);

  const pinnedChatHistoryItems = useAppSelector(
    (x) => x.communication.chatHistory.pinnedChatHistoryItems[chat.id!],
  );
  const isItemsLoading = useAppSelector(
    (x) => x.communication.chatHistory.loading.getPinnedHistoryItems![chat.id!],
  );

  const [isPinnedItemsModalOpen, setIsPinnedItemsModalOpen] = useState(false);
  const [isRespondOnNegotiationProposalModalOpen, setIsRespondOnNegotiationProposalModalOpen] =
    useState(false);

  const lastItem = useMemo(() => _.last(pinnedChatHistoryItems?.items), [pinnedChatHistoryItems]);
  const itemCount = useMemo(
    () => pinnedChatHistoryItems?.items?.length || 0,
    [pinnedChatHistoryItems],
  );

  const getPinnedChatHistoryItems = useCallback(
    (...args: Parameters<typeof chatHistorySlice.getPinnedChatHistoryItems>) =>
      thunkDispatch(chatHistorySlice.getPinnedChatHistoryItems(...args)),
    [],
  );
  const getPinnedChatHistoryItemsThrottle = useCallback(
    _.throttle(getPinnedChatHistoryItems, 500, { leading: true, trailing: false }),
    [getPinnedChatHistoryItems],
  );

  useEffect(() => {
    getPinnedChatHistoryItemsThrottle({
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      chatId: chat.id!,
      chatHistoryGetPaginatedDto: {
        offset: 0,
        limit: 10,
      },
    });
  }, [chat.id]);

  const handleRespondOnProposal = useCallback(() => {
    if (lastItem?.negotiationProposal) {
      setIsRespondOnNegotiationProposalModalOpen(true);
    }
  }, [lastItem]);

  if (isItemsLoading) {
    return <Skeleton variant='text' sx={{ fontSize: "1rem" }} />;
  }

  if (itemCount === 0 && !isItemsLoading) {
    return null;
  }

  return (
    <Stack direction='column'>
      {lastItem && (
        <Stack direction='row' spacing={1} sx={{ alignItems: "flex-start" }}>
          <Stack direction='row' spacing={1} sx={{ flex: 5, alignItems: "center" }}>
            <ChatHistoryItemTypeIcon
              item={lastItem}
              fontSize='small'
              inText
              withTooltip
              sx={{ color: (theme) => theme.palette.text.secondary }}
            />
            {lastItem.message && (
              <Typography component='span' variant='body2'>
                {lastItem.message?.body || ""}
              </Typography>
            )}

            {lastItem.negotiationProposal && (
              <>
                <Typography component='span' variant='body2'>
                  {lastItem.negotiationProposal?.statement || ""}
                </Typography>
                {lastItem.negotiationProposal.status === ProposalStatus.Open && (
                  <Button
                    variant='text'
                    color='secondary'
                    size='small'
                    onClick={handleRespondOnProposal}
                  >
                    Respond
                  </Button>
                )}
              </>
            )}
          </Stack>
          <Stack direction='row' sx={{ flex: 1, justifyContent: "flex-end" }}>
            {itemCount !== 0 && (
              <Tooltip title='See all pinned items'>
                <IconButton onClick={() => setIsPinnedItemsModalOpen(true)}>
                  <AppIcon of='list' fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      )}

      <ChatPinnedItemsModal
        open={isPinnedItemsModalOpen}
        onClose={() => setIsPinnedItemsModalOpen(false)}
        chat={chat}
        currentParticipant={currentParticipant}
        participants={participants}
      />

      {lastItem?.negotiationProposal && (
        <RespondOnNegotiationProposalModal
          open={isRespondOnNegotiationProposalModalOpen}
          onClose={() => setIsRespondOnNegotiationProposalModalOpen(false)}
          proposal={lastItem?.negotiationProposal}
        />
      )}
    </Stack>
  );
}

export default ChatPinnedItems;
