import DataTabular from "@/common/components/DataTabular/DataTabular";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { TabularColumnModel } from "@/common/ts/dataTabular";
import { ImportResultDto, ImportRowResultDto, ImportRowResultStatus } from "@/core/api/generated";
import { Dialog, DialogContent, DialogProps, Stack, Typography } from "@mui/material";
import { useMemo } from "react";

interface Props extends DialogProps {
  importResult: ImportResultDto;
}

export default function ProblemRowsViewModal({ importResult, ...dialogProps }: Props) {
  const problemRows = useMemo(() => {
    return importResult.rows?.filter((r) => r.status === ImportRowResultStatus.Failed) || [];
  }, [importResult]);

  const columns = useMemo<TabularColumnModel<ImportRowResultDto>[]>(() => {
    return [
      {
        field: "index",
        title: "Row number",
        flex: 1,
        renderCell: (row) => (
          <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
            <Typography>{(row?.index || 0) + 1}</Typography>
          </Stack>
        ),
      },
      {
        field: "error",
        title: "Error",
        flex: 1,
        renderCell: (row) => (
          <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
            <Typography color='error'>
              {row.error || row.fieldErrors?.filter((fe) => fe.error).join(",")}
            </Typography>
          </Stack>
        ),
      },
    ] as TabularColumnModel<ImportRowResultDto>[];
  }, []);

  return (
    <Dialog maxWidth='md' fullWidth {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Problem rows in file
      </AppModalTitle>
      <DialogContent sx={{ pt: 0 }}>
        <Stack sx={{ py: 1 }} spacing={1}>
          <DataTabular
            variant='datalist'
            columns={columns}
            rows={problemRows}
            getRowId={(row) => row.index?.toString() || ""}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
