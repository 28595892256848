import { Box } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import GeneralEventLogsEmbeddedView from "@/common/components/Entity/GeneralEventLog/GeneralEventLogsEmbeddedView";
import { PartyDto } from "@/core/api/generated";

interface Props {
  party: PartyDto;
}
export default function EventLogTabContent({ party }: Props) {
  return (
    <PageTabContent>
      <Box>
        <GeneralEventLogsEmbeddedView
          entityId={party.id!}
          displayProps={{
            header: true,
            filters: false,
            pagination: true,
          }}
          headerProps={{
            withLink: true,
            title: <PageTabHeader title={"Event log"} />,
          }}
        />
      </Box>
    </PageTabContent>
  );
}
