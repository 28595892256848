import {
  Box,
  Button,
  DialogContentText,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModalWithTrigger from "@/common/components/Modals/ConfirmationModalWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { VehicleType } from "@/core/api/generated";

export default function VehicleFuelTypesPage() {
  const [search, setSearch] = useState("");
  const [vehicleType, setVehicleType] = useState<VehicleType | "">("");
  const { enqueueSnackbar } = useSnackbar();

  const vehicleFuelTypesRequest = useApiRequest(
    apiClient.adminVehicleFuelTypesApi.apiV1AdminReferenceDataVehiclesFuelTypesGetPost,
    {
      adminVehicleFuelTypeGetPaginatedDto: {
        search,
        vehicleType: vehicleType || undefined,
      },
    },
    {
      deps: [vehicleType],
      debouncedDeps: {
        deps: [search],
        wait: 500,
        options: { leading: false, trailing: true },
      },
    },
  );
  const vehicleFuelTypes = vehicleFuelTypesRequest.data;

  return (
    <ListPageLayout>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={1}
        sx={{ alignItems: { xs: "stretch", md: "center" }, mb: 2 }}
      >
        <TextField
          label='Search'
          size='small'
          margin='normal'
          type='text'
          variant='outlined'
          value={search || ""}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ margin: 0, minWidth: 300, maxWidth: "100%" }}
        />
        <FormControl size='small' sx={{ minWidth: 200 }}>
          <InputLabel>Vehicle type</InputLabel>
          <Select
            label='Vehicle type'
            displayEmpty={false}
            autoWidth
            value={vehicleType}
            onChange={(e) => setVehicleType(e.target.value as VehicleType)}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {Object.values(VehicleType)
              .filter((x) => x !== "None")
              .map((x, index) => (
                <MenuItem key={index} value={x}>
                  {x}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={1}
          sx={{ flex: 1, justifyContent: { xs: "stretch", md: "flex-end" } }}
        >
          <Button
            variant='contained'
            color='primary'
            startIcon={<AppIcon of='add' />}
            component={RouterLink}
            to={ROUTE_PATH.ADMIN_VEHICLE_MAKE_CREATE}
          >
            Create new fuel type
          </Button>
        </Stack>
      </Stack>

      <Divider sx={{ my: 2 }} />

      <DataTabular
        columns={[
          {
            field: "globalNumber",
            title: "Global number",
            flex: 1,
            renderCell: (item) => item.globalNumber,
          },
          {
            field: "name",
            title: "Name",
            flex: 1,
            renderCell: (item) => (
              <Box>
                <Typography component='div'>{item.name}</Typography>
                <Typography component='div' variant='body2'>
                  {item.description}
                </Typography>
              </Box>
            ),
          },
          {
            field: "vehicleType",
            title: "Vehicle type",
            flex: 1,
            renderCell: (item) => (
              <InlineApiEnumValue type='VehicleType' value={item.vehicleType} />
            ),
          },
          {
            field: "fuelType",
            title: "Fuel type",
            flex: 1,
            renderCell: (item) => (
              <InlineApiEnumValue type='VehicleFuelType' value={item.fuelType} />
            ),
          },
          {
            field: "isBuiltIn",
            title: "Built-in",
            flex: 1,
            renderCell: (item) => <BooleanValue value={item.isBuiltIn} />,
          },
          {
            field: "isEnabled",
            title: "Enabled",
            flex: 1,
            renderCell: (item) => <BooleanValue value={item.isEnabled} />,
          },
          {
            field: "sortOrder",
            title: "Sort order",
            flex: 1,
            renderCell: (item) => <>{item.sortOrder}</>,
          },
        ]}
        rows={vehicleFuelTypes?.items}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.ADMIN_VEHICLE_FUEL_TYPES_EDIT(item.id)}
        renderRowAction={({ item }) => (
          <>
            <MenuWithTrigger
              trigger={
                <IconButton>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              {({ handleClose }) => [
                <MenuItem
                  key={0}
                  component={RouterLink}
                  to={ROUTE_PATH.ADMIN_VEHICLE_FUEL_TYPES_EDIT(item.id)}
                >
                  Edit
                </MenuItem>,
                <ConfirmationModalWithTrigger
                  key={1}
                  trigger={<MenuItem disabled={item.isBuiltIn}>Delete</MenuItem>}
                  title='Delete reference data?'
                  body={({ error }) => {
                    return (
                      <DialogContentText>
                        {`You're going to delete reference data that is used across the system. This action can't
            be undone.`}
                      </DialogContentText>
                    );
                  }}
                  onConfirm={async () => {
                    try {
                      await apiClient.adminVehicleFuelTypesApi.apiV1AdminReferenceDataVehiclesFuelTypesFuelTypeIdDelete(
                        { fuelTypeId: item.id! },
                      );
                      vehicleFuelTypesRequest.refetch();
                      enqueueSnackbar("Fuel type deleted.", { variant: "success" });
                      handleClose();
                    } catch (err) {
                      const validation2 = ValidationHelper.handleApiErrorResponse(err);
                      validation2.hasErrors &&
                        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
                    }
                  }}
                />,
              ]}
            </MenuWithTrigger>
          </>
        )}
        isLoading={vehicleFuelTypesRequest.isLoading}
      />
    </ListPageLayout>
  );
}
