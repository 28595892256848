import { LoadingButton } from "@mui/lab";
import { Alert, LinearProgress, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ROUTE_PATH } from "@/common/constants/routing";
import { renderIf } from "@/common/helpers/render/renderIf";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { authService } from "@/common/services/auth";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { InviteDto, InviteType, InviteUserType, ProfileDto } from "@/core/api/generated";
import * as invitesSlice from "@/store/invites/slice";

export type AcceptInviteQueryParams = GeneralQueryParams;

export default function InviteAcceptance() {
  const { inviteId } = useParams<{ inviteId: string }>();
  const { ...generalQueryParams } = useQueryParams<AcceptInviteQueryParams>();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const thunkDispatch = useAppThunkDispatch();

  const [isInviteInfoLoading, setIsInviteInfoLoading] = useState(false);
  const [isInviteForDifferentUser, setIsInviteForDifferentUser] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const [profile, setProfile] = useState<ProfileDto | undefined>(undefined);
  const [invite, setInvite] = useState<InviteDto | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setIsInviteInfoLoading(true);

        let profile2: ProfileDto | undefined = undefined;
        try {
          const profileResult = await apiClient.profileApi.apiV1ProfileGet({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          });
          profile2 = profileResult.data;
          setProfile(profile2);
        } catch (err) {
          // ignore as user can be unauthenticated
        }

        const { data: inviteAnonymously } =
          await apiClient.invitesApi.apiV1InvitesInviteIdAnonymouslyGet({
            inviteId: inviteId,
          });

        // if logged in and invite is for different user
        if (
          (await authService.isAuthenticated()) &&
          inviteAnonymously.type === InviteType.Personal &&
          profile2 &&
          profile2.email !== inviteAnonymously.userInfo?.email
        ) {
          setInvite(inviteAnonymously);
          setIsInviteForDifferentUser(true);
          setIsInviteInfoLoading(false);
          return;
        }

        const { data: inviteResult } = await apiClient.invitesApi.apiV1InvitesMyInviteIdGet({
          inviteId: inviteId,
        });

        // if logged in and already answered
        if ((await authService.isAuthenticated()) && inviteResult.isIAlreadyAnswered === true) {
          console.log("Invite already answered. Redirect to root ...");
          history.push(`/`);
          return;
        }

        if (inviteResult.isExpired) {
          console.log("Redirect to invite expired...");
          history.push(
            ROUTE_PATH.AUTH_INVITE_EXPIRED(inviteId, {
              ...generalQueryParams,
            }),
          );
        } else {
          setInvite(inviteResult);
        }
      } catch (err) {
        const validation2 = ValidationHelper.handleApiErrorResponse(err);
        validation2.hasErrors &&
          enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
      } finally {
        setIsInviteInfoLoading(false);
      }
    })();
  }, []);

  const handleAccept = async () => {
    setIsAccepting(true);
    try {
      await thunkDispatch(invitesSlice.acceptInvite({ inviteId: inviteId }));
      enqueueSnackbar("Invite accepted.", { variant: "success" });

      console.log("Redirect to invite user profile check...");
      history.push(ROUTE_PATH.AUTH_INVITE_USER_PROFILE_CHECK(inviteId, { ...generalQueryParams }));
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    } finally {
      setIsAccepting(false);
    }
  };

  const handleDecline = async () => {
    setIsDeclining(true);
    try {
      await thunkDispatch(invitesSlice.rejectInvite({ inviteId: inviteId }));
      enqueueSnackbar("Invite declined.", { variant: "success" });
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    } finally {
      setIsDeclining(false);
      history.push(ROUTE_PATH.SELECT_TENANT);
    }
  };

  return (
    <Box
      sx={{
        width: {
          xs: "100%",
          md: "40%",
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "background.default",
        boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.05);",
        padding: 3,
        borderRadius: (theme) => `${theme.shape.borderRadius}px`,
      }}
    >
      {renderIf()
        .if(isInviteInfoLoading)
        .then(<LinearProgress />)
        .elseif(isInviteForDifferentUser && !!profile && !!invite)
        .then(
          <Alert severity='error'>
            You logged in via email <strong>{profile?.email}</strong> but the invite is for
            different email. Probably you received the invite link by mistake.
          </Alert>,
        )
        .elseif(!!invite && !invite?.isExpired)
        .then(
          <Stack spacing={2} sx={{ alignItems: "center" }}>
            <Box sx={{ textAlign: "center" }}>
              <Typography component='div' variant='h6'>
                {renderIf()
                  .if(invite?.userType === InviteUserType.Customer)
                  .then(
                    <>
                      You have been invited to join <strong>{invite?.tenant?.name}</strong>.
                    </>,
                  )
                  .else(
                    <>
                      You have been invited to join the <strong>{invite?.tenant?.name}</strong>{" "}
                      company.
                    </>,
                  )
                  .render()}
              </Typography>
              <Typography component='div' variant='body1'>
                Please accept or decline the invitation.
              </Typography>
            </Box>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 2 }}
              sx={{ width: "100%" }}
            >
              <LoadingButton
                fullWidth
                variant='contained'
                color='primary'
                loading={isAccepting}
                onClick={handleAccept}
              >
                Accept
              </LoadingButton>

              <LoadingButton
                fullWidth
                variant='contained'
                color='primary'
                loading={isDeclining}
                onClick={handleDecline}
              >
                Decline
              </LoadingButton>
            </Stack>
          </Stack>,
        )
        .else(<LinearProgress />)
        .render()}
    </Box>
  );
}
