import { apiClient } from "@/core/api/ApiClient";
import {
  DamageCostEvaluationAggregateDto,
  DamageCostEvaluationAggregateItemDto,
  EntityType,
} from "@/core/api/generated";
import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: DamageCostEvaluationAggregateDto;
  item: DamageCostEvaluationAggregateItemDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function DamageCostEvaluationAggregateDeleteModal({
  entity,
  item,
  onDelete,
  ...otherProps
}: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.DamageCostEvaluationAggregate}
      entity={entity}
      entityId={entity.id}
      title='Delete item?'
      body={
        <>
          {`You're`} going to delete the item with number <strong>{item.localNumber}</strong>.
        </>
      }
      deleteFunc={(params) =>
        apiClient.damageCostEvaluationAggregatesApi.apiV1DamageCostEvaluationsAggregatesAggregateIdItemsItemIdDelete(
          {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            aggregateId: params.entityId,
            itemId: item.id || "",
          },
        )
      }
      {...otherProps}
    />
  );
}
