import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";

import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  DamageDetectionDto,
  DamageDetectionItemDto,
  DamageDetectionMigrateToNewVisualModelDto,
  ItemDtoInDamageDetectionMigrateToNewVisualModelDto,
  VehicleVisualModelDto,
} from "@/core/api/generated";
import { useMemo, useRef } from "react";
import NoDataAlert from "../../AppAlerts/NoDataAlert";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FieldValue from "../../Form/Display/FieldValue";
import DamagePointsVisualizer from "../../Images/DamagePointsVisualizer";
import { VisualModelImagesList } from "../../Images/VisualModelImagesList";
import VehicleVisualModelAutocomplete from "../VehicleVisualModel/VehicleVisualModelAutocomplete";
import VehicleDamageLink from "../VehicleDamage/VehicleDamageLink";
import { ReactZoomPanPinchRef } from "react-zoom-pan-pinch";
import ZoomableBlock from "../../Images/ZoomableBlock";

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%",
}));

interface OwnProps {
  damageDetection: DamageDetectionDto;
  onSave?: (newValue: DamageDetectionDto) => void;
}

export type DamageDetectionMigrateToNewVisualModelProps = OwnProps;

export default function DamageDetectionMigrateToNewVisualModel({
  damageDetection,
  onSave,
}: DamageDetectionMigrateToNewVisualModelProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  const vehicleVisualModelLatestOrDefaultRequest = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet,
    {
      vehicleId: damageDetection.vehicle?.id || undefined,
      isIgnoreDefaultForVehicle: true,
    },
    {
      deps: [damageDetection.vehicle?.id],
    },
  );
  const vehicleVisualModelLatestOrDefault = vehicleVisualModelLatestOrDefaultRequest?.data;

  const oldVehicleVisualModelRequest = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsModelIdGet,
    {
      modelId: damageDetection.vehicleVisualModel?.id || "",
    },
    {
      deps: [damageDetection.vehicleVisualModel?.id],
    },
  );
  const oldVehicleVisualModel = oldVehicleVisualModelRequest?.data;

  if (oldVehicleVisualModelRequest.isLoading && !oldVehicleVisualModelRequest.isEnded) {
    return <LinearProgress />;
  }

  return (
    <Stack spacing={1}>
      <Alert severity='info'>
        To migrate the damage to new vehicle visual model, manually select new visual model and
        place damage point on correct position.
      </Alert>

      <Formik<
        BaseFormikValues &
          Omit<DamageDetectionMigrateToNewVisualModelDto, "items"> & {
            items?: Array<
              ItemDtoInDamageDetectionMigrateToNewVisualModelDto & {
                ddItem?: DamageDetectionItemDto;
                feTempId?: string | null;
              }
            >;
            newVehicleVisualModel?: VehicleVisualModelDto;
          }
      >
        enableReinitialize
        initialValues={{
          oldVehicleVisualModelId: oldVehicleVisualModel?.id,
          newVehicleVisualModelId:
            vehicleVisualModelLatestOrDefault &&
            vehicleVisualModelLatestOrDefault.id !== oldVehicleVisualModel?.id
              ? vehicleVisualModelLatestOrDefault.id
              : undefined,
          newVehicleVisualModel:
            vehicleVisualModelLatestOrDefault &&
            vehicleVisualModelLatestOrDefault.id !== oldVehicleVisualModel?.id
              ? vehicleVisualModelLatestOrDefault
              : undefined,
          items: damageDetection?.items?.map((x) => ({
            id: x.id,
            oldPoint: x.point,
            newPoint: undefined,
            ddItem: x,
          })),
          isMigrateVehicleDamages: true,

          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            const response =
              await apiClient.damageDetectionsApi.apiV1DamageDetectionsDamageDetectionIdMigrateToNewVisualModelPost(
                {
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  damageDetectionId: damageDetection.id || "",
                  damageDetectionMigrateToNewVisualModelDto: {
                    ...values,
                  },
                },
              );
            enqueueSnackbar("Saved.", {
              variant: "success",
            });
            onSave && onSave(response.data);

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    columnGap: 2,
                  }}
                >
                  <FormControl fullWidth margin='dense'>
                    <VehicleVisualModelAutocomplete
                      disabled
                      entity={oldVehicleVisualModel}
                      label='Old visual model'
                    />
                  </FormControl>

                  <FormControl fullWidth margin='dense'>
                    <VehicleVisualModelAutocomplete
                      entity={undefined}
                      entityId={values.newVehicleVisualModelId}
                      searchFilters={{
                        excludeId: oldVehicleVisualModel?.id || undefined,
                      }}
                      label='New visual model'
                      required
                      onChange={(newValue) => {
                        setFieldValue("newVehicleVisualModelId", newValue?.id);
                        setFieldValue("newVehicleVisualModel", newValue);
                      }}
                    />
                  </FormControl>
                </Box>

                <Stack spacing={2}>
                  {/* Items */}
                  {values?.items?.map((item, i) => {
                    const oldVehicleVisualModelImage = oldVehicleVisualModel?.images?.find(
                      (x) =>
                        x.area === item?.ddItem?.area && x.projection === item?.ddItem?.projection,
                    );
                    const newVehicleVisualModelImage = values.newVehicleVisualModel?.images?.find(
                      (x) =>
                        x.area === item?.ddItem?.area && x.projection === item?.ddItem?.projection,
                    );

                    return (
                      <Card key={i}>
                        <CardHeader title={`Item ${i + 1}`} />
                        <CardContent>
                          <Stack spacing={1}>
                            {/* Item details */}
                            <Box
                              sx={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr",
                                columnGap: 2,
                              }}
                            >
                              <FieldValue label='Part & damage type' direction='column'>
                                <Box>
                                  {item.ddItem?.partType?.name} - {item.ddItem?.damageType?.name}
                                </Box>
                              </FieldValue>

                              <FieldValue label='Vehicle damage' direction='column'>
                                {item.ddItem?.damage && (
                                  <Typography
                                    component='div'
                                    variant='body2'
                                    color='text.secondary'
                                  >
                                    <VehicleDamageLink
                                      vehicleId={item.ddItem?.damage?.vehicleId}
                                      entity={item.ddItem?.damage}
                                      withIcon={false}
                                    />
                                  </Typography>
                                )}
                              </FieldValue>

                              <FieldValue label='Area & Projection' direction='column'>
                                <InlineApiEnumValue type='VehicleArea' value={item.ddItem?.area} />{" "}
                                /{" "}
                                {(item.ddItem?.projection && (
                                  <InlineApiEnumValue
                                    type='VehicleProjection'
                                    value={item.ddItem?.projection}
                                  />
                                )) ||
                                  "-"}
                              </FieldValue>
                            </Box>

                            <Box
                              sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: 4 }}
                            >
                              {/* Old model */}
                              <Stack spacing={1}>
                                <Typography variant='h6'>Old visual model</Typography>

                                {(!oldVehicleVisualModel || !oldVehicleVisualModelImage) && (
                                  <NoDataAlert title='Old visual model not found for this damage.' />
                                )}

                                <Box>
                                  {oldVehicleVisualModel && oldVehicleVisualModelImage && (
                                    <Box>
                                      <ZoomableBlock>
                                        <DamagePointsVisualizer
                                          containerSx={{
                                            width: `500px`,
                                            height: `500px`,
                                            cursor: "default",
                                          }}
                                          pointsInfo={[
                                            {
                                              ...item.ddItem,
                                              point: item.oldPoint,
                                              isDraggable: false,
                                            },
                                          ]}
                                          imageUrl={oldVehicleVisualModelImage?.file?.url}
                                        />
                                      </ZoomableBlock>

                                      <VisualModelImagesList
                                        visualModel={oldVehicleVisualModel}
                                        imageContainerSx={{ width: `100px`, height: `auto` }}
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Stack>

                              {/* New model */}
                              <Stack spacing={1}>
                                <Typography variant='h6'>New visual model</Typography>

                                {(!oldVehicleVisualModel || !oldVehicleVisualModelImage) && (
                                  <NoDataAlert title='New visual model not found for this damage.' />
                                )}

                                <Box>
                                  {values.newVehicleVisualModel && newVehicleVisualModelImage && (
                                    <Box>
                                      <ZoomableBlock>
                                        <DamagePointsVisualizer
                                          containerSx={{
                                            width: `500px`,
                                            height: `500px`,
                                            cursor: "crosshair",
                                          }}
                                          pointsInfo={[
                                            {
                                              ...item.ddItem,
                                              id: item.feTempId || undefined,
                                              point: item.newPoint,
                                              isDraggable: true,
                                            },
                                          ]}
                                          imageUrl={newVehicleVisualModelImage?.file?.url}
                                          onVisualModelClick={(pointInfo) => {
                                            setFieldValue(`items[${i}].feTempId`, pointInfo?.id);
                                            setFieldValue(`items[${i}].newPoint`, pointInfo?.point);
                                          }}
                                        />
                                      </ZoomableBlock>

                                      <VisualModelImagesList
                                        visualModel={values.newVehicleVisualModel}
                                        imageContainerSx={{ width: `100px`, height: `auto` }}
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Stack>
                            </Box>
                          </Stack>
                        </CardContent>
                      </Card>
                    );
                  })}

                  <FormControl margin='dense' fullWidth>
                    <FormControlLabel
                      sx={{ margin: 0 }}
                      disableTypography
                      control={
                        <Checkbox
                          checked={values.isMigrateVehicleDamages ?? false}
                          onChange={(e) => {
                            setFieldValue("isMigrateVehicleDamages", e.target.checked);
                          }}
                        />
                      }
                      label={"Migrate vehicle damages"}
                    />
                    <FormHelperText>
                      Check to apply updates for all vehicle damages (vehicle history) detected in
                      this damage detection.
                    </FormHelperText>
                  </FormControl>
                </Stack>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <LoadingButton
                  sx={{ mt: { xs: "auto", md: 2 } }}
                  color='primary'
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Migrate
                </LoadingButton>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </Stack>
  );
}
