import { ROUTE_PATH } from "@/common/constants/routing";
import { EntityHelper } from "@/common/helpers/entity";
import { renderIf } from "@/common/helpers/render/renderIf";
import { TextHelper } from "@/common/helpers/text";
import useCascadeActionSpec from "@/common/hooks/entity/cascadeActions/useCascadeActionSpec";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { ValidationHelper } from "@/common/validation";
import { CascadeActionType, EntityType, IBaseEntityDto } from "@/core/api/generated";
import {
  Alert,
  Box,
  Checkbox,
  DialogProps,
  FormControlLabel,
  IconButton,
  Stack,
} from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import CascadeActionSettingsInputModal from "../../CascadeActions/RelatedEntityTypesInputModal";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import AppIcon from "../../Icons/AppIcon";
import AppLink from "../../Link/AppLink";
import ConfirmationModal, { ConfirmationModalProps } from "../../Modals/ConfirmationModal";
import TenantLink from "../Tenant/TenantLink";
import { isCanDeleteEntity } from "./BaseEntityDeleteModal";
import EntityLink from "./EntityLink";

export interface BaseEntitiesDeleteModalOwnProps<TEntity extends IBaseEntityDto> {
  entityType: EntityType;
  entities: TEntity[];
  deleteFunc: (params: {
    entityType: EntityType;
    entityIds: string[];
    entities: TEntity[];
    relatedEntitiesTypes: EntityType[];
  }) => void | Promise<void> | Promise<any>;
  title?: ReactNode;
  body?: ReactNode;
  bodyBefore?: ReactNode;
  bodyAfter?: ReactNode;
  cascadeActionDisplay?: boolean;
  onDelete?: (params: { entityType: EntityType; entityIds: string[]; entities: TEntity[] }) => void;
}

export type BaseEntitiesDeleteModalProps<TEntity extends IBaseEntityDto> =
  BaseEntitiesDeleteModalOwnProps<TEntity> & ConfirmationModalProps;

/** Interface to be used by wrapper components that utilize this component. */
export interface BaseEntitiesDeleteModalInheritableProps<
  TEntity extends IBaseEntityDto = IBaseEntityDto,
> extends DialogProps {
  onDelete?: BaseEntitiesDeleteModalOwnProps<TEntity>["onDelete"];
}
export default function BaseEntitiesDeleteModal<TEntity extends IBaseEntityDto = IBaseEntityDto>({
  entityType,
  entities,
  deleteFunc,
  title,
  body,
  bodyBefore,
  bodyAfter,
  cascadeActionDisplay = false,
  onDelete,
  ...dialogProps
}: BaseEntitiesDeleteModalProps<TEntity> & ConfirmationModalProps) {
  const [isDeleteCascade, setIsDeleteCascade] = useState(false);
  const [isDeleteCascadeSettingsOpen, setIsDeleteCascadeSettingsOpen] = useState(false);
  const [relatedEntitiesTypes, setRelatedEntitiesTypes] = useState<EntityType[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const currentTenant = useCurrentTenant();
  const entitiesMeta = useMemo(
    () => entities.map((entity) => EntityHelper.getEntityMeta(entity)),
    [entities],
  );
  const entitiesCount = useMemo(() => entities.length, [entities]);
  const canDelete = useMemo(
    () => entitiesMeta.every((entityMeta) => isCanDeleteEntity({ entityMeta, currentTenant })),
    [entitiesMeta, currentTenant],
  );

  const cascadeActionSpec = useCascadeActionSpec(entityType, CascadeActionType.DeleteEntity);

  useEffect(() => {
    if (cascadeActionSpec) {
      setRelatedEntitiesTypes(cascadeActionSpec.relatedEntitiesTypes || []);
    }
  }, [cascadeActionSpec]);

  const handleDeleteConfirmed = useCallback(async () => {
    const entityIds = entities.map((entity) => entity.id) as string[];
    try {
      await deleteFunc({ entityType, entityIds, entities, relatedEntitiesTypes });
      enqueueSnackbar(
        `${entitiesCount} ${TextHelper.pluralize(
          "entity",
          entitiesCount,
        )} were successfully deleted.`,
        {
          variant: "success",
        },
      );
      onDelete && onDelete({ entityType, entityIds, entities });
      dialogProps?.onClose && dialogProps.onClose({}, "escapeKeyDown");
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [entityType, entities, relatedEntitiesTypes, deleteFunc, enqueueSnackbar]);

  return (
    <ConfirmationModal
      isConfirmEnabled={canDelete}
      title={
        title || (
          <Stack spacing={1}>
            <Box>
              Delete {entitiesCount}{" "}
              <InlineApiEnumValue
                isPluralize={entitiesCount > 1}
                type='EntityType'
                value={entityType}
                withDescription={false}
              />
              ?
            </Box>
          </Stack>
        )
      }
      body={
        <Stack spacing={1}>
          {bodyBefore && <Box>{bodyBefore}</Box>}

          {body || (
            <Stack spacing={1}>
              <Box component='div'>
                {`You're`} going to delete the next{" "}
                <InlineApiEnumValue
                  isPluralize={entities.length > 1}
                  type='EntityType'
                  value={entityType}
                  withDescription={false}
                />
                :
              </Box>

              <Stack spacing={1}>
                {entities.map((entity, i) => (
                  <EntityLink
                    key={i}
                    entityType={entityType}
                    entityId={entity.id}
                    entity={entity}
                  />
                ))}
              </Stack>

              <Box>{` This action can't be undone.`}</Box>

              {entitiesMeta.map((meta) => (
                <>
                  {meta?.grantsMeta?.isConsumedByMe && (
                    <Alert severity='warning' key={meta.id}>
                      This entity is shared to you via{" "}
                      <AppLink
                        to={ROUTE_PATH.DATA_GRANTS({
                          entityType,
                          entityId: meta.id,
                        })}
                      >
                        data grant
                      </AppLink>{" "}
                      by{" "}
                      <TenantLink
                        entity={undefined}
                        entityId={meta.grantsMeta.issuerTenantId}
                      ></TenantLink>
                      .
                    </Alert>
                  )}

                  {renderIf()
                    .if(canDelete)
                    .then(
                      <>
                        {meta?.grantsMeta && (
                          <Alert severity='warning' key={meta.id}>
                            All <AppLink to={ROUTE_PATH.DATA_GRANTS()}>data grants</AppLink> for
                            this entity will be deleted.
                          </Alert>
                        )}
                        {meta?.tenantRequestsMeta?.tenantRequestIds &&
                          !_.isEmpty(meta?.tenantRequestsMeta.tenantRequestIds) && (
                            <Alert severity='warning' key={meta.id}>
                              This entity is linked to
                              {meta.tenantRequestsMeta.tenantRequestIds.length} tenant{" "}
                              {TextHelper.pluralizeManual(
                                "request",
                                meta.tenantRequestsMeta.tenantRequestIds.length,
                                "requests",
                              )}{" "}
                              (deletion may be forbidden).
                            </Alert>
                          )}
                      </>,
                    )
                    // if entity shared to me
                    .else(
                      <>
                        <Alert severity='error'>You are not allowed to delete this entity.</Alert>
                      </>,
                    )
                    .render()}
                </>
              ))}
            </Stack>
          )}

          {bodyAfter && <Box>{bodyAfter}</Box>}

          {cascadeActionDisplay && (
            <Stack>
              <Stack direction='row'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isDeleteCascade}
                      onChange={(e) => setIsDeleteCascade(e.target.checked)}
                    />
                  }
                  label='Is delete cascade'
                />
                {isDeleteCascade && (
                  <IconButton onClick={() => setIsDeleteCascadeSettingsOpen(true)}>
                    <AppIcon of='settings' />
                  </IconButton>
                )}
              </Stack>

              <CascadeActionSettingsInputModal
                open={isDeleteCascadeSettingsOpen}
                onClose={() => setIsDeleteCascadeSettingsOpen(false)}
                spec={cascadeActionSpec}
                relatedEntitiesTypes={relatedEntitiesTypes}
                onRelatedTypesChange={(newValue) => setRelatedEntitiesTypes(newValue || [])}
              />
            </Stack>
          )}
        </Stack>
      }
      onCancel={() => dialogProps?.onClose && dialogProps.onClose({}, "escapeKeyDown")}
      onConfirm={handleDeleteConfirmed}
      {...dialogProps}
    />
  );
}
