import _ from "lodash";

import { CustomEnv, appCommonConfig } from "@/config/config";

export class EnvHelper {
  public static get customEnv(): CustomEnv {
    return appCommonConfig.customEnv;
  }

  public static get isLocalhostAny() {
    return /^\w+Localhost$/.test(this.customEnv);
  }

  public static get isDevelopmentAny() {
    return /^Development\w+$/.test(this.customEnv);
  }

  public static get isStagingAny() {
    return /^Staging\w+$/.test(this.customEnv);
  }

  public static get isProductionAny() {
    return /^Production\w+$/.test(this.customEnv);
  }
}
