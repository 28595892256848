import AppIcon from "@/common/components/Icons/AppIcon";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { TenantRequestDto, TenantRequestStage } from "@/core/api/generated";
import { Box, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import TenantRequestUpdateResultModal from "./TenantRequestUpdateResultModal";

export interface TenantRequestUpdateResultControlProps {
  tenantRequest: TenantRequestDto;
  onEntityUpdate?: (newValue: TenantRequestDto) => void;
}

export default function TenantRequestUpdateResultControl({
  tenantRequest,
  onEntityUpdate,
}: TenantRequestUpdateResultControlProps) {
  const { enqueueSnackbar } = useSnackbar();
  const currentTenant = useCurrentTenant();
  const isSentByMe = currentTenant?.id === tenantRequest?.senderTenant?.id;
  const isReceivedByMe = currentTenant?.id === tenantRequest?.receiverTenant?.id;
  const isVisible =
    isReceivedByMe &&
    (tenantRequest?.stageHistory?.stage === TenantRequestStage.InProgress ||
      tenantRequest?.stageHistory?.stage === TenantRequestStage.Completed);

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      {isVisible && (
        <Button
          variant='contained'
          color='primary'
          size='small'
          fullWidth
          startIcon={<AppIcon of='add' />}
          onClick={() => setIsModalOpen(true)}
        >
          <Box component='span'>{!tenantRequest.result ? "Add result" : "Update result"}</Box>
        </Button>
      )}

      <TenantRequestUpdateResultModal
        updateProps={{
          tenantRequest: tenantRequest,
          onSave: (newValue) => {
            onEntityUpdate && onEntityUpdate(newValue);
            setIsModalOpen(false);
          },
        }}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
