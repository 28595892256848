import { SxProps, Theme, Typography, TypographyProps } from "@mui/material";
import { forwardRef } from "react";

export interface AppTypographyDecorationProps {
  enabled?: boolean;
  variant?: "normal" | "helpText";
}

export interface AppTypographyEllipsingProps {
  /** Whether ellipsing is enabled. true by default. */
  enabled?: boolean;
  linesToStartEllipsing?: number;
}

const defaultDecoration: AppTypographyDecorationProps = {
  enabled: true,
  variant: "normal",
};

const defaultEllipsing: AppTypographyEllipsingProps = {
  enabled: true,
};

export interface AppTypographyProps extends TypographyProps {
  decoration?: AppTypographyDecorationProps;
  isInheritFontStyle?: boolean;
  ellipsing?: AppTypographyEllipsingProps;
  sx?: SxProps<Theme>;
}

/** Extends MUI's Typography. */
export default forwardRef<HTMLDivElement | HTMLSpanElement, AppTypographyProps>(
  function AppTypography(
    {
      decoration,
      isInheritFontStyle = false,
      ellipsing,
      sx,
      children,
      ...otherProps
    }: AppTypographyProps,
    ref,
  ) {
    decoration = decoration
      ? {
          ...defaultDecoration,
          ...decoration,
        }
      : undefined;
    ellipsing = ellipsing
      ? {
          ...defaultEllipsing,
          ...ellipsing,
        }
      : undefined;

    return (
      <Typography
        ref={ref}
        sx={{
          maxWidth: "100%",
          ...(decoration?.enabled &&
            decoration?.variant === "helpText" && {
              textDecoration: "underline dotted 2px",
              textDecorationColor: (th: Theme) => th.palette.divider,
              "& *": {
                textDecoration: "underline dotted 2px",
                textDecorationColor: (th: Theme) => th.palette.divider,
              },
            }),
          ...(ellipsing?.enabled
            ? ellipsing?.linesToStartEllipsing
              ? {
                  overflow: "hidden",
                  display: "-webkit-box",
                  "-webkit-line-clamp": `${ellipsing?.linesToStartEllipsing}`,
                  "-webkit-box-orient": "vertical",
                }
              : {
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }
            : {}),
          ...(isInheritFontStyle
            ? {
                fontSize: "inherit",
                fontWeight: "inherit",
                fontFamily: "inherit",
                fontStyle: "inherit",
                lineHeight: "inherit",
              }
            : {}),
          ...sx,
        }}
        {...otherProps}
      >
        {children}
      </Typography>
    );
  },
);
