import { useParams } from "react-router-dom";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import VehicleDamageView from "@/common/components/Entity/VehicleDamage/View/VehicleDamageView";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

export interface VehicleDamageViewPageRouteParams {
  vehicleId?: string;
  vehicleDamageId?: string;
}

export type VehicleDamageViewPageQueryParams = GeneralQueryParams;

export default function VehicleDamageViewPage() {
  const { vehicleId, vehicleDamageId } = useParams<VehicleDamageViewPageRouteParams>();
  const queryParams = useQueryParams<VehicleDamageViewPageQueryParams>();

  return (
    <ViewPageLayout>
      <VehicleDamageView
        vehicleId={vehicleId}
        damageId={vehicleDamageId}
        headerProps={{
          withLink: false,
          typographyProps: {
            variant: "h1",
          },
        }}
      />
    </ViewPageLayout>
  );
}
