import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogProps,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  Switch,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import CustomerContactCard from "@/common/components/Entity/Customer/CustomerContactCard";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { useModalOpenStatus } from "@/common/hooks/useModalOpenStatus";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { CustomerDto, InviteCustomerAsUserDto } from "@/core/api/generated";

interface OwnProps {
  customer: CustomerDto;
  onSaved?: () => void;
}

type Props = OwnProps & DialogProps;

export default function InviteCustomerAsUserModal({ customer, onSaved, ...dialogProps }: Props) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const modalOpenStatus = useModalOpenStatus(dialogProps);

  const [selectedContactIdsMap, setSelectedContactIdsMap] = useState<Record<string, boolean>>({});

  const inviteCustomerAsUserRequest = useApiRequest(
    apiClient.tenantCustomerInvitesApi.apiV1CustomersCustomerIdInvitesPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      customerId: customer.id!,
      inviteCustomerAsUserDto: {
        // ..._.omit(values, "submit"),
        contactIds: Object.keys(_.pickBy(selectedContactIdsMap, (value, key) => value === true)),
      },
    },
    {
      skip: !customer?.id,
    },
  );

  useEffect(() => {
    if (modalOpenStatus.isClosing) {
      setSelectedContactIdsMap({});
    }
  }, [modalOpenStatus]);

  return (
    <Dialog {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Invite the customer to become a user in the system
      </AppModalTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Select contacts that receive invite link.
        </DialogContentText>

        <Box>
          <Formik<BaseFormikValues & InviteCustomerAsUserDto>
            enableReinitialize
            initialValues={{
              contactIds: [],
              submit: "",
            }}
            validationSchema={Yup.object().shape({
              // contactIds: Yup.string().required("Required"),
            })}
            onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
              try {
                inviteCustomerAsUserRequest.refetch();
                enqueueSnackbar("Customer invited.", {
                  variant: "success",
                });

                if (mounted.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }

                onSaved && onSaved();
                dialogProps?.onClose && dialogProps.onClose({}, "escapeKeyDown");
              } catch (err) {
                if (mounted.current) {
                  ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                  setStatus({ success: false });
                  setSubmitting(false);
                }
              }

              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setErrors,
              setFieldValue,
              setValues,
            }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <Stack direction='column' spacing={2}>
                    {customer.contacts?.map((contact, index) => (
                      <FormGroup key={index}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={selectedContactIdsMap[contact.id!] || false}
                              onChange={(e) => {
                                setSelectedContactIdsMap({
                                  ...selectedContactIdsMap,
                                  [contact.id!]: !selectedContactIdsMap[contact.id!],
                                });
                              }}
                            />
                          }
                          label={
                            <CustomerContactCard
                              contact={contact}
                              sx={{ width: "100%", minWidth: 300 }}
                            />
                          }
                        />
                      </FormGroup>
                    ))}
                  </Stack>
                  <FormHelperText error={Boolean(errors.contactIds)}>
                    {_.isString(errors.contactIds) && errors.contactIds}
                  </FormHelperText>

                  <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                  <LoadingButton
                    sx={{ flex: 1, mt: 2 }}
                    fullWidth
                    color='primary'
                    loading={isSubmitting}
                    type='submit'
                    variant='contained'
                  >
                    Invite
                  </LoadingButton>
                </form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
