import {
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import AppIcon from "@/common/components/Icons/AppIcon";
import useMounted from "@/common/hooks/mount/useMounted";
import { ValidationHelper } from "@/common/validation";
import {
  SendEmailVerificationTokenDto,
  ValidateEmailVerificationTokenDto,
} from "@/core/api/generated";
import { SavedCompanyRegistrationInfo } from "@/store/auth/slice";
import { useHistory } from "react-router";
import { ROUTE_PATH } from "@/common/constants/routing";
import * as authSlice from "@/store/auth/slice";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";

const StyledForm = styled("form")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.05);",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(3),
  [theme.breakpoints.up("sm")]: {
    width: "30%",
    height: "fit-content",
    borderRadius: theme.shape.borderRadius,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
  },
}));

export default function TenantRegisterEmailVerificationPage() {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const thunkDispatch = useAppThunkDispatch();
  const savedCompanyRegistrationInfo = useAppSelector((x) => x.auth.savedCompanyRegistrationInfo);

  const handleResend = useCallback(async () => {
    try {
      await thunkDispatch(
        authSlice.sendEmailVerificationToken({ email: savedCompanyRegistrationInfo?.email }),
      );
      enqueueSnackbar("Verification code is sent to your email", { variant: "success" });
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [savedCompanyRegistrationInfo]);

  return (
    <Formik
      initialValues={{
        token: "",
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        // token: Yup.string()
        //   .matches(/^\d\d\d\d\d\d$/g, "Token should be in six digit format")
        //   .required("Token is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await thunkDispatch(
            authSlice.validateEmailVerificationToken({
              email: savedCompanyRegistrationInfo?.email,
              token: values.token,
            }),
          );
          history.push(ROUTE_PATH.AUTH_SIGN_UP_TENANT_EMAIL_PASSWORD);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err: Error | any) {
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
      }) => (
        <StyledForm noValidate onSubmit={handleSubmit}>
          <Box
            sx={{
              borderRadius: (theme) => theme.shape.borderRadius,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 2,
              width: "100%",
              mt: "auto",
            }}
          >
            <Typography component='div' variant='h1'>
              {t("auth.emailVerification")}
            </Typography>
          </Box>

          <TextField
            error={Boolean(touched.token && errors.token)}
            fullWidth
            helperText={touched.token && errors.token}
            label={t("auth.token")}
            margin='normal'
            name='token'
            onBlur={handleBlur}
            onChange={handleChange}
            type='text'
            value={values.token}
            variant='outlined'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Tooltip title='Resend code'>
                    <IconButton onClick={handleResend}>
                      <AppIcon of='mail' />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />

          <FormHelperText>Verification code is sent to your email.</FormHelperText>

          <GeneralValidationError sx={{ my: 1 }} errors={errors} />

          <Button
            sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
            color='primary'
            disabled={isSubmitting || !values.token}
            fullWidth
            size='medium'
            type='submit'
            variant='contained'
          >
            {t("common.buttons.submit")}
          </Button>
        </StyledForm>
      )}
    </Formik>
  );
}
