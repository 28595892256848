import {
  Dialog,
  DialogContent,
  DialogProps,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";

import { useModalOpenStatus } from "@/common/hooks/useModalOpenStatus";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { GeneralScopeDto, GeneralScopeUnwoundDto } from "@/core/api/generated";

import AppModalTitle from "../../../Modals/AppModalTitle";
import GeneralScopeDisplay from "./GeneralScopeDisplay";

export interface OwnProps {
  scope: GeneralScopeDto;
  title?: string;
  description?: string;
}

type Props = OwnProps & DialogProps;

export default function GeneralScopeDisplayModal({
  scope,
  title,
  description,
  ...dialogProps
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const modalStatus = useModalOpenStatus(dialogProps);

  const [isLoading, setIsLoading] = useState(false);
  const [scopeUnwound, setScopeUnwound] = useState<GeneralScopeUnwoundDto | undefined>(undefined);

  const getsScopeUnwound = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await apiClient.generalScopesApi.apiV1GeneralScopesGetUnwoundPost({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        generalScopeDto: scope,
      });
      setScopeUnwound(result.data);
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  }, [scope]);
  const getsScopeUnwoundDebounced = useCallback(
    _.debounce(getsScopeUnwound, 500, {
      leading: true,
      trailing: false,
    }),
    [],
  );

  useEffect(() => {
    if (modalStatus.isOpening || (modalStatus.isOpen && !scopeUnwound)) {
      getsScopeUnwoundDebounced();
    }
  }, [modalStatus, getsScopeUnwoundDebounced]);

  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {title || "Scope"}
      </AppModalTitle>
      <DialogContent>
        <Stack spacing={2}>
          {description && (
            <Typography component='div' variant='body2' color='text.secondary'>
              {description || ""}
            </Typography>
          )}

          {isLoading && <LinearProgress />}

          <Box>{scopeUnwound && <GeneralScopeDisplay scope={scopeUnwound} />}</Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
