import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";
import { useMemo } from "react";

import { NegotiationDto } from "@/core/api/generated";

import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import NegotiationView from "./NegotiationView";

export interface OwnProps {
  negotiationId?: string | null;
  negotiation?: NegotiationDto | null;
  onDelete?: () => void;
}

type Props = OwnProps & DialogProps;

export default function NegotiationViewModal({
  negotiationId,
  negotiation,
  onDelete,
  ...dialogProps
}: Props) {
  const request = useApiRequest(
    apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      negotiationId: negotiationId!,
    },
    {
      deps: [negotiationId],
      skip: !negotiationId,
    },
  );

  const _negotiation = useMemo(() => negotiation || request.data, [negotiation, request.data]);

  return (
    <Box>
      <Dialog maxWidth='lg' fullWidth {...dialogProps}>
        {/* <AppModalTitle onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}>Negotiation {_negotiation?.localNumber}</AppModalTitle> */}
        <DialogContent>
          <NegotiationView
            negotiation={_negotiation}
            isLoading={request.isLoading}
            headerProps={{
              withLink: true,
              titleTypographyProps: {
                variant: "h4",
              },
            }}
            onDelete={() => {
              dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown");
              onDelete && onDelete();
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
