import { TextHelper } from "@/common/helpers/text";
import useMounted from "@/common/hooks/mount/useMounted";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { EntityType, IBaseEntityDto } from "@/core/api/generated";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import * as Yup from "yup";
import GeneralValidationError from "../../Error/GeneralValidationError";
import TenantStructureMembershipInputs, {
  TenantStructureMembershipFormikValues,
} from "./TenantStructureMembershipInputs";
import TenantStructureMembershipOfEntityFoldableBlock from "./TenantStructureMembershipOfEntityFoldableBlock";

export interface TenantStructureMembershipOfEntityEditProps<TEntity extends IBaseEntityDto> {
  entities?: TEntity[];
  entityType: EntityType;

  onUpdate?: () => void;
  onSave?: () => void;
}

export default function TenantStructureMembershipOfEntityEdit<
  TEntity extends IBaseEntityDto = IBaseEntityDto,
>({ entities, entityType, onUpdate, onSave }: TenantStructureMembershipOfEntityEditProps<TEntity>) {
  const mounted = useMounted();
  const [isLoading, setIsLoading] = useState(false);
  const entitiesCount = useMemo(() => entities?.length || 0, [entities]);
  const { enqueueSnackbar } = useSnackbar();
  const entitiesIds = useMemo<string[] | undefined>(
    () => entities?.map((x) => x.id!) || [],
    [entities],
  );

  return (
    <Formik<TenantStructureMembershipFormikValues & BaseFormikValues>
      initialValues={{
        relatedEntitiesTypes: [],
        departmentId: undefined,
        locationId: undefined,
        submit: "",
      }}
      validationSchema={Yup.object().shape({})}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        if (isLoading) {
          return;
        }

        try {
          setIsLoading(true);
          const response =
            await apiClient.tenantStructureApi.apiV1TenantStructureMembershipEntityPut({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              tenantMembershipOfEntityUpdateDto: {
                ...values,
                departmentId: values.departmentId || undefined,
                locationId: values.locationId || undefined,
                entityType: entityType,
                entitiesIds: entitiesIds,
                relatedEntitiesTypes: values.relatedEntitiesTypes,
              },
            });
          enqueueSnackbar(
            `${TextHelper.pluralize(entityType, entitiesCount)} added to ${
              values.departmentId ? "department" : ""
            }
            ${values.departmentId && values.locationId ? "and" : ""} ${
              values.locationId ? "location" : ""
            }`,
            {
              variant: "success",
            },
          );
          onUpdate && onUpdate();
          onSave && onSave();
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
          }
        } finally {
          setIsLoading(false);
        }
      }}
    >
      {({ handleSubmit, values, errors }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <Stack spacing={1}>
                {entities?.map((entity, i) => (
                  <TenantStructureMembershipOfEntityFoldableBlock
                    key={i}
                    entityType={entityType}
                    entity={entity}
                  />
                ))}
              </Stack>

              <TenantStructureMembershipInputs entityType={entityType} />

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <LoadingButton
                sx={{ mt: { xs: "auto", md: 2 } }}
                color='primary'
                fullWidth
                variant='contained'
                loading={isLoading}
                disabled={!values.departmentId && !values.locationId}
                onClick={() => handleSubmit()}
              >
                Save
              </LoadingButton>
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
}
