import { TenantResolveStrategy } from "@/common/services/tenant";

export const CustomEnv = {
  DevelopmentLocalhost: "DevelopmentLocalhost",
  DevelopmentAzure: "DevelopmentAzure",
  StagingAzure: "StagingAzure",
  ProductionAzure: "ProductionAzure",
} as const;

export type CustomEnv = (typeof CustomEnv)[keyof typeof CustomEnv];

export const appCommonConfig = {
  appName: "NexusFleet",
  version: process.env.REACT_APP_VERSION || "0.0.0.0",
  customEnv: (process.env.REACT_APP_CUSTOM_ENV || CustomEnv.DevelopmentLocalhost) as CustomEnv,
  apiUrl: process.env.REACT_APP_API_URL,
  nexusOpsPublicDocsUrl: process.env.REACT_APP_NEXUSOPS_PUBLIC_DOCS_URL,
};

export const multitenancyConfig = {
  tenantResolveStrategy: process.env.REACT_APP_TENANT_RESOLVE_STRATEGY as TenantResolveStrategy,
};

const baseSentryConfig = {
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  tracePropagationTargets: [
    new RegExp(`https://.*${process.env.REACT_APP_APP_DOMAIN}.*`),
    new RegExp(`https://.*${process.env.REACT_APP_API_DOMAIN}.*`),
  ],
};

const devSentryConfig = {
  ...baseSentryConfig,
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
};
const stagingSentryConfig = {
  ...baseSentryConfig,
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
};
const prodSentryConfig = {
  ...baseSentryConfig,
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
};

export const sentryConfig =
  appCommonConfig.customEnv === CustomEnv.ProductionAzure
    ? prodSentryConfig
    : appCommonConfig.customEnv === CustomEnv.StagingAzure
    ? stagingSentryConfig
    : devSentryConfig;

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
  domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
  audience: "webapi",
  connections: {
    /** Login via email/password. */
    database: "UsernamePassword",
    /** Passwordless login via email. */
    email: "email",
    /** Passwordless login via phone. */
    sms: "sms",
  },
  defaultConnection: "UsernamePassword",
};

/** Can mirror server-side feature flags */
export const featureFlagsConfig = {
  realtimeDataUpdates: true,
  chatOnlineStatus: true,
  chatActivity: true,
  // chatTypingIndicatorEnabled: true,
  chatMessageReadStatus: true,
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "",
  vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY || "",
};

export const clarityConfig = {
  enabled: !!process.env.REACT_APP_CLARITY_TRACKING_CODE,
  trackingCode: process.env.REACT_APP_CLARITY_TRACKING_CODE || "",
};

/** Query string params that are set either internally or externally.
 *  For instance, by back-end in email, sms links.
 */
export const ExternalQueryStringParamName = {
  /** Absolute URI. */
  RedirectUrl: "redirectUrl",
  /** Value contains base64 encoded JSON object. */
  ActionParamsEnc: "actionParamsEnc",
  /** Value contains base64 encoded JSON object. */
  AuthParamsEnc: "authParamsEnc",
} as const;
export type ExternalQueryStringParamName =
  (typeof ExternalQueryStringParamName)[keyof typeof ExternalQueryStringParamName];

/** Query string params that are set internally in the app. */
export const InternalQueryStringParamName = {
  /** Relative URI (handled by react router). */
  SpaRedirectUrl: "spaRedirectUrl",
  /** Encoded highlight props, used to highlight something on the page on mount. */
  HighlightPropsEnc: "highlightPropsEnc",
  /** Id of active tab. */
  ActiveTabId: "activeTabId",
} as const;
export type InternalQueryStringParamName =
  (typeof InternalQueryStringParamName)[keyof typeof InternalQueryStringParamName];
