import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormHelperText,
  ListItem,
  ListItemText,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
} from "@mui/material";
import _ from "lodash";
import {
  DependencyList,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  AutocompleteOption,
  AutocompleteOptionType,
  EntitiesToAutocompleteOptionsFunc,
  EntityToAutocompleteOptionFunc,
  createOptionForNoOptions,
  createOptionFromInputValue,
} from "@/common/ts/autocomplete";

import { useEffectWithDeepCompare } from "@/common/hooks/effect/useEffectWithDeepCompare";
import { useMemoWithDeepCompare } from "@/common/hooks/memo/useMemoWithDeepCompare";
import { PaginationDtoOfTItemTyped } from "@/common/ts/pagination";
import { IBaseEntityDto } from "@/core/api/generated";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import useInfiniteScroll from "react-infinite-scroll-hook";
import AppIconButton from "../../Button/AppIconButton";
import AppIcon from "../../Icons/AppIcon";

export type AutocompleteSortByFunc<TEntity extends IBaseEntityDto> = (
  option: AutocompleteOption<TEntity>,
) => string;

export type AutocompleteSortOrder = "asc" | "desc";

export type AutocompleteGroupByFunc<TEntity extends IBaseEntityDto> = (
  option: AutocompleteOption<TEntity>,
) => string;

export function sortOptions<TEntity extends IBaseEntityDto>(params: {
  options: AutocompleteOption<TEntity>[];
  sortBy: AutocompleteSortByFunc<TEntity> | null | undefined;
  sortOrder: AutocompleteSortOrder | null | undefined;
}) {
  return _.chain(params.options)
    .groupBy((x) => x.groupBy)
    .mapValues((v, k) =>
      params.sortBy
        ? _.orderBy(v, (x) => params.sortBy && params.sortBy(x), params.sortOrder || "asc")
        : v,
    )
    .values()
    .flatten()
    .value();
}

export interface BaseRequestParams {
  limit?: number;
  search?: string;
  includeIds?: string[];
}

export const baseRequestParamsNamesMap: Record<keyof BaseRequestParams, boolean> = {
  limit: true,
  search: true,
  includeIds: true,
};
export const baseRequestParamsNames: string[] = Object.keys(baseRequestParamsNamesMap);

export type EntitySearchResponseData<TEntity> = TEntity[] | PaginationDtoOfTItemTyped<TEntity>;

export type EntitySearchRequestFuncParameters<
  TEntity,
  TRequestFunc extends (
    requestParameters: any,
    options?: AxiosRequestConfig,
  ) => Promise<AxiosResponse<EntitySearchResponseData<TEntity>>>,
> = Parameters<TRequestFunc>[0] & BaseRequestParams;

export interface BaseEntitySearchAutocompleteProps<
  TEntity extends IBaseEntityDto,
  TRequestFunc extends (...args: any) => Promise<AxiosResponse<EntitySearchResponseData<TEntity>>>,
  TRequestParams extends EntitySearchRequestFuncParameters<
    TEntity,
    TRequestFunc
  > = EntitySearchRequestFuncParameters<TEntity, TRequestFunc>,
> {
  // generic props
  entityId?: string | null;
  entity?: TEntity | null;
  entityToOption: EntityToAutocompleteOptionFunc<TEntity>;
  /** Load data even if the autocomplete is closed.*/
  isPreload?: boolean;
  /** Auto-select option if it's the single option in the list and the input is required. */
  isAutoSelectSingleOption?: boolean;
  request: {
    requestFunc: TRequestFunc;
    limit?: number;
    parameters: TRequestParams;
    combineParameters: (params: TRequestParams, newParams: BaseRequestParams) => TRequestParams;
    deps: DependencyList;
    skip?: boolean;
  };

  // component props
  renderOption: (
    props: HTMLAttributes<HTMLLIElement> & { key: string },
    option: AutocompleteOption<TEntity>,
  ) => ReactNode;
  disabled?: boolean;
  required?: boolean;
  size?: "small" | "medium";
  fullWidth?: boolean;
  label?: TextFieldProps["label"];
  /** If `true`, hide the selected options from the list box. */
  filterSelectedOptions?: boolean;
  placeholder: TextFieldProps["placeholder"];
  sortBy?: AutocompleteSortByFunc<TEntity>;
  sortOrder?: AutocompleteSortOrder;
  groupBy?: AutocompleteGroupByFunc<TEntity>;
  withCreate?: boolean;
  createOptionTitle?: string | ((inputValue: string) => string) | null;
  textFieldProps?: TextFieldProps;
  onChange?: (newEntity?: TEntity) => void;
  /** Works only if withCreate is true. */
  onCreate?: (newOption?: AutocompleteOption<TEntity>) => void;
  /** Fired when new entities were fetched. */
  onLoaded?: (entities: TEntity[]) => void;
  sx?: SxProps<Theme>;
}

/** Interface to be used by wrapper components that utilize this component. */
export interface BaseEntitySearchAutocompleteInheritableProps<TEntity extends IBaseEntityDto> {
  entityId?: string | null;
  entity?: TEntity | null;
  /** Load data even if the autocomplete is closed.*/
  isPreload?: boolean;
  /** Auto-select option if it's the single option in the list and the input is required. */
  isAutoSelectSingleOption?: boolean;
  disabled?: boolean;
  required?: boolean;
  size?: "small" | "medium";
  fullWidth?: boolean;
  label?: TextFieldProps["label"];
  /** If `true`, hide the selected options from the list box. */
  filterSelectedOptions?: boolean;
  withCreate?: boolean;
  createOptionTitle?: string | ((inputValue: string | null | undefined) => string) | null;
  textFieldProps?: TextFieldProps;
  onChange?: (newEntity?: TEntity) => void;
  /** Works only if withCreate is true. */
  onCreate?: (newOption?: AutocompleteOption<TEntity>) => void;
  /** Fired when new entities were fetched. */
  onLoaded?: (entities: TEntity[]) => void;
}
export default function BaseEntitySearchAutocomplete<
  TEntity extends IBaseEntityDto,
  TRequestFunc extends (
    requestParameters: any,
    options?: AxiosRequestConfig,
  ) => Promise<AxiosResponse<EntitySearchResponseData<TEntity>>>,
  TRequestParams extends EntitySearchRequestFuncParameters<
    TEntity,
    TRequestFunc
  > = EntitySearchRequestFuncParameters<TEntity, TRequestFunc>,
>({
  entityId,
  entity,
  entityToOption,
  isPreload = true,
  isAutoSelectSingleOption = true,
  request,
  renderOption,
  disabled,
  required,
  size,
  fullWidth,
  filterSelectedOptions = false,
  label,
  placeholder,
  sortBy,
  sortOrder,
  groupBy,
  withCreate,
  createOptionTitle,
  textFieldProps,
  onChange,
  onCreate,
  onLoaded,
  sx,
  ...otherProps
}: BaseEntitySearchAutocompleteProps<TEntity, TRequestFunc>) {
  const [open, setOpen] = useState(false);
  const [hasPermission, setHasPermissions] = useState(true);
  const [options, setOptions] = useState<readonly AutocompleteOption<TEntity>[]>([]);
  const [selectedOption, setSelectedOption] = useState<AutocompleteOption<TEntity> | null>(null);

  const [isTextFieldHovered, setIsTextFieldHovered] = useState<boolean>(false);
  const isRequestStarted = useRef(false);
  const [inputValue, setInputValue] = useState<string | null | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResponseData, setSearchResponseData] = useState<
    EntitySearchResponseData<TEntity> | undefined
  >(undefined);
  const currentLimitRef = useRef<number | undefined>(request.limit);

  const isSelectedOptionLoaded = !entityId || (entityId && options.some((x) => x.id === entityId));
  const canLoad =
    ((isPreload || (!isPreload && open)) && !selectedOption) || !isSelectedOptionLoaded;

  const optionsMap = useMemo(
    () =>
      options &&
      _.chain(options)
        .keyBy((x) => x.id)
        .mapValues((x) => x),
    [options],
  );
  const entityList = useMemo(
    () => (searchResponseData && _.isArray(searchResponseData) ? searchResponseData : undefined),
    [searchResponseData],
  );
  const paginatedEntities = useMemo(
    () => (searchResponseData && !_.isArray(searchResponseData) ? searchResponseData : undefined),
    [searchResponseData],
  );
  const entityListComputed = useMemo(
    () => entityList || paginatedEntities?.items || undefined,
    [entityList, paginatedEntities],
  );

  const entitiesToOptions = useMemo<EntitiesToAutocompleteOptionsFunc<TEntity>>(
    () => (entities) => (entities && entities.map((x) => entityToOption(x))) || [],
    [entityToOption],
  );

  const computeParameters = useCallback(
    (limit?: number, searchValue?: string | null, id?: string | null) => {
      const baseSearchParams: BaseRequestParams = {
        limit: limit,
        search: searchValue || undefined,
        includeIds: id ? [id] : undefined,
      };
      return request.combineParameters(request.parameters, baseSearchParams);
    },
    [request.parameters],
  );

  // compute request params
  const parametersComputed = useMemoWithDeepCompare(() => {
    return computeParameters(currentLimitRef.current, inputValue, entityId);
  }, [entityId, inputValue, request.parameters, currentLimitRef.current]);

  const searchRef = useRef(request.requestFunc);
  const searchComputed = useCallback(async (requestParams: TRequestParams) => {
    if (isRequestStarted.current) {
      return;
    }
    isRequestStarted.current = true;
    setIsLoading(true);
    try {
      const response = await searchRef.current(requestParams);
      setSearchResponseData(response.data);
    } catch (e) {
      const statusCode = (e as AxiosError).response?.status;
      if (statusCode === 403 || statusCode === 401) {
        setHasPermissions(false);
      }
    } finally {
      isRequestStarted.current = false;
      setIsLoading(false);
    }
  }, []);
  const searchThrottle = useCallback(
    _.throttle(searchComputed, 500, { leading: true, trailing: false }),
    [searchComputed],
  );
  const searchDebounce = useCallback(
    _.debounce(searchComputed, 500, { leading: false, trailing: true }),
    [searchComputed],
  );

  useEffectWithDeepCompare(() => {
    if (!canLoad) return;
    if (request.skip) return;
    if (isRequestStarted.current) {
      return;
    }
    currentLimitRef.current = request.limit;
    const params = computeParameters(currentLimitRef.current, inputValue, entityId);
    searchThrottle(params);
  }, [canLoad, request.skip, ...request.deps]);

  useEffect(() => {
    if (!canLoad) return;
    if (request.skip) return;
    if (isRequestStarted.current) {
      return;
    }
    currentLimitRef.current = request.limit;
    const params = computeParameters(currentLimitRef.current, inputValue, entityId);
    searchDebounce(params);
  }, [inputValue]);

  useEffect(() => {
    if (entityListComputed) {
      onLoaded && onLoaded(entityListComputed);
    }
  }, [entityListComputed]);

  // construct new options
  useEffect(() => {
    let newOptions = _.uniqBy(
      [...(selectedOption ? [selectedOption] : []), ...entitiesToOptions(entityListComputed || [])],
      (x) => x.id,
    );
    newOptions = sortOptions({ options: newOptions, sortBy, sortOrder });
    setOptions(newOptions);

    // handle isAutoSelectSingleOption
    const singleOption = newOptions.length === 1 ? newOptions[0] : undefined;
    if (
      isAutoSelectSingleOption &&
      required &&
      !isRequestStarted.current &&
      !isLoading &&
      !entityId &&
      !entity &&
      !selectedOption &&
      !inputValue &&
      singleOption
    ) {
      setSelectedOption(singleOption);
      onChange && onChange(singleOption.data || undefined);
    }
  }, [entityListComputed]);

  useEffect(() => {
    if (!entity && !entityId && selectedOption) {
      setSelectedOption(null);
      setInputValue(undefined);
    } else if (entity && selectedOption?.id !== entity.id) {
      setOptions(
        sortOptions({
          options: _.uniqBy([entityToOption(entity), ...options], (x) => x.id),
          sortBy,
          sortOrder,
        }),
      );
      setSelectedOption(entityToOption(entity));
    } else if (entityId && selectedOption?.id !== entityId) {
      setSelectedOption(options.find((x) => x.id === entityId) || null);
    }
  }, [entityId, entity, selectedOption, options]);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage:
      (paginatedEntities?.pagination?.limit || 0) <
      (paginatedEntities?.pagination?.totalCount || 0),
    onLoadMore: () => {
      currentLimitRef.current = (currentLimitRef.current || 0) + (request.limit || 0);
      searchComputed(parametersComputed);
    },
    rootMargin: "0px 0px 300px 0px",
  });

  const sentryOption: AutocompleteOption<TEntity> = useMemo(
    () => ({ id: "sentry", title: "", optionType: AutocompleteOptionType.Sentry }),
    [],
  );

  return (
    <Autocomplete
      sx={{ minWidth: 200, flex: 1, ...sx }}
      fullWidth={fullWidth}
      size={size}
      disabled={disabled || !hasPermission}
      filterSelectedOptions={filterSelectedOptions}
      open={open}
      options={options || []}
      loading={isLoading}
      autoComplete
      openOnFocus
      blurOnSelect
      includeInputInList
      value={selectedOption}
      inputValue={inputValue || ""}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo={withCreate}
      ListboxProps={{ ref: rootRef }}
      groupBy={groupBy}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => {
        // value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // regular option
        return option.title;
      }}
      filterOptions={(options2, params) => {
        // suggest the creation of a new value (add dynamic option with input value)
        if (withCreate) {
          const isExisting = options.some((option) => params.inputValue === option.title);
          if (params.inputValue !== "" && !isExisting) {
            const title =
              (createOptionTitle &&
                ((_.isFunction(createOptionTitle) && createOptionTitle(params.inputValue)) ||
                  (_.isString(createOptionTitle) && createOptionTitle))) ||
              `Create new "${params.inputValue}"`;

            options2.unshift(
              createOptionFromInputValue({
                inputValue: params.inputValue,
                title: title,
              }),
            );
          }
        }

        if (
          !_.isEmpty(options2) &&
          (paginatedEntities?.pagination?.limit || 0) <
            (paginatedEntities?.pagination?.totalCount || 0)
        ) {
          // add sentry option for infinite scroll
          options2.push(sentryOption);
        }

        // add 'No data' option
        if (_.isEmpty(options2) && !isLoading) {
          options2.push(createOptionForNoOptions());
        }

        return options2;
      }}
      onChange={(e, newValue) => {
        // value selected with enter, right from the input
        if (typeof newValue === "string") {
          withCreate &&
            onCreate &&
            onCreate(
              createOptionFromInputValue({
                inputValue: newValue,
              }),
            );
          return;
        } else if (newValue && newValue.inputValue) {
          // create a new value from the user input
          withCreate && onCreate && onCreate(newValue);
          return;
        } else {
          setSelectedOption(newValue || null);
          onChange && onChange(newValue?.data || undefined);
        }
      }}
      onInputChange={(e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <FormControl fullWidth error={textFieldProps?.error ?? !hasPermission}>
          <TextField
            {...textFieldProps}
            {...params}
            error={textFieldProps?.error ?? !hasPermission}
            required={required}
            label={label || textFieldProps?.label}
            placeholder={placeholder || textFieldProps?.placeholder}
            fullWidth
            onMouseEnter={() => setIsTextFieldHovered(true)}
            onMouseLeave={() => setIsTextFieldHovered(false)}
            InputProps={{
              ...textFieldProps?.InputProps,
              ...params.InputProps,
              startAdornment: textFieldProps?.InputProps?.startAdornment,
              endAdornment: (
                <>
                  {textFieldProps?.InputProps?.endAdornment || (
                    <>
                      {isLoading ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : (
                        <AppIconButton
                          title='refresh'
                          // using visibility like Mui clear button
                          sx={{ visibility: isTextFieldHovered ? "visible" : "hidden" }}
                          disabled={disabled || !hasPermission}
                          size='small'
                        >
                          <AppIcon
                            of='refresh'
                            onClick={() => searchComputed(parametersComputed)}
                          />
                        </AppIconButton>
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  )}
                </>
              ),
            }}
          />
          {!hasPermission && (
            <FormHelperText error>{`You don't have enough permission`}</FormHelperText>
          )}
        </FormControl>
      )}
      renderOption={(props, option) => {
        if (option.optionType === AutocompleteOptionType.Sentry) {
          return (
            <ListItem ref={sentryRef}>
              <ListItemText>Loading...</ListItemText>
            </ListItem>
          );
        }
        return renderOption(
          {
            ...props,
            ...(option.optionType === AutocompleteOptionType.NoOptions
              ? {
                  style: {
                    ...props?.style,
                    cursor: "default",
                  },
                }
              : undefined),
            key: option.id,
          },
          option,
        );
      }}
      {...otherProps}
    />
  );
}
