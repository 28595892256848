import {
  Box,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import { ReactNode, useMemo, useState } from "react";

import { ROUTE_PATH } from "@/common/constants/routing";
import { FileItem } from "@/common/fileItem";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, EntityType, VehicleDamageDto } from "@/core/api/generated";

import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import _ from "lodash";
import { useHistory } from "react-router";
import EntityNotFoundAlert from "../../../AppAlerts/EntityNotFoundAlert";
import AuthorizedElement from "../../../Auth/AuthorizedElement";
import Datetime from "../../../Datetime/Datetime";
import FoldableBlock from "../../../Display/FoldableBlock";
import FileListView from "../../../Files/FileListView";
import FieldValue from "../../../Form/Display/FieldValue";
import AppIcon from "../../../Icons/AppIcon";
import { DamagePointsViewer } from "../../../Images/DamagePointsViewer";
import AppLink from "../../../Link/AppLink";
import MenuWithTrigger from "../../../Menu/MenuWithTrigger";
import ContractLink from "../../Contract/ContractLink";
import DamageDetectionLink from "../../DamageDetection/DamageDetectionLink";
import GeneralInspectorDisplay from "../../General/Display/GeneralInspectorDisplay";
import { RepairOperationAddVehicleDamagesModal } from "../../RepairOperation/RepairOperationAddVehicleDamagesModal";
import VehicleBlock from "../../Vehicle/VehicleBlock";
import VehicleVisualModelLink from "../../VehicleVisualModel/VehicleVisualModelLink";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import VehicleDamageDeleteModal from "../VehicleDamageDeleteModal";
import VehicleDamageMigrateToNewVisualModelModal from "../VehicleDamageMigrateToNewVisualModelModal";
import { VehicleDamageStatus } from "../VehicleDamageStatus";

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as unknown as Partial<TypographyProps<"div">> | undefined,
  title: undefined as ReactNode | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  vehicle: true,
  inspector: true,
  damageDetection: true,
  contract: true,
  visualModel: true,
};

interface OwnProps extends BaseEntityViewInheritableProps<VehicleDamageDto> {
  vehicleId: string | null | undefined;
  damageId?: string | null;
  damage?: VehicleDamageDto;
  headerProps?: Partial<typeof defaultHeaderProps>;
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export type VehicleDamageViewProps = OwnProps;

export default function VehicleDamageView({
  vehicleId,
  damageId,
  damage,
  headerProps = defaultHeaderProps,
  displayProps = defaultDisplayProps,
}: VehicleDamageViewProps) {
  displayProps = { ...defaultDisplayProps, ...displayProps };

  const history = useHistory();

  const [isAddToRepairOperationModalOpen, setIsAddToRepairOperationModalOpen] = useState(false);
  const [isMigrateToNewVisualModelModalOpen, setIsMigrateToNewVisualModelModalOpen] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const request = useApiRequest(
    apiClient.vehicleDamagesApi.apiV1VehiclesVehicleIdDamagesVehicleDamageIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: vehicleId!,
      vehicleDamageId: damageId!,
    },
    {
      skip: !!damage || !vehicleId || !damageId,
    },
  );
  const vehicleDamage = damage || request?.data;

  const visualModelRequest = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsModelIdGet,
    {
      modelId: vehicleDamage?.visualModel?.id || "",
    },
    {
      deps: [vehicleDamage?.visualModel?.id],
      skip: !vehicleDamage?.visualModel?.id,
    },
  );
  const visualModel = visualModelRequest.data;

  const oldVisualModelRequest = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsModelIdGet,
    {
      modelId: vehicleDamage?.oldVisualModel?.id || "",
    },
    {
      deps: [vehicleDamage?.oldVisualModel?.id],
      skip: !vehicleDamage?.oldVisualModel?.id,
    },
  );
  const oldVisualModel = oldVisualModelRequest.data;

  const visualModelImageUrl = useMemo(
    () =>
      visualModel?.images?.find((model) => model.projection === vehicleDamage?.projection)?.file
        ?.url,
    [vehicleDamage, visualModel],
  );
  const oldVisualModelImageUrl = useMemo(
    () =>
      oldVisualModel?.images?.find((model) => model.projection === vehicleDamage?.projection)?.file
        ?.url,
    [vehicleDamage, oldVisualModel],
  );

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumbs:
      (displayProps?.breadcrumbs &&
        vehicleDamage && [
          {
            idValue: vehicleDamage.id!,
            newTitle: vehicleDamage.localNumber || "",
          },
          {
            idValue: vehicleDamage.vehicle?.id || "",
            newTitle: vehicleDamage.vehicle?.localNumber || "",
          },
        ]) ||
      undefined,
  });

  if (request.isLoading) {
    return <LinearProgress />;
  }

  if (!vehicleDamage) {
    return <EntityNotFoundAlert />;
  }

  return (
    <BaseEntityView
      entityType={EntityType.VehicleDamage}
      entityId={damageId}
      entity={vehicleDamage}
      entityRequest={request}
    >
      <Stack spacing={2}>
        {displayProps?.header && (
          <Stack direction='row' spacing={1} sx={{ alignItems: "flex-start", mb: 2 }}>
            <Typography
              component='div'
              variant='h1'
              sx={{ display: "flex", alignItems: "center" }}
              {...headerProps?.typographyProps}
            >
              {headerProps?.title || (
                <>
                  <span>Vehicle damage </span>&nbsp;
                  <AppLink
                    enabled={headerProps?.withLink || false}
                    to={ROUTE_PATH.VEHICLE_DAMAGE_VIEW({
                      vehicleId: vehicleDamage.vehicle?.id || undefined,
                      vehicleDamageId: vehicleDamage.id || undefined,
                    })}
                  >
                    <span>{vehicleDamage.localNumber}</span>
                  </AppLink>
                </>
              )}{" "}
              <EntityChipList entity={vehicleDamage} variant='normal' sx={{ ml: 0.5 }} />
            </Typography>

            <Stack direction='row' spacing={1} sx={{ flex: 1, justifyContent: "flex-end" }}>
              <AuthorizedElement permissions={[AppPermission.RepairOperationManage]}>
                <MenuWithTrigger
                  withAuthCloseOnClick
                  trigger={
                    <IconButton sx={{ ml: "auto" }}>
                      <AppIcon of='moreVert' />
                    </IconButton>
                  }
                >
                  <AuthorizedMenuItem
                    permissions={[AppPermission.RepairOperationManage]}
                    disabled={!vehicleDamage.id}
                    onClick={() => {
                      setIsAddToRepairOperationModalOpen(true);
                    }}
                  >
                    <ListItemIcon>
                      <AppIcon of='add' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Add to repair operation</ListItemText>
                  </AuthorizedMenuItem>
                  <AuthorizedMenuItem
                    permissions={[AppPermission.VehicleDamageManage]}
                    disabled={!vehicleDamage.id}
                    onClick={() => {
                      setIsMigrateToNewVisualModelModalOpen(true);
                    }}
                  >
                    <ListItemIcon>
                      <AppIcon of='edit' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Migrate to new visual model</ListItemText>
                  </AuthorizedMenuItem>
                  <AuthorizedMenuItem
                    permissions={[AppPermission.VehicleDamageManage]}
                    disabled={!vehicleDamage.id}
                    onClick={() => {
                      setIsDeleteModalOpen(true);
                    }}
                  >
                    <ListItemIcon>
                      <AppIcon of='delete' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </AuthorizedMenuItem>
                </MenuWithTrigger>
              </AuthorizedElement>
            </Stack>
          </Stack>
        )}

        <Stack direction='column' spacing={1}>
          {displayProps?.vehicle && (
            <VehicleBlock
              variant='reference'
              vehicle={vehicleDamage.vehicle}
              withDetailsToggle
              withLink
            />
          )}

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xxs: "1fr",
                lg: "3fr 2fr",
              },
              columnGap: 2,
              rowGap: 2,
            }}
          >
            <Stack spacing={1}>
              <Typography variant='h2'>Details</Typography>

              {vehicleDamage.localNumber && (
                <FieldValue label='Local number'>{vehicleDamage.localNumber}</FieldValue>
              )}
              <FieldValue label='Part type'>{vehicleDamage.partType?.name}</FieldValue>
              <FieldValue label='Damage type'>{vehicleDamage.damageType?.name}</FieldValue>
              <FieldValue label='Projection'>{vehicleDamage.projection}</FieldValue>
              <FieldValue label='Area'>{vehicleDamage.area}</FieldValue>
              <FieldValue label='State' isEmpty={!vehicleDamage.state}>
                <VehicleDamageStatus vehicleDamage={vehicleDamage} />
              </FieldValue>
              {vehicleDamage.inspectedAt && (
                <FieldValue label='Inspection date'>
                  <Datetime datetime={vehicleDamage.inspectedAt} withDurationFromNow />
                </FieldValue>
              )}
              {displayProps.inspector && vehicleDamage.inspector && (
                <FieldValue label='Inspector'>
                  <GeneralInspectorDisplay
                    inspector={vehicleDamage.inspector}
                    inlineUserProps={{
                      withAvatar: true,
                      withLink: true,
                    }}
                  />
                </FieldValue>
              )}

              {displayProps?.damageDetection && (
                <FieldValue label='Damage Detection' isEmpty={!vehicleDamage?.damageDetection}>
                  <DamageDetectionLink entity={vehicleDamage?.damageDetection} />
                </FieldValue>
              )}
              {displayProps?.contract && (
                <FieldValue label='Contract' isEmpty={!vehicleDamage?.contract}>
                  <ContractLink entity={vehicleDamage?.contract} />
                </FieldValue>
              )}
              {vehicleDamage.notes && <FieldValue label='Notes'>{vehicleDamage.notes}</FieldValue>}
              {!_.isEmpty(vehicleDamage.attachments) && (
                <Box sx={{ mt: 2 }}>
                  <FileListView
                    files={FileItem.createManyFrom(vehicleDamage.attachments)}
                    maxVisibleFiles={10}
                    itemWidth={100}
                    itemHeight={100}
                  />
                </Box>
              )}
            </Stack>

            <Box>
              {/* Visual model & damage */}
              {displayProps?.visualModel && (
                <Stack spacing={1}>
                  <Typography variant='h2'>Visual model</Typography>

                  <Box>
                    {visualModelRequest.isLoading && <LinearProgress />}

                    {visualModel && (
                      <Box>
                        <VehicleVisualModelLink entity={visualModel} withIcon withTooltip />
                      </Box>
                    )}

                    {visualModel && visualModelImageUrl && (
                      <DamagePointsViewer
                        imageUrl={visualModelImageUrl}
                        containerSx={{
                          height: `400px`,
                          width: `400px`,
                        }}
                        pointsInfos={[vehicleDamage]}
                        onPointClicked={() => {
                          return;
                        }}
                      />
                    )}
                  </Box>

                  {oldVisualModel && (
                    <FoldableBlock
                      defaultIsFolded
                      trigger={{
                        label: <Typography variant='subtitle1'>View old visual model</Typography>,
                      }}
                    >
                      <Box>
                        {oldVisualModelRequest.isLoading && <LinearProgress />}
                        {oldVisualModel && oldVisualModelImageUrl && (
                          <DamagePointsViewer
                            imageUrl={oldVisualModelImageUrl}
                            containerSx={{
                              height: `400px`,
                              width: `400px`,
                            }}
                            pointsInfos={[{ ...vehicleDamage, point: vehicleDamage?.oldPoint }]}
                            onPointClicked={() => {
                              return;
                            }}
                          />
                        )}
                      </Box>
                    </FoldableBlock>
                  )}
                </Stack>
              )}
            </Box>
          </Box>
        </Stack>

        {/* Add to repair operation */}
        {vehicleDamage && (
          <RepairOperationAddVehicleDamagesModal
            open={isAddToRepairOperationModalOpen}
            onClose={() => setIsAddToRepairOperationModalOpen(false)}
            damages={[vehicleDamage]}
          />
        )}

        {/* Migrate to new visual model */}
        {vehicleDamage && (
          <VehicleDamageMigrateToNewVisualModelModal
            open={isMigrateToNewVisualModelModalOpen}
            onClose={() => setIsMigrateToNewVisualModelModalOpen(false)}
            migrateProps={{
              vehicleDamage: vehicleDamage,
              onSave: (newValue) => {
                request.replaceData(newValue);
                setIsMigrateToNewVisualModelModalOpen(false);
              },
            }}
          />
        )}

        {/* Delete modal */}
        {vehicleDamage && (
          <VehicleDamageDeleteModal
            open={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            entity={vehicleDamage}
            onDelete={() => {
              setIsDeleteModalOpen(false);
              request.replaceData(null);
              history.goBack();
            }}
          />
        )}
      </Stack>
    </BaseEntityView>
  );
}
