import { Box } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import GeneralEventLogsEmbeddedView from "@/common/components/Entity/GeneralEventLog/GeneralEventLogsEmbeddedView";
import { ContractDto } from "@/core/api/generated";

interface Props {
  contract: ContractDto;
}
export default function EventLogTabContent({ contract }: Props) {
  return (
    <PageTabContent>
      <Box>
        <GeneralEventLogsEmbeddedView
          entityId={contract.id!}
          displayProps={{
            header: false,
            filters: false,
            pagination: true,
            viewVariant: ViewLayoutVariant.Tab,
          }}
          headerProps={{
            withLink: true,
            title: <PageTabHeader title={"Event log"} />,
          }}
        />
      </Box>
    </PageTabContent>
  );
}
