import { entityAffiliationEventEmitter } from "@/common/eventEmmiters/entityAffiliationEventEmitter";
import { EntityHelper } from "@/common/helpers/entity";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { apiClient } from "@/core/api/ApiClient";
import { IBaseEntityDto, PoolItemEntityType } from "@/core/api/generated";
import { Chip, Stack } from "@mui/material";
import _ from "lodash";
import { ReactNode, useEffect, useMemo } from "react";
import NoDataAlert from "../AppAlerts/NoDataAlert";
import PoolLink from "../Entity/Pool/PoolLink";
import AppPopover from "../Popover/AppPopover";
import AppPopoverContent from "../Popover/AppPopoverContent";
import TextLineSkeleton from "../Skeleton/TextLineSkeleton";

interface Props {
  entityType: PoolItemEntityType;
  entity: IBaseEntityDto | null | undefined;
  /** If entity has Asset then also display Pools to which the Asset belongs. */
  isIncludeAssetPools?: boolean;
  noDataPlaceholder?: ReactNode;
}

/** Shows all entity pools and asset pools if entity has asset. */
export default function EntityPoolsInfoDisplay({
  entityType,
  entity,
  isIncludeAssetPools,
  noDataPlaceholder,
}: Props) {
  const entityMeta = useMemo(() => EntityHelper.getEntityMeta(entity), [entity]);
  const entityPoolsIds = useMemo(() => entityMeta?.poolsMeta?.poolIds, [entityMeta]);
  const currentTenant = useCurrentTenant();

  // fetch if need Pools for Assets as we don't have info about Asset Pools locally.
  const allPoolsForEntityRequest = useApiRequest(
    apiClient.poolsApi.apiV1PoolsForEntityAllGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      entityType: entityType,
      entityId: entity?.id || "",
      isIncludeAssetPools: true,
    },
    {
      deps: [entityType, entity?.id, isIncludeAssetPools],
      skip: !entity?.id || !isIncludeAssetPools,
    },
  );
  const allPoolsForEntity = allPoolsForEntityRequest?.data;

  useEffect(() => {
    const sub = entityAffiliationEventEmitter.on2(
      "entityPoolAffiliationChanged",
      (poolItem) => poolItem.entity?.entityId === entity?.id && allPoolsForEntityRequest.refetch(),
    );
    return () => sub.off();
  }, []);

  const assetPools = useMemo(() => allPoolsForEntity?.assetPools, [allPoolsForEntity]);
  const assetPoolsIds = useMemo(() => assetPools?.map((x) => x.id), [assetPools]);
  const allPoolIds = useMemo(
    () => _.concat(entityPoolsIds || [], assetPoolsIds || []),
    [entityPoolsIds, assetPoolsIds],
  );

  if (allPoolsForEntityRequest.isLoading && !allPoolsForEntityRequest.isEnded) {
    return <TextLineSkeleton />;
  }
  return (
    <>
      {_.isEmpty(allPoolIds) && (noDataPlaceholder || <NoDataAlert title='No pools' />)}

      {!_.isEmpty(allPoolIds) && (
        <AppPopover
          hoverBehavior={{
            closeBehavior: "onTriggerOrContentMouseLeave",
          }}
          trigger={
            <Chip
              variant='outlined'
              color='secondary'
              size='small'
              label={allPoolIds?.length ?? 0}
            />
          }
          contentSx={{
            minWidth: {
              xxs: "95vw",
              md: 200,
            },
            maxWidth: {
              xxs: "95vw",
              md: 300,
            },
          }}
        >
          <AppPopoverContent>
            <Stack>
              {entityPoolsIds?.map((id, i) => (
                <PoolLink key={i} entity={undefined} entityId={id} />
              ))}
              {assetPools?.map((pool, i) => <PoolLink key={i} entity={pool} entityId={pool.id} />)}
            </Stack>
          </AppPopoverContent>
        </AppPopover>
      )}
    </>
  );
}
