import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import _ from "lodash";
import AppTooltip, { AppTooltipProps } from "../AppTooltip";
import { forwardRef } from "react";

export interface AppButtonProps extends LoadingButtonProps {
  tooltipProps?: Omit<AppTooltipProps, "children">;
}

/** Extends MUI IconButton. */
export default forwardRef<HTMLButtonElement, AppButtonProps>(function AppButton(
  { tooltipProps, children, ...props }: AppButtonProps,
  ref,
) {
  return (
    <AppTooltip
      enabled={!_.isNil(tooltipProps)}
      title={tooltipProps?.title || ""}
      {...tooltipProps}
    >
      <LoadingButton
        ref={ref}
        sx={{
          ...props?.sx,
        }}
        {...props}
      >
        {children}
      </LoadingButton>
    </AppTooltip>
  );
});
