import { useParams } from "react-router-dom";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import ContractView from "@/common/components/Entity/Contract/View/ContractView";

export default function ContractViewPage() {
  const { contractId } = useParams<{ contractId?: string }>();

  return (
    <>
      <ContractView
        contractId={contractId}
        contract={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
