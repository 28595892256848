import DoneAllIcon from "@mui/icons-material/DoneAll";
import {
  AvatarGroup,
  Box,
  CircularProgress,
  FormHelperText,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { DatetimeHelper } from "@/common/helpers/datetime";
import { useChatParticipants } from "@/common/hooks/communication/useChatParticipants";
import { chatMessageService } from "@/common/services/chatMessage";
import { ValidationHelper, ValidationInfo } from "@/common/validation";
import {
  AppPermission,
  ChatDto,
  ChatHistoryItemDto,
  ChatMessageRootNodeDto,
  ChatParticipantDto,
  ChatSettingsDto,
  TagEntityType,
} from "@/core/api/generated";
import * as chatHistorySlice from "@/store/communication/chatHistorySlice";
import * as chatMessagesSlice from "@/store/communication/chatMessagesSlice";

import AppVisibilitySensor from "../../AppVisibilitySensor";
import AuthorizedElement from "../../Auth/AuthorizedElement";
import FoldingTagList from "../General/GeneralTag/FoldingTagList";
import GeneralTagDisplay from "../General/GeneralTag/GeneralTagDisplay";
import GeneralAttachedTagsOfEntityEditModal from "../General/GeneralTag/GeneralAttachedTagsOfEntityEditModal";
import ChatParticipantAvatar from "../ChatParticipant/ChatParticipantAvatar";
import ChatParticipantListPopover from "../ChatParticipant/ChatParticipantListPopover";
import ChatMessageAttachments from "./Attachments/ChatMessageAttachments";
import ChatMessageBodyView from "./ChatMessageBodyView";
import ChatMessageReadByInfoModal from "./ChatMessageReadByInfoModal";
import ChatMessageInput from "./Input/ChatMessageInput";
import GeneralAttachedTagsDisplay from "../General/GeneralTag/GeneralAttachedTagsDisplay";
import _ from "lodash";
import ChatParticipantsModal from "../ChatParticipant/ChatParticipantsModal";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import AuthorizedMenuItem from "../../Auth/AuthorizedMenuItem";

interface OwnProps {
  chat: ChatDto;
  item: ChatHistoryItemDto;
  chatSettings?: ChatSettingsDto;
  currentParticipant?: ChatParticipantDto;
  participant?: ChatParticipantDto | null;
  participants?: ChatParticipantDto[];
}

interface Props extends OwnProps {}

export default function ChatMessage({
  chat,
  item,
  chatSettings,
  currentParticipant,
  participant,
  participants,
}: Props) {
  const message = item.message!;

  const { enqueueSnackbar } = useSnackbar();
  const { participantsMap } = useChatParticipants(item.chatId!);
  const thunkDispatch = useAppThunkDispatch();

  const [validation, setValidation] = useState<ValidationInfo>(new ValidationInfo());
  const [editValidation, setEditValidation] = useState<ValidationInfo>(new ValidationInfo());
  const [isEditing, setIsEditing] = useState(false);
  const [isPinLoading, setIsPinLoading] = useState(false);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [acknowledgePoperOpenAnchorEl, setAcknowledgePoperOpenAnchorEl] =
    useState<HTMLElement | null>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const isAcknowledgePoperOpen = Boolean(acknowledgePoperOpenAnchorEl);
  const [isEditTagsModalOpen, setIsEditTagsModalOpen] = useState(false);
  const [isReadByModalOpen, setIsReadByModalOpen] = useState(false);
  const [isViewParticipantsModalOpen, setIsViewParticipantsModalOpen] = useState(false);

  const acknowledgements = useMemo(
    () =>
      message.acknowledgements?.map((x) => ({
        ...x,
        participant: participants?.find((p) => p.id === x.participantId),
      })),
    [message, participants],
  );
  const isAcknowledged = useMemo(
    () =>
      message.acknowledgements!.some(
        (x) => x.participantId === currentParticipant?.id && x.isAcknowledged,
      ),
    [message, currentParticipant],
  );
  const isShowReadBy = useMemo(
    () =>
      currentParticipant &&
      message.readByInfo!.some((x) => x.participantId !== currentParticipant?.id),
    [message, currentParticipant],
  );
  const isReadByMe = useMemo(
    () =>
      currentParticipant &&
      message.readByInfo!.some((x) => x.participantId === currentParticipant?.id),
    [message, currentParticipant],
  );

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleEdit = () => {
    setIsEditing(true);
    handleMenuClose();
  };

  const handleEdited = async (data: { body: string; rootNode: ChatMessageRootNodeDto }) => {
    try {
      await thunkDispatch(
        chatMessagesSlice.updateChatMessage({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          chatId: message.chatId!,
          messageId: message.id!,
          updateChatMessageDto: {
            body: data.body,
            rootNode: data.rootNode,
            attachments: message.attachments,
          },
        }),
      );
      setEditValidation(new ValidationInfo());
      setIsEditing(false);
    } catch (err) {
      setEditValidation(ValidationHelper.handleApiErrorResponse(err));
      throw err;
    }
  };

  const handleDelete = useCallback(async () => {
    try {
      await thunkDispatch(
        chatMessagesSlice.deleteChatMessage({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          chatId: message.chatId!,
          messageId: message.id!,
        }),
      );
      setValidation(new ValidationInfo());
    } catch (err) {
      setValidation(ValidationHelper.handleApiErrorResponse(err));
    } finally {
      handleMenuClose();
    }
  }, [message]);

  const handleAcknowledge = useCallback(async () => {
    try {
      await thunkDispatch(
        chatMessagesSlice.acknowledgeChatMessage({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          chatId: message.chatId!,
          messageId: message.id!,
        }),
      );
      setValidation(new ValidationInfo());
    } catch (err) {
      setValidation(ValidationHelper.handleApiErrorResponse(err));
    } finally {
      handleMenuClose();
    }
  }, [message]);

  const handlePin = useCallback(async () => {
    try {
      await thunkDispatch(
        chatHistorySlice.pinChatHistoryItem({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          chatId: message.chatId!,
          itemId: item.id!,
        }),
      );
      enqueueSnackbar("Message pinned.", { variant: "success" });
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      if (validation2.hasErrors) {
        enqueueSnackbar(`Error: ${validation2.getErrorsAsString()}`, { variant: "error" });
      }
    } finally {
      handleMenuClose();
    }
  }, [item]);

  const handleUnpin = useCallback(async () => {
    try {
      await thunkDispatch(
        chatHistorySlice.unpinChatHistoryItem({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          chatId: message.chatId!,
          itemId: item.id!,
        }),
      );
      enqueueSnackbar("Message unpinned.", { variant: "success" });
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      if (validation2.hasErrors) {
        enqueueSnackbar(`Error: ${validation2.getErrorsAsString()}`, { variant: "error" });
      }
    } finally {
      handleMenuClose();
    }
  }, []);

  const participantName = participant?.personName?.name || participant?.email || "Unknown";
  const isMyMessage = message.participantId === currentParticipant?.id;

  return (
    <Box>
      {/* Mark message as read if it was at viewport for N ms */}
      <AppVisibilitySensor
        debounceParams={{
          waitMs: 1000,
          options: {
            leading: false,
            trailing: true,
          },
          isCancelWhenInvisible: true,
        }}
        onChange={(isVisible) => {
          if (isVisible && !isReadByMe) {
            chatMessageService.queueMarkChatMessageAsRead({
              chatId: message.chatId!,
              messageId: message.id!,
            });
          }
        }}
      >
        <Stack direction='row' spacing={2} sx={{ alignItems: "flex-start" }}>
          <ChatParticipantAvatar chatId={message.chatId} participant={participant} size={46} />

          <Stack direction='column' spacing={0} sx={{ flex: 1, overflow: "hidden" }}>
            <Stack direction='row' spacing={2} sx={{ alignItems: "center" }}>
              <Stack>
                <Typography component='div' variant='body1' sx={{ fontWeight: "bold" }}>
                  <div>{participantName}</div>

                  {/* Participant parties */}
                  {!_.isEmpty(participant?.parties) && (
                    <Tooltip title='Participant party'>
                      <Typography
                        component='div'
                        variant='caption'
                        sx={{ cursor: "pointer" }}
                        onClick={() => setIsViewParticipantsModalOpen(true)}
                      >
                        ({participant?.parties?.map((x) => x.party?.name).join(", ")})
                      </Typography>
                    </Tooltip>
                  )}
                </Typography>
              </Stack>

              <Typography component='span' variant='caption' color='text.secondary'>
                {DatetimeHelper.humanizeDateRangeDuration(moment(), message.createdAt)} ago
              </Typography>
              {!moment(item.createdAt).isSame(moment(item.updatedAt)) && (
                <Typography component='span' variant='caption' color='text.secondary'>
                  (edited)
                </Typography>
              )}

              {/* Message actions */}
              <Stack direction='row' sx={{ flex: 1, justifyContent: "flex-end" }}>
                <IconButton edge={false} onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                  <AppIcon of='moreVert' />
                </IconButton>

                <Menu anchorEl={menuAnchorEl} open={isMenuOpen} onClose={handleMenuClose}>
                  {chatSettings?.allowAcknowledgement && !isAcknowledged && (
                    <MenuItem dense onClick={handleAcknowledge}>
                      <ListItemIcon>
                        <AppIcon of='acknowledge' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Acknowledge</ListItemText>
                    </MenuItem>
                  )}
                  {chatSettings?.allowPinnedItems && (
                    <AuthorizedMenuItem
                      permissions={[AppPermission.FleetAppAccess]}
                      dense
                      onClick={() => (item.isPinned ? handleUnpin() : handlePin())}
                    >
                      <ListItemIcon>
                        <AppIcon of='pin' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>{item.isPinned ? "Unpin" : "Pin"}</ListItemText>
                      {isPinLoading && <CircularProgress color='inherit' size={20} />}
                    </AuthorizedMenuItem>
                  )}
                  {isMyMessage && chatSettings?.allowEditMessages && (
                    <MenuItem dense onClick={handleEdit}>
                      <ListItemIcon>
                        <AppIcon of='edit' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Edit</ListItemText>
                    </MenuItem>
                  )}
                  <AuthorizedMenuItem
                    permissions={[AppPermission.FleetAppAccess]}
                    dense
                    onClick={() => {
                      setIsEditTagsModalOpen(true);
                      handleMenuClose();
                    }}
                  >
                    <ListItemIcon>
                      <AppIcon of='tag' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Edit tags</ListItemText>
                  </AuthorizedMenuItem>
                  {isMyMessage && chatSettings?.allowDeleteMessages && (
                    <AuthorizedMenuItem
                      permissions={[AppPermission.FleetAppAccess]}
                      dense
                      onClick={handleDelete}
                    >
                      <ListItemIcon>
                        <AppIcon of='delete' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </AuthorizedMenuItem>
                  )}
                </Menu>
              </Stack>
            </Stack>

            {!isEditing && (
              <Box>
                {/* Main content */}
                <>
                  <ChatMessageBodyView message={message} />
                  <ChatMessageAttachments message={message} />
                </>

                {/* Secondary content */}
                <Stack direction='row' sx={{ justifyContent: "space-between" }}>
                  <Stack direction='column'>
                    {/* Acknowledgements */}
                    {acknowledgements && acknowledgements.length !== 0 && (
                      <>
                        <Stack
                          direction='row'
                          spacing={1}
                          sx={{ alignItems: "center" }}
                          onMouseEnter={(e) => setAcknowledgePoperOpenAnchorEl(e.currentTarget)}
                          onMouseLeave={() => setAcknowledgePoperOpenAnchorEl(null)}
                        >
                          <DoneAllIcon color='success' fontSize='small' />
                          <Typography component='span' variant='caption' color='text.secondary'>
                            {acknowledgements.length < 10 ? acknowledgements.length : "10+"}{" "}
                            acknowledged
                          </Typography>
                        </Stack>

                        <ChatParticipantListPopover
                          chatId={message?.chatId}
                          participantIds={acknowledgements.map((x) => x.participantId!)}
                          getParticipantData={(participantId) => ({
                            datetime: acknowledgements?.find(
                              (x) => x.participantId === participantId,
                            )?.acknowledgedAt,
                          })}
                          open={isAcknowledgePoperOpen}
                          anchorEl={acknowledgePoperOpenAnchorEl}
                          onClose={() => setAcknowledgePoperOpenAnchorEl(null)}
                        />
                      </>
                    )}
                    {/* Tags */}
                    <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                      {message.tags && (
                        <GeneralAttachedTagsDisplay tags={message.tags} defaultIsFolded />
                      )}
                    </AuthorizedElement>
                  </Stack>
                  <Stack direction='column'>
                    {/* ReadBy */}
                    {isShowReadBy && (
                      <Tooltip title='See who read the message'>
                        <AvatarGroup
                          total={message.readByInfo!.length}
                          max={5}
                          sx={{ cursor: "pointer" }}
                          componentsProps={{
                            additionalAvatar: {
                              sx: { width: 20, height: 20, fontSize: "small" },
                            },
                          }}
                          onClick={() => setIsReadByModalOpen(true)}
                        >
                          {message.readByInfo!.map((readBy, j) => (
                            <ChatParticipantAvatar
                              key={j}
                              chatId={message.chatId}
                              participant={participantsMap[readBy.participantId || ""]}
                              withOnlineStatus={false}
                              withPopover={false}
                              sx={{ width: 20, height: 20 }}
                            />
                          ))}
                        </AvatarGroup>
                      </Tooltip>
                    )}
                  </Stack>
                </Stack>
              </Box>
            )}

            {/* Edit message */}
            {isEditing && (
              <Box>
                <ChatMessageInput
                  chatId={message.chatId!}
                  initialValue={message}
                  placeholder='Enter message...'
                  actionText='Save'
                  autoFocus
                  validation={editValidation}
                  hasUploadedAttachments={
                    (message.attachments && message.attachments.length !== 0) || false
                  }
                  defaultEnableCancel={true}
                  // view-only attachments
                  renderAttachments={() => (
                    <ChatMessageAttachments message={message} />
                    // <FileListView files={FileItem.createManyFrom(message.attachments)} />
                  )}
                  onAction={async (newValue) => await handleEdited(newValue)}
                  onCancel={() => setIsEditing(false)}
                />
              </Box>
            )}
          </Stack>
        </Stack>
      </AppVisibilitySensor>

      <FormHelperText error={!!validation.generalError}>{validation.generalError}</FormHelperText>

      {/* Edit tags */}
      <GeneralAttachedTagsOfEntityEditModal
        open={isEditTagsModalOpen}
        onClose={() => setIsEditTagsModalOpen(false)}
        entityType={TagEntityType.ChatMessage}
        entityId={message.id!}
      />

      {/* ReadBy */}
      <ChatMessageReadByInfoModal
        open={isReadByModalOpen}
        onClose={() => setIsReadByModalOpen(false)}
        message={message}
      />

      {/* View participants */}
      <ChatParticipantsModal
        open={isViewParticipantsModalOpen}
        onClose={() => setIsViewParticipantsModalOpen(false)}
        onUpdated={() => setIsViewParticipantsModalOpen(false)}
        chat={chat}
        allowManage={false}
      />
    </Box>
  );
}
