import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import { useModalOpenStatus } from "@/common/hooks/useModalOpenStatus";

import { ROUTE_PATH } from "@/common/constants/routing";
import AppModalTitle from "../../Modals/AppModalTitle";
import GeneralEventLogsEmbeddedView from "./GeneralEventLogsEmbeddedView";

export interface OwnProps {
  entityId: string;
}

type Props = OwnProps & DialogProps;

export default function GeneralEventLogsModal({ entityId, ...dialogProps }: Props) {
  const openStatus = useModalOpenStatus(dialogProps);

  return (
    <Box>
      <Dialog fullWidth maxWidth='lg' {...dialogProps}>
        <AppModalTitle
          title={"Event log"}
          withLink
          to={ROUTE_PATH.GENERAL_EVENT_LOGS(entityId)}
          withCloseIcon
          onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
        />

        <DialogContent>
          {openStatus.isOpen && (
            <GeneralEventLogsEmbeddedView
              entityId={entityId}
              displayProps={{
                header: false,
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
