import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { Formik } from "formik";
import _ from "lodash";
import { useSnackbar } from "notistack";
import * as Yup from "yup";

import useMounted from "@/common/hooks/mount/useMounted";
import { ValidationHelper } from "@/common/validation";
import {
  GeneralAttachedTagsOfEntitiesUpdateDto,
  IBaseEntityDto,
  TagEntityType,
} from "@/core/api/generated";

import AppTooltip from "@/common/components/AppTooltip";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import AppTypography from "@/common/components/Text/AppTypography";
import { EntityHelper } from "@/common/helpers/entity";
import { TypeHelper } from "@/common/helpers/type";
import { BaseFormikValues } from "@/common/ts/error";
import { apiClient } from "@/core/api/ApiClient";
import { useMemo, useState } from "react";
import AppModalTitle from "../../../Modals/AppModalTitle";
import GeneralAttachedTagsInput from "./GeneralAttachedTagsInput";

export enum TagsUpdateMode {
  Add = "Add",
  Replace = "Replace",
  Remove = "Remove",
}
export interface OwnProps {
  tagEntityType: TagEntityType;
  entities: IBaseEntityDto[];
  onSaved?: () => void | Promise<void>;
}

type Props = OwnProps & DialogProps;

/** Edit and save tags of specified entity. */
export default function GeneralAttachedTagsOfEntitiesEditModal({
  tagEntityType,
  entities,
  onSaved,
  ...dialogProps
}: Props) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [tagsUpdateMode, setTagsUpdateMode] = useState<TagsUpdateMode>(TagsUpdateMode.Add);

  const entityIds = useMemo(
    () => entities.map((x) => x.id!).filter((x) => !_.isNil(x)),
    [entities],
  );

  return (
    <Dialog fullWidth maxWidth='sm' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Edit entity tags
      </AppModalTitle>

      <DialogContent>
        <Formik<GeneralAttachedTagsOfEntitiesUpdateDto & BaseFormikValues>
          enableReinitialize
          initialValues={{
            tagEntityType: tagEntityType,
            entityIds: entityIds,
            tags: undefined,

            submit: "",
          }}
          validationSchema={Yup.object().shape({
            // name: Yup.string().required("Name is required"),
          })}
          onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
            try {
              if (tagsUpdateMode !== TagsUpdateMode.Remove) {
                await apiClient.generalTagsApi.apiV1TagsGeneralForEntitiesUpdatePut({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  generalAttachedTagsOfEntitiesUpdateDto: {
                    ..._.omit(values, ["submit"]),
                    isReplace: tagsUpdateMode === TagsUpdateMode.Replace,
                  },
                });
              } else {
                await apiClient.generalTagsApi.apiV1TagsGeneralForEntitiesDeleteDelete({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  generalAttachedTagsOfEntitiesDeleteDto: {
                    tagEntityType: tagEntityType,
                    entityIds: entityIds,
                    tagIds: values.tags?.tags?.map((x) => x.target!.targetId!) || [],
                  },
                });
              }

              enqueueSnackbar(
                `Tags ${tagsUpdateMode !== TagsUpdateMode.Remove ? "updated" : "deleted"}.`,
                { variant: "success" },
              );
              onSaved && onSaved();
              dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown");

              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err: any) {
              if (mounted.current) {
                ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }
          }}
        >
          {({ errors, handleSubmit, isSubmitting, touched, values, setFieldValue }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <GeneralAttachedTagsInput
                    value={values.tags}
                    onChange={(newValue) => {
                      setFieldValue("tags", newValue);
                    }}
                  />

                  <FormControl>
                    <FormLabel>Tags update mode</FormLabel>
                    <RadioGroup
                      row
                      value={tagsUpdateMode}
                      onChange={(e, v) => {
                        setTagsUpdateMode(v as TagsUpdateMode);
                        if (
                          (v as TagsUpdateMode) === TagsUpdateMode.Remove &&
                          TypeHelper.isEmpty(values?.tags?.tags)
                        ) {
                          setFieldValue("tags", {
                            tags: _.uniq(
                              entities
                                .map((x) => EntityHelper.getEntityMeta(x)?.tags?.tags || [])
                                .flat(),
                            ),
                          });
                        }
                      }}
                    >
                      <FormControlLabel
                        value={TagsUpdateMode.Add}
                        control={<Radio />}
                        label={
                          <AppTooltip
                            title={`For each selected entity add selected tags to already existing ones`}
                          >
                            <AppTypography
                              decoration={{
                                variant: "helpText",
                              }}
                            >
                              Add tags
                            </AppTypography>
                          </AppTooltip>
                        }
                      />
                      <FormControlLabel
                        value={TagsUpdateMode.Replace}
                        control={<Radio />}
                        label={
                          <AppTooltip
                            title={`For each selected entity replace existing tags with the selected tags`}
                          >
                            <AppTypography
                              decoration={{
                                variant: "helpText",
                              }}
                            >
                              Replace tags
                            </AppTypography>
                          </AppTooltip>
                        }
                      />
                      <FormControlLabel
                        value={TagsUpdateMode.Remove}
                        control={<Radio />}
                        label={
                          <AppTooltip title={`For each selected entity remove all existing tags`}>
                            <AppTypography
                              decoration={{
                                variant: "helpText",
                              }}
                            >
                              Remove tags
                            </AppTypography>
                          </AppTooltip>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <Stack direction='row' spacing={1} sx={{ justifyContent: "flex-end", mt: 1 }}>
                  <Button
                    variant='text'
                    color='secondary'
                    onClick={(e) => dialogProps.onClose && dialogProps.onClose(e, "escapeKeyDown")}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    color='primary'
                    loading={isSubmitting}
                    type='submit'
                    variant='text'
                    disabled={!values.tags || values.tags.tags?.length === 0}
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
