import {
  Box,
  FormHelperText,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";

import { BaseEntitySearchAutocompleteInheritableProps } from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { enumService } from "@/common/services/enum";
import {
  EntityType,
  GeneralStrictEntityRelationInputDto,
  IBaseEntityDto,
} from "@/core/api/generated";
import { useMemo } from "react";
import BaseEntityAutocompletePlaceholder from "../../components/BaseEntityAutocompletePlaceholder";
import DropdownButton from "../../../Button/DropdownButton";
import AccessoryAutocomplete, {
  AccessoryAutocompleteProps,
} from "../../Accessory/AccessoryAutocomplete";
import AssetAutocomplete, { AssetAutocompleteProps } from "../../Asset/AssetAutocomplete";
import { ApiEnumAutocompleteProps } from "../../../Enum/ApiEnumAutocomplete";
import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";
import VehicleAutocomplete, { VehicleAutocompleteProps } from "../../Vehicle/VehicleAutocomplete";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import _ from "lodash";
import DamageDetectionAutocomplete from "../../DamageDetection/DamageDetectionAutocomplete";
import DamageDetectionAggregateAutocomplete from "../../DamageDetectionAggregate/DamageDetectionAggregateAutocomplete";
import DamageCostEvaluationAutocomplete from "../../DamageCostEvaluation/DamageCostEvaluationAutocomplete";
import DamageCostEvaluationAggregateAutocomplete from "../../DamageCostEvaluationAggregate/DamageCostEvaluationAggregateAutocomplete";
import AccessoryCheckAutocomplete from "../../AccessoryCheck/AccessoryCheckAutocomplete";
import RepairOperationAutocomplete from "../../RepairOperation/RepairOperationAutocomplete";

type BaseEntityAutocompletePropsLocal =
  BaseEntitySearchAutocompleteInheritableProps<IBaseEntityDto>;

type EntityAutocompletePropsLocal =
  | Partial<VehicleAutocompleteProps>
  | Partial<AccessoryAutocompleteProps>
  | Partial<AssetAutocompleteProps>;

export interface EntityAggregatedAutocompleteProps {
  value: GeneralStrictEntityRelationInputDto | null | undefined;
  /** Entity types that can be selected. */
  onlyEntityTypes?: EntityType[];
  entityTypeAutocompleteProps?: Partial<ApiEnumAutocompleteProps<"EntityType">>;
  entityAutocompleteProps?: EntityAutocompletePropsLocal;
  disabled?: boolean;
  onChange?: (newValue: GeneralStrictEntityRelationInputDto | null | undefined) => void;
}

export default function GeneralStrictEntityRelationInput({
  value,
  onlyEntityTypes,
  entityTypeAutocompleteProps,
  entityAutocompleteProps,
  disabled,
  onChange,
}: EntityAggregatedAutocompleteProps) {
  const entityTypes = useMemo(
    () =>
      _.orderBy(
        enumService.getEnumValues("EntityType", { only: onlyEntityTypes, exceptNone: true }),
        (x) => x,
      ),
    [onlyEntityTypes],
  );

  const handleEntityTypeChange = (entityType: EntityType | null | undefined) => {
    onChange &&
      onChange(
        entityType
          ? { ...value, entityType: entityType || undefined, entityId: undefined }
          : undefined,
      );
  };

  const handleEntityIdChange = (entityId: string | null | undefined) => {
    onChange && onChange(value ? { ...value, entityId: entityId || undefined } : undefined);
  };

  const entityTypeInput = (
    <DropdownButton
      sx={{
        fontWeight: "inherit",
      }}
      size={entityAutocompleteProps?.size || "small"}
      disabled={
        entityTypeAutocompleteProps?.disabled || entityAutocompleteProps?.disabled || disabled
      }
      buttonProps={{
        disableFocusRipple: true,
        disableElevation: true,
        disableRipple: true,
        disableTouchRipple: true,
      }}
      dropdownContent={
        <MenuList>
          {entityTypes.length <= 10 &&
            entityTypes.map((enumValue, i) => (
              <MenuItem
                key={i}
                onClick={() => {
                  handleEntityTypeChange(enumValue);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='entity' />
                </ListItemIcon>
                <ListItemText>
                  <InlineApiEnumValue type='EntityType' value={enumValue} />
                </ListItemText>
              </MenuItem>
            ))}

          {entityTypes.length > 10 && (
            <FixedSizeList
              height={250}
              width={300}
              itemSize={32}
              itemCount={entityTypes.length}
              overscanCount={10}
            >
              {(props2: ListChildComponentProps) => {
                const item = entityTypes[props2.index];

                return (
                  <MenuItem
                    style={props2.style}
                    key={props2.index}
                    onClick={() => handleEntityTypeChange(item)}
                  >
                    <ListItemIcon>
                      <AppIcon of='entity' />
                    </ListItemIcon>
                    <ListItemText>
                      <InlineApiEnumValue type='EntityType' value={item} withDescription={false} />
                    </ListItemText>
                  </MenuItem>
                );
              }}
            </FixedSizeList>
          )}
        </MenuList>
      }
    >
      {!value?.entityType && "Select entity type"}
      {value?.entityType && <InlineApiEnumValue type='EntityType' value={value?.entityType} />}
    </DropdownButton>
  );

  const entityAutocompletePropsComputed = useMemo<BaseEntityAutocompletePropsLocal>(
    () => ({
      disabled: disabled || !value?.entityType,
      withCreate: false,
      ...entityAutocompleteProps,
      entityId: value?.entityId,
      textFieldProps: {
        ...entityAutocompleteProps?.textFieldProps,
        InputProps: {
          startAdornment: (
            <InputAdornment sx={{ mx: 0 }} position='start'>
              {entityTypeInput}
            </InputAdornment>
          ),
        },
      },
      onChange: (newValue) => {
        handleEntityIdChange(newValue?.id);
      },
    }),
    [entityAutocompleteProps, entityTypeInput],
  );

  return (
    <Box>
      {/* Select EntityType */}
      {!value?.entityType && (
        <BaseEntityAutocompletePlaceholder {...entityAutocompletePropsComputed} />
      )}

      {/* Select Entity */}
      {value?.entityType && (
        <>
          {renderIf()
            .if(value.entityType === EntityType.Accessory)
            .then(<AccessoryAutocomplete {...entityAutocompletePropsComputed} />)
            .elseif(value.entityType === EntityType.Vehicle)
            .then(<VehicleAutocomplete {...entityAutocompletePropsComputed} />)
            .elseif(value.entityType === EntityType.DamageDetection)
            .then(<DamageDetectionAutocomplete {...entityAutocompletePropsComputed} />)
            .elseif(value.entityType === EntityType.DamageDetectionAggregate)
            .then(<DamageDetectionAggregateAutocomplete {...entityAutocompletePropsComputed} />)
            .elseif(value.entityType === EntityType.DamageCostEvaluation)
            .then(<DamageCostEvaluationAutocomplete {...entityAutocompletePropsComputed} />)
            .elseif(value.entityType === EntityType.DamageCostEvaluationAggregate)
            .then(
              <DamageCostEvaluationAggregateAutocomplete {...entityAutocompletePropsComputed} />,
            )
            .elseif(value.entityType === EntityType.AccessoryCheck)
            .then(<AccessoryCheckAutocomplete {...entityAutocompletePropsComputed} />)
            .elseif(value.entityType === EntityType.RepairOperation)
            .then(<RepairOperationAutocomplete {...entityAutocompletePropsComputed} />)
            .elseif(value.entityType === EntityType.Asset)
            .then(<AssetAutocomplete {...entityAutocompletePropsComputed} />)
            .else(<FormHelperText error>Unknown entity type {value.entityType}.</FormHelperText>)
            .render()}
        </>
      )}
    </Box>
  );
}
