import { Box, Button, Divider, FormHelperText, IconButton, Typography } from "@mui/material";
import axios, { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useState } from "react";

import BlockCode from "@/common/components/Code/BlockCode";
import InlineCode from "@/common/components/Code/InlineCode";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import ConfirmationModalWithTrigger from "@/common/components/Modals/ConfirmationModalWithTrigger";
import GeneralDeleteConfirmationModal from "@/common/components/Modals/GeneralDeleteConfirmationModal";
import { MiscHelper } from "@/common/helpers/misc";

import FileUploader from "../../common/components/Files/FileUploader";

function DevModals() {
  const { enqueueSnackbar } = useSnackbar();

  const [isConfirmation1ModalOpen, setIsConfirmation1ModalOpen] = useState(false);

  const successfulActionWithDelay = async () => {
    await MiscHelper.wait(2000);
    enqueueSnackbar("Action completed.", { variant: "success" });
    setIsConfirmation1ModalOpen(false);
  };

  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Modals
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>ConfirmationModal</InlineCode>
      </Typography>
      <Box>
        <Button variant='contained' onClick={() => setIsConfirmation1ModalOpen(true)}>
          Some action
        </Button>
        <ConfirmationModal
          title='Do you confirm the action?'
          body={<>Please confirm the action.</>}
          open={isConfirmation1ModalOpen}
          onClose={() => setIsConfirmation1ModalOpen(false)}
          onCancel={() => setIsConfirmation1ModalOpen(false)}
          onConfirm={successfulActionWithDelay}
        />
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>ConfirmationModalWithTrigger</InlineCode>
      </Typography>
      <Box>
        <ConfirmationModalWithTrigger
          trigger={
            <Button
              variant='contained'
              onClick={() => {
                console.log("test");
              }}
            >
              Some action
            </Button>
          }
          title='Do you confirm the action?'
          body={() => {
            return <>Please confirm the action.</>;
          }}
          onConfirm={successfulActionWithDelay}
        />
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>GeneralDeleteConfirmationModal</InlineCode>
      </Typography>
      <Box>
        <Button variant='contained' onClick={() => setIsConfirmation1ModalOpen(true)}>
          Delete it
        </Button>
        <GeneralDeleteConfirmationModal
          title='Delete the entity?'
          body={<>Please confirm the action.</>}
          open={isConfirmation1ModalOpen}
          onClose={() => setIsConfirmation1ModalOpen(false)}
          onCancel={() => setIsConfirmation1ModalOpen(false)}
          onConfirm={successfulActionWithDelay}
        />
      </Box>
    </Box>
  );
}

export default DevModals;
