import { ROUTE_PATH } from "@/common/constants/routing";
import { AppPermission, VehicleDamageDto, VehicleDamageReferenceDto } from "@/core/api/generated";

import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import VehicleDamageInline, { VehicleDamageInlineProps } from "./VehicleDamageInline";

interface Props extends BaseEntityLinkInheritableProps {
  vehicleId: string | null | undefined;
  entity: VehicleDamageDto | VehicleDamageReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<VehicleDamageInlineProps>;
}

export default function VehicleDamageLink({
  vehicleId,
  entity,
  entityId,
  inlineProps,
  ...otherProps
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  if (!entity && !entityId) {
    return null;
  }

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.VehicleDamageRead])}
      to={ROUTE_PATH.VEHICLE_DAMAGE_VIEW({
        vehicleId: vehicleId || "",
        vehicleDamageId: entity?.id || entityId || "",
      })}
      icon={undefined}
      content={
        <VehicleDamageInline entity={entity} entityId={entityId} {...inlineProps} {...otherProps} />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Vehicle damage"}
    />
  );
}
