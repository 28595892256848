import { LoadingButton } from "@mui/lab";
import { Alert, Box, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppAvatar from "@/common/components/Avatar/AppAvatar";
import TenantRolesSelector from "@/common/components/Entity/Role/TenantRolesSelector";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { UpdateUserRolesDto } from "@/core/api/generated";

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%",
}));

export default function UpdateUserRolesPage() {
  const { userId } = useParams<{ userId?: string }>();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const userRequest = useApiRequest(
    apiClient.usersApi.apiV1UsersUserIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      userId: userId!,
    },
    {
      deps: [userId],
      skip: !userId,
    },
  );
  const user = userRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: user && {
      idValue: user.id!,
      newTitle: user.personName?.name || "",
    },
  });

  return (
    <ViewLayout
      header={<SimpleViewPageHeader title='Edit user roles' primaryActions={undefined} />}
    >
      <Formik<UpdateUserRolesDto & BaseFormikValues>
        enableReinitialize
        initialValues={{
          roleIds: user?.roleIds || undefined,

          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // phoneNumber: Yup.string().required("Phone number is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            await apiClient.tenantAccountApi.apiV1TenantAccountUsersUserIdRolesPut({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              userId: user?.id || "",
              updateUserRolesDto: {
                roleIds: values.roleIds,
              },
            });
            enqueueSnackbar("User roles updated.", { variant: "success" });

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
            history.goBack();
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              {/* */}

              <Box>
                <Box>
                  <AppAvatar user={user} size={100} />
                </Box>

                <TextField
                  disabled
                  fullWidth
                  label='Email'
                  margin='normal'
                  type='email'
                  value={user?.email || ""}
                  variant='outlined'
                />
              </Box>

              <Box>
                {user?.isCustomerUser && (
                  <Alert severity='warning'>
                    {`It's forbidden to change roles for customer user.`}
                  </Alert>
                )}

                <TenantRolesSelector
                  disabled={user?.isCustomerUser}
                  roleIds={values.roleIds}
                  onChange={(newValue) =>
                    setFieldValue("roleIds", newValue?.map((x) => x.id) || [])
                  }
                  textFieldProps={{
                    error: Boolean(touched.roleIds && errors.roleIds),
                    helperText: touched.roleIds && errors.roleIds,
                  }}
                  displayProps={{
                    selectedRolesOverview: true,
                  }}
                />
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <LoadingButton
                sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                color='primary'
                loading={isSubmitting}
                fullWidth
                type='submit'
                variant='contained'
              >
                Save
              </LoadingButton>
            </form>
          );
        }}
      </Formik>
    </ViewLayout>
  );
}
