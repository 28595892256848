import _ from "lodash";

import { useAppThunkDispatch } from "@/common/hooks/redux";
import { VehicleVisualModelType } from "@/core/api/generated";
import * as filesSlice from "@/store/files/slice";
import { UploadFilesFunc } from "@/store/files/slice";

import FileUploader, { FileUploaderProps } from "./FileUploader";
import { apiClient } from "@/core/api/ApiClient";

interface Props
  extends Pick<
    FileUploaderProps,
    "defaultFiles" | "disabled" | "onChange" | "onUploadStarted" | "onUploadFinished"
  > {
  modelType?: VehicleVisualModelType;
}

/** File upload used in vehicle visual model only. */
function VehicleVisualModelImageUploader({
  defaultFiles = [],
  disabled,
  onChange,
  modelType,
  onUploadStarted,
  onUploadFinished,
}: Props) {
  const dispatch = useAppThunkDispatch();

  return (
    <FileUploader
      multiple={false}
      maxFiles={1}
      accept='image/svg+xml'
      defaultFiles={defaultFiles}
      hideUploadAreaIfCantUploadMore
      disabled={!modelType || modelType === VehicleVisualModelType.None || disabled}
      onChange={onChange}
      uploadFilesFunc={async (requestParameters) => {
        const response = await apiClient.filesApi.apiV1FilesVehicleVisualModelTypeImagesUploadPost({
          ...requestParameters,
          type: modelType!,
        });
        if (response.request.status !== 200) {
          throw response;
        }
        return response.data;
      }}
      onUploadStarted={onUploadStarted}
      onUploadFinished={onUploadFinished}
    />
  );
}

export default VehicleVisualModelImageUploader;
