import {
  Button,
  DialogContentText,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import InlineEntityChangedByInfoDisplay from "@/common/components/EntityInfo/InlineEntityChangedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModalWithTrigger from "@/common/components/Modals/ConfirmationModalWithTrigger";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AdminVehicleGenerationGetPaginatedDto, EntityType } from "@/core/api/generated";
import EntitySourceIsCar2DbChip from "../../components/Entity/EntitySourceIsCar2DbChip";

export default function VehicleGenerations() {
  const { enqueueSnackbar } = useSnackbar();
  const commonRequestParams = useCommonRequestParams<AdminVehicleGenerationGetPaginatedDto>({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityInAdminArea(EntityType.VehicleGeneration),
    },
  });

  const paginatedVehicleGenerationsRequest = useApiRequest(
    apiClient.adminVehicleGenerationsApi.apiV1AdminReferenceDataVehiclesGenerationsGetPost,
    {
      adminVehicleGenerationGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const { data: paginatedVehicleGenerations } = paginatedVehicleGenerationsRequest;

  return (
    <ListPageLayout>
      <SimpleViewPageHeader
        title={undefined}
        primaryActions={
          <Button
            variant='contained'
            color='primary'
            startIcon={<AppIcon of='add' />}
            component={RouterLink}
            to={ROUTE_PATH.ADMIN_VEHICLE_GENERATION_CREATE}
          >
            Create new
          </Button>
        }
      />

      <Divider sx={{ my: 2 }} />

      <DataTabular
        columns={[
          {
            field: "globalNumber",
            title: "Global number",
            flex: 1,
            renderCell: (item) => item.globalNumber,
          },
          {
            field: "name",
            title: "Name",
            flex: 1,
            renderCell: (item) => item.name,
          },
          {
            field: "vehicleType",
            title: "Vehicle type",
            flex: 1,
            renderCell: (item) => (
              <InlineApiEnumValue type='VehicleType' value={item.vehicleType} />
            ),
          },
          {
            field: "make",
            title: "Make, model",
            flex: 1,
            renderCell: (item) => (
              <>
                {item.make?.name} {item.model?.name}
              </>
            ),
          },
          {
            field: "startYear",
            title: "Years",
            flex: 1,
            renderCell: (item) => (
              <>{[item.startYear, item.endYear].filter((x) => !!x).join(" - ")}</>
            ),
          },
          {
            field: "isBuiltIn",
            title: "Built-in",
            flex: 1,
            renderCell: (item) => <BooleanValue value={item.isBuiltIn} />,
          },
          {
            field: "isEnabled",
            title: "Enabled",
            flex: 1,
            renderCell: (item) => <BooleanValue value={item.isEnabled} />,
          },
          {
            field: "sortOrder",
            title: "Sort order",
            flex: 1,
            renderCell: (item) => <>{item.sortOrder}</>,
          },
          {
            field: "updatedBy",
            title: "Changed by",
            flex: 1,
            renderCell: (item) => <InlineEntityChangedByInfoDisplay entity={item} />,
          },
          {
            field: "source",
            title: "Source",
            flex: 1,
            isColumnMenuDisabled: true,
            renderCell: (item) =>
              item.car2DbId ? <EntitySourceIsCar2DbChip car2DbId={item.car2DbId} /> : "-",
          },
        ]}
        rows={paginatedVehicleGenerations?.items}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.ADMIN_VEHICLE_GENERATION_EDIT(item.id)}
        renderRowAction={({ item }) => (
          <>
            <MenuWithTrigger
              trigger={
                <IconButton>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              {({ handleClose }) => [
                <MenuItem
                  key={0}
                  component={RouterLink}
                  to={ROUTE_PATH.ADMIN_VEHICLE_GENERATION_EDIT(item.id)}
                >
                  <ListItemIcon>
                    <AppIcon of='edit' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>,
                <ConfirmationModalWithTrigger
                  key={1}
                  trigger={
                    <MenuItem disabled={item.isBuiltIn}>
                      <ListItemIcon>
                        <AppIcon of='delete' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </MenuItem>
                  }
                  title='Delete reference data?'
                  body={({ error }) => {
                    return (
                      <DialogContentText>
                        {`You're going to delete the vehicle generation`}{" "}
                        <strong>{item.name}</strong>. {`This action can't be undone.`}
                      </DialogContentText>
                    );
                  }}
                  onConfirm={async () => {
                    try {
                      await apiClient.adminVehicleGenerationsApi.apiV1AdminReferenceDataVehiclesGenerationsGenerationIdDelete(
                        { generationId: item.id! },
                      );
                      enqueueSnackbar("Generation deleted.", { variant: "success" });
                      handleClose();
                    } catch (err) {
                      const validation2 = ValidationHelper.handleApiErrorResponse(err);
                      validation2.hasErrors &&
                        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
                    }
                  }}
                />,
              ]}
            </MenuWithTrigger>
          </>
        )}
        isLoading={paginatedVehicleGenerationsRequest.isLoading}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
      />
    </ListPageLayout>
  );
}
