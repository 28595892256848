import { useCallback, useEffect, useRef } from "react";
import { useEffectOnceIf } from "./effect/useEffectOnceIf";

/** Hook for setTimeout. Sets timeout once only if condition is true. */
export const useTimeoutIf = (
  condition: boolean,
  callback: (args: void) => void,
  ms?: number,
  options?: {
    /** Whether to clear already set timeout and set an new one to wait from the beginning when delay ms changes. */
    isReScheduleOnDelayChange?: boolean;
    onReSchedule?: (params: { oldMs?: number; newMs?: number }) => void;
  },
): { timeoutHandle?: NodeJS.Timeout } => {
  const prevMsRef = useRef<number | undefined>(undefined);
  const timeoutHandleRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffectOnceIf(
    condition,
    () => {
      timeoutHandleRef.current = setTimeout(callback, ms);
    },
    [],
  );

  useEffect(() => {
    if (
      ms !== prevMsRef.current &&
      options?.isReScheduleOnDelayChange &&
      timeoutHandleRef.current
    ) {
      console.log("useTimeoutIf. Re-schedule timeout.", {
        oldMs: prevMsRef.current,
        newMs: ms,
      });
      clearTimeout(timeoutHandleRef.current);
      timeoutHandleRef.current = setTimeout(callback, ms);
      options?.onReSchedule &&
        options?.onReSchedule({
          oldMs: prevMsRef.current,
          newMs: ms,
        });
    }
    prevMsRef.current = ms;
  }, [ms, options?.isReScheduleOnDelayChange]);

  return {
    timeoutHandle: timeoutHandleRef.current,
  };
};
