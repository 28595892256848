import _ from "lodash";

import {
  DiscountType,
  DiscountValueType,
  GeneralCurrencyDto,
  GeneralCurrencyInputDto,
  GeneralDiscountDto,
  GeneralDiscountInputDto,
  GeneralInsuranceDto,
  GeneralInsuranceInputDto,
  GeneralPriceSummaryDto,
  GeneralTaxDto,
  GeneralTaxInputDto,
  PriceSummaryCalcType,
  TaxType,
  TaxValueType,
} from "@/core/api/generated";

import { NUMBER_PRECISION } from "../constants/common";
import { ArrayHelper } from "./array";
import { LineItemWithTotals, GeneralPriceSummaryDtoDetailedLocal } from "../ts/lineItems";

type ExpectedCurrencyType = GeneralCurrencyDto | GeneralCurrencyInputDto;

type ExpectedTaxType = (GeneralTaxDto | GeneralTaxInputDto) & {
  isCompound?: GeneralTaxDto["isCompound"];
};

type ExpectedDiscountType = (GeneralDiscountDto | GeneralDiscountInputDto) & {
  isCompound?: GeneralDiscountDto["isCompound"];
};

type ExpectedInsuranceType = GeneralInsuranceDto | GeneralInsuranceInputDto;

export class PriceHelper {
  //#region Tax

  public static get zeroTax(): ExpectedTaxType {
    return {
      type: TaxType.Custom,
      valueType: TaxValueType.Percent,
      percent: 0,
      currency: undefined,
    };
  }

  /** Converts from percent to value type */
  public static convertPercentToValueTax(tax: ExpectedTaxType, subTotal: number) {
    if (tax.valueType == TaxValueType.Value) {
      return tax;
    }
    const newTax: ExpectedTaxType = {
      ...tax,
      valueType: TaxValueType.Value,
      percent: null,
      value: _.round(subTotal * (tax.percent || 0), NUMBER_PRECISION.MONEY),
    };
    return newTax;
  }

  /** Converts from value to percent type */
  public static convertValueToPercentTax(tax: ExpectedTaxType, subTotal: number) {
    if (tax.valueType == TaxValueType.Percent) {
      return tax;
    }
    const newTax: ExpectedTaxType = {
      ...tax,
      valueType: TaxValueType.Percent,
      value: null,
      percent: subTotal == 0 ? 0 : _.round((tax.value ?? 0) / subTotal, NUMBER_PRECISION.PERCENT),
    };
    return newTax;
  }

  /** Converts to specified type */
  public static convertTaxTo(
    tax: ExpectedTaxType,
    subTotal: number,
    desiredValueType: TaxValueType,
  ) {
    if (tax.valueType == desiredValueType) {
      return tax;
    }
    if (tax.valueType == TaxValueType.Percent && desiredValueType == TaxValueType.Value) {
      return this.convertPercentToValueTax(tax, subTotal);
    }
    if (tax.valueType == TaxValueType.Value && desiredValueType == TaxValueType.Percent) {
      return this.convertValueToPercentTax(tax, subTotal);
    }

    throw new Error(
      `Tax conversion from value type '${tax.valueType}' to '${desiredValueType}' is not supported.`,
    );
  }

  /** Combines provided N taxes into single compound tax. */
  public static createCompoundTax(
    taxes: Array<{ tax: ExpectedTaxType; subTotal: number }>,
    desiredValueType?: TaxValueType,
  ): ExpectedTaxType {
    if (taxes.length === 0) {
      return this.zeroTax;
    }
    // if (!ArrayHelper.containsAllTheSameBy(taxes, (x) => x.tax.currency?.code)) {
    //   return this.zeroTax;
    // }

    const type = ArrayHelper.containsAllTheSameBy(taxes, (x) => x.tax.type)
      ? _.first(taxes)!.tax.type
      : TaxType.Custom;
    const valueType = ArrayHelper.containsAllTheSameBy(taxes, (x) => x.tax.valueType)
      ? _.first(taxes)!.tax.valueType
      : TaxValueType.None;
    const currency = _.first(taxes)!.tax.currency;
    const grandSubTotal = _.sumBy(taxes, (x) => x.subTotal);
    let result: ExpectedTaxType;

    // for value type taxes, compound tax equals to the sum of tax values
    if (taxes.every((x) => x.tax.valueType == TaxValueType.Value)) {
      result = {
        type: type,
        valueType: valueType,
        value: _.sumBy(taxes, (x) => x.tax.value || 0),
        currency: currency,
      };
    }
    // for percent type taxes, compound tax equals to the recalculated percent based on all tax percents and grand total
    else if (taxes.every((x) => x.tax.valueType == TaxValueType.Percent)) {
      const taxValue = _.sumBy(taxes, (x) => x.subTotal * (x.tax.percent || 0));
      const grandTaxPercent = grandSubTotal == 0 ? 0 : taxValue / grandSubTotal;

      result = {
        type: type,
        valueType: valueType,
        percent: grandTaxPercent,
        currency: currency,
      };
    }
    // for mixed taxes, split them by value type, calc separately and them combine into value tax
    else {
      const valueTaxes = taxes.filter((x) => x.tax.valueType == TaxValueType.Value);
      const percentTaxes = taxes.filter((x) => x.tax.valueType == TaxValueType.Percent);
      const allValueTaxes = valueTaxes.concat(
        percentTaxes.map((x) => {
          x.tax = this.convertPercentToValueTax(x.tax, x.subTotal);
          return x;
        }),
      );
      result = this.createCompoundTax(allValueTaxes);
    }

    // convert to desired value type
    if (desiredValueType != null && result.valueType != desiredValueType) {
      result = this.convertTaxTo(result, grandSubTotal, desiredValueType);
    }

    result.isCompound = true;

    return result;
  }

  /** Based on the tax type, applies it to the provided number. */
  public static applyTax(value: number, tax: ExpectedTaxType | null | undefined): number {
    if (!tax || !tax.valueType) {
      return _.round(value, NUMBER_PRECISION.MONEY);
    }
    if (tax.valueType === TaxValueType.Percent) {
      return _.round(Math.max(value + value * (tax.percent || 0), 0), NUMBER_PRECISION.MONEY);
    } else if (tax.valueType === TaxValueType.Value) {
      return _.round(Math.max(value + (tax.value || 0), 0), NUMBER_PRECISION.MONEY);
    }
    return _.round(value, NUMBER_PRECISION.MONEY);
  }

  /** Based on the tax type, unapplies it from the provided number. */
  public static unapplyTax(value: number, tax: ExpectedTaxType | null | undefined): number {
    if (!tax || !tax.valueType) {
      return _.round(value, NUMBER_PRECISION.MONEY);
    }
    if (tax.valueType === TaxValueType.Percent) {
      // Apply formula: Total = SubTotal + SubTotal * TaxPercent = SubTotal * (1 + TaxPercent).
      // Unapply formula: SubTotal = Total / (1 + TaxPercent).
      return _.round(Math.max(value / (1 + (tax.percent || 0)), 0), NUMBER_PRECISION.MONEY);
    } else if (tax.valueType === TaxValueType.Value) {
      return _.round(Math.max(value - (tax.value || 0), 0), NUMBER_PRECISION.MONEY);
    }
    return _.round(value, NUMBER_PRECISION.MONEY);
  }

  /** Returns detailed explanation on how tax is calculated. */
  public static getTaxExplanation({
    forValue,
    tax,
    currency,
  }: {
    forValue: {
      subTotal: number;
    };
    tax: ExpectedTaxType;
    currency?: ExpectedCurrencyType | null;
  }) {
    const taxValue =
      (tax.valueType === TaxValueType.Value && tax.value) ||
      (tax.valueType === TaxValueType.Percent &&
        _.round(forValue.subTotal * (tax.percent || 0), NUMBER_PRECISION.PERCENT)) ||
      0;
    const taxPercent =
      (tax.valueType === TaxValueType.Value &&
        _.round((tax.value || 0) / forValue.subTotal, NUMBER_PRECISION.PERCENT)) ||
      (tax.valueType === TaxValueType.Percent && tax.percent) ||
      0;
    return {
      subTotal: forValue.subTotal,
      taxValue: taxValue,
      taxPercent: taxPercent,
      subTotalIncTax: _.round(Math.max(forValue.subTotal + taxValue, 0), NUMBER_PRECISION.MONEY),
      currency,
    };
  }

  //#endregion

  //#region Discount

  public static get zeroDiscount(): ExpectedDiscountType {
    return {
      type: DiscountType.Trade,
      valueType: DiscountValueType.Percent,
      percent: 0,
      currency: undefined,
    };
  }

  /** Converts from percent to value type */
  public static convertPercentToValueDiscount(
    discount: ExpectedDiscountType,
    subTotal: number,
  ): ExpectedDiscountType {
    if (discount.valueType == DiscountValueType.Value) {
      return discount;
    }
    const newDiscount: ExpectedDiscountType = {
      ...discount,
      valueType: DiscountValueType.Value,
      percent: null,
      value: _.round(subTotal * (discount.percent || 0), NUMBER_PRECISION.MONEY),
    };
    return newDiscount;
  }

  /** Converts from value to percent type */
  public static convertValueToPercentDiscount(
    discount: ExpectedDiscountType,
    subTotal: number,
  ): ExpectedDiscountType {
    if (discount.valueType == DiscountValueType.Percent) {
      return discount;
    }
    const newDiscount: ExpectedDiscountType = {
      ...discount,
      valueType: DiscountValueType.Percent,
      value: null,
      percent:
        subTotal == 0 ? 0 : _.round((discount.value ?? 0) / subTotal, NUMBER_PRECISION.PERCENT),
    };
    return newDiscount;
  }

  /** Converts to specified type */
  public static convertDiscountTo(
    discount: ExpectedDiscountType,
    subTotal: number,
    desiredValueType: DiscountValueType,
  ) {
    if (discount.valueType == desiredValueType) {
      return discount;
    }
    if (
      discount.valueType == DiscountValueType.Percent &&
      desiredValueType == DiscountValueType.Value
    ) {
      return this.convertPercentToValueDiscount(discount, subTotal);
    }
    if (
      discount.valueType == DiscountValueType.Value &&
      desiredValueType == DiscountValueType.Percent
    ) {
      return this.convertValueToPercentDiscount(discount, subTotal);
    }

    throw new Error(
      `Discount conversion from value type '${discount.valueType}' to '${desiredValueType}' is not supported.`,
    );
  }

  /** Combines provided N discounts into single compound discount. */
  public static createCompoundDiscount(
    discounts: Array<{ discount: ExpectedDiscountType; subTotal: number }>,
    desiredValueType?: DiscountValueType,
  ): ExpectedDiscountType {
    if (discounts.length === 0) {
      return this.zeroDiscount;
    }
    // ignore discounts for zero subTotals
    discounts = discounts.filter((x) => x.subTotal !== 0);

    if (discounts.length === 0) {
      return this.zeroDiscount;
    }

    // if (!ArrayHelper.containsAllTheSameBy(discounts, (x) => x.discount.currency?.code)) {
    //   return this.zeroDiscount;
    // }

    const type = ArrayHelper.containsAllTheSameBy(discounts, (x) => x.discount.type)
      ? _.first(discounts)!.discount.type
      : DiscountType.Trade;
    const valueType = ArrayHelper.containsAllTheSameBy(discounts, (x) => x.discount.valueType)
      ? _.first(discounts)!.discount.valueType
      : DiscountValueType.None;
    const currency = _.first(discounts)!.discount.currency;
    const grandSubTotal = _.sumBy(discounts, (x) => x.subTotal);
    let result: ExpectedDiscountType;

    // for value type discounts, compound discount equals to the sum of discount values
    if (discounts.every((x) => x.discount.valueType == DiscountValueType.Value)) {
      result = {
        type: type,
        valueType: valueType,
        value: _.sumBy(discounts, (x) => x.discount.value || 0),
        currency: currency,
      };
    }
    // for percent type discounts, compound discount equals to the recalculated percent based on all discount percents and grand total
    else if (discounts.every((x) => x.discount.valueType == DiscountValueType.Percent)) {
      const discountValue = _.sumBy(discounts, (x) => x.subTotal * (x.discount.percent || 0));
      const grandDiscountPercent =
        grandSubTotal == 0 ? 0 : _.round(discountValue / grandSubTotal, NUMBER_PRECISION.PERCENT);

      result = {
        type: type,
        valueType: valueType,
        percent: grandDiscountPercent,
        currency: currency,
      };
    }
    // for mixed discounts, split them by value type, calc separately and them combine into value discount
    else {
      const valueDiscounts = discounts.filter(
        (x) => x.discount.valueType == DiscountValueType.Value,
      );
      const percentDiscounts = discounts.filter(
        (x) => x.discount.valueType == DiscountValueType.Percent,
      );
      const allValueDiscounts = valueDiscounts.concat(
        percentDiscounts.map((x) => {
          x.discount = this.convertPercentToValueDiscount(x.discount, x.subTotal);
          return x;
        }),
      );
      result = this.createCompoundDiscount(allValueDiscounts);
    }

    // convert to desired value type
    if (desiredValueType != null && result.valueType != desiredValueType) {
      result = this.convertDiscountTo(result, grandSubTotal, desiredValueType);
    }

    result.isCompound = true;

    return result;
  }

  /** Based on the discount type, applies it to the provided number. */
  public static applyDiscount(
    value: number,
    discount: ExpectedDiscountType | null | undefined,
  ): number {
    if (!discount || !discount.valueType) {
      return _.round(value, NUMBER_PRECISION.MONEY);
    }
    if (discount.valueType === DiscountValueType.Percent) {
      return _.round(
        Math.max(value - value * Math.abs(discount.percent || 0), 0),
        NUMBER_PRECISION.MONEY,
      );
    } else if (discount.valueType === DiscountValueType.Value) {
      return _.round(Math.max(value - Math.abs(discount.value || 0), 0), NUMBER_PRECISION.MONEY);
    }
    return _.round(value, NUMBER_PRECISION.MONEY);
  }

  /** Based on the discount type, unapplies it from the provided number. */
  public static unapplyDiscount(
    value: number,
    discount: ExpectedDiscountType | null | undefined,
  ): number {
    if (!discount || !discount.valueType) {
      return _.round(value, NUMBER_PRECISION.MONEY);
    }
    if (discount.valueType === DiscountValueType.Percent) {
      // Apply formula: Total = SubTotal - SubTotal * DiscountPercent = SubTotal * (1 - DiscountPercent).
      // Unapply formula: SubTotal = Total / (1 - DiscountPercent).
      return _.round(value / (1 - Math.abs(discount.percent || 0)), NUMBER_PRECISION.MONEY);
    } else if (discount.valueType === DiscountValueType.Value) {
      return _.round(Math.max(value + Math.abs(discount.value || 0), 0), NUMBER_PRECISION.MONEY);
    }
    return _.round(value, NUMBER_PRECISION.MONEY);
  }

  /** Returns detailed explanation on how discount is calculated. */
  public static getDiscountExplanation({
    forValue,
    discount,
    currency,
  }: {
    forValue: {
      subTotal: number;
    };
    discount: ExpectedDiscountType;
    currency?: ExpectedCurrencyType | null;
  }) {
    const discountValue =
      (discount.valueType === DiscountValueType.Value && discount.value) ||
      (discount.valueType === DiscountValueType.Percent &&
        _.round(forValue.subTotal * (discount.percent || 0), NUMBER_PRECISION.PERCENT)) ||
      0;
    const discountPercent =
      (discount.valueType === DiscountValueType.Value &&
        _.round((discount.value || 0) / forValue.subTotal, NUMBER_PRECISION.PERCENT)) ||
      (discount.valueType === DiscountValueType.Percent && discount.percent) ||
      0;
    return {
      subTotal: forValue.subTotal,
      discountValue: discountValue,
      discountPercent: discountPercent,
      subTotalIncDiscount: _.round(
        Math.max(forValue.subTotal - discountValue, 0),
        NUMBER_PRECISION.MONEY,
      ),
      currency,
    };
  }

  //#endregion

  //#region Insurance

  /** Applies the insurance to the provided number. */
  public static applyInsurance(
    value: number,
    insurance: ExpectedInsuranceType | null | undefined,
  ): number {
    if (!insurance) {
      return _.round(value, NUMBER_PRECISION.MONEY);
    }
    return _.round(Math.max(value + (insurance?.price || 0), 0), NUMBER_PRECISION.MONEY);
  }

  /** Unapplies the insurance to the provided number. */
  public static unapplyInsurance(
    value: number,
    insurance: ExpectedInsuranceType | null | undefined,
  ): number {
    if (!insurance) {
      return _.round(value, NUMBER_PRECISION.MONEY);
    }
    return _.round(Math.max(value - (insurance?.price || 0), 0), NUMBER_PRECISION.MONEY);
  }

  //#endregion

  //#region Price summary & Line items

  public static getCurrencyFromSummary(
    summary: GeneralPriceSummaryDtoDetailedLocal,
  ): GeneralCurrencyDto | null | undefined {
    return (
      summary.currency ||
      (!_.isNumber(summary.subTotal) && summary.subTotal?.currency) ||
      (!_.isNumber(summary.total) && summary.total?.currency) ||
      summary.discount?.currency ||
      summary.tax?.currency ||
      undefined
    );
  }

  public static getSubTotalFromSummary(
    summary: GeneralPriceSummaryDtoDetailedLocal,
  ): number | null | undefined {
    return _.isNumber(summary.subTotal) ? summary.subTotal : summary.subTotal?.price;
  }

  public static getTotalFromSummary(
    summary: GeneralPriceSummaryDtoDetailedLocal,
  ): number | null | undefined {
    return _.isNumber(summary.total) ? summary.total : summary.total?.price;
  }

  /** Returns base summary from detailed summary. */
  public static getSummaryFromDetailedSummary(
    summary: GeneralPriceSummaryDtoDetailedLocal,
  ): GeneralPriceSummaryDto {
    return {
      ...(_.omit(summary, ["currency", "subTotal", "total"]) as GeneralPriceSummaryDto),
      currency: this.getCurrencyFromSummary(summary) ?? undefined,
      subTotal: this.getSubTotalFromSummary(summary) ?? undefined,
      total: this.getTotalFromSummary(summary) ?? undefined,
    };
  }

  /** Calculates total from other fields.
   *  Apply order: discount, tax, insurance.
   */
  public static calcTotalFromSummary(summary: GeneralPriceSummaryDtoDetailedLocal): number {
    const subTotal = this.getSubTotalFromSummary(summary) || 0;
    const subTotalIncDiscount = this.applyDiscount(subTotal, summary.discount);
    const subTotalIncTax = this.applyTax(subTotalIncDiscount, summary.tax);
    const subTotalIncInsurance = this.applyInsurance(subTotalIncTax, summary.insurance);
    const totalComputed = subTotalIncInsurance;
    return totalComputed;
  }

  /** Calculates sub total from other fields.
   *  Unapply order: insurance, tax, discount.
   */
  public static calcSubTotalFromSummary(summary: GeneralPriceSummaryDtoDetailedLocal): number {
    const total = this.getTotalFromSummary(summary) || 0;
    const totalExcInsurance = this.unapplyInsurance(total, summary.insurance);
    const totalExcTax = this.unapplyTax(totalExcInsurance, summary.tax);
    const totalExcDiscount = this.unapplyDiscount(totalExcTax, summary.discount);
    const subTotalComputed = totalExcDiscount;
    return subTotalComputed;
  }

  /** Calculates base summary from detailed summary. */
  public static calcPriceSummaryFromDetailedSummary({
    summary,
    calcType,
    defaultCalcType,
  }: {
    summary: GeneralPriceSummaryDtoDetailedLocal;
    /** Explicit calc type to use. */
    calcType: PriceSummaryCalcType | null | undefined;
    /** Default/fallback calc type. */
    defaultCalcType?: PriceSummaryCalcType | null | undefined;
  }): GeneralPriceSummaryDto {
    const subTotal = this.getSubTotalFromSummary(summary);
    const total = this.getTotalFromSummary(summary);

    const defaultCalcType2 = defaultCalcType || PriceSummaryCalcType.BasedOnTotal;
    const calcType2 =
      calcType ||
      (_.isNil(subTotal)
        ? PriceSummaryCalcType.BasedOnTotal
        : _.isNil(total)
        ? PriceSummaryCalcType.BasedOnSubTotal
        : defaultCalcType2);

    const isCalcSubTotal = calcType2 === PriceSummaryCalcType.BasedOnTotal;
    const isCalcTotal = calcType2 === PriceSummaryCalcType.BasedOnSubTotal;

    const subTotalComputed = isCalcSubTotal ? this.calcSubTotalFromSummary(summary) : subTotal;
    const totalComputed = isCalcTotal ? this.calcTotalFromSummary(summary) : total;

    // console.log(4, {
    //   summary,
    //   calcType,
    //   subTotal,
    //   total,
    //   calcType2,
    //   isCalcSubTotal,
    //   isCalcTotal,
    //   subTotalComputed,
    //   totalComputed,
    // });

    return {
      ...(_.omit(summary, ["currency", "subTotal", "total"]) as GeneralPriceSummaryDto),
      currency: this.getCurrencyFromSummary(summary) ?? undefined,
      subTotal: subTotalComputed ?? undefined,
      subTotalIncDiscount:
        !_.isNil(subTotalComputed) && summary.discount
          ? this.applyDiscount(subTotalComputed, summary.discount)
          : undefined,
      total: totalComputed ?? undefined,
    };
  }

  /** Calculates sub total of line item list. */
  public static calcLineItemsSubTotal(items: LineItemWithTotals[]): number {
    return _.round(_.sum(items.map((x) => x.subTotal || 0)), NUMBER_PRECISION.MONEY);
  }

  /** Calculates total of line item list. */
  public static calcLineItemsTotal(items: LineItemWithTotals[]): number {
    return _.round(_.sum(items.map((x) => x.total || 0)), NUMBER_PRECISION.MONEY);
  }

  /** Calculates summary of line item list. */
  public static calcLineItemsSummary(items: LineItemWithTotals[]): GeneralPriceSummaryDto {
    const discount = this.createCompoundDiscount(
      items.map((x) => ({
        discount: x.discount || this.zeroDiscount,
        subTotal: x.subTotal || 0,
      })),
      DiscountValueType.Percent,
    );
    const tax = this.createCompoundTax(
      items.map((x) => ({
        tax: x.tax || this.zeroTax,
        subTotal: x.subTotalIncDiscount ?? x.subTotal ?? 0,
      })),
      TaxValueType.Percent,
    );
    const subTotal = this.calcLineItemsSubTotal(items);

    return {
      discount: discount,
      tax: tax,
      subTotal: subTotal,
      subTotalIncDiscount: this.applyDiscount(subTotal, discount),
      total: this.calcLineItemsTotal(items),
    };
  }

  //#endregion
}
