import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";

import AppMountedSensor from "@/common/components/AppMountedSensor";
import BlockCode from "@/common/components/Code/BlockCode";
import InlineCode from "@/common/components/Code/InlineCode";
import ContactInput from "@/common/components/Contact/ContactInput";
import ContactSelect from "@/common/components/Contact/ContactSelect";
import GeneralDiscountInput from "@/common/components/Entity/General/GeneralDiscount/GeneralDiscountInput";
import GeneralPriceInput from "@/common/components/Entity/General/GeneralPrice/GeneralPriceInput";
import GeneralTaxInput from "@/common/components/Entity/General/GeneralTax/GeneralTaxInput";
import GeneralAddressInput from "@/common/components/Entity/General/Input/GeneralAddressInput";
import ApiEnumDtoMultiselect from "@/common/components/Enum/ApiEnumDtoMultiselect";
import ApiEnumDtoSelect from "@/common/components/Enum/ApiEnumDtoSelect";
import ApiEnumMultiselect from "@/common/components/Enum/ApiEnumMultiselect";
import ApiEnumSelect from "@/common/components/Enum/ApiEnumSelect";
import DurationInput from "@/common/components/Form/Input/DurationInput";
import PercentInput from "@/common/components/Form/Input/PercentInput";
import PhoneInput from "@/common/components/Form/Input/PhoneInput";
import PlateNoInput from "@/common/components/Form/Input/PlateNoInput";
import PriceInput from "@/common/components/Form/Input/PriceInput";
import SortSelector from "@/common/components/Form/Input/SortSelector";
import AppIcon from "@/common/components/Icons/AppIcon";
import ListOptions from "@/common/components/ListOptions/ListOptions";
import {
  CurrencyCode,
  CustomerDto,
  DiscountType,
  DiscountValueType,
  GeneralPriceInputDto,
  TaxType,
  TaxValueType,
  VehiclePartCategory,
  VehicleType,
} from "@/core/api/generated";

export default function DevInputs() {
  const { enqueueSnackbar } = useSnackbar();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined | null>(null);
  const [isoDuration, setIsoDuration] = useState<string | undefined | null>(undefined);
  const [timeSpanDuration, setTimeSpanDuration] = useState<string | undefined | null>(undefined);
  const [generalPrice, setGeneralPrice] = useState<GeneralPriceInputDto | undefined | null>(
    undefined,
  );
  const [sortBy, setSortBy] = useState<keyof CustomerDto | undefined>(undefined);

  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Inputs
      </Typography>

      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>AppMountedSensor</InlineCode>
      </Typography>
      <Typography>auto focus field on mount for example</Typography>
      <AppMountedSensor
        onMounted={() => {
          inputRef?.current?.focus();
        }}
      />
      <TextField inputRef={inputRef}></TextField>
      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>ApiEnumSelect</InlineCode>
      </Typography>
      <Box>
        <FormControl margin='normal' fullWidth>
          <InputLabel>VehicleType</InputLabel>
          <ApiEnumSelect
            type='VehicleType'
            optionProps={{
              withDescription: true,
            }}
            selectProps={{
              label: "VehicleType",
            }}
          />
        </FormControl>
        <FormControl margin='normal' fullWidth>
          <InputLabel>RepairSpecDetalizationType</InputLabel>
          <ApiEnumSelect
            type='RepairSpecDetalizationType'
            optionProps={{
              withDescription: true,
            }}
            selectProps={{
              label: "RepairSpecDetalizationType",
            }}
          />
        </FormControl>
        <FormControl margin='normal' fullWidth>
          <InputLabel>MeasurementUnit</InputLabel>
          <ApiEnumSelect
            type='MeasurementUnit'
            optionProps={{
              withDescription: true,
            }}
            selectProps={{
              label: "MeasurementUnit",
            }}
          />
        </FormControl>
      </Box>
      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>ApiEnumMultiselect</InlineCode>
      </Typography>
      <FormControl margin='normal' fullWidth>
        <ApiEnumMultiselect type='VehicleType' values={Object.values(VehicleType)} />
      </FormControl>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>GeneralDiscountInput</InlineCode>
      </Typography>
      <Box>
        <GeneralDiscountInput
          sx={{ mb: 2 }}
          defaultType={DiscountType.Trade}
          defaultValueType={DiscountValueType.Percent}
          value={undefined}
          appliesTo={undefined}
          fullWidth
          label='Test discount (percent)'
          variant='outlined'
        />

        <GeneralDiscountInput
          sx={{ mb: 2 }}
          defaultType={DiscountType.Trade}
          defaultValueType={DiscountValueType.Percent}
          value={undefined}
          appliesTo={undefined}
          fullWidth
          label='Test discount (value)'
          variant='outlined'
        />
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>GeneralTaxInput</InlineCode>
      </Typography>
      <Box>
        <GeneralTaxInput
          sx={{ mb: 2 }}
          defaultType={TaxType.Custom}
          defaultValueType={TaxValueType.Percent}
          value={undefined}
          appliesTo={undefined}
          fullWidth
          label='Test tax (percent)'
          variant='outlined'
        />

        <GeneralTaxInput
          sx={{ mb: 2 }}
          defaultType={TaxType.Custom}
          defaultValueType={TaxValueType.Value}
          value={undefined}
          appliesTo={undefined}
          fullWidth
          label='Test tax (value)'
          variant='outlined'
        />

        <GeneralTaxInput
          sx={{ mb: 2 }}
          defaultType={TaxType.Vat}
          defaultValueType={TaxValueType.Percent}
          value={undefined}
          appliesTo={undefined}
          fullWidth
          label='Test tax (VAT percent)'
          variant='outlined'
        />
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>PercentInput</InlineCode>
      </Typography>
      <Box>
        <PercentInput sx={{ mb: 2 }} fullWidth label='Percent' variant='outlined' />
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>PriceInput</InlineCode>
      </Typography>
      <Box>
        <PriceInput sx={{ mb: 2 }} fullWidth value={12.55} label='Price' variant='outlined' />
        <PriceInput
          sx={{ mb: 2 }}
          fullWidth
          value={12.55}
          label='Price with currency'
          currencyCode={CurrencyCode.Eur}
          defaultCurrencyCode={CurrencyCode.Eur}
          allowCurrencyEdit
          variant='outlined'
        />
        <PriceInput
          sx={{ mb: 2 }}
          fullWidth
          value={12.55}
          label='Price with currency'
          currencyCode={CurrencyCode.Eur}
          defaultCurrencyCode={CurrencyCode.Eur}
          variant='outlined'
        />
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>GeneralPriceInput</InlineCode>
      </Typography>
      <Box>
        <GeneralPriceInput
          sx={{ mb: 2 }}
          fullWidth
          label='Price'
          variant='outlined'
          value={generalPrice}
          onChange={(e, newValue) => setGeneralPrice(newValue)}
        />
        <GeneralPriceInput
          sx={{ mb: 2 }}
          fullWidth
          label='Price'
          allowCurrencyEdit={false}
          variant='outlined'
          value={generalPrice}
          onChange={(e, newValue) => setGeneralPrice(newValue)}
        />
        {generalPrice && <BlockCode>{JSON.stringify(generalPrice)}</BlockCode>}
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>PlateNoInput</InlineCode>
      </Typography>
      <Box>
        <PlateNoInput
          margin='dense'
          variant='outlined'
          fullWidth
          color='primary'
          placeholder='AB 1234'
          value={undefined}
          onChange={(newValue) => {
            console.log(newValue);
          }}
        />
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>DurationInput</InlineCode>
      </Typography>
      <Box>
        <Stack spacing={2}>
          <Box>
            <DurationInput
              sx={{ mb: 2 }}
              fullWidth
              label='Duration (ISO 8601)'
              variant='outlined'
              representation='ISO8601'
              value={isoDuration}
              onChange={(newValue) => setIsoDuration(newValue)}
            />
            <Typography component='div'>
              Value: <InlineCode>{isoDuration}</InlineCode>
            </Typography>
          </Box>
          <Box>
            {" "}
            <DurationInput
              sx={{ mb: 2 }}
              fullWidth
              label='Duration (.NET TimeSpan)'
              variant='outlined'
              representation='DotNetTimeSpan'
              value={timeSpanDuration}
              onChange={(newValue) => setTimeSpanDuration(newValue)}
            />
            <Typography component='div'>
              Value: <InlineCode>{timeSpanDuration}</InlineCode>
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>ListOptions</InlineCode>
      </Typography>
      <ListOptions
        startAdornment={<Button variant='outlined'>Start adornment</Button>}
        endAdornment={<Button variant='outlined'>Start adornment</Button>}
        advancedContent={
          <Stack direction={"row"} sx={{ alignItems: "center" }}>
            <TextField sx={{ mr: 1 }} margin='normal' placeholder='Placeholer text' />
            <TextField sx={{ mr: 1 }} margin='normal' placeholder='Placeholer text' />
          </Stack>
        }
      />

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>GeneralAddressInput</InlineCode>
      </Typography>
      <Box>
        <Stack direction='column' spacing={2}>
          <GeneralAddressInput />
          <GeneralAddressInput
            action={
              <IconButton>
                <AppIcon of='close' />
              </IconButton>
            }
          />
        </Stack>
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>ContactInput</InlineCode>
      </Typography>
      <Box>
        <Stack direction='row' spacing={2}>
          <ContactInput />
          <ContactInput
            action={
              <IconButton>
                <AppIcon of='close' />
              </IconButton>
            }
          />
        </Stack>
      </Box>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>ContactInput</InlineCode>
      </Typography>
      <Box>
        <Stack direction='row' spacing={2}>
          <ContactSelect
            options={[
              { id: "1", name: "John" },
              { id: "2", name: "Jack" },
              { id: "3", name: "Bob" },
            ]}
          />
        </Stack>
      </Box>
      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>ApiEnumDtoSelect</InlineCode>
      </Typography>
      <ApiEnumDtoSelect
        enumProps={{
          enumType: "VehiclePartCategory",
        }}
        label='Vehicle part category'
      />
      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>ApiEnumDtoMultiselect</InlineCode>
      </Typography>
      <ApiEnumDtoMultiselect
        enumProps={{
          enumType: "VehiclePartCategory",
        }}
        values={Object.values(VehiclePartCategory)}
        label='Vehicle part category'
      />
      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>PhoneInput</InlineCode>
      </Typography>
      <PhoneInput
        label='Phone number'
        margin='dense'
        variant='outlined'
        fullWidth
        color='primary'
        placeholder='Phone number'
        value={phoneNumber}
        onChange={(e, ph, iso) => setPhoneNumber(ph)}
      />
      <Typography variant='h5' sx={{ my: 2 }}>
        <InlineCode>SortSelector</InlineCode>
      </Typography>
      <SortSelector
        size='small'
        fields={[
          {
            field: "id",
            title: "Id",
          },
          {
            field: "type",
            title: "Type",
          },
          {
            field: "notes",
            title: "Notes",
          },
          {
            field: "address",
            title: "Address",
          },
          {
            field: "name",
            title: "Name",
          },
        ]}
        sortBy={sortBy}
        onChange={(sortByField, order) => {
          setSortBy(sortByField as keyof CustomerDto);
        }}
        sx={{ minWidth: 200 }}
      />
    </Box>
  );
}
