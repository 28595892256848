import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { apiClient } from "@/core/api/ApiClient";
import { TenantDto } from "@/core/api/generated";

import { AppThunk } from "..";

interface TenantsState {
  loading: {
    getCurrentTenant?: boolean;
    getTenants?: boolean;
    importFromCatalog?: boolean;
  };
  /** Automatically resolved tenant*/
  currentTenant?: TenantDto;
  /** Tenant selected by user */
  selectedTenant?: TenantDto;
}

const initialState: TenantsState = {
  loading: {},
  currentTenant: undefined,
  selectedTenant: undefined,
};

const tenantsSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<TenantsState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    _currentTenantFetched: (state, action: PayloadAction<TenantDto>) => {
      state.currentTenant = action.payload;
    },
    _currentTenantSelected: (state, action: PayloadAction<TenantDto>) => {
      state.selectedTenant = action.payload;
    },
  },
});

export const { setLoading, _currentTenantFetched, _currentTenantSelected } = tenantsSlice.actions;

export const tenantsReducer = tenantsSlice.reducer;

export const selectTenant =
  (tenant: TenantDto): AppThunk =>
  async (dispatch) => {
    await dispatch(_currentTenantSelected(tenant));
  };

export const getCurrentTenant = (): AppThunk => async (dispatch) => {
  dispatch(
    setLoading({
      getCurrentTenant: true,
    }),
  );
  try {
    const response = await apiClient.profileApi.apiV1ProfileTenantsCurrentGet({
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    });
    await dispatch(_currentTenantFetched(response.data));
    await dispatch(selectTenant(response.data));
  } finally {
    dispatch(
      setLoading({
        getCurrentTenant: false,
      }),
    );
  }
};
