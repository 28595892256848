import { Button, Stack, TypographyProps } from "@mui/material";

import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import {
  AppPermission,
  CustomerDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  TagEntityType,
} from "@/core/api/generated";
import { useHistory } from "react-router";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import CustomerMenu from "../CustomerMenu";
import AssetSubscriptionsTabContent from "./Tabs/AssetSubscriptionsTabContent";
import ContractsTabContent from "./Tabs/ContractsTabContent";
import EventLogTabContent from "./Tabs/EventLogTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";

export enum CustomerViewPageTabs {
  Overview = "Overview",
  Contracts = "Contracts",
  AssetSubscriptions = "AssetSubscriptions",
  EventLog = "EventLog",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<CustomerDto> {
  customerId: string | null | undefined;
  customer: CustomerDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function CustomerView({
  customerId,
  customer,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.Customer),
      viewVariant: displayProps.viewVariant,
    },
  });

  const customerRequest = useApiRequest(
    apiClient.customersApi.apiV1CustomersCustomerIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      customerId: customerId!,
    },
    {
      deps: [customerId],
      skip: !customerId || !!customer,
    },
  );
  customer = customer || customerRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.Customer, customerId || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      customerRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        customer && {
          idValue: customer.id!,
          newTitle: customer.localNumber || "",
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.Customer}
      entityId={customerId}
      entity={customer}
      entityRequest={customerRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={`Customer ${customer?.name || customer?.contact!.personName?.name}`}
              titleProps={{
                to: headerProps?.withLink ? ROUTE_PATH.CUSTOMER_VIEW(customer?.id) : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              subtitle={
                <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                  <GeneralAttachedTagsDisplay
                    tags={customer?.tags}
                    defaultIsFolded={false}
                    edit={{
                      entityType: TagEntityType.Customer,
                      entityId: customer?.id,
                      onSaved: (newValue) => {
                        customerRequest.updateData((x) => {
                          x.tags = newValue || undefined;
                        });
                      },
                    }}
                  />
                </AuthorizedElement>
              }
              primaryActions={
                displayProps.actions && (
                  <AuthorizedElement permissions={[AppPermission.CustomerManage]}>
                    <Button
                      component={AppLink}
                      to={ROUTE_PATH.CUSTOMER_EDIT(customer?.id)}
                      variant='outlined'
                      color='secondary'
                      size='medium'
                      startIcon={<AppIcon of='edit' />}
                    >
                      Edit
                    </Button>
                  </AuthorizedElement>
                )
              }
              secondaryActions={
                displayProps.actions &&
                customer && (
                  <CustomerMenu
                    entity={customer}
                    onDelete={() => history.goBack()}
                    onUpdate={(newValue) =>
                      newValue ? customerRequest.replaceData(newValue) : customerRequest.refetch()
                    }
                    displayProps={{
                      actions: {
                        edit: false,
                        delete: true,
                        inviteAsUser: true,
                      },
                    }}
                  />
                )
              }
            />
          )
        }
      >
        <Stack spacing={2}>
          <PageTabs
            tabIdsDefinition={CustomerViewPageTabs}
            defaultTabId={CustomerViewPageTabs.Overview}
            viewVariant={displayProps.viewVariant}
            commonViewParams={commonViewParams}
            tabs={[
              { label: "Overview", value: CustomerViewPageTabs.Overview, isHideable: false },
              { label: "Contracts", value: CustomerViewPageTabs.Contracts },
              {
                label: "Asset subscriptions",
                value: CustomerViewPageTabs.AssetSubscriptions,
              },
              { label: "Event log", value: CustomerViewPageTabs.EventLog },
            ]}
          >
            {({ activeTabId: activeTab }) =>
              customer && (
                <>
                  {activeTab === CustomerViewPageTabs.Overview && (
                    <OverviewTabContent customer={customer} />
                  )}
                  {activeTab === CustomerViewPageTabs.Contracts && (
                    <ContractsTabContent customer={customer} />
                  )}
                  {activeTab === CustomerViewPageTabs.AssetSubscriptions && (
                    <AssetSubscriptionsTabContent customer={customer} />
                  )}
                  {activeTab === CustomerViewPageTabs.EventLog && (
                    <EventLogTabContent customer={customer} />
                  )}
                </>
              )
            }
          </PageTabs>
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}
