import ApiEnumIcon, { ApiEnumIconProps } from "@/common/components/Icons/ApiEnumIcon";
import AppLink from "@/common/components/Link/AppLink";
import { enumService } from "@/common/services/enum";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AssessmentFlowBriefDto,
  AssessmentFlowDto,
  ContractAssessmentFlowStateFilterType,
} from "@/core/api/generated";
import { Box, CircularProgress, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";

interface Props extends Omit<ApiEnumIconProps, "type"> {
  assessmentFlowId: string;
  value: ContractAssessmentFlowStateFilterType | null | undefined;
  isEnableStateRecompute?: boolean;
  onUpdated?: (newValue: AssessmentFlowDto) => void;
}

/**  */
export default function ContractAssessmentFlowStateFilterTypeIcon({
  assessmentFlowId,
  value,
  isEnableStateRecompute,
  sx,
  onUpdated,
  ...otherProps
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const enumValueName = enumService.getEnumValueName(
    "ContractAssessmentFlowStateFilterType",
    value,
  );
  const enumValueDescription = enumService.getEnumValueDescription(
    "ContractAssessmentFlowStateFilterType",
    value,
  );

  const [isLoading, setIsLoading] = useState(false);

  return (
    <AppLink
      sx={sx}
      enabled={isEnableStateRecompute}
      to='#'
      onClick={async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
          const response =
            await apiClient.assessmentFlowsApi.apiV1FlowsAssessmentFlowsAssessmentFlowIdStateRecomputePost(
              {
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                assessmentFlowId: assessmentFlowId,
              },
            );
          enqueueSnackbar("State recomputed.", { variant: "success" });
          onUpdated && onUpdated(response.data);
        } catch (err) {
          enqueueSnackbar(ValidationHelper.getErrorsAsString(err), { variant: "error" });
        } finally {
          setIsLoading(false);
        }
      }}
    >
      {isLoading && <CircularProgress size={16} />}

      {!isLoading && (
        <ApiEnumIcon
          type='ContractAssessmentFlowStateFilterType'
          value={value}
          withTooltip
          tooltipTitle={
            <Stack>
              {value === ContractAssessmentFlowStateFilterType.All && <Box>No action needed</Box>}
              {value !== ContractAssessmentFlowStateFilterType.All && (
                <Box>
                  <Box>{enumValueName}</Box>
                  {enumValueDescription && <Box>{enumValueDescription}</Box>}
                </Box>
              )}
              {isEnableStateRecompute && <Box>Click to recompute the state</Box>}
            </Stack>
          }
          {...otherProps}
        />
      )}
    </AppLink>
  );
}
