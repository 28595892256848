import { Box, DialogContentText, FormControl, TextField } from "@mui/material";
import { useSnackbar } from "notistack";

import { ValidationHelper } from "@/common/validation";
import { AdminUserDto, UserDto, UserUnsuspendDto } from "@/core/api/generated";

import ConfirmationModal, { ConfirmationModalProps } from "../../Modals/ConfirmationModal";
import { useState } from "react";

export interface OwnProps {
  user: UserDto | AdminUserDto;
  isGlobal?: boolean;
  unsuspendFunc: (params: { targetUserId: string } & UserUnsuspendDto) => Promise<void>;
}

type Props = OwnProps &
  Omit<ConfirmationModalProps, "title" | "content" | "onCancel" | "onConfirm">;

export default function UnsuspendUserModal({
  user,
  isGlobal = false,
  unsuspendFunc,
  ...dialogProps
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [reason, setReason] = useState<string | undefined>(undefined);

  return (
    <ConfirmationModal
      fullWidth
      maxWidth='sm'
      {...dialogProps}
      title='Resume the user?'
      body={
        <>
          <DialogContentText>
            You are going to resume (unsuspend) user with the email <strong>{user.email}</strong>.
          </DialogContentText>
          <DialogContentText>
            {isGlobal
              ? `Resumed user will be able to the app.`
              : `Resumed user will be able to access your workspace.`}
          </DialogContentText>
          <DialogContentText>Please confirm the action.</DialogContentText>

          <DialogContentText>
            <FormControl fullWidth margin='dense'>
              <TextField
                multiline
                value={reason}
                label='Reason'
                onChange={(e) => setReason(e.target.value)}
              />
            </FormControl>
          </DialogContentText>
        </>
      }
      onCancel={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      onConfirm={async () => {
        try {
          await unsuspendFunc({ targetUserId: user.id!, reason });
          enqueueSnackbar("User resumed.", { variant: "success" });
          dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown");
        } catch (err) {
          const validation2 = ValidationHelper.handleApiErrorResponse(err);
          validation2.hasErrors &&
            enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
        }
      }}
    />
  );
}
