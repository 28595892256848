import { Box } from "@mui/material";

import CreateUpdateRepairMaterialPage from "@/App/MainAppView/Repair/RepairMaterial/CreateUpdateRepairMaterialPage";
import RepairMaterialViewPage from "@/App/MainAppView/Repair/RepairMaterial/RepairMaterialViewPage";
import RepairMaterialsPage from "@/App/MainAppView/Repair/RepairMaterial/RepairMaterialsPage";
import CreateUpdateRepairSpecPage from "@/App/MainAppView/Repair/RepairSpec/CreateUpdateRepairSpecPage";
import RepairSpecViewPage from "@/App/MainAppView/Repair/RepairSpec/RepairSpecViewPage";
import RepairSpecsPage from "@/App/MainAppView/Repair/RepairSpec/RepairSpecsPage";
import CreateUpdateRepairWorkPage from "@/App/MainAppView/Repair/RepairWork/CreateUpdateRepairWorkPage";
import RepairWorkListPage from "@/App/MainAppView/Repair/RepairWork/RepairWorkListPage";
import RepairWorkViewPage from "@/App/MainAppView/Repair/RepairWork/RepairWorkViewPage";
import AuthenticatedGuard from "@/common/components/Auth/AuthenticatedGuard";
import AuthorizedGuard from "@/common/components/Auth/AuthorizedGuard";
import AuthorizedRoute from "@/common/components/Auth/AuthorizedRoute";
import { AppPermission } from "@/core/api/generated";

import { RoutePathBuilder } from "@/common/builders/routePath";
import AppRoute from "@/common/components/Route/AppRoute";
import { Switch } from "react-router";
import { ROUTE_PATH } from "../../common/constants/routing";
import GlobalAlertsForAllAppAreas from "../BaseApp/GlobalAlerts/GlobalAlertsForAllAppAreas";
import NotFound from "../BaseApp/NotFound/NotFound";
import Profile from "../BaseApp/Profile/Profile";
import ProfilePersonalInfoEdit from "../BaseApp/Profile/ProfilePersonalInfoEdit";
import ProfileSettingsEdit from "../BaseApp/Profile/ProfileSettingsEdit";
import FleetCustomer_Dashboard from "../FleetCustomerApp/Dashboard/Dashboard";
import FleetAppLayout from "../Layouts/FleetAppLayout";
import AccessoriesPage from "../MainAppView/Accessories/AccessoriesPage";
import AccessoryViewPage from "../MainAppView/Accessories/AccessoriesViewPage";
import AccessoryCreateUpdatePage from "../MainAppView/Accessories/AccessoryCreateUpdatePage";
import AccessoryCheckCreateUpdatePage from "../MainAppView/AccessoryChecks/AccessoryCheckCreateUpdatePage";
import AccessoryCheckViewPage from "../MainAppView/AccessoryChecks/AccessoryCheckViewPage";
import AccessoryChecksPage from "../MainAppView/AccessoryChecks/AccessoryChecksPage";
import ChatViewPage from "../MainAppView/Chats/ChatViewPage";
import ContractCreateUpdatePage from "../MainAppView/Contracts/ContractCreateUpdatePage";
import ContractViewPage from "../MainAppView/Contracts/ContractViewPage";
import ContractsPage from "../MainAppView/Contracts/ContractsPage";
import CustomerCreateUpdatePage from "../MainAppView/Customers/CustomerCreateUpdatePage";
import CustomerViewPage from "../MainAppView/Customers/CustomerViewPage";
import CustomersPage from "../MainAppView/Customers/CustomersPage";
import DamageCostEvaluationAggregateViewPage from "../MainAppView/DamageCostEvaluationAggregates/DamageCostEvaluationAggregateViewPage";
import DamageCostEvaluationAggregatesPage from "../MainAppView/DamageCostEvaluationAggregates/DamageCostEvaluationAggregatesPage";
import DamageCostEvaluationCreateUpdatePage from "../MainAppView/DamageCostEvaluations/DamageCostEvaluationCreateUpdatePage";
import DamageCostEvaluationViewPage from "../MainAppView/DamageCostEvaluations/DamageCostEvaluationViewPage";
import DamageCostEvaluationsPage from "../MainAppView/DamageCostEvaluations/DamageCostEvaluationsPage";
import DamageDetectionAggregateViewPage from "../MainAppView/DamageDetectionAggregates/DamageDetectionAggregateViewPage";
import DamageDetectionAggregatesPage from "../MainAppView/DamageDetectionAggregates/DamageDetectionAggregatesPage";
import DamageDetectionCreateUpdatePage from "../MainAppView/DamageDetections/DamageDetectionCreateUpdatePage";
import DamageDetectionViewPage from "../MainAppView/DamageDetections/DamageDetectionViewPage";
import DamageDetectionsPage from "../MainAppView/DamageDetections/DamageDetectionsPage";
import Dashboard from "../MainAppView/Dashboard/Dashboard";
import AssessmentFlowChatPage from "../MainAppView/Flows/Assessment/AssessmentFlowChatPage";
import AssessmentFlowViewPage from "../MainAppView/Flows/Assessment/AssessmentFlowViewPage";
import AssessmentFlowsPage from "../MainAppView/Flows/Assessment/AssessmentFlowsPage";
import FlowsPage from "../MainAppView/Flows/FlowsPage";
import GeneralEventLogsPage from "../MainAppView/GeneralEventLog/GeneralEventLogsPage";
import LocationCreateUpdatePage from "../MainAppView/Locations/LocationCreateUpdatePage";
import LocationViewPage from "../MainAppView/Locations/LocationViewPage";
import LocationsPage from "../MainAppView/Locations/LocationsPage";
import CompanyProfileEditPage from "../MainAppView/Management/CompanyProfile/CompanyProfileEditPage";
import CompanyProfileViewPage from "../MainAppView/Management/CompanyProfile/CompanyProfileViewPage";
import CreateUpdateImportPage from "../MainAppView/Management/DataImport/CreateUpdateImportPage";
import ImportViewPage from "../MainAppView/Management/DataImport/ImportViewPage";
import Imports from "../MainAppView/Management/DataImport/Imports";
import DepartmentCreateUpdatePage from "../MainAppView/Management/Departments/DepartmentCreateUpdatePage";
import DepartmentViewPage from "../MainAppView/Management/Departments/DepartmentViewPage";
import DepartmentsPage from "../MainAppView/Management/Departments/DepartmentsPage";
import IntegrationApiClientCreateUpdatePage from "../MainAppView/Management/Integration/IntegrationApiClients/IntegrationApiClientCreateUpdatePage";
import IntegrationApiClientViewPage from "../MainAppView/Management/Integration/IntegrationApiClients/IntegrationApiClientViewPage";
import IntegrationApiClientsPage from "../MainAppView/Management/Integration/IntegrationApiClients/IntegrationApiClientsPage";
import IntegrationPage from "../MainAppView/Management/Integration/IntegrationPage";
import CreateNewPersonalInvite from "../MainAppView/Management/Invites/CreateNew/CreateNew";
import CreateNewAnonymousInvite from "../MainAppView/Management/Invites/CreateNewAnonymous/CreateNewAnonymous";
import InviteView from "../MainAppView/Management/Invites/InviteView";
import Invites from "../MainAppView/Management/Invites/Invites";
import InvoiceViewPage from "../MainAppView/Management/Invoices/InvoiceViewPage";
import InvoicesPage from "../MainAppView/Management/Invoices/Invoices";
import ManagementPage from "../MainAppView/Management/ManagementPage";
import PaymentProfileEdit from "../MainAppView/Management/PaymentProfile/Edit/Edit";
import PaymentProfile from "../MainAppView/Management/PaymentProfile/PaymentProfile";
import CreateUpdateRole from "../MainAppView/Management/Roles/CreateUpdate";
import Role from "../MainAppView/Management/Roles/RoleView";
import Roles from "../MainAppView/Management/Roles/Roles";
import SubscriptionPlans from "../MainAppView/Management/SubscriptionPlans/SubscriptionPlans";
import Subscriptions from "../MainAppView/Management/Subscriptions/Subscriptions";
import EditTenantSettingsPage from "../MainAppView/Management/TenantSettings/Edit/EditTenantSettingsPage";
import UpdateUserRolesPage from "../MainAppView/Management/Users/UpdateUserRolesPage";
import UserEdit from "../MainAppView/Management/Users/UserEditPage";
import UserViewPage from "../MainAppView/Management/Users/UserViewPage";
import Users from "../MainAppView/Management/Users/Users";
import WebhookCreateUpdatePage from "../MainAppView/Management/Webhooks/WebhookCreateUpdatePage";
import WebhookViewPage from "../MainAppView/Management/Webhooks/WebhookViewPage";
import WebhooksPage from "../MainAppView/Management/Webhooks/WebhooksPage";
import NegotiationViewPage from "../MainAppView/Negotiations/NegotiationViewPage";
import NegotiationsPage from "../MainAppView/Negotiations/NegotiationsPage";
import OperationsPage from "../MainAppView/Operations/OperationsPage";
import OtherPage from "../MainAppView/Other/OtherPage";
import PartyViewPage from "../MainAppView/Party/Party/PartyViewPage";
import AssetSubscriptionPlanCreateUpdatePage from "../MainAppView/Products/AssetSubscriptionPlans/AssetSubscriptionPlanCreateUpdatePage";
import AssetSubscriptionPlanViewPage from "../MainAppView/Products/AssetSubscriptionPlans/AssetSubscriptionPlanViewPage";
import AssetSubscriptionPlansPage from "../MainAppView/Products/AssetSubscriptionPlans/AssetSubscriptionPlansPage";
import AssetSubscriptionCreateUpdatePage from "../MainAppView/Products/AssetSubscriptions/AssetSubscriptionCreateUpdatePage";
import AssetSubscriptionViewPage from "../MainAppView/Products/AssetSubscriptions/AssetSubscriptionViewPage";
import AssetSubscriptionsPage from "../MainAppView/Products/AssetSubscriptions/AssetSubscriptionsPage";
import AssetCreateUpdatePage from "../MainAppView/Products/Assets/AssetCreateUpdatePage";
import AssetViewPage from "../MainAppView/Products/Assets/AssetViewPage";
import AssetsPage from "../MainAppView/Products/Assets/AssetsPage";
import PoolItemCreateUpdatePage from "../MainAppView/Products/Pools/PoolItem/PoolItemCreateUpdatePage";
import PoolItemViewPage from "../MainAppView/Products/Pools/PoolItem/PoolItemViewPage";
import PoolItemsPage from "../MainAppView/Products/Pools/PoolItem/PoolItemsPage";
import PoolCreateUpdatePage from "../MainAppView/Products/Pools/Pools/PoolCreateUpdatePage";
import PoolViewPage from "../MainAppView/Products/Pools/Pools/PoolViewPage";
import PoolsPage from "../MainAppView/Products/Pools/Pools/PoolsPage";
import ProductLocationCreateUpdatePage from "../MainAppView/Products/ProductLocations/ProductLocationCreateUpdatePage";
import ProductLocationViewPage from "../MainAppView/Products/ProductLocations/ProductLocationViewPage";
import ProductLocationsPage from "../MainAppView/Products/ProductLocations/ProductLocationsPage";
import ProductsIndexPage from "../MainAppView/Products/ProductsIndexPage";
import ReferenceDataIndexPage from "../MainAppView/ReferenceData/ReferenceDataIndexPage";
import VehicleVisualModelViewPage from "../MainAppView/ReferenceData/VehicleVisualModels/VehicleVisualModelViewPage";
import VehicleVisualModelsPage from "../MainAppView/ReferenceData/VehicleVisualModels/VehicleVisualModelsPage";
import RepairPage from "../MainAppView/Repair/RepairPage";
import CreateUpdateRepairSparePartPage from "../MainAppView/Repair/RepairSparePart/CreateUpdateRepairSparePartPage";
import RepairSparePartViewPage from "../MainAppView/Repair/RepairSparePart/RepairSparePartViewPage";
import RepairSparePartsPage from "../MainAppView/Repair/RepairSparePart/RepairSparePartsPage";
import RepairOperationCreateUpdatePage from "../MainAppView/RepairOperations/RepairOperationCreateUpdatePage";
import RepairOperationViewPage from "../MainAppView/RepairOperations/RepairOperationViewPage";
import RepairOperationsPage from "../MainAppView/RepairOperations/RepairOperationsPage";
import RootRouteEntry from "../MainAppView/RootRouteEntry";
import SpotCreateUpdatePage from "../MainAppView/Spots/SpotCreateUpdatePage";
import SpotViewPage from "../MainAppView/Spots/SpotViewPage";
import SpotsPage from "../MainAppView/Spots/SpotsPage";
import CustomTagCreateUpdatePage from "../MainAppView/Tags/CustomTags/CustomTagCreateUpdatePage";
import CustomTagViewPage from "../MainAppView/Tags/CustomTags/CustomTagViewPage";
import CustomTagsPage from "../MainAppView/Tags/CustomTags/CustomTagsPage";
import TenantViewPage from "../MainAppView/Tenant/TenantViewPage";
import DataGrantCreateUpdatePage from "../MainAppView/TenantToTenant/DataGrant/DataGrantCreateUpdatePage";
import DataGrantViewPage from "../MainAppView/TenantToTenant/DataGrant/DataGrantViewPage";
import DataGrantsPage from "../MainAppView/TenantToTenant/DataGrant/DataGrantsPage";
import TenantConnectionViewPage from "../MainAppView/TenantToTenant/TenantConnection/TenantConnectionViewPage";
import TenantConnectionsPage from "../MainAppView/TenantToTenant/TenantConnection/TenantConnectionsPage";
import TenantConnectionIndexPage from "../MainAppView/TenantToTenant/TenantConnectionIndex/TenantConnectionIndexPage";
import TenantConnectionRequestCreateUpdatePage from "../MainAppView/TenantToTenant/TenantConnectionRequest/TenantConnectionRequestCreateUpdatePage";
import TenantConnectionRequestViewPage from "../MainAppView/TenantToTenant/TenantConnectionRequest/TenantConnectionRequestViewPage";
import TenantConnectionRequestsPage from "../MainAppView/TenantToTenant/TenantConnectionRequest/TenantConnectionRequestsPage";
import TenantRequestCreateUpdatePage from "../MainAppView/TenantToTenant/TenantRequest/TenantRequestCreateUpdatePage";
import TenantRequestViewPage from "../MainAppView/TenantToTenant/TenantRequest/TenantRequestViewPage";
import TenantRequestsPage from "../MainAppView/TenantToTenant/TenantRequest/TenantRequestsPage";
import TenantToTenantIndexPage from "../MainAppView/TenantToTenant/TenantToTenantIndexPage";
import VehicleActualStatePage from "../MainAppView/VehicleActualState/VehicleActualStatePage";
import VehicleDamageViewPage from "../MainAppView/VehicleDamages/VehicleDamageViewPage";
import VehicleDamagesPage from "../MainAppView/VehicleDamages/VehicleDamagesPage";
import VehicleHistoryPage from "../MainAppView/VehicleHistory/VehicleHistoryPage";
import VehicleCreateManySimilarPage from "../MainAppView/Vehicles/VehicleCreateManySimilarPage";
import VehicleCreateUpdatePage from "../MainAppView/Vehicles/VehicleCreateUpdatePage";
import VehicleViewPage from "../MainAppView/Vehicles/VehicleViewPage";
import VehiclesPage from "../MainAppView/Vehicles/VehiclesPage";
import VisualInspectionCreateUpdatePage from "../MainAppView/VisualInspection/VisualInspectionCreateUpdatePage";
import VisualInspectionViewPage from "../MainAppView/VisualInspection/VisualInspectionViewPage";
import VisualInspectionsPage from "../MainAppView/VisualInspection/VisualInspectionsPage";

export default function FleetAppContent() {
  return (
    <AuthenticatedGuard>
      {/* FleetCustomer has access to some pages of FleetApp */}
      <AuthorizedGuard
        permissionsAny={[AppPermission.FleetAppAccess, AppPermission.FleetCustomerAppAccess]}
      >
        {/* Global alerts */}
        <Box sx={{ width: "100%" }}>
          <GlobalAlertsForAllAppAreas />
        </Box>

        <FleetAppLayout>
          {/* NB: don't use React.Fragment in Switch as it breaks routes. */}
          <Switch>
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.FLEET_PROFILE)}
              render={() => <Profile />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.FLEET_PROFILE_PERSONAL_INFO_EDIT)}
              render={() => <ProfilePersonalInfoEdit />}
            />
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.FLEET_PROFILE_SETTINGS_EDIT)}
              render={() => <ProfileSettingsEdit />}
            />
            {/* Dashboard */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DASHBOARD)}
              render={() => <Dashboard />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetCustomerAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.FLEET_CUSTOMER_DASHBOARD)}
              render={() => <FleetCustomer_Dashboard />}
            />

            {/* Management */}
            {/* Subscriptions */}
            <AuthorizedRoute
              permissions={[AppPermission.TenantSubscriptionRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_SUBSCRIPTIONS)}
              render={() => <Subscriptions />}
            />

            {/* Subscription plans */}
            <AuthorizedRoute
              permissions={[AppPermission.TenantSubscriptionRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_SUBSCRIPTION_PLANS)}
              render={() => <SubscriptionPlans />}
            />

            {/* Management */}
            <AuthorizedRoute
              permissions={[AppPermission.TenantManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT)}
              render={() => <ManagementPage />}
            />

            {/* Tenant profile */}
            <AuthorizedRoute
              permissions={[AppPermission.TenantManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_TENANT_PROFILE)}
              render={() => <CompanyProfileViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.TenantManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_TENANT_PROFILE_EDIT)}
              render={() => <CompanyProfileEditPage />}
            />

            {/* Payment profile */}
            <AuthorizedRoute
              permissions={[AppPermission.TenantSubscriptionRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_PAYMENT_PROFILE)}
              render={() => <PaymentProfile />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.TenantSubscriptionManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_PAYMENT_PROFILE_EDIT)}
              render={() => {
                return <PaymentProfileEdit />;
              }}
            />

            {/* Settings */}
            <AuthorizedRoute
              permissions={[AppPermission.TenantManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_SETTINGS_EDIT)}
              render={() => {
                return <EditTenantSettingsPage />;
              }}
            />

            {/* Imports */}
            <AuthorizedRoute
              permissions={[
                AppPermission.ImportManage,
                AppPermission.ImportRead,
                AppPermission.FleetAppAccess,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_IMPORTS_CREATE())}
              render={() => {
                return <CreateUpdateImportPage />;
              }}
            />

            <AuthorizedRoute
              permissions={[
                AppPermission.ImportManage,
                AppPermission.ImportRead,
                AppPermission.FleetAppAccess,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_IMPORTS_EDIT())}
              render={() => {
                return <CreateUpdateImportPage />;
              }}
            />

            <AuthorizedRoute
              permissions={[AppPermission.ImportRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_IMPORTS)}
              render={() => {
                return <Imports />;
              }}
            />
            <AuthorizedRoute
              permissions={[AppPermission.ImportRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_IMPORTS_VIEW())}
              render={({
                match: {
                  params: { importId },
                },
              }) => {
                return <ImportViewPage importId={importId} />;
              }}
            />

            {/* Users */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.TenantUserRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_USERS)}
              render={() => {
                return <Users />;
              }}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.FleetAppAccess,
                AppPermission.TenantRoleManage,
                AppPermission.TenantUserRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_USER_EDIT())}
              render={() => {
                return <UserEdit />;
              }}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.FleetAppAccess,
                AppPermission.RoleAssignmentManage,
                AppPermission.TenantUserRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_USER_ROLES_EDIT())}
              render={() => {
                return <UpdateUserRolesPage />;
              }}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.FleetAppAccess,
                AppPermission.TenantUserRead,
                AppPermission.TenantUserRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_USER_VIEW())}
              render={() => {
                return <UserViewPage />;
              }}
            />

            {/* Invites */}
            <AuthorizedRoute
              permissions={[AppPermission.TenantInviteRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_INVITES)}
              render={() => {
                return <Invites />;
              }}
            />
            <AuthorizedRoute
              permissions={[AppPermission.TenantInviteRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_INVITE_VIEW())}
              render={() => {
                return <InviteView />;
              }}
            />
            <AuthorizedRoute
              permissions={[AppPermission.TenantInviteRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_INVITES_CREATE_PERSONAL())}
              render={() => {
                return <CreateNewPersonalInvite />;
              }}
            />
            <AuthorizedRoute
              permissions={[AppPermission.TenantInviteRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_INVITES_CREATE_ANONYMOUS())}
              render={() => {
                return <CreateNewAnonymousInvite />;
              }}
            />

            {/* Roles */}
            <AuthorizedRoute
              permissions={[AppPermission.TenantRoleRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_ROLES)}
              render={() => {
                return <Roles />;
              }}
            />
            <AuthorizedRoute
              permissions={[AppPermission.TenantRoleRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_ROLE_CREATE)}
              render={() => {
                return <CreateUpdateRole />;
              }}
            />
            <AuthorizedRoute
              permissions={[AppPermission.TenantRoleRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_ROLE_EDIT())}
              render={() => {
                return <CreateUpdateRole />;
              }}
            />
            <AuthorizedRoute
              permissions={[AppPermission.TenantRoleRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_ROLE_VIEW())}
              render={() => {
                return <Role />;
              }}
            />

            {/* Invoices */}
            <AuthorizedRoute
              permissions={[AppPermission.InvoiceRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.INVOICES)}
              render={() => {
                return <InvoicesPage />;
              }}
            />
            <AuthorizedRoute
              permissions={[AppPermission.InvoiceRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.INVOICE_VIEW())}
              render={() => {
                return <InvoiceViewPage />;
              }}
            />

            {/* Departments */}
            <AuthorizedRoute
              permissions={[AppPermission.DepartmentRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DEPARTMENTS)}
              render={() => <DepartmentsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.DepartmentRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DEPARTMENT_VIEW())}
              render={() => <DepartmentViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.DepartmentManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DEPARTMENT_CREATE)}
              render={() => <DepartmentCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.DepartmentManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DEPARTMENT_EDIT())}
              render={() => <DepartmentCreateUpdatePage />}
            />

            {/* Locations */}
            <AuthorizedRoute
              permissions={[AppPermission.LocationRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.LOCATIONS)}
              render={() => <LocationsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.LocationRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.LOCATION_VIEW())}
              render={() => <LocationViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.LocationManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.LOCATION_CREATE)}
              render={() => <LocationCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.LocationManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.LOCATION_EDIT())}
              render={() => <LocationCreateUpdatePage />}
            />

            {/* Spots */}
            <AuthorizedRoute
              permissions={[AppPermission.SpotRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.SPOTS)}
              render={() => <SpotsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.SpotRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.SPOT_VIEW())}
              render={() => <SpotViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.SpotManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.SPOT_CREATE())}
              render={() => <SpotCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.SpotManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.SPOT_EDIT())}
              render={() => <SpotCreateUpdatePage />}
            />

            {/* Integration */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_INTEGRATION)}
              render={() => <IntegrationPage />}
            />

            {/* IntegrationApiClient */}
            <AuthorizedRoute
              permissions={[AppPermission.IntegrationApiClientRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_INTEGRATION_API_CLIENTS)}
              render={() => <IntegrationApiClientsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.IntegrationApiClientRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(
                ROUTE_PATH.MANAGEMENT_INTEGRATION_API_CLIENT_VIEW(),
              )}
              render={() => <IntegrationApiClientViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.IntegrationApiClientManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(
                ROUTE_PATH.MANAGEMENT_INTEGRATION_API_CLIENT_CREATE,
              )}
              render={() => <IntegrationApiClientCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.IntegrationApiClientManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(
                ROUTE_PATH.MANAGEMENT_INTEGRATION_API_CLIENT_EDIT(),
              )}
              render={() => <IntegrationApiClientCreateUpdatePage />}
            />

            {/* Webhook */}
            <AuthorizedRoute
              permissions={[AppPermission.WebhookRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_INTEGRATION_WEBHOOKS)}
              render={() => <WebhooksPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.WebhookRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_INTEGRATION_WEBHOOK_VIEW())}
              render={() => <WebhookViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.WebhookRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_INTEGRATION_WEBHOOK_EDIT())}
              render={() => <WebhookCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.WebhookManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.MANAGEMENT_INTEGRATION_WEBHOOK_CREATE)}
              render={() => <WebhookCreateUpdatePage />}
            />

            {/* Tenant */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.TenantRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_VIEW())}
              render={() => <TenantViewPage />}
            />

            {/* User */}
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.USER_VIEW())}
              render={() => <UserViewPage />}
            />

            {/* Vehicle */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.VehicleRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.VEHICLES)}
              render={() => <VehiclesPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.VehicleRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.VEHICLE_VIEW())}
              render={() => <VehicleViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.VehicleManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.VEHICLE_CREATE)}
              render={() => <VehicleCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.VehicleManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.VEHICLE_CREATE_MANY_SIMILAR)}
              render={() => <VehicleCreateManySimilarPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.VehicleManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.VEHICLE_EDIT())}
              render={() => <VehicleCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.VehicleHistoryRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.VEHICLE_HISTORY())}
              render={() => <VehicleHistoryPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.VehicleRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.VEHICLE_ACTUAL_STATE())}
              render={() => <VehicleActualStatePage />}
            />

            {/* VehicleDamage */}
            <AuthorizedRoute
              permissions={[AppPermission.VehicleRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.VEHICLE_DAMAGE_VIEW())}
              render={() => <VehicleDamageViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.VehicleRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.VEHICLE_DAMAGES())}
              render={() => <VehicleDamagesPage />}
            />

            {/* Accessory */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.AccessoryRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ACCESSORIES)}
              render={() => <AccessoriesPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.AccessoryManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ACCESSORY_CREATE)}
              render={() => <AccessoryCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.AccessoryManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ACCESSORY_EDIT())}
              render={() => <AccessoryCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.AccessoryRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ACCESSORY_VIEW())}
              render={() => <AccessoryViewPage />}
            />

            {/* Other */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.OTHER)}
              render={() => <OtherPage />}
            />

            {/* Customer */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.CustomerRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.CUSTOMERS)}
              render={() => <CustomersPage />}
            />

            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.CustomerRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.CUSTOMER_VIEW())}
              render={() => <CustomerViewPage />}
            />

            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.CustomerManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.CUSTOMER_CREATE)}
              render={() => <CustomerCreateUpdatePage />}
            />

            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.CustomerManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.CUSTOMER_EDIT())}
              render={() => <CustomerCreateUpdatePage />}
            />

            {/* Contract */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.ContractRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.CONTRACTS)}
              render={() => <ContractsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.ContractManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.CONTRACT_CREATE())}
              render={() => <ContractCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.ContractRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.CONTRACT_VIEW())}
              render={() => <ContractViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.ContractManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.CONTRACT_EDIT())}
              render={() => <ContractCreateUpdatePage />}
            />

            {/* Operations */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.OPERATIONS)}
              render={() => <OperationsPage />}
            />

            {/* VisualInspection */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.VisualInspectionRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.VISUAL_INSPECTIONS)}
              render={() => <VisualInspectionsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.VisualInspectionRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.VISUAL_INSPECTION_VIEW())}
              render={() => <VisualInspectionViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.VisualInspectionManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.VISUAL_INSPECTION_CREATE())}
              render={() => <VisualInspectionCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.VisualInspectionManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.VISUAL_INSPECTION_EDIT())}
              render={() => <VisualInspectionCreateUpdatePage />}
            />

            {/* DamageDetection */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.DamageDetectionRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DAMAGE_DETECTIONS)}
              render={() => <DamageDetectionsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.DamageDetectionRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DAMAGE_DETECTION_VIEW())}
              render={() => <DamageDetectionViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.DamageDetectionManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DAMAGE_DETECTION_CREATE())}
              render={() => <DamageDetectionCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.DamageDetectionManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DAMAGE_DETECTION_EDIT())}
              render={() => <DamageDetectionCreateUpdatePage />}
            />

            {/* DamageDetectionAggregate */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.DamageDetectionRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DAMAGE_DETECTION_AGGREGATES)}
              render={() => <DamageDetectionAggregatesPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.DamageDetectionRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DAMAGE_DETECTION_AGGREGATE_VIEW())}
              render={() => <DamageDetectionAggregateViewPage />}
            />

            {/* DamageCostEvaluation */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.DamageCostEvaluationRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DAMAGE_COST_EVALUATIONS)}
              render={() => <DamageCostEvaluationsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.DamageCostEvaluationRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DAMAGE_COST_EVALUATION_VIEW())}
              render={() => <DamageCostEvaluationViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.DamageCostEvaluationManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DAMAGE_COST_EVALUATION_CREATE())}
              render={() => <DamageCostEvaluationCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.DamageCostEvaluationManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DAMAGE_COST_EVALUATION_EDIT())}
              render={() => <DamageCostEvaluationCreateUpdatePage />}
            />

            {/* DamageCostEvaluationAggregate */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.DamageCostEvaluationRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DAMAGE_COST_EVALUATION_AGGREGATES)}
              render={() => <DamageCostEvaluationAggregatesPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.DamageCostEvaluationRead]}
              exact
              path={RoutePathBuilder.toDefinition(
                ROUTE_PATH.DAMAGE_COST_EVALUATION_AGGREGATE_VIEW(),
              )}
              render={() => <DamageCostEvaluationAggregateViewPage />}
            />

            {/* AccessoryCheck */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.AccessoryCheckRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ACCESSORY_CHECKS)}
              render={() => <AccessoryChecksPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.AccessoryCheckRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ACCESSORY_CHECK_VIEW())}
              render={() => <AccessoryCheckViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.AccessoryCheckManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ACCESSORY_CHECK_CREATE())}
              render={() => <AccessoryCheckCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.AccessoryCheckManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ACCESSORY_CHECK_EDIT())}
              render={() => <AccessoryCheckCreateUpdatePage />}
            />

            {/* RepairOperation */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.RepairOperationRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_OPERATIONS)}
              render={() => <RepairOperationsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.RepairOperationRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_OPERATION_VIEW())}
              render={() => <RepairOperationViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.RepairOperationManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_OPERATION_CREATE())}
              render={() => <RepairOperationCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.RepairOperationManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_OPERATION_EDIT())}
              render={() => <RepairOperationCreateUpdatePage />}
            />

            {/* Document */}
            {/* NB: temporary disabled */}
            {/* <Box>
              <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                <AuthorizedElement permissions={[AppPermission.DocumentRead]}>
                  <AppRoute
                    exact
                    path={RoutePathBuilder.toDefinition(ROUTE_PATH.DOCUMENTS())}
                    render={() => <DocumentsPage />}
                  />
                </AuthorizedElement>
                <AuthorizedElement permissions={[AppPermission.DocumentRead]}>
                  <AppRoute
                    exact
                    path={ROUTE_PATH.DOCUMENTS_DAMAGE_DETECTIONS(true)}
                    render={() => <DocumentsPage />}
                  />
                </AuthorizedElement>
                <AuthorizedElement permissions={[AppPermission.DocumentRead]}>
                  <AppRoute
                    exact
                    path={ROUTE_PATH.DOCUMENTS_DAMAGE_COST_EVALUATIONS(true)}
                    render={() => <DocumentsPage />}
                  />
                </AuthorizedElement>
                <AuthorizedElement permissions={[AppPermission.DocumentRead]}>
                  <AppRoute
                    exact
                    path={ROUTE_PATH.DOCUMENTS_ACCESSORY_CHECKS(true)}
                    render={() => <DocumentsPage />}
                  />
                </AuthorizedElement>
              </AuthorizedElement>
              <AuthorizedElement
                permissionsAny={[AppPermission.FleetAppAccess, AppPermission.FleetCustomerAppAccess]}
              >
                <AuthorizedElement permissions={[AppPermission.DocumentRead]}>
                  <AppRoute
                    exact
                    path={RoutePathBuilder.toDefinition(ROUTE_PATH.DOCUMENT_VIEW())}
                    render={() => <DocumentViewContainer />}
                  />
                </AuthorizedElement>
              </AuthorizedElement>
            </Box> */}

            {/* Flows */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.FLOWS)}
              render={() => <FlowsPage />}
            />

            {/* Assessment flow */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ASSESSMENT_FLOWS())}
              render={() => <AssessmentFlowsPage />}
            />
            <AuthorizedRoute
              permissionsAny={[AppPermission.FleetAppAccess, AppPermission.FleetCustomerAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ASSESSMENT_FLOW_VIEW())}
              render={() => <AssessmentFlowViewPage />}
            />
            <AuthorizedRoute
              permissionsAny={[AppPermission.FleetAppAccess, AppPermission.FleetCustomerAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ASSESSMENT_FLOW_CHAT_VIEW())}
              render={() => <AssessmentFlowChatPage />}
            />

            {/* Repair */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR)}
              render={() => <RepairPage />}
            />

            <AuthorizedRoute
              permissions={[AppPermission.RepairCatalogRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_MATERIALS)}
              render={() => <RepairMaterialsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.RepairCatalogManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_MATERIAL_CREATE)}
              render={() => <CreateUpdateRepairMaterialPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.RepairCatalogManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_MATERIAL_EDIT())}
              render={() => <CreateUpdateRepairMaterialPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.RepairCatalogRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_MATERIAL_VIEW())}
              render={() => <RepairMaterialViewPage />}
            />

            <AuthorizedRoute
              permissions={[AppPermission.RepairCatalogRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_WORK_LIST)}
              render={() => <RepairWorkListPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.RepairCatalogManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_WORK_CREATE)}
              render={() => <CreateUpdateRepairWorkPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.RepairCatalogManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_WORK_EDIT())}
              render={() => <CreateUpdateRepairWorkPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.RepairCatalogRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_WORK_VIEW())}
              render={() => <RepairWorkViewPage />}
            />

            <AuthorizedRoute
              permissions={[AppPermission.RepairCatalogRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_SPARE_PARTS)}
              render={() => <RepairSparePartsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.RepairCatalogManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_SPARE_PART_CREATE)}
              render={() => <CreateUpdateRepairSparePartPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.RepairCatalogManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_SPARE_PART_EDIT())}
              render={() => <CreateUpdateRepairSparePartPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.RepairCatalogRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_SPARE_PART_VIEW())}
              render={() => <RepairSparePartViewPage />}
            />

            <AuthorizedRoute
              permissions={[AppPermission.RepairCatalogRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_SPECS)}
              render={() => <RepairSpecsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.RepairCatalogRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_SPEC_VIEW())}
              render={() => <RepairSpecViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.RepairCatalogManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_SPEC_CREATE)}
              render={() => <CreateUpdateRepairSpecPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.RepairCatalogManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REPAIR_SPEC_EDIT())}
              render={() => <CreateUpdateRepairSpecPage />}
            />

            {/* ReferenceData */}
            {/* Index page */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.REFERENCE_DATA_INDEX)}
              render={() => <ReferenceDataIndexPage />}
            />

            {/* VehicleVisualModel */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.VEHICLE_VISUAL_MODELS)}
              render={() => <VehicleVisualModelsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.VEHICLE_VISUAL_MODEL_VIEW())}
              render={() => <VehicleVisualModelViewPage />}
            />

            {/* Products */}
            {/* Index page */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.ProductRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.PRODUCTS_INDEX)}
              render={() => <ProductsIndexPage />}
            />

            {/* ProductLocations */}
            <AuthorizedRoute
              permissions={[
                AppPermission.ProductLocationRead,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.PRODUCT_SALES_LOCATIONS)}
              render={() => <ProductLocationsPage />}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.ProductLocationRead,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.PRODUCT_SALES_LOCATION_VIEW())}
              render={() => <ProductLocationViewPage />}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.ProductLocationManage,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.PRODUCT_SALES_LOCATION_CREATE)}
              render={() => <ProductLocationCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.ProductLocationManage,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.PRODUCT_SALES_LOCATION_EDIT())}
              render={() => <ProductLocationCreateUpdatePage />}
            />

            {/* Asset */}
            <AuthorizedRoute
              permissions={[
                AppPermission.AssetRead,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ASSETS)}
              render={() => <AssetsPage />}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.AssetRead,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ASSET_VIEW())}
              render={() => <AssetViewPage />}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.AssetManage,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ASSET_CREATE)}
              render={() => <AssetCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.AssetManage,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ASSET_EDIT())}
              render={() => <AssetCreateUpdatePage />}
            />

            {/* Pool */}
            <AuthorizedRoute
              permissions={[
                AppPermission.PoolRead,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.POOLS)}
              render={() => <PoolsPage />}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.PoolRead,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.POOL_VIEW())}
              render={() => <PoolViewPage />}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.PoolManage,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.POOL_CREATE)}
              render={() => <PoolCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.PoolManage,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.POOL_EDIT())}
              render={() => <PoolCreateUpdatePage />}
            />
            {/* PoolItem */}
            <AuthorizedRoute
              permissions={[
                AppPermission.PoolRead,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.POOL_ITEMS())}
              render={() => <PoolItemsPage />}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.PoolRead,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.POOL_ITEM_VIEW())}
              render={() => <PoolItemViewPage />}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.PoolManage,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.POOL_ITEM_CREATE())}
              render={() => <PoolItemCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.PoolManage,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.POOL_ITEM_EDIT())}
              render={() => <PoolItemCreateUpdatePage />}
            />

            {/* AssetSubscriptionPlans */}
            <AuthorizedRoute
              permissions={[
                AppPermission.AssetSubscriptionPlanRead,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ASSET_SUBSCRIPTION_PLANS)}
              render={() => <AssetSubscriptionPlansPage />}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.AssetSubscriptionPlanRead,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ASSET_SUBSCRIPTION_PLAN_VIEW())}
              render={() => <AssetSubscriptionPlanViewPage />}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.AssetSubscriptionPlanManage,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ASSET_SUBSCRIPTION_PLAN_CREATE)}
              render={() => <AssetSubscriptionPlanCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.AssetSubscriptionPlanManage,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ASSET_SUBSCRIPTION_PLAN_EDIT())}
              render={() => <AssetSubscriptionPlanCreateUpdatePage />}
            />

            {/* AssetSubscriptions */}
            <AuthorizedRoute
              permissions={[AppPermission.AssetSubscriptionRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ASSET_SUBSCRIPTIONS)}
              render={() => <AssetSubscriptionsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.AssetSubscriptionRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ASSET_SUBSCRIPTION_VIEW())}
              render={() => <AssetSubscriptionViewPage />}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.AssetSubscriptionManage,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ASSET_SUBSCRIPTION_CREATE())}
              render={() => <AssetSubscriptionCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[
                AppPermission.AssetSubscriptionManage,
                AppPermission.FleetAppAccess,
                AppPermission.ProductRead,
              ]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.ASSET_SUBSCRIPTION_EDIT())}
              render={() => <AssetSubscriptionCreateUpdatePage />}
            />

            {/* Tenant-to-Tenant */}
            {/* Index page */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_TO_TENANT_INDEX)}
              render={() => <TenantToTenantIndexPage />}
            />

            {/* TenantConnection index page */}
            <AuthorizedRoute
              permissions={[AppPermission.TenantConnectionRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_CONNECTIONS_INDEX)}
              render={() => <TenantConnectionIndexPage />}
            />

            {/* TenantConnectionRequest */}
            <AuthorizedRoute
              permissions={[AppPermission.TenantConnectionRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_CONNECTION_REQUESTS)}
              render={() => <TenantConnectionRequestsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.TenantConnectionRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_CONNECTION_REQUEST_VIEW())}
              render={() => <TenantConnectionRequestViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.TenantConnectionRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_CONNECTION_REQUEST_CREATE)}
              render={() => <TenantConnectionRequestCreateUpdatePage />}
            />

            {/* TenantConnection */}
            <AuthorizedRoute
              permissions={[AppPermission.TenantConnectionRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_CONNECTIONS)}
              render={() => <TenantConnectionsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.TenantConnectionRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_CONNECTION_VIEW())}
              render={() => <TenantConnectionViewPage />}
            />

            {/* TenantRequest */}
            <AuthorizedRoute
              permissions={[AppPermission.TenantRequestRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_REQUESTS)}
              render={() => <TenantRequestsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.TenantRequestRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_REQUEST_VIEW())}
              render={() => <TenantRequestViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.TenantRequestManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_REQUEST_CREATE)}
              render={() => <TenantRequestCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.TenantRequestManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.TENANT_REQUEST_EDIT())}
              render={() => <TenantRequestCreateUpdatePage />}
            />

            {/* DataGrant */}
            <AuthorizedRoute
              permissions={[AppPermission.DataGrantRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DATA_GRANTS())}
              render={() => <DataGrantsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.DataGrantRead, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DATA_GRANT_VIEW())}
              render={() => <DataGrantViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.DataGrantManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DATA_GRANT_CREATE())}
              render={() => <DataGrantCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.DataGrantManage, AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.DATA_GRANT_EDIT())}
              render={() => <DataGrantCreateUpdatePage />}
            />

            {/* Chat */}
            <AuthorizedRoute
              permissions={[]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.CHAT_VIEW())}
              render={() => <ChatViewPage />}
            />
            <AuthorizedRoute
              permissions={[]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.CHAT_VIEW_BY_SCOPE())}
              render={() => <ChatViewPage />}
            />

            {/* Negotiation */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.NEGOTIATIONS)}
              render={() => <NegotiationsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.NegotiationRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.NEGOTIATION_VIEW())}
              render={() => <NegotiationViewPage />}
            />

            {/* GeneralEventLog */}
            <AuthorizedRoute
              permissions={[]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.GENERAL_EVENT_LOGS())}
              render={() => <GeneralEventLogsPage />}
            />
            {/* <AuthorizedElement permissions={[]}>
              <AppRoute
                exact
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.GENERAL_EVENT_LOG_VIEW())}
                render={() => <GeneralEventLogItemPage />}
              />
            </AuthorizedElement> */}

            {/* Party */}
            {/* Index page */}
            {/* <AuthorizedRoute
                permissions={[]}
                exact
                path={RoutePathBuilder.toDefinition(ROUTE_PATH.PRODUCTS_INDEX)}
                render={() => <ProductsIndexPage />}
              /> */}

            {/* Party */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.PARTY_VIEW())}
              render={() => <PartyViewPage />}
            />

            {/* CustomTag */}
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.CustomTagRead]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.CUSTOM_TAGS)}
              render={() => <CustomTagsPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.CustomTagManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.CUSTOM_TAG_VIEW())}
              render={() => <CustomTagViewPage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.CustomTagManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.CUSTOM_TAG_CREATE())}
              render={() => <CustomTagCreateUpdatePage />}
            />
            <AuthorizedRoute
              permissions={[AppPermission.FleetAppAccess, AppPermission.CustomTagManage]}
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.CUSTOM_TAG_EDIT())}
              render={() => <CustomTagCreateUpdatePage />}
            />

            <AppRoute
              exact
              path={ROUTE_PATH.APP_AREA_FLEET_APP}
              render={() => <RootRouteEntry />}
            />
            <AppRoute render={() => <NotFound />} />
          </Switch>
        </FleetAppLayout>
      </AuthorizedGuard>
    </AuthenticatedGuard>
  );
}
