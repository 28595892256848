import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import Datetime from "@/common/components/Datetime/Datetime";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import GeneralByWhoDisplay from "@/common/components/Entity/General/Display/GeneralByWhoDisplay";
import GeneralAttachedTagsDisplay from "@/common/components/Entity/General/GeneralTag/GeneralAttachedTagsDisplay";
import { renderIf } from "@/common/helpers/render/renderIf";
import { AppPermission, VehicleHistoryItemDto, VehicleHistoryItemType } from "@/core/api/generated";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";

interface Props {
  item: VehicleHistoryItemDto;
}

export default function VehicleHistoryItem({ item }: Props) {
  return (
    <Card>
      <CardContent>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={2}
          sx={{
            justifyContent: { xs: "space-between", md: "space-between" },
            alignItems: { xs: "flex-start", md: "center" },
          }}
        >
          <Box>
            <Typography component='div' variant='subtitle1'>
              {renderIf()
                .if(item.type === VehicleHistoryItemType.MileageChanged)
                .then(
                  <>
                    Mileage changed from {item.mileageChanged?.oldMileage} to{" "}
                    {item.mileageChanged?.newMileage}
                  </>,
                )
                .else(<InlineApiEnumValue type='VehicleHistoryItemType' value={item.type} />)
                .render()}{" "}
              <EntityChipList entity={item} variant='compact' />
            </Typography>
            <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
              {item.tags && <GeneralAttachedTagsDisplay tags={item.tags} defaultIsFolded={false} />}
            </AuthorizedElement>
          </Box>

          <Box>
            <Typography component='span' variant='caption' color='text.secondary'>
              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={{ xs: 0, md: 1 }}
                sx={{ alignItems: "center" }}
              >
                <strong>Raised</strong>{" "}
                <Datetime
                  datetime={item.date}
                  withDurationFromNow
                  direction={{ xs: "column", md: "row" }}
                />
                {item.who && <GeneralByWhoDisplay who={item.who} sx={{ display: "inline-flex" }} />}
              </Stack>
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}
