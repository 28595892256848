import { LoadingButton } from "@mui/lab";
import { Box, DialogContent, FormControl, Stack } from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useState } from "react";

import SpotsAutocompleteOrCreate from "@/common/components/Entity/Spot/SpotsAutocompleteOrCreate";
import AppModal, { AppModalProps } from "@/common/components/Modals/AppModal";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { TextHelper } from "@/common/helpers/text";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { LocationDto } from "@/core/api/generated";

interface Props extends Omit<AppModalProps, "children"> {
  location: LocationDto;
  onAdded: () => void;
}

export default function LocationAddSpotsModal({ location, onAdded, ...modalProps }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedSpotIds, setSelectedSpotIds] = useState<string[] | undefined>(undefined);

  const reset = () => {
    setSelectedSpotIds(undefined);
  };

  const handleAdd = async () => {
    setIsSubmitting(true);

    try {
      await apiClient.locationsApi.apiV1LocationsLocationIdSpotsPost({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        locationId: location.id!,
        addLocationSpotsDto: {
          spotIds: selectedSpotIds,
        },
      });
      enqueueSnackbar(
        `${TextHelper.pluralize("Spot", selectedSpotIds?.length || 0)} added to location`,
        {
          variant: "success",
        },
      );
      modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown");
      onAdded && onAdded();
      reset();
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AppModal fullWidth keepMounted={false} {...modalProps}>
      <AppModalTitle
        title={"Add spots to location"}
        withCloseIcon
        onCloseClicked={() => modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown")}
      />
      <DialogContent>
        <Stack spacing={2}>
          <Box>
            <FormControl margin='dense' fullWidth>
              <SpotsAutocompleteOrCreate
                autocompleteProps={{
                  fullWidth: true,
                  entityIds: selectedSpotIds,
                  entities: undefined,
                  searchFilters: {
                    notForLocationId: location?.id || undefined,
                    isWithLocation: false,
                  },
                  onChange: (newValues) =>
                    setSelectedSpotIds(newValues?.map((x) => x.id!) || undefined),
                }}
                createUpdateProps={{
                  defaultValues: {
                    locationId: location.id!,
                  },
                }}
                onCreate={(newValue) => {
                  setSelectedSpotIds([...(selectedSpotIds || []), newValue.id!]);
                }}
              />
            </FormControl>
          </Box>

          <LoadingButton
            fullWidth
            variant='contained'
            disabled={_.isEmpty(selectedSpotIds)}
            loading={isSubmitting}
            onClick={handleAdd}
          >
            Save
          </LoadingButton>
        </Stack>
      </DialogContent>
    </AppModal>
  );
}
