import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";

import { ImportEntityType } from "@/core/api/generated";

interface ImportEntityTypeSelectProps {
  importEntityType?: ImportEntityType | null;
  selectProps?: SelectProps;
  onChange?: (
    e: SelectChangeEvent<unknown>,
    importEntityType?: ImportEntityType | undefined,
  ) => void;
}

function ImportEntityTypeSelect({
  importEntityType,
  selectProps,
  onChange,
}: ImportEntityTypeSelectProps) {
  const importEntityTypes = [ImportEntityType.Accessory, ImportEntityType.Vehicle];

  const _onChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const entityType = (importEntityTypes || []).find((x) => x === e.target.value);
      onChange && onChange(e, entityType);
    },
    [onChange],
  );

  return (
    <Box>
      <Select
        label='Import type'
        displayEmpty={false}
        fullWidth
        value={importEntityType || ""}
        onChange={_onChange}
        {...selectProps}
      >
        {importEntityTypes &&
          importEntityTypes.map((type, index) => (
            <MenuItem key={index} value={type}>
              {type}
            </MenuItem>
          ))}
      </Select>
    </Box>
  );
}

export default ImportEntityTypeSelect;
