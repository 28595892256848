import {
  Badge,
  IconButton,
  IconButtonProps,
  Popover,
  SxProps,
  Theme,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ROUTE_PATH } from "@/common/constants/routing";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import { chatHubService } from "@/common/realtime/chatHubService";
import { ValidationHelper } from "@/common/validation";
import { featureFlagsConfig } from "@/config/config";
import { apiClient } from "@/core/api/ApiClient";
import {
  ChatActivityDto,
  ChatDto,
  ChatType,
  GeneralScopeDto,
  GeneralScopeRequestDto,
  GeneralScopeType,
} from "@/core/api/generated";
import * as chatActivitySlice from "@/store/communication/chatActivityOverviewSlice";
import * as chatsSlice from "@/store/communication/chatsSlice";
import {
  CommentChatParams,
  OpenChatInfo,
  OpenChatPlacement,
} from "@/store/communication/chatsSlice";
import { selectChat, selectIsChatOpening } from "@/store/communication/selectors";

import AppIcon from "../../Icons/AppIcon";
import Chat from "./Chat";
import { useEffectOnce } from "@/common/hooks/effect/useEffectOnce";
import { useEffectOnceIf } from "@/common/hooks/effect/useEffectOnceIf";
import AppIconButton from "../../Button/AppIconButton";

export interface OwnProps {
  chatType: ChatType;
  chatId?: string | null;
  chatScope?: GeneralScopeDto | null;
  chatPlacement?: OpenChatPlacement | "custom";
  commentChatParams?: CommentChatParams;
  /** Link to custom page. */
  to?: string;
  /** Whether dot is shown when new activity has been performed in the chat. */
  withActivityIndicator?: boolean;
  withAutoOpenOnMount?: boolean;
  tooltipTitle?: string;
  disabled?: boolean;
  activatorButtonProps?: IconButtonProps;
  sx?: SxProps<Theme>;
}

interface Props extends OwnProps {}

/** Displays button/icon that opens chat. */
export default function ChatActivator({
  chatType,
  chatId,
  chatScope,
  chatPlacement,
  commentChatParams,
  to,
  withActivityIndicator,
  withAutoOpenOnMount,
  tooltipTitle = "Open chat",
  disabled,
  activatorButtonProps,
  sx,
}: Props) {
  chatPlacement ||= "popover";

  const history = useHistory();
  const thunkDispatch = useAppThunkDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [chatIdLocal, setChatIdLocal] = useState<string | undefined | null>(undefined);
  const [openedChat, setOpenedChat] = useState<OpenChatInfo | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isPopoverOpen = Boolean(anchorEl);

  const chat = useAppSelector((state) => selectChat(state, { chatId: chatId, scope: chatScope }));
  const resolvedChatId = chatId || chat?.id || chatIdLocal;
  const chatActivity = useAppSelector(
    (x) => x.communication.chatActivityOverviews.chatActivityMap[resolvedChatId || ""] || undefined,
  );
  const isChatOpening = useAppSelector((state) => selectIsChatOpening(state, resolvedChatId));

  const shouldTrackActivity = featureFlagsConfig.chatActivity && withActivityIndicator;
  const hasNewActivity = (withActivityIndicator && chatActivity?.hasNewActivity) || false;

  const getChatActivityOverviewThrottle = useCallback(
    _.throttle(
      (...args: Parameters<typeof chatActivitySlice.getChatActivityOverview>) =>
        thunkDispatch(chatActivitySlice.getChatActivityOverview(...args)),
      1000,
    ),
    [],
  );
  const getChatActivityByScopeThrottle = useCallback(
    _.throttle(
      (...args: Parameters<typeof chatActivitySlice.getChatActivityOverviewByScope>) =>
        thunkDispatch(chatActivitySlice.getChatActivityOverviewByScope(...args)),
      1000,
    ),
    [],
  );

  useEffect(() => {
    (async () => {
      if (shouldTrackActivity) {
        if (chatId) {
          thunkDispatch(chatActivitySlice.queueGetChatActivityOverview(chatId));
        } else if (chatScope) {
          const scopeRequest: GeneralScopeRequestDto = {
            identifier: chatScope.identifier || undefined,
            subIdentifier: chatScope.subIdentifier || undefined,
            type: chatScope.type || GeneralScopeType.Dynamic,
          };
          const chatByScope = await apiClient.chatsApi.apiV1ChatsByScopeGet({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            identifier: scopeRequest.identifier || undefined,
            subIdentifier: scopeRequest.subIdentifier || undefined,
            type: scopeRequest.type || undefined,
          });
          const chatIdByScope = chatByScope.data.id!;
          setChatIdLocal(chatIdByScope);
          thunkDispatch(chatActivitySlice.queueGetChatActivityOverview(chatIdByScope));
        }
      }
    })();
  }, [shouldTrackActivity, chatId, chatScope]);

  // subscribe on chat activity updates
  useEffect(() => {
    if (shouldTrackActivity) {
      chatHubService.subscribeOnChatActivityUpdates({
        chatId: resolvedChatId!,
      });
    }

    return () => {
      if (shouldTrackActivity) {
        chatHubService.unsubscribeFromChatActivityUpdates({
          chatId: resolvedChatId!,
        });
      }
    };
  }, [resolvedChatId]);

  const openChatInPopover = async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e?.currentTarget || null);
    try {
      const newOpenedChat = await thunkDispatch(
        chatsSlice.openChat({
          placement: "popover",
          chatType,
          chatId,
          scope: chatScope,
          commentChatParams,
        }),
      );
      setOpenedChat(newOpenedChat);
    } catch (err) {
      const newValidation = ValidationHelper.handleApiErrorResponse(err);
      enqueueSnackbar(newValidation.generalError, { variant: "error" });
    }
  };

  const openChatInSideStack = async () => {
    try {
      const newOpenedChat = await thunkDispatch(
        chatsSlice.openChat({
          placement: "stack",
          chatType,
          chatId,
          scope: chatScope,
          commentChatParams,
        }),
      );
      setOpenedChat(newOpenedChat);
    } catch (err) {
      const newValidation = ValidationHelper.handleApiErrorResponse(err);
      enqueueSnackbar(newValidation.generalError, { variant: "error" });
    }
  };

  const openChatInPage = useCallback(async () => {
    if (chatId) {
      history.push(ROUTE_PATH.CHAT_VIEW(chatId, { chatType }));
    } else if (chatScope) {
      history.push(ROUTE_PATH.CHAT_VIEW_BY_SCOPE(chatScope, { chatType }));
    }
  }, [chatScope, history]);

  const handleActivatorClick = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.stopPropagation();
    if (to) {
      history.push(to);
      return;
    }

    if (chatPlacement === "popover") {
      openChatInPopover(e);
    } else if (chatPlacement === "stack") {
      openChatInSideStack();
    } else if (chatPlacement === "page") {
      openChatInPage();
    }
  };

  const handlePinChat = useCallback(() => {
    if (chatPlacement !== "stack") {
      openChatInSideStack();
      setAnchorEl(null);
    }
  }, [chatPlacement, openChatInSideStack, setAnchorEl]);

  const handleChatPopoverClose = useCallback(() => {
    setAnchorEl(null);
    thunkDispatch(chatsSlice.closeChat({ openedChat, chat }));
  }, [openedChat, chat, setAnchorEl]);

  useEffectOnceIf(!!withAutoOpenOnMount, () => {
    handleActivatorClick();
  });

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <AppIconButton
          {...activatorButtonProps}
          disabled={disabled}
          loading={isChatOpening}
          onClick={handleActivatorClick}
          sx={sx}
        >
          <Badge
            color='primary'
            variant='dot'
            sx={{
              "& .MuiBadge-badge": {
                opacity: hasNewActivity ? 1 : 0,
              },
            }}
          >
            <AppIcon of='chat' />
          </Badge>
        </AppIconButton>
      </Tooltip>

      {/* Chat popover */}
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleChatPopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Box sx={{ minWidth: "400px", maxWidth: { sm: "100vw", md: "400px" } }}>
          <Chat
            chatType={chatType}
            chatId={chatId}
            scope={chatScope}
            allowPin
            allowClose
            allowLeave
            size='small'
            displayProps={{
              contentHeader: false,
              contentFolding: false,
            }}
            onPin={handlePinChat}
            onClose={handleChatPopoverClose}
          />
        </Box>
      </Popover>
    </>
  );
}
