import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";

import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { FormikHelper } from "@/common/helpers/formik";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { useUserAffiliation } from "@/common/hooks/useUserAffiliation";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AssetCreateDto,
  AssetDto,
  AssetEntityType,
  AssetSpecDto,
  AssetSpecInputDto,
  AssetUpdateDto,
  EntityType,
} from "@/core/api/generated";
import { useState } from "react";
import FoldableBlock from "../../Display/FoldableBlock";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FileUploader from "../../Files/FileUploader";
import AccessoriesSelectOrCreate from "../Accessory/AccessoriesSelectOrCreate";
import EntityAffiliationInput from "../EntityAffiliation/EntityAffiliationInput";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";
import GeneralStrictEntityRelationInput from "../General/Input/GeneralStrictEntityRelationInput";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";
import AssetSpecInput, {
  assetSpecToInputDto,
  assetSpecToInputDtoFormatBeforePost,
} from "./AssetSpecInput";

type DefaultValues = {
  entity?: AssetCreateDto["entity"];
  name?: AssetCreateDto["name"];
  spec?: AssetSpecDto | AssetSpecInputDto | null;
};

export interface AssetCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<AssetDto, DefaultValues> {}

export type AssetCreateUpdateProps = AssetCreateUpdateOwnProps;

export default function AssetCreateUpdate({
  entityId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: AssetCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const isCreate = !entityId;
  const isEdit = !!entityId;

  const [isUploadingFiles, setIsUploadingFiles] = useState(false);

  const assetRequest = useApiRequest(
    apiClient.assetsApi.apiV1AssetsAssetIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetId: entityId!,
    },
    {
      skip: !entityId,
    },
  );
  const asset = assetRequest?.data;

  const { departments, locations } = useUserAffiliation();

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: asset && {
      idValue: asset.id!,
      newTitle: asset.localNumber || "",
    },
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.Asset}
      entityId={entityId}
      entity={asset}
      entityRequest={assetRequest}
    >
      <Formik<
        BaseFormikValues &
          AssetCreateDto &
          AssetUpdateDto & {
            initialAttachments: AssetCreateDto["attachments"];
            uploadedAttachments?: FileItem[];
          }
      >
        enableReinitialize={true}
        initialValues={{
          entity:
            defaultValues?.entity || asset?.entity
              ? {
                  entityType:
                    defaultValues?.entity?.entityType ||
                    (asset?.entity?.entityType as AssetEntityType) ||
                    undefined,
                  entityId: defaultValues?.entity?.entityId || asset?.entity?.entityId || undefined,
                }
              : undefined,
          name: defaultValues?.name || asset?.name || undefined,
          description: asset?.description || undefined,
          departmentId:
            asset?.departmentIds?.at(0) ||
            (departments && departments[0] && departments[0].id) ||
            undefined,
          locationId:
            asset?.locationIds?.at(0) ||
            (locations && locations[0] && locations[0].id) ||
            undefined,
          spec:
            (asset?.spec && assetSpecToInputDto(asset?.spec)) ||
            (defaultValues?.spec && assetSpecToInputDto(defaultValues?.spec)) ||
            (defaultValues?.entity?.entityType && {
              entityType: defaultValues.entity.entityType,
            }) ||
            undefined,
          accessoryIds: asset?.accessories?.map((x) => x.id!) || undefined,
          attachments: asset?.attachments || undefined,
          settings: asset?.settings || undefined,
          tags: asset?.tags || undefined,

          initialAttachments: asset?.attachments || undefined,

          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          const values2: typeof values = {
            ...values,
            spec: assetSpecToInputDtoFormatBeforePost(values.spec),
          };

          try {
            if (isCreate) {
              const response = await apiClient.assetsApi.apiV1AssetsPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                assetCreateDto: {
                  ...values2,
                },
              });
              enqueueSnackbar("Asset created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response = await apiClient.assetsApi.apiV1AssetsAssetIdPut({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                assetId: entityId,
                assetUpdateDto: {
                  ...values2,
                },
              });
              enqueueSnackbar("Asset updated.", { variant: "success" });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {(formikProps) => {
          const {
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setErrors,
            setFieldValue,
            setValues,
          } = formikProps;

          const specFormikProps = FormikHelper.getSubProps(formikProps, "spec", (v) => v.spec);

          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Box>
                  {/* Tags */}
                  <FormControl margin='dense' fullWidth>
                    <GeneralAttachedTagsInput
                      value={values.tags}
                      onChange={(newValue) => {
                        setFieldValue("tags", newValue);
                      }}
                    />
                    <FormHelperText error>
                      {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                        isIncludeNested: false,
                      })}
                    </FormHelperText>
                  </FormControl>

                  {/* Entity */}
                  <FormControl margin='dense' fullWidth required>
                    <GeneralStrictEntityRelationInput
                      onlyEntityTypes={Object.values(AssetEntityType)}
                      value={values.entity}
                      disabled={isEdit}
                      entityTypeAutocompleteProps={{
                        required: true,
                        textFieldProps: {
                          error: Boolean(errors?.entity),
                          helperText: ValidationHelper.getFormikErrorsAsString(errors?.entity),
                        },
                      }}
                      entityAutocompleteProps={{
                        label: "Entity",
                        required: true,
                        textFieldProps: {
                          error: Boolean(errors?.entity),
                          helperText: ValidationHelper.getFormikErrorsAsString(errors?.entity),
                        },
                        searchFilters: {
                          assetMeta: {
                            hasAsset: false,
                          },
                        },
                      }}
                      onChange={async (newValue) => {
                        setFieldValue(`entity`, newValue);
                        setFieldValue(`spec`, undefined);
                        setFieldValue(`spec.entityType`, newValue?.entityType as AssetEntityType);

                        // infer spec
                        if (newValue && newValue.entityId) {
                          const inferredSpec = (
                            await apiClient.assetsApi.apiV1AssetsSpecInferFromEntityGet({
                              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                              entityType: newValue.entityType as AssetEntityType,
                              entityId: newValue.entityId,
                            })
                          ).data;
                          setFieldValue(`spec`, assetSpecToInputDto(inferredSpec));
                        }
                      }}
                    />
                  </FormControl>

                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label='Name'
                    margin='dense'
                    name='name'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                    value={values.name || ""}
                    variant='outlined'
                  />

                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    multiline
                    minRows={2}
                    helperText={touched.description && errors.description}
                    label='Description'
                    margin='dense'
                    name='description'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                    value={values.description || ""}
                    variant='outlined'
                  />
                  {/* Entity affiliation */}

                  <EntityAffiliationInput
                    department={{
                      departmentId: values.departmentId,
                      onChange: (d) => {
                        setFieldValue("departmentId", d?.id);
                        setFieldValue("locationId", undefined);
                      },
                      error: errors.departmentId,
                    }}
                    location={{
                      locationId: values.locationId,
                      onChange: (l) => {
                        setFieldValue("locationId", l?.id);
                      },
                      searchFilters: { departmentId: values.departmentId },
                      createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                      error: errors.locationId,
                      disabled: !values.departmentId,
                    }}
                  />
                </Box>

                <Fade
                  in={!!values.entity && !!values.entity.entityType && !!values.entity.entityId}
                  unmountOnExit
                >
                  <Stack spacing={2}>
                    {/* AssetSpec */}
                    <FoldableBlock
                      defaultIsFolded
                      trigger={{
                        label: <Typography variant='subtitle1'>Asset spec *</Typography>,
                      }}
                    >
                      <AssetSpecInput
                        formikProps={specFormikProps}
                        displayProps={{
                          editEntityType: false,
                          enableEditEntityType: false,
                        }}
                      />
                    </FoldableBlock>

                    {/* Accessories */}
                    <FoldableBlock
                      defaultIsFolded
                      trigger={{
                        label: <Typography variant='subtitle1'>Accessories</Typography>,
                      }}
                    >
                      <FormControl margin='dense' fullWidth>
                        <AccessoriesSelectOrCreate
                          autocompleteProps={{
                            entityIds: values.accessoryIds,
                            entities: undefined,
                            isPreload: isCreate,
                            textFieldProps: {
                              error: Boolean(errors.accessoryIds),
                              helperText: ValidationHelper.getFormikErrorsAsString(
                                errors.accessoryIds,
                              ),
                            },
                            onChange: (newValues) => {
                              setFieldValue("accessoryIds", newValues?.map((x) => x.id!));
                            },
                          }}
                          createFormPlacement='modal'
                          onCreate={(newValue) => {
                            setFieldValue("accessoryIds", [
                              ...(values.accessoryIds || []),
                              newValue.id!,
                            ]);
                          }}
                        />
                      </FormControl>
                    </FoldableBlock>

                    {/* Attachments */}
                    <FoldableBlock
                      defaultIsFolded
                      trigger={{
                        label: <Typography variant='subtitle1'>Attachments</Typography>,
                      }}
                    >
                      <FormControl fullWidth margin='none'>
                        <FileUploader
                          multiple
                          maxFiles={100}
                          defaultFiles={FileItem.createManyFrom(
                            values.uploadedAttachments ||
                              values.attachments ||
                              values.initialAttachments,
                          )}
                          onChange={(newFiles) => {
                            setFieldValue(
                              "attachments",
                              FileItem.toManyGeneralAttachmentInputDto(newFiles),
                            );
                            setFieldValue("uploadedAttachments", newFiles);
                          }}
                          onUploadStarted={() => {
                            setIsUploadingFiles(true);
                          }}
                          onUploadFinished={() => {
                            setIsUploadingFiles(false);
                          }}
                        />

                        {errors.attachments && (
                          <FormHelperText error>
                            {ValidationHelper.getFormikErrorsAsString(errors.attachments)}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </FoldableBlock>

                    {/* Settings */}
                    <FoldableBlock
                      defaultIsFolded
                      trigger={{
                        label: <Typography variant='subtitle1'>Settings</Typography>,
                      }}
                    >
                      <Box>
                        <FormControl margin='dense'>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.settings?.isSyncSpec ?? false}
                                onChange={(e) =>
                                  setFieldValue("settings.isSyncSpec", e.target.checked)
                                }
                              />
                            }
                            label='Sync spec'
                          />
                          <FormHelperText>
                            If checked, asset spec will be automatically synced with the source
                            entity spec. For instance, changes to vehicle spec will be synced to its
                            asset.
                          </FormHelperText>
                        </FormControl>
                      </Box>
                    </FoldableBlock>
                  </Stack>
                </Fade>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <LoadingButton
                  sx={{ mt: { xs: "auto", md: 2 } }}
                  color='primary'
                  loading={isSubmitting}
                  disabled={isUploadingFiles}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </LoadingButton>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
