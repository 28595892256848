import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import ApiEnumDtoSelect from "@/common/components/Enum/ApiEnumDtoSelect";
import ApiEnumMultiselect from "@/common/components/Enum/ApiEnumMultiselect";
import ApiEnumSelect from "@/common/components/Enum/ApiEnumSelect";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AdminCreateVehiclePartTypeDto,
  AdminUpdateVehiclePartTypeDto,
  VehicleType,
} from "@/core/api/generated";
import DamageTypesSelector from "../../components/ReferenceData/DamageTypesSelector";
import ReferenceDataSyncStopAlert from "../ReferenceDataSyncStopAlert";

export default function VehiclePartTypeCreateUpdatePage() {
  const { partTypeId } = useParams<{ partTypeId?: string }>();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const isCreate = !partTypeId;
  const isEdit = !!partTypeId;

  const partTypeRequest = useApiRequest(
    apiClient.adminVehiclePartTypesApi.apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdGet,
    {
      partTypeId: partTypeId!,
    },
    {
      skip: !partTypeId,
    },
  );
  const partType = partTypeRequest?.data;

  const vehiclePartTypesEnumRequest = useApiRequest(
    apiClient.enumsApi.apiV1EnumsByTypeNameVehicleparttypeGet,
    {},
    {},
  );
  const vehiclePartTypesEnum = vehiclePartTypesEnumRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: partType && {
      idValue: partType.id!,
      newTitle: partType.name || partType.globalNumber || "",
    },
  });

  return (
    <Formik<AdminCreateVehiclePartTypeDto & AdminUpdateVehiclePartTypeDto & BaseFormikValues>
      enableReinitialize
      initialValues={{
        vehicleType: partType?.vehicleType || VehicleType.Car,
        category: partType?.category || undefined,
        type: partType?.type || undefined,
        areas: partType?.areas || undefined,
        projections: partType?.projections,
        name: partType?.name || undefined,
        description: partType?.description || undefined,
        isCategoryRoot: partType?.isCategoryRoot || false,
        isTypeRoot: partType?.isTypeRoot || false,
        isEnabledForDamageDetection: partType?.isEnabledForDamageDetection || true,
        damageTypeIds: partType?.damageTypeIds || undefined,
        isEnabled: partType?.isEnabled ?? true,
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        // category: Yup.string().required("Category is required"),
        // name: Yup.string().required("Name is required"),
      })}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          if (isCreate) {
            await apiClient.adminVehiclePartTypesApi.apiV1AdminReferenceDataVehiclesPartTypesPost({
              adminCreateVehiclePartTypeDto: {
                ..._.omit(values, "submit"),
              },
            });
            enqueueSnackbar("Vehicle part type created.", { variant: "success" });
          } else {
            await apiClient.adminVehiclePartTypesApi.apiV1AdminReferenceDataVehiclesPartTypesPartTypeIdPut(
              {
                partTypeId,
                adminUpdateVehiclePartTypeDto: {
                  ..._.omit(values, "submit"),
                },
              },
            );
            enqueueSnackbar("Vehicle part type updated.", { variant: "success" });
          }
          if (mounted.current) {
            setStatus({ success: true });
          }

          history.goBack();
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <CreateUpdatePageLayout
            header={
              <SimpleViewPageHeader title={isCreate ? "Create new part type" : "Edit part type"} />
            }
          >
            <form noValidate onSubmit={handleSubmit}>
              {partType && isEdit && (
                <ReferenceDataSyncStopAlert
                  sx={{ mb: 2 }}
                  isBuiltIn={partType.isBuiltIn ?? false}
                  car2DbId={undefined}
                />
              )}

              <Box>
                <FormControl
                  sx={{ minWidth: 200 }}
                  fullWidth
                  margin='normal'
                  error={Boolean(touched.vehicleType && errors.vehicleType)}
                >
                  <InputLabel>Vehicle type</InputLabel>
                  <ApiEnumSelect
                    type='VehicleType'
                    value={values.vehicleType}
                    onChange={(newValue) => setFieldValue("vehicleType", newValue)}
                    selectProps={{
                      required: true,
                      disabled: isEdit,
                      label: "Vehicle type",
                    }}
                  />
                  <FormHelperText>{touched.vehicleType && errors.vehicleType}</FormHelperText>
                </FormControl>

                <FormControl
                  sx={{ minWidth: 200 }}
                  fullWidth
                  margin='normal'
                  required
                  error={Boolean(touched.category && errors.category)}
                >
                  <InputLabel>Category</InputLabel>
                  <ApiEnumDtoSelect
                    enumProps={{
                      enumType: "VehiclePartCategory",
                    }}
                    value={values.category}
                    onChange={(newValue, newValueDto) => {
                      setFieldValue("category", newValue);

                      const linkedArea = newValueDto?.enumLinks?.find(
                        (x) => x.enumTypeName === "VehicleArea",
                      );
                      const linkedProjection = newValueDto?.enumLinks?.find(
                        (x) => x.enumTypeName === "VehicleProjection",
                      );
                      if (linkedArea && linkedArea.enumValues?.length !== 0) {
                        setFieldValue("area", linkedArea.enumValues![0]);
                      } else {
                        setFieldValue("projection", "");
                      }
                      if (linkedProjection && linkedProjection.enumValues?.length !== 0) {
                        setFieldValue("projection", linkedProjection.enumValues![0]);
                      } else {
                        setFieldValue("projection", "");
                      }
                    }}
                    allowNone
                    label='Category'
                  />
                  <FormHelperText>{touched.category && errors.category}</FormHelperText>
                </FormControl>

                <FormControl
                  sx={{ minWidth: 200 }}
                  fullWidth
                  margin='normal'
                  // required
                  error={Boolean(touched.type && errors.type)}
                >
                  <InputLabel>Type</InputLabel>
                  <ApiEnumDtoSelect
                    disabled={values.isCategoryRoot}
                    enumProps={{
                      enumType: "VehiclePartType",
                      category: values.category,
                    }}
                    value={values.type}
                    onChange={(newValue, newValueDto) => {
                      setFieldValue("type", newValue);

                      const linkedArea = newValueDto?.enumLinks?.find(
                        (x) => x.enumTypeName === "VehicleArea",
                      );
                      const linkedProjection = newValueDto?.enumLinks?.find(
                        (x) => x.enumTypeName === "VehicleProjection",
                      );
                      if (linkedArea && linkedArea.enumValues?.length !== 0) {
                        setFieldValue("area", linkedArea.enumValues![0]);
                      } else {
                        setFieldValue("projection", "");
                      }
                      if (linkedProjection && linkedProjection.enumValues?.length !== 0) {
                        setFieldValue("projection", linkedProjection.enumValues![0]);
                      } else {
                        setFieldValue("projection", "");
                      }
                    }}
                    allowNone={true}
                    selectProps={{
                      label: "Type",
                    }}
                  />
                  <FormHelperText>{touched.type && errors.type}</FormHelperText>
                </FormControl>

                <FormControl
                  sx={{ minWidth: 200 }}
                  fullWidth
                  margin='normal'
                  required
                  error={Boolean(errors.areas)}
                >
                  <InputLabel>Areas</InputLabel>
                  <ApiEnumMultiselect
                    type='VehicleArea'
                    values={values.areas}
                    onChange={(newValue) => setFieldValue("areas", newValue)}
                    selectProps={{
                      label: "Areas",
                    }}
                  />
                  <FormHelperText>
                    {ValidationHelper.getErrorsAsString(errors.areas)}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  sx={{ minWidth: 200 }}
                  fullWidth
                  margin='normal'
                  error={Boolean(touched.projections && errors.projections)}
                >
                  <InputLabel>Projections</InputLabel>
                  <ApiEnumMultiselect
                    type='VehicleProjection'
                    values={values.projections}
                    onChange={(newValue) => setFieldValue("projections", newValue)}
                    selectProps={{
                      label: "Projection",
                    }}
                  />
                  <FormHelperText>{touched.projections && errors.projections}</FormHelperText>
                </FormControl>

                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='isCategoryRoot'
                        checked={values.isCategoryRoot}
                        disabled={values.isTypeRoot}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("type", undefined);
                          setFieldValue("isTypeRoot", false);
                        }}
                      />
                    }
                    label={
                      <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                        <span>Category root?</span>
                        <Tooltip
                          title={`If true - part type entity represents part type category, meaning it's the most basic/generic in this category.`}
                        >
                          <InfoIcon fontSize='inherit' />
                        </Tooltip>
                      </Stack>
                    }
                  />
                </FormGroup>

                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='isTypeRoot'
                        checked={values.isTypeRoot}
                        disabled={values.isCategoryRoot}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("isCategoryRoot", false);
                        }}
                      />
                    }
                    label={
                      <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                        <span>Type root?</span>
                        <Tooltip
                          title={`If true - part type entity represents part type, meaning it's the most basic/generic in this type.`}
                        >
                          <InfoIcon fontSize='inherit' />
                        </Tooltip>
                      </Stack>
                    }
                  />
                </FormGroup>

                <TextField
                  required
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label='Name'
                  margin='normal'
                  name='name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.name || ""}
                  variant='outlined'
                />

                <TextField
                  multiline
                  rows={2}
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  label='Description'
                  margin='normal'
                  name='description'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.description || ""}
                  variant='outlined'
                />

                <TextField
                  autoFocus
                  rows={2}
                  error={Boolean(touched.sortOrder && errors.sortOrder)}
                  fullWidth
                  helperText={touched.sortOrder && errors.sortOrder}
                  label='SortOrder'
                  margin='normal'
                  name='sortOrder'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputMode='numeric'
                  value={values.sortOrder || 0}
                  variant='outlined'
                  InputProps={{ inputProps: { min: 0, max: 10000000 } }}
                />

                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='isEnabledForDamageDetection'
                        checked={values.isEnabledForDamageDetection}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    }
                    label='Enabled for damage detection'
                  />
                </FormGroup>

                <FormGroup sx={{ mt: 2 }}>
                  <Typography component='div' variant='h6'>
                    Damage types
                  </Typography>
                  <FormHelperText>
                    Select damage types applicable for this vehicle part type.
                  </FormHelperText>

                  <DamageTypesSelector
                    entityIds={values.damageTypeIds}
                    entities={undefined}
                    onChange={(newValues) =>
                      setFieldValue(
                        "damageTypeIds",
                        newValues?.length !== 0 ? newValues?.map((x) => x.id!) : null,
                      )
                    }
                  />
                </FormGroup>

                <FormControl error={Boolean(errors.isEnabled)}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='isEnabled'
                        checked={values.isEnabled}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    }
                    label='Enabled'
                  />
                  <FormHelperText>
                    Whether this reference data entity is enabled for referencing by other entities.
                    When disabled, the entity is unavailable for paginated/search/list requests but
                    available by id for old entities already referencing it.
                  </FormHelperText>
                  {errors.isEnabled && <FormHelperText error>{errors.isEnabled}</FormHelperText>}
                </FormControl>
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <LoadingButton
                sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                color='primary'
                disabled={!values.name}
                loading={isSubmitting}
                fullWidth
                type='submit'
                variant='contained'
              >
                Save
              </LoadingButton>
            </form>
          </CreateUpdatePageLayout>
        );
      }}
    </Formik>
  );
}
