import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogProps,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";

import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import { useModalOpenStatus } from "@/common/hooks/useModalOpenStatus";
import { ValidationHelper } from "@/common/validation";
import { appCommonConfig } from "@/config/config";
import { apiClient } from "@/core/api/ApiClient";
import * as repairCatalogSlice from "@/store/repair/repairCatalogSlice";

export interface OwnProps {
  onImportEnd?: () => void;
}

type Props = DialogProps & OwnProps;

export default function ImportFromRepairCatalogModal({
  onImportEnd,

  ...dialogProps
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const modalStatus = useModalOpenStatus(dialogProps);
  const thunkDispatch = useAppThunkDispatch();
  const isImportLoading = useAppSelector((x) => x.repair.catalogs.loading.importFromCatalog);

  const [isImportAll, setIsImportAll] = useState(false);
  const [isImportAllMaterials, setIsImportAllMaterials] = useState(true);
  const [isImportAllWork, setIsImportAllWork] = useState(true);
  const [isImportAllSpecs, setIsImportAllSpecs] = useState(true);
  const [selectedMaterialIdsMap, setSelectedMaterialIdsMap] = useState<Record<string, boolean>>({});
  const [selectedWorkIdsMap, setSelectedWorkIdsMap] = useState<Record<string, boolean>>({});
  const [selectedSpecIdsMap, setSelectedSpecIdsMap] = useState<Record<string, boolean>>({});

  const getImportFromCatalogInfoRequest = useApiRequest(
    apiClient.repairCatalogsApi.apiV1RepairCatalogsImportFromCatalogInfoGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
    {
      skip: !modalStatus.isOpening,
      deps: [modalStatus],
      debounce: { wait: 500 },
    },
  );
  const importFromCatalogInfo = getImportFromCatalogInfoRequest?.data;

  const handleImport = useCallback(async () => {
    if (!importFromCatalogInfo) {
      return;
    }

    try {
      await thunkDispatch(
        repairCatalogSlice.importFromCatalog({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          importFromCatalogDto: {
            sourceCatalogId: importFromCatalogInfo.sourceCatalog?.id,
            targetCatalogId: importFromCatalogInfo.targetCatalog?.id,
            isImportAll,
            isImportAllMaterials,
            isImportAllWork,
            isImportAllSpecs,
            materialIds: Object.keys(selectedMaterialIdsMap).filter(
              (id) => selectedMaterialIdsMap[id],
            ),
            workIds: Object.keys(selectedWorkIdsMap).filter((id) => selectedWorkIdsMap[id]),
            specIds: Object.keys(selectedSpecIdsMap).filter((id) => selectedSpecIdsMap[id]),
          },
        }),
      );
      enqueueSnackbar("Data imported.", { variant: "success" });
      dialogProps?.onClose && dialogProps.onClose({}, "escapeKeyDown");
      onImportEnd && onImportEnd();
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [importFromCatalogInfo]);

  return (
    <Dialog fullWidth maxWidth='sm' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Import repair data
      </AppModalTitle>

      {getImportFromCatalogInfoRequest.isLoading && (
        <DialogContent>
          <LinearProgress sx={{ my: 1 }} />
        </DialogContent>
      )}

      {!getImportFromCatalogInfoRequest.isLoading && (
        <DialogContent>
          <Stack direction='column' spacing={2}>
            <Typography component='div' variant='body1' color='text.secondary'>
              You can import repair data prebuilt by {appCommonConfig.appName}. Please select what
              data should be imported.
            </Typography>

            <Box>
              <Stack direction='row' spacing={4}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isImportAll}
                        onChange={(e) => {
                          setIsImportAll(e.target.checked);
                          setIsImportAllMaterials(false);
                          setIsImportAllWork(false);
                          setIsImportAllSpecs(false);
                        }}
                      />
                    }
                    label='Import all'
                  />
                </FormGroup>

                {!isImportAll && (
                  <Stack direction='column' spacing={0}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isImportAllMaterials}
                            onChange={(e) => {
                              setIsImportAllMaterials(e.target.checked);
                              setIsImportAll(false);
                            }}
                          />
                        }
                        label='Import all materials'
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isImportAllWork}
                            onChange={(e) => {
                              setIsImportAllWork(e.target.checked);
                              setIsImportAll(false);
                            }}
                          />
                        }
                        label='Import all work'
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isImportAllSpecs}
                            onChange={(e) => {
                              setIsImportAllSpecs(e.target.checked);
                              setIsImportAll(false);
                            }}
                          />
                        }
                        label='Import all specs'
                      />
                    </FormGroup>
                  </Stack>
                )}
              </Stack>
            </Box>

            {!isImportAll && !isImportAllMaterials && (
              <Stack direction='column' spacing={1}>
                <Typography component='div' variant='subtitle1'>
                  Materials to import
                </Typography>

                <Box>
                  {importFromCatalogInfo?.materials?.length === 0 && (
                    <NoDataAlert title='Nothing to import' />
                  )}
                  {importFromCatalogInfo?.materials?.map((item) => (
                    <FormGroup key={item.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size='small'
                            checked={selectedMaterialIdsMap[item.id!]}
                            onChange={(e) =>
                              setSelectedMaterialIdsMap({
                                ...selectedMaterialIdsMap,
                                [item.id!]: e.target.checked,
                              })
                            }
                          />
                        }
                        label={
                          <Typography component='div' variant='body1'>
                            {item.name}{" "}
                            {item.description && (
                              <Typography component='span' variant='body2'>
                                {item.description}
                              </Typography>
                            )}
                          </Typography>
                        }
                      />
                    </FormGroup>
                  ))}
                </Box>
              </Stack>
            )}

            {!isImportAll && !isImportAllWork && (
              <Stack direction='column' spacing={1}>
                <Typography component='div' variant='subtitle1'>
                  Work to import
                </Typography>

                <Box>
                  {importFromCatalogInfo?.work?.length === 0 && (
                    <NoDataAlert title='Nothing to import' />
                  )}
                  {importFromCatalogInfo?.work?.map((item) => (
                    <FormGroup key={item.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size='small'
                            checked={selectedWorkIdsMap[item.id!]}
                            onChange={(e) =>
                              setSelectedWorkIdsMap({
                                ...selectedWorkIdsMap,
                                [item.id!]: e.target.checked,
                              })
                            }
                          />
                        }
                        label={
                          <Typography component='div' variant='body1'>
                            {item.name}{" "}
                            {item.description && (
                              <Typography component='span' variant='body2'>
                                {item.description}
                              </Typography>
                            )}
                          </Typography>
                        }
                      />
                    </FormGroup>
                  ))}
                </Box>
              </Stack>
            )}

            {!isImportAll && !isImportAllSpecs && (
              <Stack direction='column' spacing={1}>
                <Typography component='div' variant='subtitle1'>
                  Specs to import
                </Typography>

                <Box>
                  {importFromCatalogInfo?.specs?.length === 0 && (
                    <NoDataAlert title='Nothing to import' />
                  )}
                  {importFromCatalogInfo?.specs?.map((item) => (
                    <FormGroup key={item.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size='small'
                            checked={selectedSpecIdsMap[item.id!]}
                            onChange={(e) =>
                              setSelectedSpecIdsMap({
                                ...selectedSpecIdsMap,
                                [item.id!]: e.target.checked,
                              })
                            }
                          />
                        }
                        label={
                          <Typography component='div' variant='body1'>
                            {item.partType?.name} - {item.damageType?.name}
                          </Typography>
                        }
                      />
                    </FormGroup>
                  ))}
                </Box>
              </Stack>
            )}

            <LoadingButton
              fullWidth
              loading={isImportLoading}
              variant='contained'
              onClick={handleImport}
            >
              Import
            </LoadingButton>
          </Stack>
        </DialogContent>
      )}
    </Dialog>
  );
}
