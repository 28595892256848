import { Alert, Box, Chip, FormHelperText, Stack, Typography } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import {
  TenantRequestDto,
  TenantRequestOperationType,
  TenantRequestStage,
  TenantRequestType,
} from "@/core/api/generated";

import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import Datetime from "@/common/components/Datetime/Datetime";
import VehicleLink from "@/common/components/Entity/Vehicle/VehicleLink";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FileListView from "@/common/components/Files/FileListView";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import GeneralStageHistory from "@/common/components/GeneralStageHistory";
import AppLink from "@/common/components/Link/AppLink";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FileItem } from "@/common/fileItem";
import { renderIf } from "@/common/helpers/render/renderIf";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import _ from "lodash";
import AccessoryCheckLink from "../../../AccessoryCheck/AccessoryCheckLink";
import AssessmentFlowLink from "../../../AssessmentFlow/AssessmentFlowLink";
import ContractLink from "../../../Contract/ContractLink";
import DamageCostEvaluationLink from "../../../DamageCostEvaluation/DamageCostEvaluationLink";
import DamageDetectionLink from "../../../DamageDetection/DamageDetectionLink";
import GeneralByWhoDisplay from "../../../General/Display/GeneralByWhoDisplay";
import GeneralCurrencyDisplay from "../../../General/Display/GeneralCurrencyDisplay";
import GeneralAttachedTagsDisplay from "../../../General/GeneralTag/GeneralAttachedTagsDisplay";
import RepairOperationLink from "../../../RepairOperation/RepairOperationLink";
import TenantLink from "../../../Tenant/TenantLink";
import VehicleDamageLink from "../../../VehicleDamage/VehicleDamageLink";
import VisualInspectionLink from "../../../VisualInspection/VisualInspectionLink";
import TenantRequestApplyResultControl from "../../TenantRequestApplyResultControl";
import TenantRequestCreateOperationControl from "../../TenantRequestCreateOperationControl";
import TenantRequestUpdateResultControl from "../../TenantRequestUpdateResultControl";

interface Props {
  tenantRequest: TenantRequestDto;
  onUpdate?: (newValue: TenantRequestDto) => void;
}

export default function OverviewTabContent({ tenantRequest, onUpdate }: Props) {
  const currentTenant = useCurrentTenant();
  const isSentByMe = currentTenant?.id === tenantRequest?.senderTenant?.id;
  const isReceivedByMe = currentTenant?.id === tenantRequest?.receiverTenant?.id;

  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={tenantRequest} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack spacing={1}>
            <Stack spacing={2}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xxs: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                  },
                  gridTemplateRows: "auto",
                  rowGap: 2,
                  columnGap: 2,
                }}
              >
                <Stack spacing={1}>
                  <FieldValue label='Number'>{tenantRequest?.globalNumber}</FieldValue>

                  <FieldValue label='Sender'>
                    <TenantLink entity={tenantRequest?.senderTenant} entityId={undefined} />{" "}
                    {currentTenant?.id === tenantRequest?.senderTenant?.id && (
                      <Chip size='small' variant='outlined' color='secondary' label='You' />
                    )}
                  </FieldValue>

                  <FieldValue label='Receiver'>
                    <TenantLink entity={tenantRequest?.receiverTenant} entityId={undefined} />{" "}
                    {currentTenant?.id === tenantRequest?.receiverTenant?.id && (
                      <Chip size='small' variant='outlined' color='secondary' label='You' />
                    )}
                  </FieldValue>

                  <FieldValue label='Name' isEmpty={!tenantRequest?.name}>
                    {tenantRequest?.name}
                  </FieldValue>

                  <FieldValue label='Description' isEmpty={!tenantRequest?.description}>
                    {tenantRequest?.description}
                  </FieldValue>

                  {isSentByMe && (
                    <>
                      {tenantRequest?.contract && (
                        <FieldValue label='Contract' isEmpty={!tenantRequest?.contract}>
                          <ContractLink entity={tenantRequest?.contract} />
                        </FieldValue>
                      )}
                      {tenantRequest?.assessmentFlow && (
                        <FieldValue
                          label='Assessment flow'
                          isEmpty={!tenantRequest?.assessmentFlow}
                        >
                          <AssessmentFlowLink entity={tenantRequest?.assessmentFlow} />
                        </FieldValue>
                      )}
                    </>
                  )}
                </Stack>

                <Stack spacing={1}>
                  {/* <Typography variant='h4'>Stage</Typography> */}

                  <GeneralStageHistory
                    stageEnumType='TenantRequestStage'
                    stageHistory={tenantRequest?.stageHistory}
                    withUpdateButtons={false}
                    withUpdateConfirmation
                  />
                </Stack>
              </Box>

              {/* Content */}
              <Stack spacing={1}>
                <Typography variant='h4'>Content</Typography>

                {isReceivedByMe &&
                  tenantRequest?.stageHistory?.stage === TenantRequestStage.Pending && (
                    <Alert severity='info'>
                      You may not see all the data or see partial data until you accept the request.
                    </Alert>
                  )}

                <Stack spacing={1}>
                  <FieldValue label='Type'>
                    <InlineApiEnumValue
                      type='TenantRequestType'
                      value={tenantRequest.content?.type}
                      withDescription
                    />
                  </FieldValue>

                  {/* Arbitrary */}
                  {tenantRequest.content?.type === TenantRequestType.Arbitrary && <Box>1</Box>}

                  {/* Operation */}
                  {tenantRequest.content?.type === TenantRequestType.Operation && (
                    <Stack spacing={1}>
                      <FieldValue label='Operation type'>
                        <Stack direction='row' spacing={1} sx={{ justifyContent: "space-between" }}>
                          <InlineApiEnumValue
                            type='TenantRequestOperationType'
                            value={tenantRequest.content?.operation?.operationType}
                          />
                          <TenantRequestCreateOperationControl
                            tenantRequest={tenantRequest}
                            onEntityUpdate={onUpdate}
                          />
                        </Stack>
                      </FieldValue>

                      {renderIf()
                        .if(
                          tenantRequest.content?.operation?.operationType ===
                            TenantRequestOperationType.VisualInspection,
                        )
                        .then(
                          <FieldValue label='Vehicle'>
                            <VehicleLink
                              entity={undefined}
                              entityId={
                                tenantRequest.content?.operation?.visualInspection?.vehicleId
                              }
                            />
                          </FieldValue>,
                        )
                        .elseif(
                          tenantRequest.content?.operation?.operationType ===
                            TenantRequestOperationType.DamageDetection,
                        )
                        .then(
                          <FieldValue label='Vehicle'>
                            <VehicleLink
                              entity={undefined}
                              entityId={
                                tenantRequest.content?.operation?.damageDetection?.vehicleId
                              }
                            />
                          </FieldValue>,
                        )
                        .elseif(
                          tenantRequest.content?.operation?.operationType ===
                            TenantRequestOperationType.DamageCostEvaluation,
                        )
                        .then(
                          <Stack spacing={1}>
                            <FieldValue label='Vehicle'>
                              <VehicleLink
                                entity={undefined}
                                entityId={
                                  tenantRequest.content?.operation?.damageCostEvaluation?.vehicleId
                                }
                              />
                            </FieldValue>

                            <FieldValue label='Vehicle damages'>
                              <Stack spacing={0.5}>
                                {tenantRequest.content?.operation?.damageCostEvaluation?.vehicleDamageIds?.map(
                                  (vehicleDamageId, i) => (
                                    <VehicleDamageLink
                                      key={i}
                                      vehicleId={
                                        tenantRequest.content?.operation?.damageCostEvaluation
                                          ?.vehicleId
                                      }
                                      entity={undefined}
                                      entityId={vehicleDamageId}
                                    />
                                  ),
                                )}
                              </Stack>
                            </FieldValue>

                            <FieldValue
                              label='Currency'
                              isEmpty={
                                !tenantRequest.content?.operation?.damageCostEvaluation?.currency
                              }
                            >
                              <GeneralCurrencyDisplay
                                currency={
                                  tenantRequest.content?.operation?.damageCostEvaluation?.currency
                                }
                              />
                            </FieldValue>
                          </Stack>,
                        )
                        .elseif(
                          tenantRequest.content?.operation?.operationType ===
                            TenantRequestOperationType.AccessoryCheck,
                        )
                        .then(
                          <FieldValue label='Vehicle'>
                            <VehicleLink
                              entity={undefined}
                              entityId={tenantRequest.content?.operation?.accessoryCheck?.vehicleId}
                            />
                          </FieldValue>,
                        )
                        .elseif(
                          tenantRequest.content?.operation?.operationType ===
                            TenantRequestOperationType.RepairOperation,
                        )
                        .then(
                          <Stack spacing={1}>
                            <FieldValue label='Vehicle'>
                              <VehicleLink
                                entity={undefined}
                                entityId={
                                  tenantRequest.content?.operation?.repairOperation?.vehicleId
                                }
                              />
                            </FieldValue>

                            <FieldValue label='Vehicle damages'>
                              <Stack spacing={0.5}>
                                {tenantRequest.content?.operation?.repairOperation?.vehicleDamageIds?.map(
                                  (vehicleDamageId, i) => (
                                    <VehicleDamageLink
                                      key={i}
                                      vehicleId={
                                        tenantRequest.content?.operation?.repairOperation?.vehicleId
                                      }
                                      entity={undefined}
                                      entityId={vehicleDamageId}
                                    />
                                  ),
                                )}
                              </Stack>
                            </FieldValue>

                            <FieldValue
                              label='Currency'
                              isEmpty={!tenantRequest.content?.operation?.repairOperation?.currency}
                            >
                              <GeneralCurrencyDisplay
                                currency={
                                  tenantRequest.content?.operation?.repairOperation?.currency
                                }
                              />
                            </FieldValue>
                          </Stack>,
                        )
                        .else(
                          <FormHelperText error>
                            <InlineApiEnumValue
                              type='TenantRequestType'
                              value={tenantRequest?.content?.type}
                            />{" "}
                            not supported yet!
                          </FormHelperText>,
                        )
                        .render()}
                    </Stack>
                  )}

                  {/* Common */}
                  <Stack spacing={1}>
                    <FieldValue label='Notes' isEmpty={!tenantRequest?.content?.notes}>
                      {tenantRequest?.content?.notes}
                    </FieldValue>

                    <Typography variant='subtitle1'>Attachments</Typography>

                    {_.isEmpty(tenantRequest?.content?.attachments) && (
                      <NoDataAlert title='No attachments added' />
                    )}
                    {!_.isEmpty(tenantRequest?.content?.attachments) && (
                      <FileListView
                        files={FileItem.createManyFrom(tenantRequest?.content?.attachments)}
                        maxVisibleFiles={10}
                      />
                    )}
                  </Stack>
                </Stack>
              </Stack>

              {/* Result */}
              <Stack spacing={2}>
                <Typography variant='h4'>Result</Typography>

                {isReceivedByMe &&
                  (tenantRequest?.stageHistory?.stage === TenantRequestStage.InProgress ||
                    tenantRequest?.stageHistory?.stage === TenantRequestStage.Completed ||
                    tenantRequest?.stageHistory?.stage === TenantRequestStage.Reporting ||
                    tenantRequest?.stageHistory?.stage === TenantRequestStage.Closed) && (
                    <Alert severity='info'>
                      The request result that you add, will be shared with the sender company via{" "}
                      <AppLink to={ROUTE_PATH.DATA_GRANTS()}>data grants</AppLink>. For instance,
                      sender company will be able to read your operations, but only those added to
                      the request result.
                    </Alert>
                  )}

                {!tenantRequest.result && <NoDataAlert title='No result added yet.' />}

                {/* Result */}
                {tenantRequest.result && (
                  <Stack spacing={1}>
                    {/* Operation */}
                    {tenantRequest.result?.type === TenantRequestType.Operation && (
                      <Stack spacing={1}>
                        <FieldValue label='Operation type'>
                          <Stack
                            direction='row'
                            spacing={1}
                            sx={{ justifyContent: "space-between" }}
                          >
                            <InlineApiEnumValue
                              type='TenantRequestOperationType'
                              value={tenantRequest.content?.operation?.operationType}
                            />

                            {/* Apply result */}
                            <Box>
                              <TenantRequestApplyResultControl
                                tenantRequest={tenantRequest}
                                isForOperation
                                onEntityUpdate={onUpdate}
                              />
                            </Box>
                          </Stack>
                        </FieldValue>

                        {renderIf()
                          .if(
                            tenantRequest.content?.operation?.operationType ===
                              TenantRequestOperationType.VisualInspection,
                          )
                          .then(
                            <FieldValue label='Visual inspection'>
                              <VisualInspectionLink
                                entity={undefined}
                                entityId={
                                  tenantRequest.result?.operation?.visualInspection
                                    ?.visualInspectionId
                                }
                              />
                            </FieldValue>,
                          )
                          .elseif(
                            tenantRequest.content?.operation?.operationType ===
                              TenantRequestOperationType.DamageDetection,
                          )
                          .then(
                            <FieldValue label='Damage detection'>
                              <DamageDetectionLink
                                entity={undefined}
                                entityId={
                                  tenantRequest.result?.operation?.damageDetection
                                    ?.damageDetectionId
                                }
                              />
                            </FieldValue>,
                          )
                          .elseif(
                            tenantRequest.result?.operation?.operationType ===
                              TenantRequestOperationType.DamageCostEvaluation,
                          )
                          .then(
                            <FieldValue label='Damage cost evaluation'>
                              <DamageCostEvaluationLink
                                entity={undefined}
                                entityId={
                                  tenantRequest.result?.operation?.damageCostEvaluation
                                    ?.damageCostEvaluationId
                                }
                              />
                            </FieldValue>,
                          )
                          .elseif(
                            tenantRequest.result?.operation?.operationType ===
                              TenantRequestOperationType.AccessoryCheck,
                          )
                          .then(
                            <FieldValue label='Accessory check'>
                              <AccessoryCheckLink
                                entity={undefined}
                                entityId={
                                  tenantRequest.result?.operation?.accessoryCheck?.accessoryCheckId
                                }
                              />
                            </FieldValue>,
                          )
                          .elseif(
                            tenantRequest.result?.operation?.operationType ===
                              TenantRequestOperationType.RepairOperation,
                          )
                          .then(
                            <FieldValue label='Repair operation'>
                              <RepairOperationLink
                                entity={undefined}
                                entityId={
                                  tenantRequest.result?.operation?.repairOperation
                                    ?.repairOperationId
                                }
                              />
                            </FieldValue>,
                          )
                          .else(
                            <FormHelperText error>
                              <InlineApiEnumValue
                                type='TenantRequestType'
                                value={tenantRequest?.result?.type}
                              />{" "}
                              not supported yet!
                            </FormHelperText>,
                          )
                          .render()}
                      </Stack>
                    )}

                    {/* Common */}
                    <Stack spacing={1}>
                      <FieldValue label='Notes' isEmpty={!tenantRequest?.result?.notes}>
                        {tenantRequest?.result?.notes}
                      </FieldValue>

                      <Typography variant='subtitle1'>Attachments</Typography>

                      {_.isEmpty(tenantRequest?.result?.attachments) && (
                        <NoDataAlert title='No attachments added' />
                      )}
                      {!_.isEmpty(tenantRequest?.result?.attachments) && (
                        <FileListView
                          files={FileItem.createManyFrom(tenantRequest?.result?.attachments)}
                          maxVisibleFiles={10}
                        />
                      )}
                    </Stack>
                  </Stack>
                )}

                {/* Add result */}
                <Box>
                  <TenantRequestUpdateResultControl
                    tenantRequest={tenantRequest}
                    onEntityUpdate={onUpdate}
                  />
                </Box>

                {/* Apply result */}
                {/* <Box>
                  <TenantRequestApplyResultControl
                    tenantRequest={tenantRequest}
                    onEntityUpdate={onUpdate}
                  />
                </Box> */}
              </Stack>

              {/* AppliedResult */}
              {isSentByMe && tenantRequest.appliedResult && (
                <Stack spacing={2}>
                  <Typography variant='h4'>Applied result </Typography>

                  <Alert severity='info'>
                    This section contains info about when, who and where request result was applied
                    to your company.
                  </Alert>

                  {!tenantRequest.appliedResult && <NoDataAlert title='No applied result yet.' />}

                  {tenantRequest.appliedResult && (
                    <Stack spacing={1}>
                      <Stack spacing={1}>
                        <FieldValue
                          label='Applied at'
                          isEmpty={!tenantRequest?.appliedResult?.appliedAt}
                        >
                          <Datetime
                            datetime={tenantRequest?.appliedResult?.appliedAt}
                            withDurationFromNow
                          />
                        </FieldValue>

                        <FieldValue
                          label='Applied by'
                          isEmpty={!tenantRequest?.appliedResult?.appliedBy}
                        >
                          <GeneralByWhoDisplay
                            who={{ userId: tenantRequest?.appliedResult?.appliedBy }}
                          />
                        </FieldValue>
                      </Stack>

                      <Stack spacing={1}>
                        <Typography variant='subtitle1'>Created entities</Typography>

                        {!tenantRequest?.appliedResult?.createdEntityTags && <NoDataAlert />}

                        {tenantRequest?.appliedResult?.createdEntityTags && (
                          <GeneralAttachedTagsDisplay
                            tags={tenantRequest?.appliedResult?.createdEntityTags}
                          />
                        )}
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
