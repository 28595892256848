import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  DialogContentText,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import AppTooltip from "@/common/components/AppTooltip";
import InlineCode from "@/common/components/Code/InlineCode";
import InlineEntityChangedByInfoDisplay from "@/common/components/EntityInfo/InlineEntityChangedByInfoDisplay";
import ApiEnumSelect from "@/common/components/Enum/ApiEnumSelect";
import AppIcon from "@/common/components/Icons/AppIcon";
import ConfirmationModalWithTrigger from "@/common/components/Modals/ConfirmationModalWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { ValidationHelper } from "@/common/validation";
import {
  VehicleArea,
  VehicleBodyType,
  VehicleProjection,
  VehicleType,
  VehicleVisualModelType,
} from "@/core/api/generated";

import DataTabular from "@/common/components/DataTabular/DataTabular";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import TableCellContentOfAttachments from "@/common/components/Table/TableCell/TableCellContentOfAttachments";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import _ from "lodash";
import VisualModelMetadataHelperModal from "./components/VisualModelMetadataHelperModal";

export default function VehicleVisualModelsPage() {
  const { enqueueSnackbar } = useSnackbar();

  const [search, setSearch] = useState("");
  const [visualModelType, setVisualModelType] = useState<VehicleVisualModelType | "">("");
  const [vehicleTypes, setVehicleTypes] = useState<VehicleType[]>([]);
  const [bodyTypes, setBodyTypes] = useState<VehicleBodyType[]>([]);
  const [areas, setAreas] = useState<VehicleArea[]>([]);
  const [projections, setProjections] = useState<VehicleProjection[]>([]);

  const [metadataModalOpen, setMetadataModalOpen] = useState(false);

  const vehicleVisualModelsRequest = useApiRequest(
    apiClient.adminVehicleVisualModelsApi.apiV1AdminReferenceDataVehiclesVisualModelsGetPost,
    {
      adminVehicleVisualModelGetPaginatedDto: {
        search,
        type: visualModelType || undefined,
        vehicleTypes,
        bodyTypes,
        areas,
        projections,
      },
    },
    {
      deps: [visualModelType, vehicleTypes, bodyTypes, areas, projections],
      debouncedDeps: {
        deps: [search],
        wait: 500,
        options: { leading: false, trailing: true },
      },
    },
  );
  const { data: vehicleVisualModels } = vehicleVisualModelsRequest;

  const isDefaultModelSet = useMemo(
    () => vehicleVisualModels?.items?.some((x) => x.isDefault) || false,
    [vehicleVisualModels],
  );

  return (
    <ListPageLayout>
      {!vehicleVisualModelsRequest.isLoading && !isDefaultModelSet && (
        <Alert sx={{ mb: 2 }} severity='warning'>
          <AlertTitle>Default vehicle visual model is not set!</AlertTitle>
          It is crucial to define the default model, as other system components can function
          incorrectly (relying on default model).
        </Alert>
      )}

      <Stack
        direction={{ xs: "column", desktop: "row" }}
        spacing={1}
        sx={{ alignItems: { xs: "stretch", desktop: "center" }, mb: 2 }}
      >
        <Stack
          direction={{ xs: "column", xl: "row" }}
          spacing={1}
          sx={{ justifyContent: { xs: "stretch", md: "flex-end" } }}
        >
          <TextField
            label='Search'
            size='small'
            margin='normal'
            type='text'
            variant='outlined'
            value={search || ""}
            onChange={(e) => setSearch(e.target.value)}
            sx={{ margin: 0, minWidth: 300, maxWidth: "100%" }}
          />
          <FormControl sx={{ minWidth: 200 }} margin='normal' size='small'>
            <InputLabel>Model type</InputLabel>
            <ApiEnumSelect
              type='VehicleVisualModelType'
              value={visualModelType as VehicleVisualModelType}
              onChange={(newValue) => setVisualModelType(newValue || "")}
              selectProps={{
                label: "Model type",
              }}
            />
          </FormControl>
        </Stack>

        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={1}
          sx={{ ml: "auto !important", justifyContent: { xs: "stretch", md: "flex-end" } }}
        >
          <Button
            variant='contained'
            color='primary'
            startIcon={<AppIcon of='settings' />}
            onClick={() => setMetadataModalOpen(true)}
          >
            Open SVG metadata builder
          </Button>

          <Button
            variant='contained'
            color='primary'
            startIcon={<AppIcon of='add' />}
            component={RouterLink}
            to={ROUTE_PATH.ADMIN_VEHICLE_VISUAL_MODEL_CREATE}
          >
            Create new visual model
          </Button>
        </Stack>
      </Stack>

      <Divider sx={{ my: 2 }} />

      <DataTabular
        columns={[
          {
            field: "globalNumber",
            title: "Global number",
            flex: 1,
            renderCell: (item) => item.globalNumber,
          },
          {
            field: "name",
            title: "Name",
            flex: 1,
            renderCell: (item) => (
              <>
                <Typography component='div'>
                  {item.name} v{item.version}
                  {item.isLatestVersion && (
                    <Chip
                      size='small'
                      color='primary'
                      variant='outlined'
                      label='Latest'
                      sx={{ ml: 1 }}
                    />
                  )}
                  {item.isDefault && (
                    <Chip
                      size='small'
                      color='primary'
                      variant='filled'
                      label='Default'
                      sx={{ ml: 1 }}
                    />
                  )}
                </Typography>
              </>
            ),
          },
          {
            field: "type",
            title: "Type",
            flex: 1,
            renderCell: (item) => (
              <AppTooltip title={item.type as string}>
                <Typography component='div' noWrap>
                  {item.type}
                </Typography>
              </AppTooltip>
            ),
          },
          {
            field: "vehicleTypes",
            title: "Vehicle types",
            flex: 1,
            renderCell: (item) => (
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {item.vehicleTypes?.map((vt, j) => (
                  <InlineCode key={j} sx={{ mr: 0.5 }}>
                    {vt}
                  </InlineCode>
                ))}
              </Box>
            ),
          },
          {
            field: "bodyTypes",
            title: "For",
            flex: 1,
            renderCell: (item) => (
              <Stack spacing={1}>
                <Box>
                  <Typography component='div' variant='subtitle2'>
                    Body types:
                  </Typography>
                  <Stack direction='row' sx={{ flexWrap: "wrap" }}>
                    {_.isEmpty(item.bodyTypes) && "Any"}
                    {item.bodyTypes?.map((x, j) => (
                      <InlineCode key={j} sx={{ mr: 0.5, mb: 0.5 }}>
                        {x}
                      </InlineCode>
                    ))}
                  </Stack>
                </Box>

                <Box>
                  <Typography component='div' variant='subtitle2'>
                    Makes:
                  </Typography>
                  <Stack direction='row' sx={{ flexWrap: "wrap" }}>
                    {_.isEmpty(item.bodyTypes) && "Any"}
                    {item.makes?.map((x, j) => (
                      <Box component='span' key={j} sx={{ mr: 0.5, mb: 0.5 }}>
                        {x.name},
                      </Box>
                    ))}
                  </Stack>
                </Box>

                <Box>
                  <Typography component='div' variant='subtitle2'>
                    Models:
                  </Typography>
                  <Stack direction='row' sx={{ flexWrap: "wrap" }}>
                    {_.isEmpty(item.bodyTypes) && "Any"}
                    {item.models?.map((x, j) => (
                      <Box component='span' key={j} sx={{ mr: 0.5, mb: 0.5 }}>
                        {x.name},
                      </Box>
                    ))}
                  </Stack>
                </Box>

                <Box>
                  <Typography component='div' variant='subtitle2'>
                    Generations:
                  </Typography>
                  <Stack direction='row' sx={{ flexWrap: "wrap" }}>
                    {_.isEmpty(item.bodyTypes) && "Any"}
                    {item.generations?.map((x, j) => (
                      <Box component='span' key={j} sx={{ mr: 0.5, mb: 0.5 }}>
                        {x.name},
                      </Box>
                    ))}
                  </Stack>
                </Box>

                <Box>
                  <Typography component='div' variant='subtitle2'>
                    Modifications:
                  </Typography>
                  <Stack direction='row' sx={{ flexWrap: "wrap" }}>
                    {_.isEmpty(item.bodyTypes) && "Any"}
                    {item.modifications?.map((x, j) => (
                      <Box component='span' key={j} sx={{ mr: 0.5, mb: 0.5 }}>
                        {x.name},
                      </Box>
                    ))}
                  </Stack>
                </Box>
              </Stack>
            ),
          },
          {
            field: "areas",
            title: "Areas",
            flex: 1,
            renderCell: (item) => (
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {item.areas?.map((area, j) => (
                  <InlineCode key={j} sx={{ mr: 0.5 }}>
                    {area}
                  </InlineCode>
                ))}
              </Box>
            ),
          },
          {
            field: "projections",
            title: "Projections",
            flex: 1,
            renderCell: (item) => (
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {item.projections?.map((projection, j) => (
                  <InlineCode key={j} sx={{ mr: 0.5 }}>
                    {projection}
                  </InlineCode>
                ))}
              </Box>
            ),
          },
          {
            field: "sortOrder",
            title: "Sort order",
            flex: 1,
            renderCell: (item) => <>{item.sortOrder}</>,
          },
          {
            field: "updatedBy",
            title: "Changed by",
            flex: 1,
            renderCell: (item) => <InlineEntityChangedByInfoDisplay entity={item} />,
          },
          {
            field: "images",
            title: "Images",
            flex: 1,
            renderCell: (item) => <TableCellContentOfAttachments attachments={item.images} />,
          },
        ]}
        rows={vehicleVisualModels?.items}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.ADMIN_VEHICLE_VISUAL_MODELS_EDIT(item.id)}
        renderRowAction={({ item }) => (
          <MenuWithTrigger
            withAuthCloseOnClick
            trigger={
              <IconButton>
                <AppIcon of='moreVert' />
              </IconButton>
            }
          >
            <MenuItem
              component={RouterLink}
              to={ROUTE_PATH.ADMIN_VEHICLE_VISUAL_MODELS_EDIT(item.id)}
            >
              Edit
            </MenuItem>

            <ConfirmationModalWithTrigger
              trigger={<MenuItem>Delete</MenuItem>}
              title='Delete reference data?'
              body={({ error }) => {
                return (
                  <DialogContentText>
                    {`You're going to delete reference data that is used across the system. This action can't
            be undone.`}
                  </DialogContentText>
                );
              }}
              onConfirm={async () => {
                try {
                  await apiClient.adminVehicleVisualModelsApi.apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdDelete(
                    { visualModelId: item.id! },
                  );
                  vehicleVisualModelsRequest.refetch();
                  enqueueSnackbar("Visual model deleted.", { variant: "success" });
                } catch (err) {
                  const validation2 = ValidationHelper.handleApiErrorResponse(err);
                  validation2.hasErrors &&
                    enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
                }
              }}
            />
          </MenuWithTrigger>
        )}
        isLoading={vehicleVisualModelsRequest.isLoading}
      />

      <VisualModelMetadataHelperModal
        open={metadataModalOpen}
        onClose={() => setMetadataModalOpen(false)}
      />
    </ListPageLayout>
  );
}
