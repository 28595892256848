import { Button, IconButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AdminIntegrationApiClientGetPaginatedDto,
  AppPermission,
  EntityType,
  IntegrationApiClientDto,
} from "@/core/api/generated";

export default function IntegrationApiClientsPage() {
  const { enqueueSnackbar } = useSnackbar();
  const commonRequestParams = useCommonRequestParams<AdminIntegrationApiClientGetPaginatedDto>({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityInAdminArea(EntityType.IntegrationApiClient),
    },
  });

  const [integrationApiClient, setIntegrationApiClient] = useState<IntegrationApiClientDto | null>(
    null,
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const paginatedIntegrationApiClientsRequest = useApiRequest(
    apiClient.adminIntegrationApiClientsApi.apiV1AdminIntegrationApiClientsGetPost,
    {
      adminIntegrationApiClientGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedIntegrationApiClients = paginatedIntegrationApiClientsRequest.data;

  const handleDeleteConfirmed = useCallback(async () => {
    try {
      await apiClient.adminIntegrationApiClientsApi.apiV1AdminIntegrationApiClientsIntegrationApiClientIdDelete(
        {
          integrationApiClientId: integrationApiClient!.id!,
        },
      );
      enqueueSnackbar("API client was deleted.", { variant: "success" });
      paginatedIntegrationApiClientsRequest.refetch();
      setIsDeleteModalOpen(false);
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [integrationApiClient]);

  return (
    <ListPageLayout>
      <SimpleViewPageHeader
        title={undefined}
        primaryActions={
          <AuthorizedElement permissions={[AppPermission.IntegrationApiClientManage]}>
            <Button
              variant='contained'
              color='primary'
              startIcon={<AppIcon of='add' />}
              component={RouterLink}
              to={ROUTE_PATH.ADMIN_INTEGRATION_API_CLIENT_CREATE}
            >
              Create new API Client
            </Button>
          </AuthorizedElement>
        }
      />

      <Stack direction='column' spacing={1}>
        <DataTabular
          columns={[
            {
              field: "tenantId",
              title: "Tenant",
              flex: 1,
              renderCell: (item) => <>{item.tenantId}</>,
            },
            {
              field: "localNumber",
              title: "Number",
              flex: 1,
              renderCell: (item) => <>{item.localNumber}</>,
            },
            {
              field: "name",
              title: "Name",
              flex: 1,
              renderCell: (item) => <>{item.name}</>,
            },
            {
              field: "keys",
              title: "Keys",
              flex: 1,
              renderCell: (item) => <>{item.keys?.length}</>,
            },
          ]}
          isLoading={paginatedIntegrationApiClientsRequest.isLoading}
          rows={paginatedIntegrationApiClients?.items}
          getRowId={(item) => item.id!}
          rowTo={(item) => ROUTE_PATH.ADMIN_INTEGRATION_API_CLIENT_VIEW(item.id)}
          renderRowAction={(actionParams) => (
            <MenuWithTrigger
              trigger={
                <IconButton>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              <AuthorizedMenuItem
                permissions={[AppPermission.IntegrationApiClientManage]}
                component={RouterLink}
                to={ROUTE_PATH.ADMIN_INTEGRATION_API_CLIENT_EDIT(actionParams.item.id)}
              >
                <ListItemIcon>
                  <AppIcon of='edit' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </AuthorizedMenuItem>
              <AuthorizedMenuItem
                permissions={[AppPermission.IntegrationApiClientManage]}
                onClick={() => {
                  setIntegrationApiClient(actionParams.item);
                  setIsDeleteModalOpen(true);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='delete' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </AuthorizedMenuItem>
            </MenuWithTrigger>
          )}
          statePersistence={commonRequestParams.dataTabularProps.statePersistence}
          pagination={commonRequestParams.dataTabularProps.pagination}
          sort={commonRequestParams.dataTabularProps.sort}
          quickFilter={commonRequestParams.dataTabularProps.quickFilter}
          filters={commonRequestParams.dataTabularProps.filters}
        />

        {integrationApiClient && (
          <ConfirmationModal
            title='Delete the API Client?'
            body={
              <>
                {`You're going to delete the API Client`}{" "}
                <strong>{integrationApiClient.name}</strong>. {`This action can't be undone.`}
              </>
            }
            open={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onCancel={() => setIsDeleteModalOpen(false)}
            onConfirm={handleDeleteConfirmed}
          />
        )}
      </Stack>
    </ListPageLayout>
  );
}
