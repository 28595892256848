import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

import { AppPermission, GeneralEventLogDto } from "@/core/api/generated";

import AuthorizedElement from "../../Auth/AuthorizedElement";
import EntityChangedByInfoDisplay from "../../EntityInfo/EntityChangedByInfoDisplay";
import AppTypography from "../../Text/AppTypography";
import GeneralAttachedTagsDisplay from "../General/GeneralTag/GeneralAttachedTagsDisplay";

type OwnProps = {
  item: GeneralEventLogDto;
};

type Props = OwnProps;

export default function GeneralEventLogItem({ item }: Props) {
  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xxs: "1fr",
              md: "2fr 1fr",
            },
            gridTemplateRows: "auto",
            rowGap: 1,
            columnGap: 1,
          }}
        >
          <Box>
            <AppTypography
              ellipsing={{ enabled: true, linesToStartEllipsing: 5 }}
              component='div'
              variant='subtitle1'
            >
              {item.title}
            </AppTypography>

            {item.tags && (
              <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                <GeneralAttachedTagsDisplay tags={item.tags} defaultIsFolded={false} />
              </AuthorizedElement>
            )}
          </Box>

          <Typography component='span' variant='body2'>
            <Stack
              direction='row'
              spacing={0.5}
              sx={{ justifyContent: "flex-end", alignItems: "center" }}
            >
              <span>Raised</span>{" "}
              <EntityChangedByInfoDisplay
                datetime={item.date}
                userId={undefined}
                who={item.raisedBy}
                direction='row'
              />
            </Stack>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
