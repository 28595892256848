import { Box, Dialog, DialogContent, DialogProps, Stack, SxProps } from "@mui/material";

import AppBreadcrumbs from "@/common/components/AppBreadcrumbs";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { IBasePageLayout } from "@/common/ts/layout";
import { Theme } from "@mui/material/styles";
import { useState } from "react";

export enum ViewLayoutVariant {
  Page = "page",
  Tab = "tab",
  Modal = "modal",
}

const defaultDisplayProps = {
  breadcrumbs: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface Props extends IBasePageLayout {
  sx?: SxProps<Theme>;
  displayProps?: Partial<typeof defaultDisplayProps>;
  dialogProps?: DialogProps;
}

export default function ViewLayout({
  breadcrumbs,
  header,
  body,
  footer,
  sx,
  displayProps,
  dialogProps,
  children,
}: Props) {
  const bodyComputed = children || body;
  const displayPropsComputed = { ...defaultDisplayProps, ...displayProps };
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      {displayPropsComputed?.viewVariant === ViewLayoutVariant.Page && (
        <Box>
          <Stack direction='column' spacing={2} sx={{ height: "100%", pb: 10, ...sx }}>
            <Stack
              sx={{
                px: 3,
                pb: 1.5,
                background: (t) => t.palette.background.paper,
              }}
              spacing={2}
            >
              {displayPropsComputed?.breadcrumbs && (
                <AppBreadcrumbs replacements={breadcrumbs?.replacements} />
              )}
              {header && header}
            </Stack>
            <Stack
              sx={{
                mx: "24px !important",
                px: 2,
                py: 1,
                borderRadius: 2,
                background: (t) => t.palette.background.paper,
              }}
            >
              {bodyComputed && bodyComputed}
              {footer && footer}
            </Stack>
          </Stack>
        </Box>
      )}

      {displayPropsComputed?.viewVariant === ViewLayoutVariant.Tab && (
        <Stack direction='column' sx={{ height: "100%", ...sx }}>
          {header && (
            <Stack
              sx={{
                p: 1,
              }}
              spacing={2}
            >
              {header}
            </Stack>
          )}
          <Stack sx={{ p: 1 }}>
            {bodyComputed && bodyComputed}
            {footer && footer}
          </Stack>
        </Stack>
      )}
      {displayPropsComputed?.viewVariant === ViewLayoutVariant.Modal && (
        <Dialog fullWidth maxWidth='lg' open={dialogProps ? dialogProps.open : open}>
          <AppModalTitle
            withCloseIcon
            onCloseClicked={() =>
              dialogProps?.onClose ? dialogProps.onClose({}, "escapeKeyDown") : setOpen(false)
            }
          />
          <Stack sx={{ px: 3, pb: 1 }}>{header && header}</Stack>
          <DialogContent>
            {bodyComputed}
            {footer && footer}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
