import { Box, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import InlineApiEnumValueList from "@/common/components/Enum/InlineApiEnumValueList";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { enumService } from "@/common/services/enum";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { VehicleVisualModelDto, VehicleVisualModelSearchPaginatedDto } from "@/core/api/generated";

export type VehicleVisualModelAutocompleteOption = BaseAutocompleteOption<VehicleVisualModelDto>;

export const getVehicleVisualModelOptionTitle = (data: VehicleVisualModelDto): string =>
  StringHelper.joinIntoString(
    [
      data.name || data.globalNumber,
      enumService.getEnumValueName("VehicleVisualModelType", data.type),
      data.vehicleTypes?.map((x) => enumService.getEnumValueName("VehicleType", x))?.join(" | "),
      data.bodyTypes?.map((x) => enumService.getEnumValueName("VehicleBodyType", x))?.join(" | ") ||
        "All body types",
      `v${data.version}`,
    ],
    ", ",
    {
      skipEmpty: true,
    },
  );

export const vehicleVisualModelToOption = (
  data: VehicleVisualModelDto,
): VehicleVisualModelAutocompleteOption => ({
  id: data.id!,
  title: getVehicleVisualModelOptionTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface VehicleVisualModelAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<VehicleVisualModelDto> {
  searchFilters?: Partial<Omit<VehicleVisualModelSearchPaginatedDto, "search" | "includeIds">>;
}

export default function VehicleVisualModelAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: VehicleVisualModelAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={vehicleVisualModelToOption}
      isPreload={isPreload}
      request={{
        requestFunc:
          apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsSearchPost,
        limit: 25,
        parameters: {
          vehicleVisualModelSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          vehicleVisualModelSearchPaginatedDto: {
            ...params.vehicleVisualModelSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Vehicle visual model'
      placeholder='Search for vehicle visual model...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='vehicleVisualModel' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography component='div' variant='body1'>
                    {option.title}
                  </Typography>
                  {option.data && (
                    <Typography component='div' variant='body2' color='secondary'>
                      <Box>
                        <InlineApiEnumValue
                          type='VehicleVisualModelType'
                          value={option.data.type}
                        />
                      </Box>
                      <Box>
                        <InlineApiEnumValueList type='VehicleArea' values={option.data.areas} /> -{" "}
                        <InlineApiEnumValueList
                          type='VehicleProjection'
                          values={option.data.projections}
                        />
                      </Box>
                      <Box>
                        <InlineApiEnumValueList
                          type='VehicleType'
                          values={option.data.vehicleTypes}
                        />
                      </Box>
                      <Box>
                        <InlineApiEnumValueList
                          type='VehicleBodyType'
                          values={option.data.bodyTypes}
                        />
                      </Box>
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}
