import { LinearProgress, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useCallback } from "react";

import { useModalOpenStatus } from "@/common/hooks/useModalOpenStatus";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";

import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { RepairSparePartDto } from "@/core/api/generated";
import { AxiosResponse } from "axios";
import ConfirmationModal, { ConfirmationModalProps } from "../../Modals/ConfirmationModal";
import BlockRepairSpec from "../RepairSpec/BlockRepairSpec";

export interface OwnProps {
  entity: RepairSparePartDto;
  onDelete?: () => void;
  deleteFunc?: (params: { repairSparePartId: string }) => Promise<AxiosResponse<unknown, unknown>>;
}

type Props = OwnProps & ConfirmationModalProps;

export default function DeleteRepairSparePartModal({
  entity,
  onDelete,
  deleteFunc,
  ...dialogProps
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const modalStatus = useModalOpenStatus(dialogProps);

  const deleteInfoRequest = useApiRequest(
    apiClient.repairSparePartApi.apiV1RepairSparePartsRepairSparePartIdDeleteInfoGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      repairSparePartId: entity.id!,
    },
    {
      skip: !modalStatus.isOpening && !modalStatus.isOpen,
    },
  );

  const handleDeleteConfirmed = useCallback(async () => {
    try {
      deleteFunc
        ? deleteFunc({
            repairSparePartId: entity.id!,
          })
        : await apiClient.repairSparePartApi.apiV1RepairSparePartsRepairSparePartIdDelete({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            repairSparePartId: entity.id!,
          });
      enqueueSnackbar("Spare part was successfully deleted.", { variant: "success" });
      onDelete ? onDelete() : dialogProps?.onClose && dialogProps.onClose({}, "escapeKeyDown");
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [entity, onDelete]);

  return (
    <ConfirmationModal
      title='Delete the repair spare part?'
      body={
        <Stack spacing={2}>
          <Box>
            {`You're going to delete the repair spare part`} <strong>{entity.name}</strong>.
          </Box>

          {deleteInfoRequest.isLoading && <LinearProgress />}

          {deleteInfoRequest.data &&
            !deleteInfoRequest.isLoading &&
            (deleteInfoRequest.data.affectedRepairSpecs?.length || 0) !== 0 && (
              <Stack spacing={2}>
                <Box>
                  Repair spare part <strong>{entity.name}</strong> is referenced in{" "}
                  <strong>{deleteInfoRequest.data.affectedRepairSpecs?.length}</strong> repair
                  specs. If you confirm, the spare part will be automatically deleted from the
                  repair specs.
                </Box>
                <Stack spacing={1}>
                  {deleteInfoRequest.data.affectedRepairSpecs?.map((repairSpec, i) => (
                    <BlockRepairSpec key={i} repairSpec={repairSpec} withDetailsToggle withLink />
                  ))}
                </Stack>
              </Stack>
            )}

          <Box>{`This action can't be undone.`}</Box>
        </Stack>
      }
      onCancel={() => dialogProps?.onClose && dialogProps.onClose({}, "escapeKeyDown")}
      onConfirm={handleDeleteConfirmed}
      {...dialogProps}
    />
  );
}
