import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { ContractDto, ContractReferenceDto } from "@/core/api/generated";
import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getContractOptionTitle } from "./ContractAutocomplete";

export interface ContractInlineProps extends BaseEntityInlineInheritableProps {
  entity: ContractDto | ContractReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the contract. */
export default function ContractInline({ entity, entityId, ...otherProps }: ContractInlineProps) {
  const request = useApiRequest(
    apiClient.contractsApi.apiV1ContractsContractIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      contractId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  const refRequest = useApiRequest(
    apiClient.contractsApi.apiV1ContractsContractIdReferenceGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      contractId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity || (request.isLoading && !request.isEnded),
    },
  );
  entity = request?.data || entity || refRequest?.data;

  const full = entity as ContractDto;
  const ref = entity as ContractReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request.data ? request : refRequest}
      icon={<AppIcon of='contract' inText />}
      content={<>{full && getContractOptionTitle(full)}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Contract"}
    />
  );
}
