import { AnyAction, Middleware } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { tenantService } from "@/common/services/tenant";
import { apiClient } from "@/core/api/ApiClient";
import { AppRootStateType } from "@/store";
import { _authenticateSucceeded } from "@/store/auth/slice";
import { getCurrentTenant } from "@/store/tenants/slice";

import { getTenantProfile } from "../tenantProfile/slice";

export const tenantsMiddleware: Middleware<
  any,
  AppRootStateType,
  ThunkDispatch<any, any, AnyAction>
> =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case _authenticateSucceeded.type: {
        (async () => {
          // resolve and get tenant
          const tenantInfo = tenantService.resolveTenant();
          if (
            tenantInfo &&
            tenantInfo.identifier !== getState().tenants.currentTenant?.identifier
          ) {
            console.log("Resolved tenant:", tenantInfo);
            apiClient.updateTenantIdentifier(tenantInfo.identifier);
            dispatch(getCurrentTenant());
            dispatch(
              getTenantProfile({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              }),
            );
          }
        })();

        break;
      }

      default:
        break;
    }

    return next(action);
  };
