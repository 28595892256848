import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useMemo, useState } from "react";

import { DatetimeHelper } from "@/common/helpers/datetime";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { ValidationHelper, ValidationInfo } from "@/common/validation";
import { ChatDto, ChatParticipantDto } from "@/core/api/generated";
import * as chatParticipantsSlice from "@/store/communication/chatParticipantsSlice";

import _ from "lodash";
import AppIcon from "../../Icons/AppIcon";
import AppModalTitle from "../../Modals/AppModalTitle";
import PartyLink from "../Party/PartyLink";
import AddChatParticipantsModal from "./AddChatParticipantsModal";
import ChatParticipantAvatar from "./ChatParticipantAvatar";

interface Props {
  open: boolean;
  onClose?: () => void;
  onUpdated?: (newValues?: ChatDto) => void;

  chat: ChatDto;
  allowManage?: boolean;
}

function ChatParticipantsModal({ open, onClose, onUpdated, chat, allowManage }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const thunkDispatch = useAppThunkDispatch();

  const profile = useUserProfile();
  const paginatedParticipants = useAppSelector(
    (x) =>
      x.communication.chatParticipants.paginatedChatParticipantMap[chat?.id || ""] || undefined,
  );
  const removeChatParticipantLoadingMap = useAppSelector(
    (x) => x.communication.chatParticipants.loading.removeChatParticipant || {},
  );
  const currentParticipant = useMemo(
    () => paginatedParticipants?.items?.find((x) => x.userId === profile?.id),
    [profile, paginatedParticipants],
  );

  const [validation, setValidation] = useState(new ValidationInfo());
  const [prevOpen, setPrevOpen] = useState(false);
  const [isAddParticipantsModalOpen, setIsAddParticipantsModalOpen] = useState(false);

  useEffect(() => {
    const isOpening = open && !prevOpen;
    const isClosing = prevOpen && !open;

    if (isOpening) {
      thunkDispatch(
        chatParticipantsSlice.getChatParticipants({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          chatId: chat.id!,
          chatParticipantGetPaginatedDto: {
            offset: 0,
            limit: 200,
          },
        }),
      );
    }
    setPrevOpen(open);
  }, [open]);

  const handleAddParticipantsModalClose = () => setIsAddParticipantsModalOpen(false);

  const handleAddParticipant = () => {
    setIsAddParticipantsModalOpen(true);
  };

  const handleRemoveParticipant = async (participant: ChatParticipantDto) => {
    try {
      await thunkDispatch(
        chatParticipantsSlice.removeChatParticipant({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          chatId: chat.id!,
          participantId: participant.id!,
        }),
      );
      enqueueSnackbar(
        `'${participant.personName?.name || participant.email}' removed from the chat.`,
        {
          variant: "success",
        },
      );
    } catch (err) {
      const newValidation = ValidationHelper.handleApiErrorResponse(err);
      setValidation(newValidation);
      if (newValidation.generalError) {
        enqueueSnackbar(newValidation.generalError, {
          variant: "error",
        });
      }
    }
  };

  const participants = paginatedParticipants?.items;

  return (
    <Box>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
        <AppModalTitle onCloseClicked={() => onClose && onClose()}>Chat participants</AppModalTitle>

        <DialogContent>
          <List dense>
            {allowManage && (
              <ListItem sx={{ p: 0 }}>
                <ListItemButton onClick={handleAddParticipant}>
                  <ListItemAvatar>
                    <Avatar>
                      <AppIcon of='invites' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary='Add participant' />
                </ListItemButton>
              </ListItem>
            )}

            {participants?.map((participant) => {
              const isCurrentParticipant = participant.id === currentParticipant?.id;

              return (
                <ListItem
                  key={participant.id}
                  secondaryAction={
                    allowManage &&
                    !isCurrentParticipant && (
                      <LoadingButton
                        color='secondary'
                        loading={removeChatParticipantLoadingMap[participant.id!]}
                        onClick={() => handleRemoveParticipant(participant)}
                      >
                        <AppIcon of='removePerson' />
                      </LoadingButton>
                    )
                  }
                >
                  <ListItemAvatar>
                    <ChatParticipantAvatar participant={participant} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        <Stack direction='row' sx={{ justifyContent: "space-between" }}>
                          <span>
                            {participant?.personName?.name || participant?.email}{" "}
                            {isCurrentParticipant ? "(you)" : ""}
                          </span>
                          <Typography component='span' variant='caption' color='text.secondary'>
                            Joined{" "}
                            {DatetimeHelper.humanizeDateRangeDuration(
                              moment(),
                              participant.createdAt,
                            )}{" "}
                            ago
                          </Typography>
                        </Stack>
                      </>
                    }
                    secondary={
                      <Stack component='span'>
                        <Typography component='span' variant='body2' color='text.secondary'>
                          {participant.email}
                        </Typography>

                        {participant.parties && (
                          <Typography component='span' variant='body2' color='text.secondary'>
                            Belongs to {participant.parties.length}{" "}
                            {participant.parties.length === 1 ? "party" : "parties"}
                            {!_.isEmpty(participant.parties) && (
                              <>
                                :{" "}
                                {participant.parties.map((x, i) => (
                                  <Fragment key={i}>
                                    {i !== 0 && ", "}
                                    <PartyLink entity={x.party} />
                                  </Fragment>
                                ))}
                              </>
                            )}
                          </Typography>
                        )}
                      </Stack>
                    }
                  />
                </ListItem>
              );
            })}
          </List>

          <AddChatParticipantsModal
            open={isAddParticipantsModalOpen}
            onClose={handleAddParticipantsModalClose}
            onUpdated={handleAddParticipantsModalClose}
            chat={chat}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ChatParticipantsModal;
