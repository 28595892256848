import { IBaseEntityDto } from "@/core/api/generated";
import { Box, IconButton } from "@mui/material";
import { ReactNode, useState } from "react";
import AppIcon from "../Icons/AppIcon";
import MenuWithTrigger from "../Menu/MenuWithTrigger";

export interface ActionTriggersProps<TActionIdentifier extends string> {
  currentAction: TActionIdentifier | null;
  startAction: (props: TActionIdentifier) => void;
  entity?: IBaseEntityDto | null;
  handleClose: () => void;
  definition: Record<TActionIdentifier, string>;
}
interface ActionHandlersProps<TActionIdentifier extends string> {
  entity?: IBaseEntityDto | null;
  currentAction: TActionIdentifier | null;
  cancelAction: () => void;
  completeAction: () => void;
}
export interface EntityMenuOwnProps<TActionIdentifier extends string> {
  definition: Record<TActionIdentifier, string>;
  actionTriggers?: (props: ActionTriggersProps<TActionIdentifier>) => ReactNode;
  actionHandlers?: (props: ActionHandlersProps<TActionIdentifier>) => ReactNode;
  disabled?: boolean;
  entity?: IBaseEntityDto | null;
}

export default function EntityMenu<TActionIdentifier extends string>({
  disabled,
  entity,
  definition,
  actionTriggers,
  actionHandlers,
}: EntityMenuOwnProps<TActionIdentifier>) {
  const [currentAction, setCurrentAction] = useState<TActionIdentifier | null>(null);
  return (
    <>
      <MenuWithTrigger
        trigger={
          <IconButton disabled={disabled}>
            <AppIcon of='moreVert' />
          </IconButton>
        }
      >
        {({ handleClose }) => (
          <Box>
            {/**render actions buttons */}
            {actionTriggers &&
              actionTriggers({
                definition,
                handleClose,
                currentAction: currentAction,
                entity: entity,
                startAction: (actionDefinition) => setCurrentAction(actionDefinition),
              })}
          </Box>
        )}
      </MenuWithTrigger>

      {/** render actions handlers */}
      {actionHandlers &&
        actionHandlers({
          entity: entity,
          currentAction: currentAction,
          cancelAction: () => setCurrentAction(null),
          completeAction: () => setCurrentAction(null),
        })}
    </>
  );
}
