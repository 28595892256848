import { LoadingButton } from "@mui/lab";
import { Box, Container, FormControl, Grid, Stack, TextField } from "@mui/material";
import { Formik, getIn } from "formik";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import AppAvatar from "@/common/components/Avatar/AppAvatar";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import PhoneInput from "@/common/components/Form/Input/PhoneInput";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import { BaseFormikValues } from "@/common/ts/error";
import { ROUTE_PATH } from "@/common/constants/routing";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { UpdateTenantUserDto } from "@/core/api/generated";
import * as tenantUsersSlice from "@/store/management/users/slice";

export default function UserEditPage() {
  const { userId } = useParams<{ userId?: string }>();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const thunkDispatch = useAppThunkDispatch();

  const userRequest = useApiRequest(
    apiClient.usersApi.apiV1UsersUserIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      userId: userId!,
    },
    {
      skip: !userId,
    },
  );
  const user = userRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: user && {
      idValue: user.id!,
      newTitle: user.personName?.name || "",
    },
  });

  return (
    <CreateUpdatePageLayout header={<SimpleViewPageHeader title={"Edit user info"} />}>
      <Formik<
        BaseFormikValues &
          UpdateTenantUserDto & {
            departmentId?: string | undefined;
            locationId?: string | undefined;
            email?: string;
          }
      >
        enableReinitialize
        initialValues={{
          email: user?.email || "",
          personName: {
            firstName: user?.personName?.firstName || "",
            lastName: user?.personName?.lastName || "",
          },
          phoneNumber: user?.phoneNumber || "",
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // firstName: Yup.string().required("First name is required"),
          // lastName: Yup.string().required("Last name is required"),
          // phoneNumber: Yup.string().required("Phone number is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            const result = await thunkDispatch(
              tenantUsersSlice.updateUserInfo({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                userId: user!.id!,
                updateTenantUserDto: {
                  personName: values.personName,
                  phoneNumber: values.phoneNumber,
                },
              }),
            );
            enqueueSnackbar("User updated.", { variant: "success" });

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
            history.replace(ROUTE_PATH.USER_VIEW(result.id));
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Box>
                  <AppAvatar user={user} size={100} />
                </Box>

                <Box>
                  <TextField
                    disabled
                    fullWidth
                    required
                    label='Email'
                    margin='dense'
                    type='email'
                    value={values.email}
                    variant='outlined'
                  />

                  <FormControl margin='dense' fullWidth>
                    <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: 2 }}>
                      <TextField
                        autoFocus
                        error={Boolean(
                          getIn(touched, "personName.firstName") &&
                            getIn(errors, "personName.firstName"),
                        )}
                        fullWidth
                        required
                        helperText={
                          getIn(touched, "personName.firstName") &&
                          getIn(errors, "personName.firstName")
                        }
                        label='First name'
                        margin='none'
                        name='personName.firstName'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='text'
                        value={values.personName?.firstName}
                        variant='outlined'
                      />

                      <TextField
                        error={Boolean(
                          getIn(touched, "personName.lastName") &&
                            getIn(errors, "personName.lastName"),
                        )}
                        fullWidth
                        required
                        helperText={
                          getIn(touched, "personName.lastName") &&
                          getIn(errors, "personName.lastName")
                        }
                        label='Last name'
                        margin='none'
                        name='personName.lastName'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='text'
                        value={values.personName?.lastName}
                        variant='outlined'
                      />
                    </Box>
                  </FormControl>

                  <PhoneInput
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    fullWidth
                    required
                    label='Phone number'
                    margin='dense'
                    name='phoneNumber'
                    value={values.phoneNumber || ""}
                    variant='outlined'
                    placeholder='Phone number'
                    onBlur={handleBlur}
                    onChange={(e, ph, iso) => {
                      setFieldValue(`phoneNumber`, ph);
                    }}
                  />
                </Box>
              </Stack>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <LoadingButton
                sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                color='primary'
                loading={isSubmitting}
                disabled={
                  !values.personName?.firstName ||
                  !values.personName?.lastName ||
                  !values.phoneNumber
                }
                fullWidth
                type='submit'
                variant='contained'
              >
                Save
              </LoadingButton>
            </form>
          );
        }}
      </Formik>
    </CreateUpdatePageLayout>
  );
}
