import { Stack } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import FileListView from "@/common/components/Files/FileListView";
import { FileItem } from "@/common/fileItem";
import { VehicleDto } from "@/core/api/generated";

interface Props {
  vehicle: VehicleDto;
}
export default function AttachmentsTabContent({ vehicle }: Props) {
  const imageAttachments = useMemo(
    () => vehicle.attachments?.filter((x) => x.file?.contentType?.indexOf("image") !== -1),
    [vehicle],
  );
  const docAttachments = useMemo(
    () => vehicle.attachments?.filter((x) => x.file?.contentType?.indexOf("image") === -1),
    [vehicle],
  );

  return (
    <PageTabContent>
      <Stack direction='column' sx={{ width: "100%" }}>
        <PageTabHeader
          title={"Attachments"}
          subtitle2={<EntityCreatedByInfoDisplay entity={vehicle} />}
        />

        {_.isEmpty(vehicle.attachments) && <NoDataAlert title='No attachments added' />}
        {imageAttachments!.length !== 0 && (
          <FileListView
            files={FileItem.createManyFrom(imageAttachments)}
            maxVisibleFiles={10}
            itemWidth={250}
            itemHeight={190}
            sx={{ mt: 2 }}
          />
        )}
        {docAttachments!.length !== 0 && (
          <FileListView
            files={FileItem.createManyFrom(docAttachments)}
            maxVisibleFiles={10}
            itemWidth={250}
            sx={{ mt: 2 }}
          />
        )}
      </Stack>
    </PageTabContent>
  );
}
