import { HubConnection } from "@microsoft/signalr";

import { featureFlagsConfig } from "@/config/config";
import { apiClient } from "@/core/api/ApiClient";
import {
  ChatAcknowledgedDto,
  ChatActivityPerformedDto,
  ChatEventReceivedDto,
  ChatHistoryItemCreatedDto,
  ChatHistoryItemDeletedDto,
  ChatHistoryItemPinStatusChangedDto,
  ChatHistoryItemUpdatedDto,
  ChatHubClientMethodName,
  ChatHubServerMethodName,
  ChatMessageAcknowledgedDto,
  ChatMessageDeletedDto,
  ChatMessageSentDto,
  ChatMessageUpdatedDto,
  ChatMessagesReadDto,
  ChatParticipantConnectedDto,
  ChatParticipantDisconnectedDto,
  ChatParticipantJoinedDto,
  ChatParticipantLeftDto,
  ChatParticipantRemovedDto,
  ChatParticipantStatusUpdatedDto,
  ChatParticipantsAddedDto,
  ChatResolvedDto,
  ChatUpdatedDto,
  ConnectToChatDto,
  DisconnectFromChatDto,
  ParticipantOnlineStatus,
  ReportMyChatParticipantStatusDto,
  SubscribeOnChatActivityUpdatesDto,
  UnsubscribeFromChatActivityUpdatesDto,
} from "@/core/api/generated";
import store from "@/store";
import * as chatActivitySlice from "@/store/communication/chatActivityOverviewSlice";
import * as chatHistorySlice from "@/store/communication/chatHistorySlice";
import * as chatMessagesSlice from "@/store/communication/chatMessagesSlice";
import * as chatParticipantsSlice from "@/store/communication/chatParticipantsSlice";
import * as chatsSlice from "@/store/communication/chatsSlice";
import {
  selectChatCurrentParticipant,
  selectChatParticipantOnlineStatus,
  selectChatsWithMeInParticipants,
  selectCurrentUserChatParticipants,
} from "@/store/communication/selectors";

import { BaseHubService } from "./baseHubService";
import { HubSubscription } from "./hubSubscription";

export { ChatHubClientMethodName, ChatHubServerMethodName };

/** Client methods that can be called by the server. */
export const clientMethods = ChatHubClientMethodName;

/** Server methods that can be called by the client. */
export const serverMethods = ChatHubServerMethodName;

export class ChatHubService extends BaseHubService {
  constructor() {
    super({
      hubName: "ChatHub",
      showLogs: true,
      logIncomingMessages: true,
      logOutcomingMessages: true,
    });

    this.on("initialized", ({ connection }) => {
      this.registerMethodHandlers(connection);
    });

    this.on("connected", ({ connectionId }) => {
      apiClient.updateSignalConnectionIds({
        chatHubConnectionId: connectionId,
      });

      // this.echo("Hello");

      // I am online now
      if (featureFlagsConfig.chatOnlineStatus) {
        // mark me as online locally
        const currentParticipants = selectCurrentUserChatParticipants(store.getState());
        for (const participant of currentParticipants) {
          store.dispatch(
            chatParticipantsSlice.updateParticipantStatusInAllChats({
              participantId: participant.id,
              status: {
                onlineStatus: ParticipantOnlineStatus.Online,
              },
            }),
          );
        }

        // report my online status and ask everyone for their statuses
        const chats = selectChatsWithMeInParticipants(store.getState());
        for (const chat of chats) {
          const currentParticipant = selectChatCurrentParticipant(store.getState(), chat.id);
          this.reportMyChatParticipantStatus({
            chatId: chat.id,
            participantId: currentParticipant!.id,
            onlineStatus: ParticipantOnlineStatus.Online,
            onlyToConnectionIds: undefined,
            isReportBack: true,
          });
        }
      }
    });

    this.on("disconnected", () => {
      // everyone is offline
      if (featureFlagsConfig.chatOnlineStatus) {
        store.dispatch(
          chatParticipantsSlice.updateAllParticipantsStatusInAllChats({
            status: {
              onlineStatus: ParticipantOnlineStatus.Offline,
            },
          }),
        );
      }
    });

    this.on("reconnecting", () => {
      // everyone is offline
      if (featureFlagsConfig.chatOnlineStatus) {
        store.dispatch(
          chatParticipantsSlice.updateAllParticipantsStatusInAllChats({
            status: {
              onlineStatus: ParticipantOnlineStatus.Offline,
            },
          }),
        );
      }
    });
  }

  public async connect(): Promise<void> {
    await super.connectToHub("/signalr/chat");
  }

  public async disconnect(): Promise<void> {
    await super.disconnectFromHub();
  }

  private registerMethodHandlers(connection: HubConnection) {
    this.registerMethodHandler(clientMethods.TestNotify, this.onTestNotify.bind(this));
    this.registerMethodHandler(clientMethods.Echo, this.onEcho.bind(this));
    this.registerMethodHandler(
      clientMethods.ServerErrorOccurred,
      this.onServerErrorOccurred.bind(this),
    );

    this.registerMethodHandler(
      clientMethods.ParticipantConnected,
      this.onChatParticipantConnected.bind(this),
    );
    this.registerMethodHandler(
      clientMethods.ParticipantDisconnected,
      this.onChatParticipantDisconnected.bind(this),
    );
    this.registerMethodHandler(
      clientMethods.ParticipantStatusUpdated,
      this.onParticipantStatusUpdated.bind(this),
    );
    this.registerMethodHandler(
      clientMethods.ParticipantJoined,
      this.onParticipantJoined.bind(this),
    );
    this.registerMethodHandler(clientMethods.ParticipantLeft, this.onParticipantLeft.bind(this));

    this.registerMethodHandler(
      clientMethods.ParticipantsAdded,
      this.onParticipantsAdded.bind(this),
    );
    this.registerMethodHandler(
      clientMethods.ParticipantRemoved,
      this.onParticipantRemoved.bind(this),
    );

    this.registerMethodHandler(
      clientMethods.ChatActivityPerformed,
      this.onChatActivityPerformed.bind(this),
    );

    this.registerMethodHandler(clientMethods.ChatUpdated, this.onChatUpdated.bind(this));
    this.registerMethodHandler(clientMethods.ChatResolved, this.onChatResolved.bind(this));
    this.registerMethodHandler(clientMethods.ChatReopened, this.onChatReopened.bind(this));
    this.registerMethodHandler(clientMethods.ChatAcknowledged, this.onChatAcknowledged.bind(this));

    this.registerMethodHandler(clientMethods.MessageSent, this.onMessageSent.bind(this));
    this.registerMethodHandler(clientMethods.MessageUpdated, this.onMessageUpdated.bind(this));
    this.registerMethodHandler(clientMethods.MessageDeleted, this.onMessageDeleted.bind(this));
    this.registerMethodHandler(
      clientMethods.MessageAcknowledged,
      this.onMessageAcknowledged.bind(this),
    );
    this.registerMethodHandler(clientMethods.MessagesRead, this.onMessageRead.bind(this));

    this.registerMethodHandler(
      clientMethods.ChatEventReceived,
      this.onChatEventReceived.bind(this),
    );

    this.registerMethodHandler(
      clientMethods.ChatHistoryItemCreated,
      this.onChatHistoryItemCreated.bind(this),
    );
    this.registerMethodHandler(
      clientMethods.ChatHistoryItemUpdated,
      this.onChatHistoryItemUpdated.bind(this),
    );
    this.registerMethodHandler(
      clientMethods.ChatHistoryItemDeleted,
      this.onChatHistoryItemDeleted.bind(this),
    );
    this.registerMethodHandler(
      clientMethods.ChatHistoryItemPinStatusChanged,
      this.onChatHistoryItemPinStatusChanged.bind(this),
    );
  }

  //#region Method handlers

  private onTestNotify(data: any) {}

  private onEcho(data: any) {}

  private onServerErrorOccurred(data: any) {
    // always log error response from the BE
    console.error(this.logPrefix, "BE notified about server error:", data);
  }

  /** New participant manually informs everyone in the chat about connection. */
  private onChatParticipantConnected(data: ChatParticipantConnectedDto) {
    if (
      this.connection?.connectionId !== data.connectionId &&
      featureFlagsConfig.chatOnlineStatus
    ) {
      store.dispatch(
        chatParticipantsSlice.updateParticipantStatus({
          chatId: data.chatId,
          participantId: data.participantId,
          status: {
            onlineStatus: ParticipantOnlineStatus.Online,
          },
        }),
      );

      // when someone new connected, report my status in the chat to the newly connected participant
      const currentParticipant = selectChatCurrentParticipant(store.getState(), data.chatId);
      if (currentParticipant && currentParticipant.id !== data.participantId) {
        const onlineStatus = selectChatParticipantOnlineStatus(
          store.getState(),
          data.chatId,
          currentParticipant.id,
        );
        onlineStatus &&
          this.reportMyChatParticipantStatus({
            chatId: data.chatId,
            participantId: currentParticipant.id,
            onlineStatus: onlineStatus,
            onlyToConnectionIds: data.connectionId ? [data.connectionId] : undefined,
          });
      }
    }
  }

  /** Participant manually informs everyone in the chat about disconnection. */
  private onChatParticipantDisconnected(data: ChatParticipantDisconnectedDto) {
    if (
      this.connection?.connectionId !== data.connectionId &&
      featureFlagsConfig.chatOnlineStatus
    ) {
      store.dispatch(
        chatParticipantsSlice.updateParticipantStatus({
          chatId: data.chatId,
          participantId: data.participantId,
          status: {
            onlineStatus: ParticipantOnlineStatus.Offline,
          },
        }),
      );
    }
  }

  /** Server informs us about change in participant status. */
  private onParticipantStatusUpdated(data: ChatParticipantStatusUpdatedDto) {
    if (
      this.connection?.connectionId !== data.connectionId &&
      featureFlagsConfig.chatOnlineStatus
    ) {
      store.dispatch(
        chatParticipantsSlice.updateParticipantStatus({
          chatId: data.chatId,
          participantId: data.participantId,
          status: {
            onlineStatus: data.onlineStatus,
          },
        }),
      );

      // report back if asked for
      if (data.isReportBack) {
        const currentParticipant = selectChatCurrentParticipant(store.getState(), data.chatId);

        if (currentParticipant) {
          this.reportMyChatParticipantStatus({
            chatId: data.chatId,
            participantId: currentParticipant.id,
            onlineStatus: ParticipantOnlineStatus.Online,
            onlyToConnectionIds: data.connectionId ? [data.connectionId] : undefined,
          });
        }
      }
    }
  }

  private onParticipantJoined(data: ChatParticipantJoinedDto) {
    store.dispatch(
      chatParticipantsSlice.chatParticipantJoined({
        chatId: data.chatId!,
        participant: data.participant!,
      }),
    );
  }

  private onParticipantLeft(data: ChatParticipantLeftDto) {
    store.dispatch(
      chatParticipantsSlice.chatParticipantLeft({
        chatId: data.chatId!,
        participant: data.participant!,
      }),
    );
  }

  private onParticipantsAdded(data: ChatParticipantsAddedDto) {
    store.dispatch(chatParticipantsSlice.chatParticipantsAdded(data));
  }

  private onParticipantRemoved(data: ChatParticipantRemovedDto) {
    store.dispatch(chatParticipantsSlice.chatParticipantRemoved(data));
  }

  private onChatActivityPerformed(data: ChatActivityPerformedDto) {
    const currentParticipant = selectChatCurrentParticipant(store.getState(), data.chatId);
    const isByMe = data.participantId && currentParticipant?.id === data.participantId;
    if (!isByMe) {
      store.dispatch(chatActivitySlice.chatActivityPerformed(data));
    }
  }

  private onChatUpdated(data: ChatUpdatedDto) {
    store.dispatch(chatsSlice.chatUpdated(data));
  }

  private onChatResolved(data: ChatResolvedDto) {
    store.dispatch(chatsSlice.chatResolved(data));
  }

  private onChatReopened(data: ChatResolvedDto) {
    store.dispatch(chatsSlice.chatReopened(data));
  }

  private onChatAcknowledged(data: ChatAcknowledgedDto) {
    store.dispatch(chatsSlice.chatAcknowledged(data));
    store.dispatch(chatMessagesSlice.chatAcknowledged(data));
    store.dispatch(chatHistorySlice.chatAcknowledged(data));
  }

  private onMessageSent(data: ChatMessageSentDto) {
    store.dispatch(chatMessagesSlice.newChatMessageReceived(data));
    store.dispatch(chatHistorySlice.newChatMessageReceived(data));
  }

  private onMessageUpdated(data: ChatMessageUpdatedDto) {
    store.dispatch(chatMessagesSlice.chatMessageUpdated(data));
    store.dispatch(chatHistorySlice.chatMessageUpdated(data));
  }

  private onMessageDeleted(data: ChatMessageDeletedDto) {
    store.dispatch(chatMessagesSlice.chatMessageDeleted(data));
    store.dispatch(chatHistorySlice.chatMessageDeleted(data));
  }

  private onMessageAcknowledged(data: ChatMessageAcknowledgedDto) {
    store.dispatch(chatMessagesSlice.chatMessageAcknowledged(data));
    store.dispatch(chatHistorySlice.chatMessageAcknowledged(data));
  }

  private onMessageRead(data: ChatMessagesReadDto) {
    if (featureFlagsConfig.chatMessageReadStatus) {
      store.dispatch(chatMessagesSlice.chatMessagesRead(data));
      store.dispatch(chatHistorySlice.chatMessagesRead(data));
    }
  }

  private onChatEventReceived(data: ChatEventReceivedDto) {
    store.dispatch(chatHistorySlice.newChatEventReceived(data));
  }

  private onChatHistoryItemCreated(data: ChatHistoryItemCreatedDto) {
    store.dispatch(chatHistorySlice.chatHistoryItemCreated(data));
  }

  private onChatHistoryItemUpdated(data: ChatHistoryItemUpdatedDto) {
    store.dispatch(chatHistorySlice.chatHistoryItemUpdated(data));
  }

  private onChatHistoryItemDeleted(data: ChatHistoryItemDeletedDto) {
    store.dispatch(chatHistorySlice.chatHistoryItemDeleted(data));
  }

  private onChatHistoryItemPinStatusChanged(data: ChatHistoryItemPinStatusChangedDto) {
    store.dispatch(chatHistorySlice.chatHistoryItemPinStatusChanged(data));
  }

  //#endregion

  //#region Server methods

  public echo(message: string) {
    this.send(serverMethods.Echo, message);
  }

  /** Join SignalR chat group */
  public async connectToChat(data: ConnectToChatDto) {
    if (!data.chatId) {
      throw new Error("Chat id is required.");
    }
    await this.ensureConnectedToHub();

    const subscription = new HubSubscription({
      identifier: `connectToChat:Chat:${data.chatId}`,
      subscribe: (async () => {
        await this.send(serverMethods.ConnectToChat, data);

        if (featureFlagsConfig.chatOnlineStatus) {
          store.dispatch(
            chatParticipantsSlice.updateParticipantStatus({
              chatId: data.chatId,
              participantId: data.participantId,
              status: {
                onlineStatus: ParticipantOnlineStatus.Online,
              },
            }),
          );
        }
      }).bind(this),
      unsubscribe: (async () => {
        await this.ensureConnectedToHub();
        await this.send(serverMethods.DisconnectFromChat, data);
      }).bind(this),
    });
    await this.addSubscription(subscription);

    // cleanup function
    return subscription.unsubscribe;
  }

  /** Leave SignalR chat group */
  public async disconnectFromChat(data: DisconnectFromChatDto) {
    if (!data.chatId) {
      throw new Error("Chat id is required.");
    }

    await this.ensureConnectedToHub();
    await this.removeSubscription({
      identifier: `connectToChat:Chat:${data.chatId}`,
    });

    if (featureFlagsConfig.chatOnlineStatus) {
      store.dispatch(
        chatParticipantsSlice.updateParticipantStatus({
          chatId: data.chatId,
          participantId: data.participantId,
          status: {
            onlineStatus: ParticipantOnlineStatus.Offline,
          },
        }),
      );
    }
  }

  private async reportMyChatParticipantStatus(data: ReportMyChatParticipantStatusDto) {
    if (!data.chatId) {
      return;
    }

    if (featureFlagsConfig.chatOnlineStatus) {
      await this.ensureConnectedToHub();
      this.send(serverMethods.ReportMyChatParticipantStatus, data);
    }
  }

  public async subscribeOnChatActivityUpdates(data: SubscribeOnChatActivityUpdatesDto) {
    if (!data.chatId) {
      return;
    }
    if (!featureFlagsConfig.chatActivity) {
      return;
    }
    await this.ensureConnectedToHub();

    const subscription = new HubSubscription({
      identifier: `subscribeOnChatActivityUpdates:Chat:${data.chatId}`,
      subscribe: (() => {
        return this.send(serverMethods.SubscribeOnChatActivityUpdates, data);
      }).bind(this),
      unsubscribe: (() => {
        return this.unsubscribeFromChatActivityUpdates(data);
      }).bind(this),
    });
    await this.addSubscription(subscription);

    // cleanup function
    return subscription.unsubscribe;
  }

  public async unsubscribeFromChatActivityUpdates(data: UnsubscribeFromChatActivityUpdatesDto) {
    if (!data.chatId) {
      return;
    }

    if (featureFlagsConfig.chatActivity) {
      await this.ensureConnectedToHub();
      this.send(serverMethods.UnsubscribeFromChatActivityUpdates, data);
    }
  }

  //#endregion
}

export const chatHubService = new ChatHubService();
