import { DialogContentText, FormControl, TextField } from "@mui/material";
import { useSnackbar } from "notistack";

import { ValidationHelper } from "@/common/validation";
import { AdminUserDto, UserDto, UserActivateDto } from "@/core/api/generated";

import ConfirmationModal, { ConfirmationModalProps } from "../../Modals/ConfirmationModal";
import { useState } from "react";

export interface OwnProps {
  user: UserDto | AdminUserDto;
  activateFunc: (params: { targetUserId: string } & UserActivateDto) => Promise<void>;
}

type Props = OwnProps &
  Omit<ConfirmationModalProps, "title" | "content" | "onCancel" | "onConfirm">;

export default function UserActivateModal({ user, activateFunc, ...dialogProps }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [reason, setReason] = useState<string | undefined>(undefined);

  return (
    <ConfirmationModal
      fullWidth
      maxWidth='sm'
      {...dialogProps}
      title='Activate the user?'
      body={
        <>
          <DialogContentText>
            You are going to activate user with the email <strong>{user.email}</strong>.
          </DialogContentText>
          <DialogContentText>Please confirm the action.</DialogContentText>

          <DialogContentText>
            <FormControl fullWidth margin='dense'>
              <TextField
                multiline
                value={reason}
                label='Reason'
                onChange={(e) => setReason(e.target.value)}
              />
            </FormControl>
          </DialogContentText>
        </>
      }
      onCancel={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      onConfirm={async () => {
        try {
          await activateFunc({ targetUserId: user.id!, reason });
          enqueueSnackbar("User activated.", { variant: "success" });
          dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown");
        } catch (err) {
          const validation2 = ValidationHelper.handleApiErrorResponse(err);
          validation2.hasErrors &&
            enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
        }
      }}
    />
  );
}
