import { useEffect, useRef } from "react";

import { TenantDto } from "@/core/api/generated";
import { getCurrentTenant } from "@/store/tenants/slice";

import { useAppSelector, useAppThunkDispatch } from "../../redux";

/** Returns currently resolved tenant. */
export const useCurrentTenant = (): TenantDto | undefined => {
  const dispatch = useAppThunkDispatch();
  const currentTenant = useAppSelector((x) => x.tenants.currentTenant);
  const isLoading = useAppSelector((x) => x.tenants.loading.getCurrentTenant);
  const isRequestedRef = useRef(false);

  useEffect(() => {
    (async () => {
      if (currentTenant === undefined && !isLoading && !isRequestedRef.current) {
        isRequestedRef.current = true;
        try {
          await dispatch(getCurrentTenant());
        } catch (err) {
          console.error(err);
        }
      }
    })();
  }, [dispatch, currentTenant, isLoading]);

  return currentTenant;
};
