import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Modal, Stack, SxProps, Theme, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";

import { FileItem } from "@/common/fileItem";
import { useAppDispatch, useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";

import FileItemView from "../Files/FileItemView";
import * as filesSlice from "@/store/files/slice";
import { ReactZoomPanPinchRef } from "react-zoom-pan-pinch";
import ZoomableBlock from "./ZoomableBlock";
import { TurnedIn } from "@mui/icons-material";
import AppIcon from "../Icons/AppIcon";
import { FileHelper } from "@/common/helpers/file";

interface Props {
  sx?: SxProps<Theme>;
}

function FullScreenFileViewer({ sx }: Props) {
  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();
  const fullscreenFiles = useAppSelector((x) => x.files.fullScreen);
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
  const files = useAppSelector((x) => x.files.fullScreen.files || []);

  const [selectedFile, setSelectedFile] = useState<Nullable<FileItem>>(null);
  const [fullscreenFile, setFullscreenFile] = useState<Nullable<FileItem>>(null);
  const isOpen = files.length > 0;
  const isFullscreenOpen = !!fullscreenFile;

  useEffect(() => {
    if (!selectedFile && files.length > 0) {
      setSelectedFile(files[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleSelectImage = (file: FileItem) => {
    setSelectedFile(file);
  };

  const handleClose = () => {
    thunkDispatch(filesSlice.setFullScreenImages({ files: [] }));
    setSelectedFile(null);
  };

  const handleFullscreenClose = () => {
    setFullscreenFile(null);
  };

  const handleDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedFile?.blob) {
      FileHelper.downloadBlob(selectedFile.blob, selectedFile.blob.name);
    } else if (selectedFile?.file) {
      FileHelper.downloadFileByUrl(
        selectedFile.file.url!,
        selectedFile.file.originalFileName || selectedFile.file.fileName!,
      );
    }
  };
  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            ...(sx as unknown as any),
          }}
        >
          {/* Close button */}
          <IconButton
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              zIndex: 1,
            }}
            onClick={handleClose}
          >
            <CloseIcon
              sx={{
                fill: "white",
              }}
            />
          </IconButton>

          {/* Selected file viewer */}
          <Box
            sx={{
              width: "100%",
              height: "auto",
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              mt: 1,
            }}
          >
            {selectedFile && (
              <Box
                sx={{
                  position: "relative",
                  width: { xxs: "95%", md: "unset" },
                  maxWidth: { xxs: "95%", md: "90%", lg: "90%" },
                  height: "auto",
                  maxHeight: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ZoomableBlock wrapperRef={transformComponentRef}>
                  <FileItemView
                    fileItem={selectedFile}
                    actions={(item) => ({
                      enabled: false,
                      click: false,
                      download: true,
                      openInNewTab: false,
                      fullscreen: item.fileType.isImage,
                      onFullscreen: (item2) => {
                        setFullscreenFile(item2);
                      },
                    })}
                    fullWidth
                    sx={{
                      width: "auto",
                      height: "100%",
                      overflow: "hidden",
                    }}
                    imageSx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </ZoomableBlock>
                {/* Fullscreen actions must be outside from ZoomableBlock */}
                <Stack
                  className='controls'
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    borderRadius: "4px",
                    padding: "2px",
                    backgroundColor: (theme) => theme.palette.background.default,
                  }}
                  direction='column'
                  spacing='4px'
                >
                  <Tooltip
                    title='You can use the mouse to zoom in/out and navigate through the image by dragging the image'
                    placement='top'
                  >
                    <IconButton size='extraSmall'>
                      <AppIcon of='info' />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Download' placement='top'>
                    <IconButton onClick={handleDownload} size='extraSmall'>
                      <AppIcon of='download' />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Box>
            )}
          </Box>

          {/* Files preview */}
          {files.length > 1 && (
            <Box
              sx={{
                mt: "auto",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  maxWidth: "100%",
                  overflowX: "auto",
                  display: "grid",
                  gridAutoFlow: "column",
                  padding: 2,
                  gridGap: "10px",
                  webkitOverflowScrolling: "touch",
                }}
              >
                {files.map((file, index) => (
                  <Box sx={{ position: "relative" }} key={index}>
                    <FileItemView
                      fileItem={file}
                      actions={(item) => ({
                        enabled: true,
                        click: true,
                        onClick: () => {
                          transformComponentRef?.current?.resetTransform();
                          handleSelectImage(file);
                        },
                      })}
                      sx={{
                        height: "150px",
                        width: "unset",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          )}

          {/* Fullscreen file view modal */}
          <Modal open={isFullscreenOpen} onClose={handleFullscreenClose}>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              }}
            >
              {/* Close button */}
              <IconButton
                sx={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                }}
                onClick={handleFullscreenClose}
              >
                <CloseIcon
                  sx={{
                    fill: "white",
                  }}
                />
              </IconButton>

              <ZoomableBlock>
                <FileItemView
                  fileItem={fullscreenFile!}
                  actions={(item) => ({
                    enabled: true,
                    click: false,
                    download: true,
                    openInNewTab: false,
                    fullscreen: false,
                  })}
                  fullWidth
                  sx={{
                    width: "auto",
                    height: "100%",
                  }}
                  imageSx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </ZoomableBlock>
            </Box>
          </Modal>
        </Box>
      </Modal>
    </>
  );
}

export default FullScreenFileViewer;
