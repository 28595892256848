import { apiClient } from "@/core/api/ApiClient";
import { VehicleSpecDto, VehicleSpecInputDto, VehicleVisualModelDto } from "@/core/api/generated";

export class VehicleVisualModelHelper {
  /** Returns the most suitable or default VehicleVisualModel for provided VehicleSpec. */
  public static async getSuggestedModel(
    spec: VehicleSpecDto | VehicleSpecInputDto | null | undefined,
  ): Promise<VehicleVisualModelDto | null> {
    const response =
      await apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet(
        {
          vehicleType: spec?.type || undefined,
          bodyType: spec?.bodyType || undefined,
        },
      );
    return response.data;
  }
}
