import { Dialog, DialogContent, DialogProps, LinearProgress, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { ChatDto, ChatParticipantDto } from "@/core/api/generated";

import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useAppSelector } from "@/common/hooks/redux";
import { apiClient } from "@/core/api/ApiClient";
import ChatMessage from "../ChatMessage/ChatMessage";

export interface OwnProps extends DialogProps {
  chat: ChatDto;
  currentParticipant?: ChatParticipantDto;
  participants?: ChatParticipantDto[];
}

interface Props extends OwnProps {}

/** Display all pinned items */
function ChatPinnedItemsModal({
  chat,
  currentParticipant,
  participants,

  ...dialogProps
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const prevOpenRef = useRef(false);
  const [limit, setLimit] = useState<number>(50);
  const isItemsLoading = useAppSelector(
    (x) => x.communication.chatHistory.loading.getPinnedHistoryItems![chat.id!],
  );

  const getPinnedChatHistoryItemsRequest = useApiRequest(
    apiClient.chatHistoryApi.apiV1ChatsChatIdHistoryGetPinnedPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      chatId: chat.id!,
      chatHistoryGetPaginatedDto: {
        offset: 0,
        limit: limit,
      },
    },
    {
      deps: [chat.id, prevOpenRef.current, dialogProps.open],
      skip: prevOpenRef.current && !dialogProps.open,
    },
  );
  const pinnedChatHistoryItems = getPinnedChatHistoryItemsRequest.data;
  // load on open only
  // useEffect(() => {
  //   const limit = 50;
  //   const isOpening = !prevOpenRef.current && dialogProps.open;
  //   if (
  //     isOpening &&
  //     (!pinnedChatHistoryItems || pinnedChatHistoryItems.pagination!.limit !== limit)
  //   ) {
  //     getPinnedChatHistoryItemsRequest.refetch();
  //   }
  // }, [chat.id, pinnedChatHistoryItems, prevOpenRef.current, dialogProps.open]);
  useEffect(() => {
    prevOpenRef.current = dialogProps.open;
  }, [dialogProps.open]);

  return (
    <Box>
      <Dialog fullWidth maxWidth='md' {...dialogProps}>
        <AppModalTitle
          onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
        >
          Chat pinned items
        </AppModalTitle>
        <DialogContent>
          {isItemsLoading && (
            <Box sx={{ mb: 1 }}>
              <LinearProgress color='primary' />
            </Box>
          )}

          <Stack direction='column' spacing={1}>
            {pinnedChatHistoryItems &&
              pinnedChatHistoryItems.items!.map((item, i) => {
                const participantId =
                  item.participantId ||
                  item.event?.participantId ||
                  item.message?.participantId ||
                  item.negotiationProposal?.participantId;
                const participant = participants?.find((x) => x.id === participantId);

                return (
                  <Box key={i}>
                    {item.message && (
                      <ChatMessage
                        chat={chat}
                        item={item}
                        chatSettings={chat.settings}
                        currentParticipant={currentParticipant}
                        participant={participant}
                        participants={participants}
                      />
                    )}

                    {/* {item.negotiationProposal && (
                      <ChatNegotiationProposalContainer
                        item={item}
                        chat={chat}
                        chatSettings={chat.settings}
                      />
                    )} */}
                    <Box></Box>
                  </Box>
                );
              })}
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ChatPinnedItemsModal;
