import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik, getIn } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";

import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { ArrayHelper } from "@/common/helpers/array";
import { FormikHelper } from "@/common/helpers/formik";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useTenantProfile } from "@/common/hooks/entity/tenant/useTenantProfile";
import useMounted from "@/common/hooks/mount/useMounted";
import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import { useUserAffiliation } from "@/common/hooks/useUserAffiliation";
import { enumService } from "@/common/services/enum";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AssetSpecDto,
  AssetSubscriptionPlanCreateDto,
  AssetSubscriptionPlanDto,
  AssetSubscriptionPlanUpdateDto,
  BillingPeriod,
  DiscountType,
  DiscountValueType,
  EntityType,
  PaymentRegularityType,
  PoolItemEntityType,
  SpotType,
  SubscriptionPlanExtraOptionInputDto,
  SubscriptionPlanIncludedOptionInputDto,
  SubscriptionPlanOptionForVehicleType,
  SubscriptionPlanOptionType,
  SubscriptionPlanPriceInputDto,
  UnitOfTime,
  VehicleTaxType,
} from "@/core/api/generated";
import _ from "lodash";
import { Fragment, useCallback, useState } from "react";
import NoDataAlert from "../../AppAlerts/NoDataAlert";
import DropdownButton from "../../Button/DropdownButton";
import FoldableBlock from "../../Display/FoldableBlock";
import ApiEnumAutocomplete from "../../Enum/ApiEnumAutocomplete";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FileUploader from "../../Files/FileUploader";
import ImageUploader from "../../Files/ImageUploader";
import DurationInput from "../../Form/Input/DurationInput";
import AppIcon from "../../Icons/AppIcon";
import PriceSummaryInput from "../../PriceSummary/PriceSummaryInput";
import AccessoryAutocompleteOrCreate from "../Accessory/AccessoryAutocompleteOrCreate";
import AssetSpecInput, {
  assetSpecToInputDto,
  assetSpecToInputDtoFormatBeforePost,
} from "../Asset/AssetSpecInput";
import EntityAffiliationInput from "../EntityAffiliation/EntityAffiliationInput";
import GeneralDiscountInput from "../General/GeneralDiscount/GeneralDiscountInput";
import GeneralPriceInput from "../General/GeneralPrice/GeneralPriceInput";
import PoolAutocompleteOrCreate from "../Pool/PoolAutocompleteOrCreate";
import ProductLocationAutocompleteOrCreate from "../ProductLocation/ProductLocationAutocompleteOrCreate";
import CountryAutocomplete from "../ReferenceData/CountryAutocomplete";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";
import AssetSubscriptionPlanPoolsIntersectionAlert from "./AssetSubscriptionPlanPoolsIntersectionAlert";

type DefaultValues = {
  productLocationIds?: string[] | null | undefined;
  name?: AssetSubscriptionPlanCreateDto["name"];
};

export interface AssetSubscriptionPlanCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<AssetSubscriptionPlanDto, DefaultValues> {
  assetSubscriptionPlanId?: string;
}

export type AssetSubscriptionPlanCreateUpdateProps = AssetSubscriptionPlanCreateUpdateOwnProps;

export default function AssetSubscriptionPlanCreateUpdate({
  assetSubscriptionPlanId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: AssetSubscriptionPlanCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();

  const currentCurrency = useCurrentCurrency();
  const tenantProfile = useTenantProfile();

  const isCreate = !assetSubscriptionPlanId;

  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [isInferAssetSpecLoading, setIsInferAssetSpecLoading] = useState(false);

  const assetSubscriptionPlanRequest = useApiRequest(
    apiClient.assetSubscriptionPlansApi.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetSubscriptionPlanId: assetSubscriptionPlanId!,
    },
    {
      skip: !assetSubscriptionPlanId,
    },
  );
  const assetSubscriptionPlan = assetSubscriptionPlanRequest?.data;

  const { departments, locations } = useUserAffiliation();

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: assetSubscriptionPlan && {
      idValue: assetSubscriptionPlan.id!,
      newTitle: assetSubscriptionPlan.localNumber || "",
    },
  });

  const inferAssetSpecFromPool = useCallback(
    async (poolId: string | null | undefined): Promise<AssetSpecDto | null> => {
      if (!poolId) {
        return null;
      }
      setIsInferAssetSpecLoading(true);
      try {
        const response = await apiClient.assetsApi.apiV1AssetsSpecInferFromPoolGet({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          poolId: poolId,
        });
        return response.data || null;
      } catch (err) {
        return null;
      } finally {
        setIsInferAssetSpecLoading(false);
      }
    },
    [],
  );

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.AssetSubscriptionPlan}
      entityId={assetSubscriptionPlanId}
      entity={assetSubscriptionPlan}
      entityRequest={assetSubscriptionPlanRequest}
    >
      <Formik<
        BaseFormikValues &
          Omit<AssetSubscriptionPlanCreateDto, "basePrice" | "alternativePrices"> &
          Omit<AssetSubscriptionPlanUpdateDto, "basePrice" | "alternativePrices"> & {
            basePrice?: SubscriptionPlanPriceInputDto & {
              total?: number;
            };
            alternativePrices?: Array<
              SubscriptionPlanPriceInputDto & {
                total?: number;
              }
            >;

            initialImage: AssetSubscriptionPlanDto["image"];
            initialImages: AssetSubscriptionPlanDto["images"];
            initialAttachments: AssetSubscriptionPlanDto["attachments"];

            uploadedImage?: FileItem;
            uploadedImages?: FileItem[];
            uploadedAttachments?: FileItem[];
          }
      >
        enableReinitialize
        initialValues={{
          departmentId:
            assetSubscriptionPlan?.departmentIds?.[0] ||
            (departments && departments[0] && departments[0].id) ||
            undefined,
          locationId:
            assetSubscriptionPlan?.locationIds?.[0] ||
            (locations && locations[0] && locations[0].id) ||
            undefined,
          productLocations:
            defaultValues?.productLocationIds?.map((id) => ({ productLocationId: id })) ||
            assetSubscriptionPlan?.productLocations?.map((x) => ({
              ...x,
              productLocationId: x.productLocation?.id,
              poolId: x.pool?.id,
            })) ||
            undefined,
          poolId: assetSubscriptionPlan?.pool?.id || undefined,
          isEnabled: !_.isNil(assetSubscriptionPlan?.isEnabled)
            ? assetSubscriptionPlan?.isEnabled
            : undefined,
          isFree: !_.isNil(assetSubscriptionPlan?.isFree)
            ? assetSubscriptionPlan?.isFree
            : undefined,
          name: defaultValues?.name || assetSubscriptionPlan?.name || undefined,
          description: assetSubscriptionPlan?.description || undefined,
          assetSpec: assetSpecToInputDto(assetSubscriptionPlan?.assetSpec) || undefined,
          settings: assetSubscriptionPlan?.settings || undefined,
          settings2: assetSubscriptionPlan?.settings2 || undefined,
          image: assetSubscriptionPlan?.image || undefined,
          images: assetSubscriptionPlan?.images || undefined,
          attachments: assetSubscriptionPlan?.attachments || undefined,
          country: assetSubscriptionPlan?.country || tenantProfile?.settings?.country || undefined,
          currency: assetSubscriptionPlan?.currency || currentCurrency || undefined,
          basePrice: assetSubscriptionPlan?.basePrice || {
            period: BillingPeriod.Month,
          },
          alternativePrices: assetSubscriptionPlan?.alternativePrices || undefined,
          durations: assetSubscriptionPlan?.durations || undefined,
          includedOptions:
            assetSubscriptionPlan?.includedOptions?.map((x) => ({
              ...x,
              option: { ...x.option!, accessoryId: x.option!.accessory?.id },
            })) || undefined,
          extraOptions:
            assetSubscriptionPlan?.extraOptions?.map((x) => ({
              ...x,
              option: { ...x.option!, accessoryId: x.option!.accessory?.id },
            })) || undefined,

          initialImage: assetSubscriptionPlan?.image || undefined,
          initialImages: assetSubscriptionPlan?.images || undefined,
          initialAttachments: assetSubscriptionPlan?.attachments || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          const values2: typeof values = {
            ...values,
            productLocations: _.isEmpty(values.productLocations)
              ? undefined
              : values.productLocations,
            assetSpec: assetSpecToInputDtoFormatBeforePost(values.assetSpec),
          };

          try {
            if (isCreate) {
              const response =
                await apiClient.assetSubscriptionPlansApi.apiV1AssetSubscriptionPlansPost({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  assetSubscriptionPlanCreateDto: {
                    ...values2,
                  },
                });
              enqueueSnackbar("Asset subscription plan created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response =
                await apiClient.assetSubscriptionPlansApi.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdPut(
                  {
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    assetSubscriptionPlanId,
                    assetSubscriptionPlanUpdateDto: {
                      ...values2,
                    },
                  },
                );
              enqueueSnackbar("Asset subscription plan updated.", { variant: "success" });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {(formikProps) => {
          const {
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setErrors,
            setFieldValue,
            setValues,
          } = formikProps;
          const canSubmit = !isUploadingFiles;

          const assetSpecFormikProps = FormikHelper.getSubProps(
            formikProps,
            "assetSpec",
            (v) => v.assetSpec,
          );

          const selectedPoolIds = _.concat(
            values.poolId ? [values.poolId] : [],
            values.productLocations?.map((y) => y.poolId!).filter((y) => !!y) || [],
          );

          const billingPeriods = enumService.getEnumValues("BillingPeriod", { exceptNone: true });
          const selectedBillingPeriods = [
            ...(values.basePrice?.period ? [values.basePrice.period] : []),
            ...(values.alternativePrices?.map((x) => x.period!)?.filter((x) => !!x) || []),
          ];
          const notSelectedBillingPeriods = billingPeriods.filter(
            (x) => !selectedBillingPeriods.includes(x),
          );

          const canAddAlternativePrice =
            (values.alternativePrices?.length || 0) < notSelectedBillingPeriods.length;

          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Box>
                  {/* <FormControl margin='dense' fullWidth error={Boolean(errors.isFree)}>
                    <FormControlLabel control={<Checkbox checked={values.isFree|| false} onChange={(e) => setFieldValue("isFree", e.target.checked)} />} label='Free' />
                    <FormHelperText>{`Free plan doesn't require any payments.`}.</FormHelperText>
                    <FormHelperText>{errors.isFree}</FormHelperText>
                  </FormControl> */}

                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    required
                    fullWidth
                    helperText={touched.name && errors.name}
                    label='Name'
                    margin='dense'
                    name='name'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                    value={values.name || ""}
                    variant='outlined'
                  />

                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    multiline
                    minRows={2}
                    helperText={touched.description && errors.description}
                    label='Description'
                    margin='dense'
                    name='description'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                    value={values.description || ""}
                    variant='outlined'
                  />

                  {/* Entity affiliation */}
                  <EntityAffiliationInput
                    department={{
                      departmentId: values.departmentId,
                      onChange: (d) => {
                        setFieldValue("departmentId", d?.id);
                        setFieldValue("locationId", undefined);
                      },
                      error: errors.departmentId,
                    }}
                    location={{
                      locationId: values.locationId,
                      onChange: (l) => {
                        setFieldValue("locationId", l?.id);
                      },
                      searchFilters: { departmentId: values.departmentId },
                      createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                      error: errors.locationId,
                    }}
                  />

                  <FormControl margin='dense' fullWidth>
                    <CountryAutocomplete
                      entity={values.country || undefined}
                      entityId={undefined}
                      onChange={(newValue) => {
                        setFieldValue(`country`, newValue);
                      }}
                      textFieldProps={{
                        error: Boolean(errors.country),
                        helperText: ValidationHelper.getErrorsAsString(errors.country),
                      }}
                    />
                    <FormHelperText>Country in which the subscription will be sold.</FormHelperText>
                  </FormControl>
                </Box>

                {/* AssetSpec */}
                <FoldableBlock
                  defaultIsFolded={false}
                  trigger={{
                    label: (
                      <Typography component='span' variant='h6'>
                        Asset spec *
                      </Typography>
                    ),
                  }}
                >
                  <Alert severity='info' sx={{ mb: 1 }}>
                    Asset spec describes characteristics of underlying assets and can be displayed
                    to end customers.
                  </Alert>

                  {isInferAssetSpecLoading && <LinearProgress sx={{ my: 1 }} />}

                  {!isInferAssetSpecLoading && (
                    <AssetSpecInput
                      formikProps={assetSpecFormikProps}
                      onEntityTypeChange={(newValue) => {
                        // reset selected pools as AssetEntityType influences Pools that can be selected
                        setFieldValue("poolId", undefined);
                        setFieldValue(
                          "productLocations",
                          values.productLocations?.map((x) => ({ ...x, poolId: undefined })),
                        );
                      }}
                    />
                  )}

                  {errors.assetSpec && (
                    <FormHelperText error sx={{ mt: 1 }}>
                      {ValidationHelper.getFormikErrorsAsString(errors.assetSpec)}
                    </FormHelperText>
                  )}
                </FoldableBlock>

                {/* ProductLocation & Spots */}
                <FoldableBlock
                  defaultIsFolded={false}
                  trigger={{
                    label: (
                      <Typography component='span' variant='h6'>
                        Sales locations & pools
                      </Typography>
                    ),
                  }}
                >
                  <Stack spacing={2}>
                    <Alert severity='info'>
                      <Stack spacing={1}>
                        <Box>
                          <Typography variant='subtitle2'>About pools:</Typography>
                          <Box>
                            Pool is used for asset accounting and availability management. The
                            primary pool is used by the whole plan by default while the secondary
                            pools are assigned per sales location and used only for those locations.
                          </Box>
                        </Box>

                        <Box>
                          <Typography variant='subtitle2'>About sales locations:</Typography>
                          <Box>
                            Sales locations are places where the plan is available for end
                            customers. The plan will be available for all locations if no locations
                            are set.
                          </Box>
                          <Box>
                            For each location you can setup list of spots. For instance, separate
                            spots for{" "}
                            <strong>
                              <InlineApiEnumValue type='SpotType' value={SpotType.CheckOut} />
                            </strong>{" "}
                            and{" "}
                            <strong>
                              <InlineApiEnumValue type='SpotType' value={SpotType.CheckIn} />
                            </strong>
                            .
                          </Box>
                        </Box>
                      </Stack>
                    </Alert>

                    <AssetSubscriptionPlanPoolsIntersectionAlert poolIds={selectedPoolIds} />

                    {/* Pool */}
                    <FormControl margin='dense' fullWidth>
                      <PoolAutocompleteOrCreate
                        autocompleteProps={{
                          entityId: values.poolId,
                          searchFilters: {
                            itemEntityType: PoolItemEntityType.Asset,
                            itemEntitySubType: values.assetSpec?.entityType,
                            excludeIds: selectedPoolIds?.filter((y) => y !== values.poolId),
                          },
                          isPreload: isCreate,
                          disabled: !values.assetSpec?.entityType,
                          label: "Primary asset pool",
                          textFieldProps: {
                            error: Boolean(errors.poolId),
                            helperText: errors.poolId,
                          },
                          onChange: async (newValue) => {
                            setFieldValue("poolId", newValue?.id);

                            // infer asset spec
                            if (!values.assetSpec) {
                              const inferredAssetSpec = await inferAssetSpecFromPool(newValue?.id);
                              setFieldValue("assetSpec", assetSpecToInputDto(inferredAssetSpec));
                            }
                          },
                        }}
                        createUpdateProps={{
                          defaultValues: {
                            itemEntityType: PoolItemEntityType.Asset,
                          },
                        }}
                        createFormPlacement='modal'
                        onCreate={async (newValue) => {
                          if (!newValue || newValue.itemEntityType === PoolItemEntityType.Asset) {
                            setFieldValue("poolId", newValue?.id);

                            // infer asset spec
                            if (!values.assetSpec) {
                              const inferredAssetSpec = await inferAssetSpecFromPool(newValue?.id);
                              setFieldValue("assetSpec", assetSpecToInputDto(inferredAssetSpec));
                            }
                          }
                        }}
                      />
                    </FormControl>

                    <FormControl margin='dense'>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.settings2?.isEnsurePoolBelongToSinglePlan || false}
                            onBlur={handleBlur}
                            onChange={(e) =>
                              setFieldValue(
                                "settings2.isEnsurePoolBelongToSinglePlan",
                                e.target.checked,
                              )
                            }
                          />
                        }
                        label='Ensure pools belong to a single plan'
                      />
                      <FormHelperText>
                        When checked, ensures that single pool can be added max to one plan.
                      </FormHelperText>
                    </FormControl>

                    <Stack spacing={2}>
                      <Typography variant='subtitle1'>Sales locations</Typography>

                      {_.isEmpty(values.productLocations) && (
                        <NoDataAlert title='No sales locations added' />
                      )}

                      <Stack spacing={1}>
                        {values.productLocations?.map((x, i) => (
                          <Card key={i}>
                            <CardContent>
                              <Box
                                sx={{
                                  display: "grid",
                                  gridTemplateColumns: {
                                    xxs: "1fr",
                                    md: "1fr 1fr 0fr",
                                  },
                                  gap: 2,
                                }}
                              >
                                {/* ProductLocation */}
                                <FormControl margin='dense' fullWidth>
                                  <ProductLocationAutocompleteOrCreate
                                    autocompleteProps={{
                                      entityId: x.productLocationId,
                                      entity: undefined,
                                      required: true,
                                      isPreload: isCreate,
                                      searchFilters: {
                                        excludeIds: values.productLocations
                                          ?.map((y) => y.productLocationId!)
                                          .filter((y) => !!y && y !== x.productLocationId),
                                      },
                                      textFieldProps: {
                                        error: Boolean(
                                          getIn(errors, `productLocations[${i}].productLocationId`),
                                        ),
                                        helperText: ValidationHelper.getFormikErrorsAsString(
                                          getIn(errors, `productLocations[${i}].productLocationId`),
                                        ),
                                      },
                                      onChange: (newValue) => {
                                        setFieldValue(
                                          `productLocations[${i}].productLocationId`,
                                          newValue?.id,
                                        );
                                      },
                                    }}
                                    createFormPlacement='modal'
                                    onCreate={(newValue) => {
                                      setFieldValue(
                                        `productLocations[${i}].productLocationId`,
                                        newValue?.id,
                                      );
                                    }}
                                  />
                                </FormControl>

                                {/* Pool */}
                                <FormControl margin='dense' fullWidth>
                                  <PoolAutocompleteOrCreate
                                    autocompleteProps={{
                                      entityId: x.poolId,
                                      searchFilters: {
                                        itemEntityType: PoolItemEntityType.Asset,
                                        itemEntitySubType: values.assetSpec?.entityType,
                                        excludeIds: selectedPoolIds?.filter((y) => y !== x.poolId),
                                      },
                                      isPreload: isCreate,
                                      disabled: !values.assetSpec?.entityType,
                                      label: "Secondary asset pool (for the sales location)",
                                      textFieldProps: {
                                        error: Boolean(
                                          getIn(errors, `productLocations[${i}].poolId`),
                                        ),
                                        helperText: getIn(errors, `productLocations[${i}].poolId`),
                                      },
                                      onChange: async (newValue) => {
                                        setFieldValue(
                                          `productLocations[${i}].poolId`,
                                          newValue?.id,
                                        );

                                        // infer asset spec
                                        if (!values.assetSpec) {
                                          const inferredAssetSpec = await inferAssetSpecFromPool(
                                            newValue?.id,
                                          );
                                          setFieldValue(
                                            "assetSpec",
                                            assetSpecToInputDto(inferredAssetSpec),
                                          );
                                        }
                                      },
                                    }}
                                    createUpdateProps={{
                                      defaultValues: {
                                        itemEntityType: PoolItemEntityType.Asset,
                                      },
                                    }}
                                    createFormPlacement='modal'
                                    onCreate={async (newValue) => {
                                      if (
                                        !newValue ||
                                        newValue.itemEntityType === PoolItemEntityType.Asset
                                      ) {
                                        setFieldValue(
                                          `productLocations[${i}].poolId`,
                                          newValue?.id,
                                        );

                                        // infer asset spec
                                        if (!values.assetSpec) {
                                          const inferredAssetSpec = await inferAssetSpecFromPool(
                                            newValue?.id,
                                          );
                                          setFieldValue(
                                            "assetSpec",
                                            assetSpecToInputDto(inferredAssetSpec),
                                          );
                                        }
                                      }
                                    }}
                                  />
                                </FormControl>

                                {/* Controls */}
                                <Stack direction='row' sx={{ alignItems: "center" }}>
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue(
                                        "productLocations",
                                        ArrayHelper.removeByIndex(values.productLocations, i),
                                      )
                                    }
                                  >
                                    <AppIcon of='delete' />
                                  </IconButton>
                                </Stack>
                              </Box>
                            </CardContent>
                          </Card>
                        ))}
                      </Stack>

                      {errors.productLocations && _.isString(errors.productLocations) && (
                        <FormHelperText error>
                          {ValidationHelper.getFormikErrorsAsString(errors.productLocations)}
                        </FormHelperText>
                      )}

                      <Box>
                        <Button
                          variant='outlined'
                          color='secondary'
                          size='small'
                          startIcon={<AppIcon of='add' />}
                          onClick={() => {
                            setFieldValue("productLocations", [
                              ...(values.productLocations || []),
                              {},
                            ]);
                          }}
                        >
                          Add sales location
                        </Button>
                      </Box>
                    </Stack>
                  </Stack>
                </FoldableBlock>

                {/* Attachments */}
                <FoldableBlock
                  defaultIsFolded
                  trigger={{
                    label: (
                      <Typography component='span' variant='h6'>
                        Attachments
                      </Typography>
                    ),
                  }}
                >
                  {/* Image */}
                  <Stack spacing={1}>
                    <Typography component='div' variant='subtitle1'>
                      Image (primary)
                    </Typography>

                    <FormControl fullWidth margin='none'>
                      <ImageUploader
                        multiple={false}
                        maxFiles={1}
                        defaultFiles={FileItem.createManyFrom([
                          ...((values.uploadedImage && [values.uploadedImage]) ||
                            undefined ||
                            (values.image && [values.image]) ||
                            undefined ||
                            (values.initialImage && [values.initialImage]) ||
                            []),
                        ])}
                        onChange={(newFiles) => {
                          setFieldValue(
                            "image",
                            newFiles[0]
                              ? FileItem.toGeneralAttachmentInputDto(newFiles[0])
                              : undefined,
                          );
                          setFieldValue("uploadedImage", newFiles[0]);
                        }}
                        onUploadStarted={() => {
                          setIsUploadingFiles(true);
                        }}
                        onUploadFinished={() => {
                          setIsUploadingFiles(false);
                        }}
                      />

                      {errors.image && (
                        <FormHelperText error>
                          {ValidationHelper.getFormikErrorsAsString(errors.image)}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  {/* Images */}
                  <Stack spacing={1}>
                    <Typography component='div' variant='subtitle1'>
                      Images (secondary or gallery)
                    </Typography>

                    <FormControl fullWidth margin='none'>
                      <ImageUploader
                        multiple
                        maxFiles={100}
                        defaultFiles={FileItem.createManyFrom(
                          values.uploadedImages || values.images || values.initialImages,
                        )}
                        onChange={(newFiles) => {
                          setFieldValue(
                            "images",
                            FileItem.toManyGeneralAttachmentInputDto(newFiles),
                          );
                          setFieldValue("uploadedImages", newFiles);
                        }}
                        onUploadStarted={() => {
                          setIsUploadingFiles(true);
                        }}
                        onUploadFinished={() => {
                          setIsUploadingFiles(false);
                        }}
                      />

                      {errors.images && (
                        <FormHelperText error>
                          {ValidationHelper.getFormikErrorsAsString(errors.images)}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  {/* Attachments */}
                  <Stack spacing={1}>
                    <Typography component='div' variant='subtitle1'>
                      Attachments (any other files)
                    </Typography>

                    <FormControl fullWidth margin='none'>
                      <FileUploader
                        multiple
                        maxFiles={100}
                        defaultFiles={FileItem.createManyFrom(
                          values.uploadedAttachments ||
                            values.attachments ||
                            values.initialAttachments,
                        )}
                        onChange={(newFiles) => {
                          setFieldValue(
                            "attachments",
                            FileItem.toManyGeneralAttachmentInputDto(newFiles),
                          );
                          setFieldValue("uploadedAttachments", newFiles);
                        }}
                        onUploadStarted={() => {
                          setIsUploadingFiles(true);
                        }}
                        onUploadFinished={() => {
                          setIsUploadingFiles(false);
                        }}
                      />

                      {errors.attachments && (
                        <FormHelperText error>
                          {ValidationHelper.getFormikErrorsAsString(errors.attachments)}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </FoldableBlock>

                {/* Settings */}
                <FoldableBlock
                  defaultIsFolded
                  trigger={{
                    label: (
                      <Typography component='span' variant='h6'>
                        Settings
                      </Typography>
                    ),
                  }}
                >
                  {/* Min, max, default durations */}
                  <Stack
                    direction={{
                      xxs: "column",
                      md: "row",
                    }}
                    spacing={2}
                  >
                    <Box sx={{ flex: 1 }}>
                      <DurationInput
                        error={Boolean(getIn(errors, "settings.minDuration"))}
                        helperText={getIn(errors, "settings.minDuration")}
                        fullWidth
                        margin='dense'
                        label='Min duration'
                        variant='outlined'
                        defaultUnitOfTime={UnitOfTime.Month}
                        value={values.settings?.minDuration}
                        onChange={(newValue) => {
                          setFieldValue("settings.minDuration", newValue);
                        }}
                        onBlur={handleBlur}
                      />
                    </Box>

                    <Box sx={{ flex: 1 }}>
                      <DurationInput
                        error={Boolean(getIn(errors, "settings.maxDuration"))}
                        helperText={getIn(errors, "settings.maxDuration")}
                        fullWidth
                        margin='dense'
                        label='Max duration'
                        variant='outlined'
                        defaultUnitOfTime={UnitOfTime.Month}
                        value={values.settings?.maxDuration}
                        onChange={(newValue) => {
                          setFieldValue("settings.maxDuration", newValue);
                        }}
                        onBlur={handleBlur}
                      />
                    </Box>

                    <Box sx={{ flex: 1 }}>
                      <DurationInput
                        error={Boolean(getIn(errors, "settings.exactDuration"))}
                        helperText={getIn(errors, "settings.exactDuration")}
                        fullWidth
                        margin='dense'
                        label='Exact duration'
                        variant='outlined'
                        defaultUnitOfTime={UnitOfTime.Month}
                        value={values.settings?.exactDuration}
                        onChange={(newValue) => {
                          setFieldValue("settings.exactDuration", newValue);
                        }}
                        onBlur={handleBlur}
                      />
                    </Box>

                    <Box sx={{ flex: 1 }}>
                      <DurationInput
                        error={Boolean(getIn(errors, "settings.defaultDuration"))}
                        helperText={getIn(errors, "settings.defaultDuration")}
                        fullWidth
                        margin='dense'
                        label='Default duration'
                        variant='outlined'
                        defaultUnitOfTime={UnitOfTime.Month}
                        value={values.settings?.defaultDuration}
                        onChange={(newValue) => {
                          setFieldValue("settings.defaultDuration", newValue);
                        }}
                        onBlur={handleBlur}
                      />
                    </Box>
                  </Stack>
                </FoldableBlock>

                {/* Prices */}
                <FoldableBlock
                  defaultIsFolded={false}
                  trigger={{
                    label: (
                      <Typography component='span' variant='h6'>
                        Prices *
                      </Typography>
                    ),
                  }}
                >
                  <Stack spacing={2}>
                    {/* BasePrice */}
                    <Stack spacing={0}>
                      <Typography component='div' variant='subtitle1' sx={{ mb: 1 }}>
                        Base price *
                      </Typography>

                      {/* Currency */}
                      {/* <FormControl fullWidth margin='dense'>
                        <CurrencyAutocomplete
                          required
                          entity={values.currency}
                          textFieldProps={{
                            label: "Currency",
                            margin: "dense",
                            error: Boolean(errors.currency),
                            helperText: ValidationHelper.getFormikErrorsAsString(errors.currency),
                          }}
                          onChange={(newValue) => setFieldValue("currency", newValue)}
                        />
                      </FormControl> */}

                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            sm: "repeat(1, 1fr)",
                            md: "repeat(2, 1fr)",
                            xl: "repeat(3, 1fr)",
                          },
                        }}
                      >
                        <FormControl
                          margin='dense'
                          fullWidth
                          required
                          error={Boolean(getIn(errors, "basePrice.period"))}
                        >
                          <ApiEnumAutocomplete
                            type='BillingPeriod'
                            value={values.basePrice?.period}
                            onChange={(newValue) => setFieldValue("basePrice.period", newValue)}
                            label='Billing period'
                            required
                            textFieldProps={{
                              error: Boolean(getIn(errors, "basePrice.period")),
                              helperText: ValidationHelper.getErrorsAsString(
                                getIn(errors, "basePrice.period"),
                              ),
                            }}
                          />
                        </FormControl>
                      </Box>

                      <FormControl fullWidth margin='dense'>
                        <PriceSummaryInput
                          values={{
                            currency: values.currency,
                            subTotal: values.basePrice?.price,
                            discount: values.basePrice?.discount,
                            tax: values.basePrice?.tax,
                            insurance: values.basePrice?.insurance,
                            total: values.basePrice?.total,
                          }}
                          formikProps={{
                            errors: {
                              subTotal: getIn(errors, `basePrice.price`),
                              discount: getIn(errors, `basePrice.discount`),
                              tax: getIn(errors, `basePrice.tax`),
                              insurance: getIn(errors, `basePrice.insurance`),
                              total: getIn(errors, `basePrice.total`),
                            },
                          }}
                          displayProps={{
                            insurance: true,
                            calcExplanation: true,
                          }}
                          inputsProps={{
                            all: {
                              margin: "none",
                            },
                            subTotal: {
                              label: `Price per ${
                                values.basePrice?.period
                                  ? enumService.getEnumValueName(
                                      "BillingPeriod",
                                      values.basePrice?.period,
                                    )
                                  : "-"
                              }`,
                              required: true,
                            },
                            total: {
                              required: true,
                            },
                            tax: {
                              vehicleTaxCalcRequestParams: values.currency &&
                                values.assetSpec?.vehicle && {
                                  type: VehicleTaxType.FixedFreeValueForRental,
                                  spec: values.assetSpec?.vehicle,
                                  price: {
                                    price: values.basePrice?.price,
                                    currency: values.currency,
                                  },
                                  tax: values.basePrice?.tax,
                                  country: tenantProfile?.settings?.country,
                                  currency: values.currency,
                                },
                            },
                          }}
                          onChange={(newValue) => {
                            setFieldValue(`currency`, newValue?.currency);
                            setFieldValue(`basePrice.price`, newValue?.subTotal);
                            setFieldValue(`basePrice.discount`, newValue?.discount);
                            setFieldValue(`basePrice.tax`, newValue?.tax);
                            setFieldValue(`basePrice.insurance`, newValue?.insurance);
                            setFieldValue(`basePrice.total`, newValue?.total);
                          }}
                        />

                        <FormHelperText error>
                          {ValidationHelper.getFormikErrorsAsString(errors.basePrice, {
                            isIncludeNested: false,
                          })}
                        </FormHelperText>
                      </FormControl>
                    </Stack>

                    {/* AlternativePrice */}
                    <Stack spacing={2}>
                      <Typography component='div' variant='subtitle1'>
                        Alternative prices
                      </Typography>

                      <Alert severity='info'>
                        <Box>Alternative prices relatively to the base price.</Box>
                        <Box>
                          For instance, when the base price is 10 USD/month (billed monthly, i.e.
                          120 USD/year), then alternative price can be 8 USD/month (billed yearly,
                          i.e. 96 USD/year) which is 20% discount when subscribing on yearly basis
                          comparing to monthly.
                        </Box>
                      </Alert>

                      {values.alternativePrices && values.alternativePrices.length !== 0 && (
                        <Stack spacing={1}>
                          {values.alternativePrices?.map((alternativePrice, i) => (
                            <Box key={i}>
                              <Stack
                                direction={{
                                  xxs: "column",
                                  md: "row",
                                }}
                                spacing={{
                                  xxs: 1,
                                  md: 1,
                                }}
                                alignItems='flex-start'
                              >
                                <Box sx={{ flex: 1 }}>
                                  <FormControl
                                    margin='none'
                                    fullWidth
                                    required
                                    error={Boolean(getIn(errors, `alternativePrices[${i}].period`))}
                                  >
                                    <ApiEnumAutocomplete
                                      type='BillingPeriod'
                                      value={values.alternativePrices?.at(i)?.period}
                                      excludeEnumValues={[
                                        ...(values.basePrice?.period
                                          ? [values.basePrice.period]
                                          : []),
                                        ...(values.alternativePrices
                                          ?.map((x) => x.period!)
                                          ?.filter((x) => !!x) || []),
                                      ]}
                                      onChange={(newValue) =>
                                        setFieldValue(`alternativePrices[${i}].period`, newValue)
                                      }
                                      label='Billing period'
                                      required
                                      textFieldProps={{
                                        error: Boolean(
                                          getIn(errors, `alternativePrices[${i}].period`),
                                        ),
                                        helperText: ValidationHelper.getErrorsAsString(
                                          getIn(errors, `alternativePrices[${i}].period`),
                                        ),
                                      }}
                                    />
                                  </FormControl>

                                  <FormControl fullWidth margin='normal'>
                                    <PriceSummaryInput
                                      values={{
                                        currency: values.currency,
                                        subTotal: alternativePrice.price,
                                        discount: alternativePrice.discount,
                                        tax: alternativePrice.tax,
                                        insurance: alternativePrice.insurance,
                                        total: alternativePrice.total,
                                      }}
                                      formikProps={{
                                        errors: {
                                          subTotal: getIn(errors, `alternativePrices[${i}].price`),
                                          discount: getIn(
                                            errors,
                                            `alternativePrices[${i}].discount`,
                                          ),
                                          tax: getIn(errors, `alternativePrices[${i}].tax`),
                                          insurance: getIn(
                                            errors,
                                            `alternativePrices[${i}].insurance`,
                                          ),
                                          total: getIn(errors, `alternativePrices[${i}].total`),
                                        },
                                      }}
                                      displayProps={{
                                        insurance: true,
                                        calcExplanation: true,
                                      }}
                                      inputsProps={{
                                        all: {
                                          margin: "none",
                                        },
                                        subTotal: {
                                          label: `Price per ${
                                            values.basePrice?.period
                                              ? enumService.getEnumValueName(
                                                  "BillingPeriod",
                                                  values.basePrice?.period,
                                                )
                                              : "-"
                                          }`,
                                          required: true,
                                        },
                                        total: {
                                          required: true,
                                        },
                                        tax: {
                                          vehicleTaxCalcRequestParams: values.currency &&
                                            values.assetSpec?.vehicle && {
                                              type: VehicleTaxType.FixedFreeValueForRental,
                                              spec: values.assetSpec?.vehicle,
                                              price: {
                                                price: alternativePrice.price,
                                                currency: values.currency,
                                              },
                                              tax: alternativePrice.tax,
                                              country: tenantProfile?.settings?.country,
                                              currency: values.currency,
                                            },
                                        },
                                      }}
                                      onChange={(newValue) => {
                                        setFieldValue(`currency`, newValue?.currency);
                                        setFieldValue(
                                          `alternativePrices[${i}].price`,
                                          newValue?.subTotal,
                                        );
                                        setFieldValue(
                                          `alternativePrices[${i}].discount`,
                                          newValue?.discount,
                                        );
                                        setFieldValue(`alternativePrices[${i}].tax`, newValue?.tax);
                                        setFieldValue(
                                          `alternativePrices[${i}].insurance`,
                                          newValue?.insurance,
                                        );
                                        setFieldValue(
                                          `alternativePrices[${i}].total`,
                                          newValue?.total,
                                        );
                                      }}
                                    />

                                    {_.isString(values.alternativePrices?.at(i)) && (
                                      <FormHelperText error>
                                        {values.alternativePrices?.at(i)?.toString()}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Box>

                                <Box>
                                  <IconButton
                                    onClick={() => {
                                      setFieldValue(
                                        "alternativePrices",
                                        ArrayHelper.removeByIndex(
                                          [...(values.alternativePrices || [])],
                                          i,
                                        ),
                                      );
                                    }}
                                  >
                                    <AppIcon of='remove' />
                                  </IconButton>
                                </Box>
                              </Stack>
                            </Box>
                          ))}
                        </Stack>
                      )}

                      <Box>
                        <Button
                          variant='outlined'
                          color='secondary'
                          size='small'
                          disabled={!canAddAlternativePrice}
                          startIcon={<AppIcon of='add' />}
                          onClick={() => {
                            setFieldValue("alternativePrices", [
                              ...(values.alternativePrices || []),
                              {},
                            ]);
                          }}
                        >
                          Add price
                        </Button>
                      </Box>
                    </Stack>
                  </Stack>
                </FoldableBlock>

                {/* Durations */}
                <FoldableBlock
                  defaultIsFolded={false}
                  trigger={{
                    label: (
                      <Typography component='span' variant='h6'>
                        Durations
                      </Typography>
                    ),
                  }}
                >
                  <Stack spacing={2}>
                    <Alert severity='info'>
                      <Box>Add price adjustments based on subscription duration.</Box>
                      <Box>
                        For instance, when customer subscribes for 6 months, discount is applied.
                      </Box>
                    </Alert>

                    {values.durations && values.durations.length !== 0 && (
                      <Stack spacing={1}>
                        {values.durations?.map((duration, i) => (
                          <Box key={i}>
                            <Stack
                              direction={{
                                xxs: "column",
                                md: "row",
                              }}
                              spacing={{
                                xxs: 0,
                                md: 1,
                              }}
                            >
                              <Box sx={{ flex: 1 }}>
                                <DurationInput
                                  error={Boolean(getIn(errors, `durations[${i}].minDuration`))}
                                  helperText={getIn(errors, `durations[${i}].minDuration`)}
                                  fullWidth
                                  required
                                  margin='dense'
                                  label='Min duration'
                                  variant='outlined'
                                  size='small'
                                  defaultUnitOfTime={UnitOfTime.Month}
                                  value={values.durations?.at(i)?.minDuration}
                                  onChange={(newValue) => {
                                    setFieldValue(`durations[${i}].minDuration`, newValue);
                                  }}
                                  onBlur={handleBlur}
                                />
                              </Box>

                              <Box sx={{ flex: 1 }}>
                                <GeneralDiscountInput
                                  error={Boolean(getIn(errors, `durations[${i}].discount`))}
                                  helperText={ValidationHelper.getFormikErrorsAsString(
                                    getIn(errors, `durations[${i}].discount`),
                                  )}
                                  fullWidth
                                  required
                                  defaultType={DiscountType.Trade}
                                  defaultValueType={DiscountValueType.Percent}
                                  value={values.durations?.at(i)?.discount}
                                  appliesTo={undefined}
                                  currency={values.currency}
                                  margin='dense'
                                  label='Discount'
                                  variant='outlined'
                                  size='small'
                                  onChange={(newValue) => {
                                    setFieldValue(`durations[${i}].discount`, newValue);
                                  }}
                                />
                              </Box>

                              <Box>
                                <IconButton
                                  onClick={() => {
                                    setFieldValue(
                                      "durations",
                                      ArrayHelper.removeByIndex([...(values.durations || [])], i),
                                    );
                                  }}
                                >
                                  <AppIcon of='remove' />
                                </IconButton>
                              </Box>
                            </Stack>
                          </Box>
                        ))}
                      </Stack>
                    )}

                    <Box>
                      <Button
                        variant='outlined'
                        color='secondary'
                        size='small'
                        startIcon={<AppIcon of='add' />}
                        onClick={() => {
                          setFieldValue("durations", [...(values.durations || []), {}]);
                        }}
                      >
                        Add duration
                      </Button>
                    </Box>
                  </Stack>
                </FoldableBlock>

                {/* Options */}
                <FoldableBlock
                  defaultIsFolded={false}
                  trigger={{
                    label: (
                      <Typography component='span' variant='h6'>
                        Options
                      </Typography>
                    ),
                  }}
                >
                  <Stack spacing={2}>
                    {/* IncludedOptions */}
                    <Stack spacing={2}>
                      <Typography component='div' variant='subtitle1'>
                        Included options
                      </Typography>

                      <Alert severity='info'>
                        <Box>Add options included in the base price.</Box>
                      </Alert>

                      {values.includedOptions && values.includedOptions.length !== 0 && (
                        <Stack spacing={1}>
                          {values.includedOptions?.map((option, i) => (
                            <Box key={i}>
                              <Typography component='div' variant='subtitle2' sx={{ mb: 1 }}>
                                {i + 1}.{" "}
                                <InlineApiEnumValue
                                  type='SubscriptionPlanOptionType'
                                  value={option.option?.type}
                                />
                              </Typography>

                              <Stack
                                direction={{
                                  xxs: "column",
                                  md: "row",
                                }}
                                spacing={{
                                  xxs: 0,
                                  md: 1,
                                }}
                              >
                                <Box sx={{ flex: 1 }}>
                                  <TextField
                                    error={Boolean(
                                      getIn(errors, `includedOptions[${i}].option.name`),
                                    )}
                                    helperText={getIn(errors, `includedOptions[${i}].option.name`)}
                                    required
                                    fullWidth
                                    size='small'
                                    label='Name'
                                    margin='dense'
                                    name={`includedOptions[${i}].option.name`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type='text'
                                    value={option.option?.name || ""}
                                    variant='outlined'
                                  />
                                </Box>

                                <Box sx={{ flex: 1 }}>
                                  <TextField
                                    error={Boolean(
                                      getIn(errors, `includedOptions[${i}].option.description`),
                                    )}
                                    helperText={getIn(
                                      errors,
                                      `includedOptions[${i}].option.description`,
                                    )}
                                    fullWidth
                                    multiline
                                    minRows={1}
                                    size='small'
                                    label='Description'
                                    margin='dense'
                                    name={`includedOptions[${i}].option.description`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type='text'
                                    value={option.option?.description || ""}
                                    variant='outlined'
                                  />
                                </Box>
                              </Stack>

                              <Stack
                                direction={{
                                  xxs: "column",
                                  md: "row",
                                }}
                                spacing={{
                                  xxs: 0,
                                  md: 1,
                                }}
                              >
                                <Box sx={{ flex: 1 }}>
                                  {/* Accessory */}
                                  {option.option?.type === SubscriptionPlanOptionType.Accessory && (
                                    <FormControl
                                      margin='dense'
                                      size='small'
                                      fullWidth
                                      error={getIn(
                                        errors,
                                        `includedOptions[${i}].option.accessoryId`,
                                      )}
                                    >
                                      <AccessoryAutocompleteOrCreate
                                        autocompleteProps={{
                                          entityId: option.option?.accessoryId,
                                          isPreload: isCreate,
                                          size: "small",
                                          required: true,
                                          textFieldProps: {
                                            error: Boolean(
                                              getIn(
                                                errors,
                                                `includedOptions[${i}].option.accessoryId`,
                                              ),
                                            ),
                                            helperText: ValidationHelper.getFormikErrorsAsString(
                                              getIn(
                                                errors,
                                                `includedOptions[${i}].option.accessoryId`,
                                              ),
                                            ),
                                          },
                                          onChange: (newValue) => {
                                            setFieldValue(
                                              `includedOptions[${i}].option.accessoryId`,
                                              newValue?.id,
                                            );
                                          },
                                        }}
                                        createFormPlacement='modal'
                                        onCreate={(newValue) => {
                                          setFieldValue(
                                            `includedOptions[${i}].option.accessoryId`,
                                            newValue?.id,
                                          );
                                        }}
                                      />
                                    </FormControl>
                                  )}

                                  {/* ForVehicle */}
                                  {option.option?.type === SubscriptionPlanOptionType.ForVehicle &&
                                    option.option?.forVehicle?.type ===
                                      SubscriptionPlanOptionForVehicleType.Mileage && (
                                      <TextField
                                        error={Boolean(
                                          getIn(
                                            errors,
                                            `includedOptions[${i}].option.forVehicle.mileage`,
                                          ),
                                        )}
                                        helperText={getIn(
                                          errors,
                                          `includedOptions[${i}].option.forVehicle.mileage`,
                                        )}
                                        required
                                        fullWidth
                                        size='small'
                                        label='Mileage'
                                        margin='dense'
                                        name={`includedOptions[${i}].option.forVehicle.mileage`}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputMode='decimal'
                                        value={option.option?.forVehicle?.mileage || ""}
                                        variant='outlined'
                                      />
                                    )}
                                </Box>
                              </Stack>
                            </Box>
                          ))}
                        </Stack>
                      )}

                      <Box>
                        <DropdownButton
                          buttonProps={{
                            color: "secondary",
                            size: "small",
                            variant: "outlined",
                            startIcon: <AppIcon of='add' />,
                          }}
                          dropdownContent={
                            <MenuList>
                              {enumService
                                .getEnumValues("SubscriptionPlanOptionType", {
                                  except: [SubscriptionPlanOptionType.None],
                                })
                                .map((type) => {
                                  if (type === SubscriptionPlanOptionType.ForVehicle) {
                                    return (
                                      <Fragment key={type}>
                                        <Divider />

                                        <ListItem sx={{ pt: 0 }}>
                                          <ListItemText
                                            sx={{ m: 0, p: 0 }}
                                            primary={
                                              <Typography
                                                component='div'
                                                variant='subtitle2'
                                                color='text.secondary'
                                                sx={{ m: 0, p: 0 }}
                                              >
                                                <InlineApiEnumValue
                                                  type='SubscriptionPlanOptionType'
                                                  value={type}
                                                />
                                              </Typography>
                                            }
                                          />
                                        </ListItem>

                                        {enumService
                                          .getEnumValues("SubscriptionPlanOptionForVehicleType", {
                                            except: [SubscriptionPlanOptionForVehicleType.None],
                                          })
                                          .map((forVehicleType) => (
                                            <MenuItem
                                              key={forVehicleType}
                                              onClick={() => {
                                                setFieldValue("includedOptions", [
                                                  ...(values.includedOptions || []),
                                                  {
                                                    option: {
                                                      type: type,
                                                      forVehicle: {
                                                        type: forVehicleType,
                                                      },
                                                      name: enumService.getEnumValueName(
                                                        "SubscriptionPlanOptionForVehicleType",
                                                        forVehicleType,
                                                      ),
                                                    },
                                                  } as SubscriptionPlanIncludedOptionInputDto,
                                                ]);
                                              }}
                                            >
                                              <ListItemIcon>
                                                <AppIcon of='add' fontSize='small' />
                                              </ListItemIcon>
                                              <ListItemText>
                                                <InlineApiEnumValue
                                                  type='SubscriptionPlanOptionForVehicleType'
                                                  value={forVehicleType}
                                                />
                                              </ListItemText>
                                            </MenuItem>
                                          ))}
                                      </Fragment>
                                    );
                                  }

                                  return (
                                    <MenuItem
                                      key={type}
                                      onClick={() => {
                                        setFieldValue("includedOptions", [
                                          ...(values.includedOptions || []),
                                          {
                                            option: {
                                              type: type,
                                              name: enumService.getEnumValueName(
                                                "SubscriptionPlanOptionType",
                                                type,
                                              ),
                                            },
                                          } as SubscriptionPlanIncludedOptionInputDto,
                                        ]);
                                      }}
                                    >
                                      <ListItemIcon>
                                        <AppIcon of='add' fontSize='small' />
                                      </ListItemIcon>
                                      <ListItemText>
                                        <InlineApiEnumValue
                                          type='SubscriptionPlanOptionType'
                                          value={type}
                                        />
                                      </ListItemText>
                                    </MenuItem>
                                  );
                                })}
                            </MenuList>
                          }
                        >
                          Add option
                        </DropdownButton>
                      </Box>
                    </Stack>

                    {/* ExtraOptions */}
                    <Stack spacing={2}>
                      <Typography component='div' variant='subtitle1'>
                        Extra options
                      </Typography>

                      <Alert severity='info'>
                        <Box>Add price adjustments based on extra options.</Box>
                      </Alert>

                      {values.extraOptions && values.extraOptions.length !== 0 && (
                        <Stack spacing={1}>
                          {values.extraOptions?.map((option, i) => (
                            <Box key={i}>
                              <Typography component='div' variant='subtitle2' sx={{ mb: 1 }}>
                                {i + 1}.{" "}
                                <InlineApiEnumValue
                                  type='SubscriptionPlanOptionType'
                                  value={option.option?.type}
                                />
                              </Typography>

                              <Stack
                                direction={{
                                  xxs: "column",
                                  md: "row",
                                }}
                                spacing={{
                                  xxs: 0,
                                  md: 1,
                                }}
                              >
                                <Box sx={{ flex: 1 }}>
                                  <TextField
                                    error={Boolean(getIn(errors, `extraOptions[${i}].option.name`))}
                                    helperText={getIn(errors, `extraOptions[${i}].option.name`)}
                                    required
                                    fullWidth
                                    size='small'
                                    label='Name'
                                    margin='dense'
                                    name={`extraOptions[${i}].option.name`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type='text'
                                    value={option.option?.name || ""}
                                    variant='outlined'
                                  />
                                </Box>

                                <Box sx={{ flex: 1 }}>
                                  <TextField
                                    error={Boolean(
                                      getIn(errors, `extraOptions[${i}].option.description`),
                                    )}
                                    helperText={getIn(
                                      errors,
                                      `extraOptions[${i}].option.description`,
                                    )}
                                    fullWidth
                                    multiline
                                    minRows={1}
                                    size='small'
                                    label='Description'
                                    margin='dense'
                                    name={`extraOptions[${i}].option.description`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type='text'
                                    value={option.option?.description || ""}
                                    variant='outlined'
                                  />
                                </Box>
                              </Stack>

                              <Stack
                                direction={{
                                  xxs: "column",
                                  md: "row",
                                }}
                                spacing={{
                                  xxs: 0,
                                  md: 1,
                                }}
                              >
                                <Box sx={{ flex: 1 }}>
                                  {/* Accessory */}
                                  {option.option?.type === SubscriptionPlanOptionType.Accessory && (
                                    <FormControl
                                      margin='dense'
                                      size='small'
                                      fullWidth
                                      error={getIn(errors, `extraOptions[${i}].option.accessoryId`)}
                                    >
                                      <AccessoryAutocompleteOrCreate
                                        autocompleteProps={{
                                          entityId: option.option?.accessoryId,
                                          isPreload: isCreate,
                                          size: "small",
                                          required: true,
                                          textFieldProps: {
                                            error: Boolean(
                                              getIn(
                                                errors,
                                                `extraOptions[${i}].option.accessoryId`,
                                              ),
                                            ),
                                            helperText: ValidationHelper.getFormikErrorsAsString(
                                              getIn(
                                                errors,
                                                `extraOptions[${i}].option.accessoryId`,
                                              ),
                                            ),
                                          },
                                          onChange: (newValue) => {
                                            setFieldValue(
                                              `extraOptions[${i}].option.accessoryId`,
                                              newValue?.id,
                                            );
                                          },
                                        }}
                                        createFormPlacement='modal'
                                        onCreate={(newValue) => {
                                          setFieldValue(
                                            `extraOptions[${i}].option.accessoryId`,
                                            newValue?.id,
                                          );
                                        }}
                                      />
                                    </FormControl>
                                  )}

                                  {/* ForVehicle */}
                                  {option.option?.type === SubscriptionPlanOptionType.ForVehicle &&
                                    option.option?.forVehicle?.type ===
                                      SubscriptionPlanOptionForVehicleType.Mileage && (
                                      <TextField
                                        error={Boolean(
                                          getIn(
                                            errors,
                                            `extraOptions[${i}].option.forVehicle.mileage`,
                                          ),
                                        )}
                                        helperText={getIn(
                                          errors,
                                          `extraOptions[${i}].option.forVehicle.mileage`,
                                        )}
                                        required
                                        fullWidth
                                        size='small'
                                        label='Mileage'
                                        margin='dense'
                                        name={`extraOptions[${i}].option.forVehicle.mileage`}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputMode='decimal'
                                        value={option.option?.forVehicle?.mileage || ""}
                                        variant='outlined'
                                      />
                                    )}
                                </Box>
                              </Stack>

                              <Stack
                                direction={{
                                  xxs: "column",
                                  md: "row",
                                }}
                                spacing={{
                                  xxs: 0,
                                  md: 1,
                                }}
                              >
                                <Box sx={{ flex: 1 }}>
                                  <GeneralPriceInput
                                    error={Boolean(getIn(errors, `extraOptions[${i}].price`))}
                                    helperText={getIn(errors, `extraOptions[${i}].price`)}
                                    fullWidth
                                    required
                                    allowCurrencyEdit={false}
                                    label='Price'
                                    margin='dense'
                                    variant='outlined'
                                    size='small'
                                    value={{
                                      price: option.price ?? undefined,
                                      currency: values.currency,
                                    }}
                                    customPriceTypeEdit={{
                                      enumType: "PaymentRegularityType",
                                      enumValue: option.regularityType,
                                      onChange: (newValue) =>
                                        setFieldValue(
                                          `extraOptions[${i}].regularityType`,
                                          newValue,
                                        ),
                                    }}
                                    onChange={(e, newValue) =>
                                      setFieldValue(`extraOptions[${i}].price`, newValue?.price)
                                    }
                                  />
                                </Box>

                                <Box sx={{ flex: 1 }}>
                                  <GeneralDiscountInput
                                    error={Boolean(getIn(errors, `extraOptions[${i}].discount`))}
                                    helperText={ValidationHelper.getFormikErrorsAsString(
                                      getIn(errors, `extraOptions[${i}].discount`),
                                    )}
                                    fullWidth
                                    defaultType={DiscountType.Trade}
                                    defaultValueType={DiscountValueType.Percent}
                                    value={option.discount}
                                    appliesTo={undefined}
                                    currency={values.currency}
                                    margin='dense'
                                    label='Discount'
                                    variant='outlined'
                                    size='small'
                                    onChange={(newValue) => {
                                      setFieldValue(`extraOptions[${i}].discount`, newValue);
                                    }}
                                  />
                                </Box>

                                <Box>
                                  <IconButton
                                    onClick={() => {
                                      setFieldValue(
                                        "extraOptions",
                                        ArrayHelper.removeByIndex(
                                          [...(values.extraOptions || [])],
                                          i,
                                        ),
                                      );
                                    }}
                                  >
                                    <AppIcon of='remove' />
                                  </IconButton>
                                </Box>
                              </Stack>
                            </Box>
                          ))}
                        </Stack>
                      )}

                      <Box>
                        <DropdownButton
                          buttonProps={{
                            color: "secondary",
                            size: "small",
                            variant: "outlined",
                            startIcon: <AppIcon of='add' />,
                          }}
                          dropdownContent={
                            <MenuList>
                              {enumService
                                .getEnumValues("SubscriptionPlanOptionType", {
                                  except: [SubscriptionPlanOptionType.None],
                                })
                                .map((type) => {
                                  if (type === SubscriptionPlanOptionType.ForVehicle) {
                                    return (
                                      <Fragment key={type}>
                                        <Divider />

                                        <ListItem sx={{ pt: 0 }}>
                                          <ListItemText
                                            sx={{ m: 0, p: 0 }}
                                            primary={
                                              <Typography
                                                component='div'
                                                variant='subtitle2'
                                                color='text.secondary'
                                                sx={{ m: 0, p: 0 }}
                                              >
                                                <InlineApiEnumValue
                                                  type='SubscriptionPlanOptionType'
                                                  value={type}
                                                />
                                              </Typography>
                                            }
                                          />
                                        </ListItem>

                                        {enumService
                                          .getEnumValues("SubscriptionPlanOptionForVehicleType", {
                                            except: [SubscriptionPlanOptionForVehicleType.None],
                                          })
                                          .map((forVehicleType) => (
                                            <MenuItem
                                              key={forVehicleType}
                                              onClick={() => {
                                                setFieldValue("extraOptions", [
                                                  ...(values.extraOptions || []),
                                                  {
                                                    option: {
                                                      type: type,
                                                      forVehicle: {
                                                        type: forVehicleType,
                                                      },
                                                      name: enumService.getEnumValueName(
                                                        "SubscriptionPlanOptionForVehicleType",
                                                        forVehicleType,
                                                      ),
                                                    },
                                                    regularityType: PaymentRegularityType.OneTime,
                                                  } as SubscriptionPlanExtraOptionInputDto,
                                                ]);
                                              }}
                                            >
                                              <ListItemIcon>
                                                <AppIcon of='add' fontSize='small' />
                                              </ListItemIcon>
                                              <ListItemText>
                                                <InlineApiEnumValue
                                                  type='SubscriptionPlanOptionForVehicleType'
                                                  value={forVehicleType}
                                                />
                                              </ListItemText>
                                            </MenuItem>
                                          ))}
                                      </Fragment>
                                    );
                                  }

                                  return (
                                    <MenuItem
                                      key={type}
                                      onClick={() => {
                                        setFieldValue("extraOptions", [
                                          ...(values.extraOptions || []),
                                          {
                                            option: {
                                              type: type,
                                              name: enumService.getEnumValueName(
                                                "SubscriptionPlanOptionType",
                                                type,
                                              ),
                                            },
                                            regularityType: PaymentRegularityType.OneTime,
                                          } as SubscriptionPlanExtraOptionInputDto,
                                        ]);
                                      }}
                                    >
                                      <ListItemIcon>
                                        <AppIcon of='add' fontSize='small' />
                                      </ListItemIcon>
                                      <ListItemText>
                                        <InlineApiEnumValue
                                          type='SubscriptionPlanOptionType'
                                          value={type}
                                        />
                                      </ListItemText>
                                    </MenuItem>
                                  );
                                })}
                            </MenuList>
                          }
                        >
                          Add option
                        </DropdownButton>
                      </Box>
                    </Stack>
                  </Stack>
                </FoldableBlock>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <LoadingButton
                  sx={{ mt: { xs: "auto", md: 2 } }}
                  color='primary'
                  disabled={!canSubmit}
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </LoadingButton>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
