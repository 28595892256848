import { LoadingButton } from "@mui/lab";
import { FormHelperText, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import InviteInfo from "@/App/MainAppView/components/Invite/InviteInfo";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { authService } from "@/common/services/auth";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { PasswordPolicyDto, PasswordValidateResultDto } from "@/core/api/generated";
import * as authSlice from "@/store/auth/slice";
import * as invitesSlice from "@/store/invites/slice";

import AppIcon from "@/common/components/Icons/AppIcon";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import PasswordValidationHint from "../../Register/components/PasswordValidationHint";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import { BaseFormikValues } from "@/common/ts/error";

const StyledForm = styled("form")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.05);",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(3),
  [theme.breakpoints.up("sm")]: {
    width: "30%",
    height: "fit-content",
    borderRadius: theme.shape.borderRadius,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
  },
}));

export interface CreatePasswordQueryParams extends GeneralQueryParams {
  email: string;
}

export default function CreatePassword() {
  const { t } = useTranslation();
  const { inviteId } = useParams<{ inviteId: string }>();
  const { email, ...generalQueryParams } = useQueryParams<CreatePasswordQueryParams>();
  const thunkDispatch = useAppThunkDispatch();

  const mounted = useMounted();
  const [isPasswordHindOpened, setIsPasswordHindOpened] = useState(false);
  const [isPasswordOpened, setIsPasswordOpened] = useState(false);
  const [passwordPolicy, setPasswordPolicy] = useState<PasswordPolicyDto | undefined>(undefined);
  const [passwordValidationResult, setPasswordValidationResult] = useState<
    PasswordValidateResultDto | undefined
  >(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const inviteRequest = useApiRequest(
    apiClient.invitesApi.apiV1InvitesInviteIdAnonymouslyGet,
    {
      inviteId: inviteId!,
    },
    {
      skip: !inviteId,
    },
  );
  const invite = inviteRequest?.data;
  const redirectUrl = invite?.redirectUrl || generalQueryParams.redirectUrl;

  useEffect(() => {
    thunkDispatch(authSlice.getPasswordPolicy()).then((res) => {
      setPasswordPolicy(res);
    });
  }, []);

  return (
    <Formik<BaseFormikValues & { password?: string | null; confirmPassword?: string | null }>
      initialValues={{
        password: "",
        confirmPassword: "",
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required("Password is required"),
        confirmPassword: Yup.string()
          .required("Confirm password is required")
          .oneOf([Yup.ref("password")], "Passwords must be equal"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, setFieldError }) => {
        try {
          await thunkDispatch(
            invitesSlice.registerInviteUser({
              inviteId: inviteId,
              registerInvitedUserDto: {
                email,
                password: values.password || undefined,
                confirmPassword: values.confirmPassword || undefined,
              },
            }),
          );
          enqueueSnackbar("Registration succeeded. Redirect to sign in after 3 seconds...", {
            variant: "success",
          });
          setTimeout(() => {
            authService.loginWithRedirect({
              loginHint: email,
              appState: {
                spaRedirectUrl: ROUTE_PATH.AUTH_INVITE_ACCEPT(inviteId, {
                  ...generalQueryParams,
                }),
              },
            });
          }, 3000);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <>
          {invite && <InviteInfo invite={invite} hasRedirectUrl={!!redirectUrl} />}
          <form noValidate onSubmit={handleSubmit}>
            <Box
              sx={{
                borderRadius: (theme) => theme.shape.borderRadius,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 2,
                width: "100%",
                mt: "auto",
              }}
            >
              <Typography component='div' variant='h4'>
                {t("auth.createPassword")}
              </Typography>
            </Box>

            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label={t("auth.password")}
              margin='normal'
              name='password'
              onFocus={() => {
                setIsPasswordHindOpened(true);
              }}
              onBlur={(e) => {
                handleBlur(e);
                setIsPasswordHindOpened(false);
              }}
              onChange={async (e) => {
                handleChange(e);

                const result = await thunkDispatch(
                  authSlice.validateAccountPassword({
                    password: e.target.value,
                  }),
                );
                setPasswordValidationResult(result);
              }}
              type={!isPasswordOpened ? "password" : "text"}
              value={values.password}
              variant='outlined'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={{ position: "relative" }}>
                    <IconButton onClick={() => setIsPasswordOpened((state) => !state)}>
                      <AppIcon of='visibility' />
                    </IconButton>

                    {isPasswordHindOpened && (
                      <PasswordValidationHint
                        passwordPolicy={passwordPolicy}
                        passwordValidationResult={passwordValidationResult}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              fullWidth
              helperText={touched.confirmPassword && errors.confirmPassword}
              label={t("auth.confirmPassword")}
              margin='normal'
              name='confirmPassword'
              onBlur={handleBlur}
              onChange={handleChange}
              type={!isPasswordOpened ? "password" : "text"}
              value={values.confirmPassword}
              variant='outlined'
            />

            <GeneralValidationError sx={{ my: 1 }} errors={errors} />

            <LoadingButton
              sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
              color='primary'
              disabled={isSubmitting || !values.password || !values.confirmPassword}
              fullWidth
              size='large'
              type='submit'
              variant='contained'
            >
              Accept the invite and register
            </LoadingButton>
          </form>
        </>
      )}
    </Formik>
  );
}
