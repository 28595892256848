import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, Fade, IconButton, SxProps, Theme, Typography } from "@mui/material";
import { useMemo, useState } from "react";

import FileItemView from "@/common/components/Files/FileItemView";
import { FileItem } from "@/common/fileItem";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import { ChatMessageDto } from "@/core/api/generated";
import * as filesSlice from "@/store/files/slice";

interface Props {
  message: ChatMessageDto;
}

function ChatMessageAttachments({ message }: Props) {
  const [isFolded, setIsFolded] = useState(false);
  const fullscreenFiles = useAppSelector((x) => x.files.fullScreen);
  const files = useMemo(
    () => FileItem.createManyFrom(message.attachments || []),
    [message.attachments],
  );
  const dispatch = useAppThunkDispatch();

  if (!message.attachments || message.attachments.length === 0) {
    return null;
  }
  const handleFileClick = (file: FileItem) => {
    dispatch(filesSlice.setFullScreenImages({ files }));
  };

  return (
    <Box>
      <Typography component='div' variant='caption' color='text.secondary'>
        {message.attachments.length} {message.attachments.length > 1 ? "attachments" : "attachment"}
        <IconButton sx={{ p: 0, ml: 1 }} onClick={() => setIsFolded(!isFolded)}>
          {isFolded ? <ArrowRightIcon fontSize='small' /> : <ArrowDropDownIcon fontSize='small' />}
        </IconButton>
      </Typography>
      <Fade in={!isFolded} unmountOnExit>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {files.map((f, i) => (
            <Box
              key={i}
              sx={{
                mt: 1,
                mr: 1,
              }}
            >
              <FileItemView
                fileItem={f}
                size={{
                  width: 120,
                }}
                useThumbnail
                actions={(item2) => ({
                  enabled: true,
                  click: true,
                  download: true,
                  openInNewTab: false,
                  onClick: handleFileClick,
                })}
                imageSx={{
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          ))}
        </Box>
      </Fade>
    </Box>
  );
}

export default ChatMessageAttachments;
