import {
  Button,
  DialogContentText,
  Divider,
  IconButton,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import InlineEntityChangedByInfoDisplay from "@/common/components/EntityInfo/InlineEntityChangedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModalWithTrigger from "@/common/components/Modals/ConfirmationModalWithTrigger";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AdminVehicleModificationGetPaginatedDto, EntityType } from "@/core/api/generated";

export default function VehicleModifications() {
  const { enqueueSnackbar } = useSnackbar();
  const commonRequestParams = useCommonRequestParams<AdminVehicleModificationGetPaginatedDto>({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityInAdminArea(EntityType.VehicleModification),
    },
  });

  const paginatedVehicleModificationsRequest = useApiRequest(
    apiClient.adminVehicleModificationsApi.apiV1AdminReferenceDataVehiclesModificationsGetPost,
    {
      adminVehicleModificationGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const { data: paginatedVehicleModifications } = paginatedVehicleModificationsRequest;

  return (
    <ListPageLayout>
      <SimpleViewPageHeader
        title={undefined}
        primaryActions={
          <Button
            variant='contained'
            color='primary'
            startIcon={<AppIcon of='add' />}
            component={RouterLink}
            to={ROUTE_PATH.ADMIN_VEHICLE_MODIFICATION_CREATE}
          >
            Create new
          </Button>
        }
      />

      <Divider sx={{ my: 2 }} />

      <DataTabular
        columns={[
          {
            field: "globalNumber",
            title: "Global number",
            flex: 1,
            renderCell: (item) => item.globalNumber,
          },
          {
            field: "name",
            title: "Name",
            flex: 1,
            renderCell: (item) => item.name,
          },
          {
            field: "vehicleType",
            title: "Vehicle type",
            flex: 1,
            renderCell: (item) => (
              <InlineApiEnumValue type='VehicleType' value={item.vehicleType} />
            ),
          },
          {
            field: "make.name",
            title: "Make",
            flex: 1,
            renderCell: (item) => item.make?.name,
          },
          {
            field: "model.name",
            title: "Model",
            flex: 1,
            renderCell: (item) => item.model?.name,
          },
          {
            field: "generation.name",
            title: "Generation",
            flex: 1,
            renderCell: (item) => item.generation?.name || "-",
          },
          {
            field: "bodyType.name",
            title: "Body type",
            flex: 1,
            renderCell: (item) => item.bodyType?.name || "-",
          },
          {
            field: "fuelType.name",
            title: "Fuel type",
            flex: 1,
            renderCell: (item) => item.fuelType?.name || "-",
          },
          {
            field: "vehicleSize",
            title: "Vehicle size",
            flex: 1,
            renderCell: (item) => (
              <InlineApiEnumValue type='VehicleSize' value={item.vehicleSize} />
            ),
          },
          {
            field: "isBuiltIn",
            title: "Built-in",
            flex: 1,
            renderCell: (item) => <BooleanValue value={item.isBuiltIn} />,
          },
          {
            field: "isEnabled",
            title: "Enabled",
            flex: 1,
            renderCell: (item) => <BooleanValue value={item.isEnabled} />,
          },
          {
            field: "sortOrder",
            title: "Sort order",
            flex: 1,
            renderCell: (item) => <>{item.sortOrder}</>,
          },
          {
            field: "updatedBy",
            title: "Changed by",
            flex: 1,
            renderCell: (item) => <InlineEntityChangedByInfoDisplay entity={item} />,
          },
        ]}
        rows={paginatedVehicleModifications?.items}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.ADMIN_VEHICLE_MODIFICATION_EDIT(item.id)}
        renderRowAction={({ item }) => (
          <>
            <MenuWithTrigger
              trigger={
                <IconButton>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              {({ handleClose }) => [
                <MenuItem
                  key='edit'
                  component={RouterLink}
                  to={ROUTE_PATH.ADMIN_VEHICLE_MODIFICATION_EDIT(item.id)}
                >
                  <ListItemText>Edit</ListItemText>
                </MenuItem>,
                <ConfirmationModalWithTrigger
                  key='delete'
                  trigger={
                    <MenuItem>
                      <ListItemText>Delete</ListItemText>
                    </MenuItem>
                  }
                  title='Delete reference data?'
                  body={({ error }) => {
                    return (
                      <DialogContentText>
                        {`You're going to delete the vehicle modification`}{" "}
                        <strong>{item.name}</strong>. {`This action can't be undone.`}
                      </DialogContentText>
                    );
                  }}
                  onConfirm={async () => {
                    try {
                      await apiClient.adminVehicleModificationsApi.apiV1AdminReferenceDataVehiclesModificationsModificationIdDelete(
                        { modificationId: item.id! },
                      );
                      paginatedVehicleModificationsRequest.refetch();
                      enqueueSnackbar("Modification deleted.", { variant: "success" });
                      handleClose();
                    } catch (err) {
                      const validation2 = ValidationHelper.handleApiErrorResponse(err);
                      validation2.hasErrors &&
                        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
                    }
                  }}
                />,
              ]}
            </MenuWithTrigger>
          </>
        )}
        isLoading={paginatedVehicleModificationsRequest.isLoading}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
      />
    </ListPageLayout>
  );
}
