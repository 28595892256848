import { Box, DialogProps, LinearProgress, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback } from "react";

import CustomerContactCard from "@/common/components/Entity/Customer/CustomerContactCard";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { renderIf } from "@/common/helpers/render/renderIf";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AssessmentFlowDto } from "@/core/api/generated";

interface OwnProps {
  assessmentFlow: AssessmentFlowDto;
}

type Props = OwnProps & DialogProps;

export default function SendAssessmentFlowToCustomerModal({
  assessmentFlow,
  ...dialogProps
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const customerRequest = useApiRequest(
    apiClient.customersApi.apiV1CustomersCustomerIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      customerId: assessmentFlow.customer?.id || "",
    },
    {
      deps: [assessmentFlow.customer?.id],
    },
  );
  const customer = customerRequest.data;

  const handleSendToCustomer = useCallback(async () => {
    try {
      await apiClient.assessmentFlowsApi.apiV1FlowsAssessmentFlowsAssessmentFlowIdSendToCustomerPost(
        {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          assessmentFlowId: assessmentFlow.id!,
        },
      );
      enqueueSnackbar("Sent to customer.", { variant: "success" });
      dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown");
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [assessmentFlow]);

  return (
    <ConfirmationModal
      title='Send assessment to contract customer?'
      body={
        <Stack spacing={2}>
          {renderIf()
            .if(customerRequest.isLoading && !customerRequest.isEnded)
            .then(<LinearProgress />)
            .else(
              <Stack spacing={1}>
                <Box>{`You're going to send links to the assessment page to the next customer contacts:`}</Box>
                {customer?.contacts!.map((x) => <CustomerContactCard key={x.id} contact={x} />)}
              </Stack>,
            )
            .render()}
        </Stack>
      }
      onConfirm={handleSendToCustomer}
      {...dialogProps}
    />
  );
}
