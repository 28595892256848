import {
  Box,
  Divider,
  FormControl,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Moment } from "moment";
import { ReactNode, useRef, useState } from "react";

import VehicleHistoryList from "@/App/MainAppView/VehicleHistory/VehicleHistoryList";
import { DATETIME_FORMATS } from "@/common/constants/common";
import { ROUTE_PATH } from "@/common/constants/routing";
import { apiClient } from "@/core/api/ApiClient";

import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import AppIcon from "../../Icons/AppIcon";
import AppLink from "../../Link/AppLink";
import AppPagination from "../../Pagination/AppPagination";

const defaultHeaderProps = {
  withLink: false,
  title: undefined as ReactNode | undefined,
};

const defaultDisplayProps = {
  filters: true,
  pagination: true,
};

type OwnProps = {
  vehicleId?: string;
  headerProps?: Partial<typeof defaultHeaderProps>;
  displayProps?: Partial<typeof defaultDisplayProps>;
};

type Props = OwnProps;

export default function VehicleHistoryEmbeddedView({
  vehicleId,
  headerProps = defaultHeaderProps,
  displayProps = defaultDisplayProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const topAnchorRef = useRef<HTMLElement | null>(null);

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const [from, setFrom] = useState<Moment | undefined | null>(null);
  const [to, setTo] = useState<Moment | undefined | null>(null);

  const paginatedVehicleHistoryRequest = useApiRequest(
    apiClient.vehicleHistoryApi.apiV1VehiclesVehicleIdHistoryGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: vehicleId || "",
      vehicleHistoryGetPaginatedDto: {
        offset,
        limit,
        range: {
          from: from?.clone()?.utc()?.format() || undefined,
          to: to?.clone()?.utc()?.format() || undefined,
        },
      },
    },
    {
      deps: [offset, limit],
      debouncedDeps: {
        deps: [],
        wait: 500,
        options: { leading: false, trailing: true },
      },
    },
  );

  const paginatedVehicleHistory = paginatedVehicleHistoryRequest.data;

  return (
    <Stack spacing={2}>
      <Box>
        <AppLink enabled={headerProps?.withLink} to={ROUTE_PATH.VEHICLE_HISTORY(vehicleId)}>
          {headerProps?.title || (
            <Typography component='div' variant='subtitle1'>
              Vehicle history
            </Typography>
          )}
        </AppLink>
      </Box>

      {displayProps.filters && (
        <>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            sx={{ alignItems: { xs: "stretch", md: "center" }, m: 1 }}
          >
            <Stack direction={"row"} spacing={1} sx={{ mb: 1, alignItems: "center" }}>
              <FormControl>
                <MobileDatePicker
                  label='From date'
                  value={from}
                  format={DATETIME_FORMATS.DISPLAY_DATE}
                  onChange={(newValue) => {
                    setFrom(newValue);
                  }}
                  slots={{
                    textField: (params) => (
                      <TextField
                        {...params}
                        size='small'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <IconButton onClick={() => setFrom(null)}>
                              <AppIcon of='clear' color='inherit' />
                            </IconButton>
                          ),
                        }}
                      />
                    ),
                  }}
                />
              </FormControl>

              <FormControl>
                <MobileDatePicker
                  label='To date'
                  value={to}
                  format={DATETIME_FORMATS.DISPLAY_DATE}
                  onChange={(newValue) => {
                    setTo(newValue);
                  }}
                  slots={{
                    textField: (params) => (
                      <TextField
                        {...params}
                        size='small'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <IconButton onClick={() => setTo(null)}>
                              <AppIcon of='clear' color='inherit' />
                            </IconButton>
                          ),
                        }}
                      />
                    ),
                  }}
                />
              </FormControl>
            </Stack>
          </Stack>
          <Divider />
        </>
      )}

      {paginatedVehicleHistoryRequest.isLoading && <LinearProgress />}

      <Stack direction='column' spacing={0}>
        <Box ref={topAnchorRef} sx={{ m: 0, p: 0 }}></Box>

        {paginatedVehicleHistory && (
          <VehicleHistoryList paginatedHistoryItems={paginatedVehicleHistory} />
        )}
      </Stack>

      {displayProps?.pagination && (
        <AppPagination
          paginationInfo={paginatedVehicleHistory?.pagination}
          onChange={(newValue) => {
            setOffset(newValue.offset ?? 0);
            setLimit(newValue.limit ?? 0);
            topAnchorRef.current?.scrollIntoView({ behavior: "smooth" });
          }}
        />
      )}
    </Stack>
  );
}
