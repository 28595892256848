import {
  Alert,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  LinearProgress,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { Formik, getIn } from "formik";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import * as Yup from "yup";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  ContractStage,
  DamageCostEvaluationCreateDto,
  DamageCostEvaluationDto,
  DamageCostEvaluationItemCreateDto,
  DamageCostEvaluationItemDamageInputDto,
  DamageCostEvaluationItemUpdateDto,
  DamageCostEvaluationStage,
  DamageCostEvaluationUpdateDto,
  EntityType,
  GeneralDiscountInputDto,
  GeneralStageUpdateDtoOfDamageCostEvaluationStage,
  GeneralTaxInputDto,
  RepairSpecDataDto,
  RepairSpecDataInputDto,
  RepairSpecDetalizationType,
  VehicleDamageDto,
  VehicleDamageFullReferenceDto,
  VehicleType,
} from "@/core/api/generated";

import CurrencyMismatchWithRepairSpecAlert from "@/common/components/Entity/DamageCostEvaluation/CurrencyMismatchWithRepairSpecAlert";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { ArrayHelper } from "@/common/helpers/array";
import { PriceHelper } from "@/common/helpers/price";
import { useTenantProfile } from "@/common/hooks/entity/tenant/useTenantProfile";
import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import { useUserAffiliation } from "@/common/hooks/useUserAffiliation";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { BaseFormikValues } from "@/common/ts/error";
import _ from "lodash";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import NoDataAlert from "../../AppAlerts/NoDataAlert";
import AuthorizedElement from "../../Auth/AuthorizedElement";
import DropdownIconButton from "../../Button/DropdownIconButton";
import SplitDropdownButton from "../../Button/SplitDropdownButton";
import FoldableBlock from "../../Display/FoldableBlock";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FileUploader from "../../Files/FileUploader";
import FormikComputedField from "../../Form/Formik/FormikComputedField";
import AppIcon from "../../Icons/AppIcon";
import CompoundIcon from "../../Icons/CompoundIcon";
import AppLink from "../../Link/AppLink";
import GeneralPriceSummaryDisplay from "../../PriceSummary/GeneralPriceSummaryDisplay";
import ContractAutocompleteOrCreate from "../Contract/ContractAutocompleteOrCreate";
import EntityAffiliationInput from "../EntityAffiliation/EntityAffiliationInput";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";
import CurrencyAutocomplete from "../ReferenceData/CurrencyAutocomplete";
import CreateUpdateRepairSpecModal from "../RepairSpec/CreateUpdateRepairSpecModal";
import RepairSpecDataInputDtoFormInputs, {
  mapRepairSpecDtoToInputDto,
} from "../RepairSpec/RepairSpecDataInputDtoFormInputs";
import SelectOrCreateRepairSpec from "../RepairSpec/SelectOrCreateRepairSpec";
import VehicleAutocompleteOrCreate from "../Vehicle/VehicleAutocompleteOrCreate";
import VehicleDamageAutocomplete from "../VehicleDamage/VehicleDamageAutocomplete";
import VehicleDamageViewModal from "../VehicleDamage/View/VehicleDamageViewModal";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  vehicleId?: DamageCostEvaluationCreateDto["vehicleId"];
  contractId?: DamageCostEvaluationCreateDto["contractId"];
  vehicleDamageIds?: string[] | null;
  currency?: DamageCostEvaluationCreateDto["currency"];
  tenantRequestsMeta?: DamageCostEvaluationCreateDto["tenantRequestsMeta"];
};

export interface DamageCostEvaluationCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<DamageCostEvaluationDto, DefaultValues> {
  damageCostEvaluationId?: string;
}

export type DamageCostEvaluationCreateUpdateProps = DamageCostEvaluationCreateUpdateOwnProps;

export default function DamageCostEvaluationCreateUpdate({
  damageCostEvaluationId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: DamageCostEvaluationCreateUpdateProps) {
  const [isAttachmentFilesUploading, setIsAttachmentFilesUploading] = useState(false);
  const isCreate = !damageCostEvaluationId;
  const isEdit = !!damageCostEvaluationId;

  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const profile = useUserProfile();
  const tenantProfile = useTenantProfile();
  const history = useHistory();
  const currentCurrency = useCurrentCurrency({ useDefault: true });

  const damageCostEvaluationRequest = useApiRequest(
    apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      damageCostEvaluationId: damageCostEvaluationId!,
    },
    {
      deps: [damageCostEvaluationId],
      skip: !damageCostEvaluationId,
    },
  );
  const damageCostEvaluation = damageCostEvaluationRequest?.data;

  const defaultVehicleDamagesRequest = useApiRequest(
    apiClient.vehicleDamagesApi.apiV1VehiclesVehicleIdDamagesByIdsGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: defaultValues?.vehicleId || "",
      vehicleDamageIds: defaultValues?.vehicleDamageIds || undefined,
    },
    {
      deps: [defaultValues?.vehicleId, defaultValues?.vehicleDamageIds],
      skip: !defaultValues?.vehicleId && !defaultValues?.vehicleDamageIds,
    },
  );
  const defaultVehicleDamages = defaultVehicleDamagesRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: damageCostEvaluation && {
      idValue: damageCostEvaluation.id!,
      newTitle: damageCostEvaluation.localNumber || "",
    },
  });

  const canEdit =
    isCreate ||
    (isEdit && damageCostEvaluation?.stageHistory?.stage !== DamageCostEvaluationStage.Completed);
  const canEditDamages = canEdit;
  const canAddDamages = canEdit;
  const canRemoveDamages = canEdit;
  const canAddItems = canEdit;
  const canRemoveItems = canEdit;

  const [isCreateRepairSpecModalOpen, setIsCreateRepairSpecModalOpen] = useState(false);
  const [createRepairSpecFromSpecData, setCreateRepairSpecFromSpecData] = useState<
    (RepairSpecDataDto & RepairSpecDataInputDto) | undefined
  >(undefined);
  const [isViewVehicleDamageModalOpen, setIsViewVehicleDamageModalOpen] = useState(false);
  const [vehicleDamageIdToView, setVehicleDamageIdToView] = useState<string | undefined>(undefined);
  const [vehicleDamageToView, setVehicleDamageToView] = useState<VehicleDamageDto | undefined>(
    undefined,
  );

  const isInitialLoading =
    damageCostEvaluationRequest.isLoading || defaultVehicleDamagesRequest.isLoading;

  const { departments, locations } = useUserAffiliation();

  if (!tenantProfile || isInitialLoading) {
    return <LinearProgress />;
  }

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.DamageCostEvaluation}
      entityId={damageCostEvaluationId}
      entity={damageCostEvaluation}
      entityRequest={damageCostEvaluationRequest}
    >
      <Formik<
        BaseFormikValues &
          Omit<DamageCostEvaluationCreateDto, "items"> &
          Omit<DamageCostEvaluationUpdateDto, "items"> & {
            vehicle?: DamageCostEvaluationDto["vehicle"];
            initialAttachments: DamageCostEvaluationDto["attachments"];
            items?: Array<
              Omit<DamageCostEvaluationItemCreateDto, "damages"> &
                Omit<DamageCostEvaluationItemUpdateDto, "damages"> & {
                  damages?: Array<
                    DamageCostEvaluationItemDamageInputDto & {
                      damage?: VehicleDamageFullReferenceDto;
                    }
                  >;
                  repairSpec?: RepairSpecDataDto & RepairSpecDataInputDto;
                  initialAttachments?: DamageCostEvaluationDto["attachments"];
                  isEditedManually?: boolean;
                  isInitiallyAutoSelectedBestMatchingRepairSpec?: boolean;

                  // computed
                  tax?: GeneralTaxInputDto | null;
                  discount?: GeneralDiscountInputDto | null;
                  subTotal?: number | null;
                  subTotalIncDiscount?: number | null;
                  total?: number | null;
                }
            >;

            // computed
            tax?: GeneralTaxInputDto | null;
            discount?: GeneralDiscountInputDto | null;
            subTotal?: number | null;
            subTotalIncDiscount?: number | null;
            total?: number | null;
            selectedDamageIds: string[];
            selectedDamageIdsMap: Record<string, boolean>;
          }
      >
        initialValues={{
          vehicleId: damageCostEvaluation?.vehicle?.id || defaultValues?.vehicleId || undefined,
          contractId: damageCostEvaluation?.contract?.id || defaultValues?.contractId || undefined,
          departmentId:
            (damageCostEvaluation?.departmentIds || []).at(0) ||
            (departments && departments[0] && departments[0].id) ||
            undefined,
          locationId:
            (damageCostEvaluation?.locationIds || []).at(0) ||
            (locations && locations[0] && locations[0].id) ||
            undefined,
          vehicle: damageCostEvaluation?.vehicle || undefined,
          currency:
            damageCostEvaluation?.currency ||
            defaultValues?.currency ||
            currentCurrency ||
            undefined,
          inspectedAt:
            damageCostEvaluation?.inspectedAt || moment().format(DATETIME_FORMATS.DISPLAY_DATETIME),
          inspector: {
            isCurrentUser: true,
            ...(damageCostEvaluation?.inspector || {}),
            phoneNumber: damageCostEvaluation?.inspector?.phoneNumber,
          },
          notes: damageCostEvaluation?.notes || "",
          initialAttachments: damageCostEvaluation?.attachments || undefined,
          attachments:
            damageCostEvaluation?.attachments && damageCostEvaluation.attachments.length > 0
              ? damageCostEvaluation.attachments
              : undefined,
          items: damageCostEvaluation?.items?.map((x) => ({
            ...x,
            id: x.id,
            name: x.name || undefined,
            damages: x.damages?.map((y) => ({ ...y, damageId: y.damage?.id, damage: y.damage })),
            repairSpec: x.repairSpec
              ? ({
                  ...x.repairSpec,
                  ...mapRepairSpecDtoToInputDto(x.repairSpec),
                } as RepairSpecDataDto & RepairSpecDataInputDto)
              : undefined,
            repairSpecId: x.repairSpec?.id,
            notes: x.notes || undefined,
            initialAttachments: x?.attachments || undefined,
            attachments: x.attachments && x.attachments.length > 0 ? x.attachments : undefined,
            tax: x.tax,
            subTotal: x.subTotal,
            subTotalIncDiscount: x.subTotalIncDiscount,
            total: x.total,
          })) ||
            defaultValues?.vehicleDamageIds?.map((damageId) => {
              const damage = defaultVehicleDamages?.find((x) => x.id === damageId);
              return {
                damages: [
                  {
                    damageId: damageId,
                    damage: damage,
                  },
                ],
                initialAttachments: damage?.attachments,
                attachments: damage?.attachments,
                notes: damage?.notes,
              };
            }) || [{ damages: [{}] }],

          isSendToCustomer: damageCostEvaluation?.isSendToCustomer || undefined,
          tenantRequestsMeta:
            damageCostEvaluation?.tenantRequestsMeta ||
            defaultValues?.tenantRequestsMeta ||
            undefined,
          tags: damageCostEvaluation?.tags || undefined,
          newStage: undefined,

          // computed
          selectedDamageIds: [],
          selectedDamageIdsMap: {},

          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // type: Yup.string().required("Type is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response =
                await apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsPost({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  damageCostEvaluationCreateDto: {
                    ..._.omit(values, ["submit"]),
                    inspectedAt: values.inspectedAt
                      ? moment(values.inspectedAt).utc().format()
                      : undefined,
                    attachments: values.attachments,
                    items: values.items,
                  },
                });
              enqueueSnackbar("Damage cost evaluation created.", { variant: "success" });
              onCreate && (await onCreate(response.data));
              onSave && (await onSave(response.data));
            } else {
              const response =
                await apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdPut(
                  {
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    damageCostEvaluationId,
                    damageCostEvaluationUpdateDto: {
                      ..._.omit(values, ["submit"]),
                      inspectedAt: values.inspectedAt
                        ? moment(values.inspectedAt).utc().format()
                        : undefined,
                      attachments: values.attachments,
                      items: values.items,
                    },
                  },
                );
              enqueueSnackbar("Damage cost evaluation updated.", { variant: "success" });
              onUpdate && (await onUpdate(response.data));
              onSave && (await onSave(response.data));
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {(formikProps) => {
          const {
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setErrors,
            setFieldValue,
            setValues,
            submitForm,
          } = formikProps;
          //
          return (
            <form noValidate onSubmit={handleSubmit}>
              <FormikComputedField<
                typeof values,
                Pick<typeof values, "departmentId" | "locationId">
              >
                deps={[values?.vehicleId]}
                compute={async (v) => {
                  const vehicleResponse = v.vehicleId
                    ? await apiClient.vehiclesApi.apiV1VehiclesVehicleIdGet({
                        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                        vehicleId: v.vehicleId!,
                      })
                    : null;
                  return {
                    departmentId: !values.departmentId
                      ? vehicleResponse?.data?.departmentIds?.at(0)
                      : undefined,
                    locationId: !values.locationId
                      ? vehicleResponse?.data?.locationIds?.at(0)
                      : undefined,
                  };
                }}
                onComputed={(computed) => {
                  setFieldValue("departmentId", computed.departmentId);
                  setFieldValue("locationId", computed.locationId);
                }}
              />
              {/* Computed fields (read-only) */}
              <FormikComputedField<
                typeof values,
                Pick<
                  typeof values,
                  "items" | "subTotal" | "subTotalIncDiscount" | "discount" | "tax" | "total"
                >
              >
                deps={[values.items]}
                compute={(v) => {
                  const computedItems = (v.items || []).map((item) => ({
                    ...item,
                    subTotal: item.repairSpec?.subTotal,
                    subTotalIncDiscount: item.repairSpec?.subTotalIncDiscount,
                    discount: item.repairSpec?.discount,
                    tax: item.repairSpec?.tax,
                    total: item.repairSpec?.total,
                  }));
                  const computedSummary = PriceHelper.calcLineItemsSummary(computedItems);

                  return {
                    items: computedItems,
                    subTotal: computedSummary.subTotal,
                    subTotalIncDiscount: computedSummary.subTotalIncDiscount,
                    discount: computedSummary.discount,
                    tax: computedSummary.tax,
                    total: computedSummary.total,
                  };
                }}
                onComputed={(newValue) => {
                  setFieldValue("items", newValue.items);
                  setFieldValue("subTotal", newValue.subTotal);
                  setFieldValue("subTotal", newValue.subTotalIncDiscount);
                  setFieldValue("discount", newValue.discount);
                  setFieldValue("tax", newValue.tax);
                  setFieldValue("total", newValue.total);
                }}
              />
              <FormikComputedField<
                typeof values,
                Pick<typeof values, "selectedDamageIds" | "selectedDamageIdsMap">
              >
                deps={[values.items]}
                compute={(v) => {
                  const selectedDamageIds = _.flatten(
                    v.items
                      ?.map((x) => x.damages?.map((y) => y.damageId || "") ?? [])
                      ?.filter(Boolean) ?? [],
                  );
                  return {
                    selectedDamageIds,
                    selectedDamageIdsMap: _.chain(selectedDamageIds)
                      .keyBy((x) => x)
                      .mapValues((x) => true)
                      .value(),
                  };
                }}
                onComputed={(newValue) => {
                  setFieldValue("selectedDamageIds", newValue.selectedDamageIds);
                  setFieldValue("selectedDamageIdsMap", newValue.selectedDamageIdsMap);
                }}
              />

              {!canEdit && (
                <Alert severity='info' sx={{ my: 2 }}>
                  {`This cost evaluation is completed and can't be edited.`}
                </Alert>
              )}

              <Box>
                {/* Tags */}
                <FormControl margin='dense' fullWidth>
                  <GeneralAttachedTagsInput
                    value={values.tags}
                    onChange={(newValue) => {
                      setFieldValue("tags", newValue);
                    }}
                  />
                  <FormHelperText error>
                    {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                      isIncludeNested: false,
                    })}
                  </FormHelperText>
                </FormControl>

                {!isCreate && (
                  <TextField
                    fullWidth
                    disabled
                    label='Number'
                    margin='dense'
                    type='text'
                    value={damageCostEvaluation?.localNumber}
                    variant='outlined'
                  />
                )}

                <FormControl margin='dense' fullWidth error={Boolean(errors.vehicleId)}>
                  <VehicleAutocompleteOrCreate
                    autocompleteProps={{
                      required: true,
                      disabled: !isCreate,
                      entityId: values.vehicleId,
                      isPreload: isCreate,
                      onChange: (newValue) => {
                        setFieldValue(`vehicleId`, newValue?.id);
                        setFieldValue(`vehicle`, newValue || undefined);
                      },
                    }}
                    createFormPlacement='modal'
                    onCreate={(newValue) => {
                      setFieldValue(`vehicleId`, newValue?.id);
                      setFieldValue(`vehicle`, newValue || undefined);
                    }}
                  />
                  <FormHelperText>{errors.vehicleId}</FormHelperText>
                </FormControl>

                {/* Entity affiliation */}
                <EntityAffiliationInput
                  department={{
                    departmentId: values.departmentId,
                    onChange: (d) => {
                      setFieldValue("departmentId", d?.id);
                      setFieldValue("locationId", undefined);
                    },
                    error: errors.departmentId,
                  }}
                  location={{
                    locationId: values.locationId,
                    onChange: (l) => {
                      setFieldValue("locationId", l?.id);
                    },
                    searchFilters: { departmentId: values.departmentId },
                    createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                    error: errors.locationId,
                    disabled: !values.departmentId,
                  }}
                />

                {values.contractId && (
                  <FormControl margin='dense' fullWidth error={Boolean(errors.contractId)}>
                    <ContractAutocompleteOrCreate
                      autocompleteProps={{
                        disabled: true,
                        entityId: values.contractId,
                        isPreload: isCreate,
                        searchFilters: {
                          vehicleId: values.vehicleId || undefined,
                          excludeStage: ContractStage.Draft,
                        },
                        onChange: (newValue) => {
                          setFieldValue(`contractId`, newValue?.id);
                          setFieldValue(`contract`, newValue || undefined);
                        },
                      }}
                      createFormPlacement='modal'
                      onCreate={(newValue) => {
                        setFieldValue(`contractId`, newValue?.id);
                        setFieldValue(`contract`, newValue || undefined);
                      }}
                    />
                    <FormHelperText>{errors.contractId}</FormHelperText>
                  </FormControl>
                )}

                {values.vehicleId && (
                  <Stack spacing={2}>
                    <Box>
                      <FormControl
                        margin='dense'
                        fullWidth
                        required
                        error={Boolean(errors.currency)}
                      >
                        <CurrencyAutocomplete
                          required
                          entity={values.currency}
                          onChange={(newValue) => setFieldValue("currency", newValue)}
                          textFieldProps={{
                            label: "Currency",
                            error: Boolean(errors.currency),
                            helperText: ValidationHelper.getFormikErrorsAsString(errors.currency),
                          }}
                        />
                        <FormHelperText>Using currency from tenant settings</FormHelperText>
                      </FormControl>

                      <FormControl
                        margin='dense'
                        fullWidth
                        error={Boolean(touched.inspectedAt && errors.inspectedAt)}
                      >
                        <MobileDateTimePicker
                          ampm={false}
                          label='Inspected at'
                          value={(values.inspectedAt && moment(values.inspectedAt)) || null}
                          format={DATETIME_FORMATS.DISPLAY_DATETIME}
                          onChange={(newValue: Moment | null) => {
                            setFieldValue("inspectedAt", newValue?.format() || null);
                          }}
                          slots={{ textField: (params) => <TextField {...params} /> }}
                        />
                        <FormHelperText>{touched.inspectedAt && errors.inspectedAt}</FormHelperText>
                      </FormControl>
                    </Box>

                    {/* Items */}
                    <FoldableBlock
                      defaultIsFolded={false}
                      trigger={{
                        label: <Typography variant='h2'>Items *</Typography>,
                      }}
                    >
                      <Stack spacing={2}>
                        {_.isEmpty(values.items) && <NoDataAlert title='No items yet' />}

                        <Stack direction='column' spacing={1}>
                          {values.items &&
                            values.items.map((item, i) => (
                              <Box key={i}>
                                <Card>
                                  <CardContent>
                                    <Stack spacing={2}>
                                      {/* Header */}
                                      <Box
                                        sx={{
                                          display: "grid",
                                          gridTemplateColumns: "1fr 0fr",
                                          gridTemplateRows: "auto",
                                          gap: 2,
                                          alignItems: "start",
                                        }}
                                      >
                                        <Stack
                                          direction='row'
                                          spacing={2}
                                          sx={{ alignItems: "center" }}
                                        >
                                          <Typography variant='h3'>#{i + 1}</Typography>

                                          <TextField
                                            variant='outlined'
                                            size='small'
                                            label='Name'
                                            required
                                            fullWidth
                                            error={Boolean(getIn(errors, `items[${i}].name`))}
                                            helperText={ValidationHelper.getFormikErrorsAsString(
                                              getIn(errors, `items[${i}].name`),
                                            )}
                                            value={item.name || ""}
                                            onChange={(e) => {
                                              setFieldValue(
                                                `items[${i}].name`,
                                                e.target.value || undefined,
                                              );
                                              setFieldValue(`items[${i}].isEditedManually`, true);
                                            }}
                                          />
                                        </Stack>

                                        {/* Controls */}
                                        <Stack
                                          direction='row'
                                          sx={{
                                            ml: "auto",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          {/* Notes */}
                                          {(() => {
                                            const isAdd = _.isNil(values.items?.at(i)?.notes);
                                            return (
                                              <Tooltip title={isAdd ? "Add notes" : "Remove notes"}>
                                                <IconButton
                                                  onClick={() =>
                                                    setFieldValue(
                                                      `items[${i}].notes`,
                                                      isAdd ? "" : undefined,
                                                    )
                                                  }
                                                >
                                                  <CompoundIcon
                                                    mainIcon={<AppIcon of='notes' />}
                                                    badgeIcon={
                                                      <AppIcon of={isAdd ? "add" : "remove"} />
                                                    }
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            );
                                          })()}

                                          {/* Attachments */}
                                          {(() => {
                                            const isAdd = _.isNil(values.items?.at(i)?.attachments);
                                            return (
                                              <Tooltip
                                                title={
                                                  isAdd ? "Add attachments" : "Remove attachments"
                                                }
                                              >
                                                <IconButton
                                                  onClick={() =>
                                                    setFieldValue(
                                                      `items[${i}].attachments`,
                                                      isAdd ? [] : undefined,
                                                    )
                                                  }
                                                >
                                                  <CompoundIcon
                                                    mainIcon={<AppIcon of='attachments' />}
                                                    badgeIcon={
                                                      <AppIcon of={isAdd ? "add" : "remove"} />
                                                    }
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            );
                                          })()}

                                          {/* Remove item */}
                                          <Tooltip title='Remove item'>
                                            <IconButton
                                              disabled={!canRemoveItems}
                                              onClick={() =>
                                                setFieldValue(
                                                  `items`,
                                                  ArrayHelper.removeByIndex(values.items, i),
                                                )
                                              }
                                            >
                                              <AppIcon of='remove' />
                                            </IconButton>
                                          </Tooltip>
                                        </Stack>
                                      </Box>

                                      <Divider />

                                      {/* Currency mismatch alert */}
                                      {values.currency && (
                                        <CurrencyMismatchWithRepairSpecAlert
                                          entity={values}
                                          item={item}
                                        />
                                      )}

                                      <Box
                                        sx={{
                                          display: "grid",
                                          gridTemplateColumns: "1fr 1fr",
                                          gridTemplateRows: "auto",
                                          gap: 2,
                                          alignItems: "start",
                                        }}
                                      >
                                        {/* Damages */}
                                        <Stack spacing={2}>
                                          <Stack spacing={2}>
                                            {_.isEmpty(item.damages) && (
                                              <NoDataAlert
                                                title='No damages added'
                                                variant='inline'
                                              />
                                            )}

                                            {item.damages?.map((damage, j) => (
                                              <Box
                                                key={j}
                                                sx={{
                                                  display: "grid",
                                                  gridTemplateColumns: "1fr 0fr",
                                                  gridTemplateRows: "auto",
                                                  columnGap: 1,
                                                  rowGap: 1,
                                                }}
                                              >
                                                {/* Damage */}
                                                <FormControl fullWidth margin='none'>
                                                  <VehicleDamageAutocomplete
                                                    entityId={damage.damageId}
                                                    searchFilters={{
                                                      vehicleId: values.vehicleId || "",
                                                      excludeIds: values.selectedDamageIds.filter(
                                                        (x) => !!x && x !== damage.damageId,
                                                      ),
                                                    }}
                                                    disabled={!canEditDamages}
                                                    required
                                                    fullWidth
                                                    isAutoSelectSingleOption={false}
                                                    size='small'
                                                    textFieldProps={{
                                                      error: Boolean(
                                                        getIn(
                                                          errors,
                                                          `items[${i}].damages[${j}].damageId`,
                                                        ),
                                                      ),
                                                      helperText:
                                                        ValidationHelper.getFormikErrorsAsString(
                                                          getIn(
                                                            errors,
                                                            `items[${i}].damages[${j}].damageId`,
                                                          ),
                                                        ),
                                                    }}
                                                    onChange={(newValue) => {
                                                      setFieldValue(
                                                        `items[${i}].damages[${j}].damageId`,
                                                        newValue?.id,
                                                      );
                                                      setFieldValue(
                                                        `items[${i}].damages[${j}].damage`,
                                                        newValue,
                                                      );
                                                    }}
                                                  />
                                                </FormControl>

                                                {/* Controls */}
                                                <Stack
                                                  direction='row'
                                                  sx={{
                                                    ml: "auto",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                  }}
                                                >
                                                  {/* View damage in modal */}
                                                  {values.vehicleId && damage.damageId && (
                                                    <Tooltip title='View damage in a modal'>
                                                      <IconButton
                                                        size='small'
                                                        color='secondary'
                                                        onClick={() => {
                                                          setVehicleDamageIdToView(damage.damageId);
                                                          setVehicleDamageToView(damage.damage);
                                                          setIsViewVehicleDamageModalOpen(true);
                                                        }}
                                                      >
                                                        <AppIcon of='view' />
                                                      </IconButton>
                                                    </Tooltip>
                                                  )}

                                                  {/* View damage in a new tab */}
                                                  {values.vehicleId && damage.damageId && (
                                                    <Tooltip title='View damage in a new tab'>
                                                      <IconButton
                                                        component={AppLink}
                                                        to={ROUTE_PATH.VEHICLE_DAMAGE_VIEW({
                                                          vehicleId: values.vehicleId,
                                                          vehicleDamageId: damage.damageId,
                                                        })}
                                                        target='_blank'
                                                        size='small'
                                                        color='secondary'
                                                      >
                                                        <AppIcon of='openInNew' />
                                                      </IconButton>
                                                    </Tooltip>
                                                  )}

                                                  {/* Move damage to different item */}
                                                  {damage.damageId &&
                                                    (values?.items?.length ?? 0) > 1 && (
                                                      <Tooltip title='Move damage to different item'>
                                                        <DropdownIconButton
                                                          variant='default'
                                                          size='small'
                                                          color='secondary'
                                                          dropdownContent={
                                                            <MenuList>
                                                              {values?.items
                                                                ?.map(
                                                                  (item2, i2) =>
                                                                    i2 !== i && (
                                                                      <MenuItem
                                                                        key={i2}
                                                                        onClick={() => {
                                                                          const movedDamage =
                                                                            damage;
                                                                          setFieldValue(
                                                                            `items[${i}].damages`,
                                                                            ArrayHelper.removeByIndex(
                                                                              [
                                                                                ...(item.damages ||
                                                                                  []),
                                                                              ],
                                                                              j,
                                                                            ),
                                                                          );
                                                                          setFieldValue(
                                                                            `items[${i2}].damages`,
                                                                            [
                                                                              ...(values?.items?.at(
                                                                                i2,
                                                                              )?.damages || []),
                                                                              movedDamage,
                                                                            ],
                                                                          );
                                                                        }}
                                                                      >
                                                                        {/* <ListItemIcon>
                                                                  <AppIcon of='item' />
                                                                </ListItemIcon> */}
                                                                        <ListItemText>
                                                                          Move to #{i2 + 1}
                                                                        </ListItemText>
                                                                      </MenuItem>
                                                                    ),
                                                                )
                                                                .filter((x) => !!x)}
                                                            </MenuList>
                                                          }
                                                        >
                                                          <AppIcon of='moveItem' />
                                                        </DropdownIconButton>
                                                      </Tooltip>
                                                    )}

                                                  {/* Remove damage */}
                                                  <Tooltip title='Remove damage'>
                                                    <IconButton
                                                      size='small'
                                                      color='secondary'
                                                      disabled={!canRemoveDamages}
                                                      onClick={() =>
                                                        setFieldValue(
                                                          `items[${i}].damages`,
                                                          ArrayHelper.removeByIndex(
                                                            [...(item.damages || [])],
                                                            j,
                                                          ),
                                                        )
                                                      }
                                                    >
                                                      <AppIcon of='remove' />
                                                    </IconButton>
                                                  </Tooltip>
                                                </Stack>
                                              </Box>
                                            ))}
                                          </Stack>

                                          {ValidationHelper.getFormikErrorsAsString(
                                            getIn(errors, `items[${i}].damages`),
                                            { isIncludeNested: false },
                                          ) && (
                                            <FormHelperText error>
                                              {ValidationHelper.getFormikErrorsAsString(
                                                getIn(errors, `items[${i}].damages`),
                                                { isIncludeNested: false },
                                              )}
                                            </FormHelperText>
                                          )}

                                          {/* Add damage */}
                                          <Box>
                                            <Button
                                              disabled={!canAddDamages}
                                              variant='outlined'
                                              size='small'
                                              color='secondary'
                                              onClick={() =>
                                                setFieldValue(`items[${i}].damages`, [
                                                  ...(item.damages || []),
                                                  {},
                                                ])
                                              }
                                            >
                                              Add damage
                                            </Button>
                                          </Box>
                                        </Stack>

                                        {/* Repair spec  */}
                                        <Stack
                                          direction={{ xxs: "column", md: "row" }}
                                          spacing={1}
                                          sx={{ alignItems: "center" }}
                                        >
                                          <FormControl
                                            sx={{ flex: 10 }}
                                            margin='none'
                                            error={Boolean(
                                              getIn(errors, `items[${i}].repairSpecId`) ||
                                                getIn(errors, `items[${i}].repairSpec`),
                                            )}
                                          >
                                            <SelectOrCreateRepairSpec
                                              autocompleteProps={{
                                                entityId: item.repairSpec?.id,
                                                disabled: item.isRepairSpecChanged,
                                                required: true,
                                                size: "small",
                                                isPreload: isCreate,
                                                searchFilters: {
                                                  // use match to query best suggestions (match by the first damage)
                                                  match: {
                                                    damageTypeId:
                                                      item.damages?.at(0)?.damage?.damageType?.id ||
                                                      undefined,
                                                    partTypeId:
                                                      item.damages?.at(0)?.damage?.partType?.id ||
                                                      undefined,
                                                    vehicleId: values.vehicleId || undefined,
                                                  },
                                                },
                                                textFieldProps: {
                                                  error: Boolean(
                                                    getIn(errors, `items[${i}].repairSpecId`) ||
                                                      getIn(errors, `items[${i}].repairSpec`),
                                                  ),
                                                  helperText: (
                                                    <>
                                                      {getIn(
                                                        errors,
                                                        `items[${i}].repairSpecId`,
                                                      ) && (
                                                        <Box>
                                                          {ValidationHelper.getFormikErrorsAsString(
                                                            getIn(
                                                              errors,
                                                              `items[${i}].repairSpecId`,
                                                            ),
                                                          )}
                                                        </Box>
                                                      )}
                                                      {getIn(errors, `items[${i}].repairSpec`) && (
                                                        <Box>
                                                          {ValidationHelper.getFormikErrorsAsString(
                                                            getIn(errors, `items[${i}].repairSpec`),
                                                          )}
                                                        </Box>
                                                      )}
                                                    </>
                                                  ),
                                                },
                                                onChange: (newValue) => {
                                                  setFieldValue(
                                                    `items[${i}].repairSpec`,
                                                    newValue?.id
                                                      ? mapRepairSpecDtoToInputDto(newValue)
                                                      : undefined,
                                                  );
                                                  setFieldValue(
                                                    `items[${i}].isRepairSpecChanged`,
                                                    false,
                                                  );
                                                  setFieldValue(
                                                    `items[${i}].name`,
                                                    item.isEditedManually && item.name
                                                      ? item.name
                                                      : newValue?.name,
                                                  );
                                                },
                                                onLoaded: (entities) => {
                                                  // when creating DCE with prefilled damages, auto-select the best matching repair spec for each DCE item
                                                  // NB: ensure best matching repair spec is auto-selected only initially

                                                  // Problem: setFieldValue experiences inconsistent behavior in the onLoaded method
                                                  // due to React's asynchronous state updates and batching. This can lead to stale
                                                  // or outdated values being applied, especially when multiple state changes occur simultaneously.

                                                  // Temporary solution: Wrapping setFieldValue in setTimeout ensures that the state
                                                  // updates occur after React's rendering process is completed, preventing issues
                                                  // with stale values. This approach is used as other solutions (like async/await)
                                                  // were found unreliable.
                                                  setTimeout(() => {
                                                    if (
                                                      isCreate &&
                                                      !_.isEmpty(defaultVehicleDamages) &&
                                                      !item.isInitiallyAutoSelectedBestMatchingRepairSpec
                                                    ) {
                                                      const newValue = _.orderBy(
                                                        entities,
                                                        (x) => x.matchMeta?.score ?? 0,
                                                        "desc",
                                                      ).at(0);

                                                      if (newValue) {
                                                        setFieldValue(
                                                          `items[${i}].repairSpec`,
                                                          newValue?.id
                                                            ? mapRepairSpecDtoToInputDto(newValue)
                                                            : undefined,
                                                        );
                                                        setFieldValue(
                                                          `items[${i}].isRepairSpecChanged`,
                                                          false,
                                                        );
                                                        setFieldValue(
                                                          `items[${i}].name`,
                                                          item.isEditedManually && item.name
                                                            ? item.name
                                                            : newValue?.name,
                                                        );
                                                        setFieldValue(
                                                          `items[${i}].isInitiallyAutoSelectedBestMatchingRepairSpec`,
                                                          true,
                                                        );
                                                      }
                                                    }
                                                  }, 3000);
                                                },
                                              }}
                                              createUpdateProps={{
                                                defaultValues: {
                                                  damageTypeId:
                                                    item.damages?.at(0)?.damage?.damageType?.id ||
                                                    undefined,
                                                  partTypeId:
                                                    item.damages?.at(0)?.damage?.partType?.id ||
                                                    undefined,
                                                  currency: values.currency,
                                                },
                                              }}
                                              createFormPlacement='modal'
                                              onCreate={(newValue) => {
                                                setFieldValue(`items[${i}].repairSpec`, newValue);
                                                setFieldValue(
                                                  `items[${i}].name`,
                                                  item.isEditedManually && item.name
                                                    ? item.name
                                                    : newValue?.name,
                                                );
                                              }}
                                            />
                                          </FormControl>

                                          <AuthorizedElement
                                            permissions={[
                                              AppPermission.FleetAppAccess,
                                              AppPermission.RepairCatalogRead,
                                              AppPermission.RepairCatalogManage,
                                            ]}
                                          >
                                            {!item.isRepairSpecChanged && (
                                              <Tooltip title='Enter custom values for this repair spec'>
                                                <Button
                                                  variant='outlined'
                                                  color='secondary'
                                                  size='small'
                                                  startIcon={<AppIcon of='edit' />}
                                                  onClick={() => {
                                                    setFieldValue(
                                                      `items[${i}].isRepairSpecChanged`,
                                                      true,
                                                    );
                                                    if (!item.repairSpec) {
                                                      setFieldValue(`items[${i}].repairSpec`, {});
                                                    }
                                                  }}
                                                >
                                                  Edit repair spec
                                                </Button>
                                              </Tooltip>
                                            )}
                                            {item.isRepairSpecChanged && (
                                              <Button
                                                variant='outlined'
                                                color='secondary'
                                                size='small'
                                                startIcon={<AppIcon of='list' />}
                                                onClick={() => {
                                                  setFieldValue(
                                                    `items[${i}].isRepairSpecChanged`,
                                                    false,
                                                  );
                                                  setFieldValue(
                                                    `items[${i}].repairSpec`,
                                                    undefined,
                                                  );
                                                }}
                                              >
                                                Select existing spec
                                              </Button>
                                            )}

                                            {/* Create repair spec from spec data */}
                                            {item.isRepairSpecChanged && (
                                              <Tooltip title='Create new repair spec based on local evaluation data '>
                                                <Button
                                                  size='small'
                                                  color='secondary'
                                                  variant='outlined'
                                                  startIcon={<AppIcon of='add' />}
                                                  onClick={() => {
                                                    setCreateRepairSpecFromSpecData(
                                                      item.repairSpec,
                                                    );
                                                    setIsCreateRepairSpecModalOpen(true);
                                                  }}
                                                >
                                                  Save as new spec
                                                </Button>
                                              </Tooltip>
                                            )}
                                          </AuthorizedElement>
                                        </Stack>
                                      </Box>

                                      {/* Selected repair spec */}
                                      {item.repairSpec && (
                                        <Box>
                                          <FoldableBlock
                                            defaultIsFolded={false}
                                            trigger={{
                                              label: (
                                                <Typography variant='h4'>Repair spec</Typography>
                                              ),
                                            }}
                                          >
                                            <Stack spacing={1}>
                                              {/* Repair spec details with create/edit */}
                                              <AuthorizedElement
                                                permissions={[
                                                  AppPermission.FleetAppAccess,
                                                  AppPermission.RepairCatalogRead,
                                                  AppPermission.RepairCatalogManage,
                                                ]}
                                              >
                                                <FoldableBlock
                                                  defaultIsFolded={false}
                                                  trigger={{
                                                    label: (
                                                      <Typography component='span' variant='h6'>
                                                        Details
                                                      </Typography>
                                                    ),
                                                  }}
                                                >
                                                  <RepairSpecDataInputDtoFormInputs
                                                    disabled={!item.isRepairSpecChanged}
                                                    mode={isEdit ? "edit" : "create"}
                                                    values={item.repairSpec || undefined}
                                                    defaultValues={{
                                                      currency: values.currency,
                                                      vehicleType:
                                                        values?.vehicle?.spec?.type ||
                                                        VehicleType.Car,
                                                      partTypeId:
                                                        item.damages?.at(0)?.damage?.partType?.id,
                                                      damageTypeId:
                                                        item.damages?.at(0)?.damage?.damageType?.id,
                                                      detalization: {
                                                        type: RepairSpecDetalizationType.General,
                                                      },
                                                      items: [],
                                                    }}
                                                    formikProps={{
                                                      errors: getIn(
                                                        errors,
                                                        `items[${i}].repairSpec`,
                                                      ),
                                                      touched: getIn(
                                                        touched,
                                                        `items[${i}].repairSpec`,
                                                      ),
                                                    }}
                                                    displayProps={{
                                                      mainInputs: false,
                                                      items: true,
                                                      itemsHeader: false,
                                                      itemInputs: true,
                                                      lineItemsSummary: false,
                                                    }}
                                                    withGeneralValidationError
                                                    onChange={(newValue) => {
                                                      setFieldValue(
                                                        `items[${i}].repairSpec`,
                                                        newValue || undefined,
                                                      );
                                                      setFieldValue(
                                                        `items[${i}].name`,
                                                        item.isEditedManually && item.name
                                                          ? item.name
                                                          : newValue?.name,
                                                      );
                                                    }}
                                                  />
                                                </FoldableBlock>
                                              </AuthorizedElement>

                                              {/* Repair spec summary */}
                                              <FoldableBlock
                                                defaultIsFolded={false}
                                                trigger={{
                                                  label: (
                                                    <Typography variant='h6'>Summary</Typography>
                                                  ),
                                                }}
                                              >
                                                <Box>
                                                  <GeneralPriceSummaryDisplay
                                                    direction='row'
                                                    summary={{
                                                      currency: item.repairSpec?.currency,
                                                      subTotal: item.repairSpec?.subTotal,
                                                      discount:
                                                        item?.repairSpec?.discount || undefined,
                                                      tax: item?.repairSpec?.tax || undefined,
                                                      total: item.repairSpec?.total || 0,
                                                    }}
                                                  />
                                                </Box>
                                              </FoldableBlock>
                                            </Stack>
                                          </FoldableBlock>
                                        </Box>
                                      )}

                                      <Box>
                                        {values.items &&
                                          !_.isNil(values.items?.at(i)?.attachments) && (
                                            <FormControl fullWidth margin='normal'>
                                              <FileUploader
                                                multiple
                                                defaultFiles={FileItem.createManyFrom(
                                                  values.items?.at(i)?.initialAttachments,
                                                )}
                                                onChange={(newFiles) =>
                                                  setFieldValue(
                                                    `items[${i}].attachments`,
                                                    FileItem.toManyGeneralAttachmentInputDto(
                                                      newFiles,
                                                    ),
                                                  )
                                                }
                                                onUploadStarted={() => {
                                                  setIsAttachmentFilesUploading(true);
                                                }}
                                                onUploadFinished={() => {
                                                  setIsAttachmentFilesUploading(false);
                                                }}
                                              />
                                            </FormControl>
                                          )}

                                        {!_.isNil(item.notes) && (
                                          <TextField
                                            error={Boolean(getIn(errors, `items[${i}].notes`))}
                                            fullWidth
                                            multiline
                                            rows={2}
                                            helperText={getIn(errors, `items[${i}].notes`)}
                                            label='Notes'
                                            margin='dense'
                                            name={`items[${i}].notes`}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type='text'
                                            value={values.items?.at(i)?.notes}
                                            variant='outlined'
                                          />
                                        )}
                                      </Box>
                                    </Stack>
                                  </CardContent>
                                </Card>
                              </Box>
                            ))}
                        </Stack>

                        {errors.items && _.isString(errors.items) && (
                          <FormHelperText error>{errors.items}</FormHelperText>
                        )}

                        <Stack
                          direction='row'
                          spacing={2}
                          sx={{ justifyContent: "space-between", alignItems: "flex-start" }}
                        >
                          {/* Add item */}
                          <Button
                            disabled={!canAddItems}
                            variant='outlined'
                            size='small'
                            color='secondary'
                            onClick={() =>
                              setFieldValue("items", [...(values.items || []), { damages: [{}] }])
                            }
                          >
                            Add item
                          </Button>

                          {/* Summary */}
                          <Box>
                            <Stack direction='row' justifyContent='flex-end'>
                              <GeneralPriceSummaryDisplay
                                sx={{ minWidth: { xxs: "100%", md: "300px" } }}
                                summary={{
                                  currency: values.currency,
                                  subTotal: values.subTotal || 0,
                                  subTotalIncDiscount: values.subTotalIncDiscount,
                                  discount: values.discount,
                                  tax: values.tax,
                                  total: values.total || 0,
                                }}
                              />
                            </Stack>
                          </Box>
                        </Stack>
                      </Stack>
                    </FoldableBlock>

                    {/* Other */}
                    <FoldableBlock
                      defaultIsFolded={false}
                      trigger={{
                        label: <Typography variant='h2'>Other</Typography>,
                      }}
                    >
                      <TextField
                        error={Boolean(touched.notes && errors.notes)}
                        fullWidth
                        multiline
                        rows={2}
                        helperText={touched.notes && errors.notes}
                        label='Notes'
                        margin='dense'
                        name='notes'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='text'
                        value={values.notes}
                        variant='outlined'
                      />

                      <FormControl fullWidth component='fieldset' margin='dense'>
                        <FileUploader
                          multiple
                          defaultFiles={FileItem.createManyFrom(values.initialAttachments)}
                          onChange={(newFiles) => {
                            setFieldValue(
                              `attachments`,
                              FileItem.toManyGeneralAttachmentInputDto(newFiles),
                            );
                          }}
                          onUploadStarted={() => {
                            setIsAttachmentFilesUploading(true);
                          }}
                          onUploadFinished={() => {
                            setIsAttachmentFilesUploading(false);
                          }}
                        />
                      </FormControl>
                    </FoldableBlock>

                    <FormControl>
                      <FormControlLabel
                        control={
                          <Switch
                            disabled
                            checked={values.inspector?.isCurrentUser}
                            onChange={handleChange}
                            name='inspector.isCurrentUser'
                          />
                        }
                        label={
                          <>
                            Inspected by{" "}
                            {values.inspector?.personName
                              ? `${values.inspector.personName?.firstName} ${values.inspector.personName?.lastName}`
                              : profile?.personName?.name}
                          </>
                        }
                      />
                      <FormHelperText>
                        Currently logged in user evaluated the damages
                      </FormHelperText>
                    </FormControl>

                    {values.contractId && (
                      <FormControl margin='dense' fullWidth>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isSendToCustomer ?? undefined}
                              onChange={(e) => setFieldValue("isSendToCustomer", e.target.checked)}
                            />
                          }
                          label='Send to customer'
                        />
                      </FormControl>
                    )}

                    <GeneralValidationError errors={errors} />

                    <Box>
                      <SplitDropdownButton
                        isSelectAndTriggerAction={false}
                        buttonProps={{
                          variant: "contained",
                          color: "primary",
                          type: "submit",
                          fullWidth: true,
                          loading: isSubmitting,
                          disabled: isAttachmentFilesUploading || !canEdit,
                        }}
                        options={[
                          {
                            content: "Save",
                            onClick: () => {
                              submitForm();
                            },
                          },
                          {
                            content: "Save & Complete",
                            if: isCreate,
                            onClick: () => {
                              const newValue: GeneralStageUpdateDtoOfDamageCostEvaluationStage = {
                                newStage: DamageCostEvaluationStage.Completed,
                              };
                              setFieldValue("newStage", newValue);
                              submitForm();
                            },
                          },
                        ]}
                      />
                    </Box>
                  </Stack>
                )}
              </Box>

              {/* Create repair spec from local repair spec data */}
              {createRepairSpecFromSpecData && (
                <CreateUpdateRepairSpecModal
                  open={isCreateRepairSpecModalOpen}
                  onClose={() => {
                    setIsCreateRepairSpecModalOpen(false);
                    setCreateRepairSpecFromSpecData(undefined);
                  }}
                  createUpdateProps={{
                    defaultValues: {
                      data: createRepairSpecFromSpecData,
                    },
                    onSave: () => {
                      setIsCreateRepairSpecModalOpen(false);
                      setCreateRepairSpecFromSpecData(undefined);
                    },
                  }}
                />
              )}

              {/* View damage */}
              {values.vehicleId && (vehicleDamageIdToView || vehicleDamageToView) && (
                <VehicleDamageViewModal
                  open={isViewVehicleDamageModalOpen}
                  onClose={() => {
                    setIsViewVehicleDamageModalOpen(false);
                    setVehicleDamageIdToView(undefined);
                    setVehicleDamageToView(undefined);
                  }}
                  viewProps={{
                    vehicleId: values.vehicleId,
                    damageId: vehicleDamageIdToView,
                    damage: vehicleDamageToView,
                  }}
                />
              )}
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
