import { LoadingButton } from "@mui/lab";
import { FormControl } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import TenantRolesSelector from "@/common/components/Entity/Role/TenantRolesSelector";
import DurationInput from "@/common/components/Form/Input/DurationInput";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import { clipboardService } from "@/common/services/clipboard";
import { TimeSpan } from "@/common/ts/timespan";
import { ValidationHelper } from "@/common/validation";
import { AdminCreateAnonymousInviteDto, UnitOfTime } from "@/core/api/generated";
import * as tenantInvitesSlice from "@/store/management/tenantInvites/slice";
import { useHistory } from "react-router";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import { BaseFormikValues } from "@/common/ts/error";
import AdminTenantAutocomplete from "@/common/components/Entity/Tenant/AdminTenantAutocomplete";
import AdminTenantRolesSelector from "@/common/components/Entity/Role/AdminTenantRolesSelector";
import { apiClient } from "@/core/api/ApiClient";

const StyledForm = styled("form")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: (theme.shadows as string[])[1],
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  width: "100%",
  height: "100%",
  overflow: "hidden",
}));

export default function CreateAnonymousInvitePage() {
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const thunkDispatch = useAppThunkDispatch();

  return (
    <Formik<AdminCreateAnonymousInviteDto & BaseFormikValues>
      enableReinitialize
      initialValues={{
        tenantId: undefined,
        lifetime: new TimeSpan({ day: 7 }).toString(),
        roleIds: undefined,
        departmentId: undefined,

        submit: "",
      }}
      validationSchema={Yup.object().shape({})}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          const response = await apiClient.adminInvitesApi.apiV1AdminInvitesAnonymousPost({
            adminCreateAnonymousInviteDto: {
              ...values,
            },
          });
          enqueueSnackbar("Anonymous invite created successfully.", {
            variant: "success",
          });
          try {
            await clipboardService.writeText(response.data.inviteUrl as string);
            enqueueSnackbar("Link copied to clipboard.", {
              variant: "success",
            });
          } catch (error) {
            console.error("Clipboard copy error", error);
          }

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
          history.goBack();
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <CreateUpdatePageLayout header={<SimpleViewPageHeader title={"New anonymous invite"} />}>
            <form noValidate onSubmit={handleSubmit}>
              <Box>
                <AdminTenantAutocomplete
                  entityId={values.tenantId}
                  fullWidth
                  onChange={(newValue) => {
                    setFieldValue("tenantId", newValue?.id);
                  }}
                />
                <DurationInput
                  error={Boolean(errors.lifetime)}
                  helperText={errors.lifetime}
                  fullWidth
                  margin='dense'
                  label='Invite lifetime'
                  variant='outlined'
                  defaultUnitOfTime={UnitOfTime.Day}
                  value={values.lifetime}
                  onChange={(newValue) => {
                    setFieldValue("lifetime", newValue);
                  }}
                  onBlur={handleBlur}
                />

                <FormControl margin='none' fullWidth>
                  <AdminTenantRolesSelector
                    tenantId={values.tenantId}
                    roleIds={values.roleIds}
                    onChange={(newValue) =>
                      setFieldValue("roleIds", newValue?.map((x) => x.id) || [])
                    }
                    textFieldProps={{
                      error: Boolean(touched.roleIds && errors.roleIds),
                      helperText: touched.roleIds && errors.roleIds,
                    }}
                  />
                </FormControl>
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <LoadingButton
                sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                color='primary'
                loading={isSubmitting}
                disabled={isSubmitting}
                fullWidth
                type='submit'
                variant='contained'
              >
                Create
              </LoadingButton>
            </form>
          </CreateUpdatePageLayout>
        );
      }}
    </Formik>
  );
}
