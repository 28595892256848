import { TypeHelper } from "@/common/helpers/type";
import useCascadeActionSpec from "@/common/hooks/entity/cascadeActions/useCascadeActionSpec";
import useMounted from "@/common/hooks/mount/useMounted";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { CascadeActionInputDto, CascadeActionType, EntityType } from "@/core/api/generated";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  DialogContent,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Stack,
} from "@mui/material";
import { getIn, useFormik } from "formik";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import RelatedEntityTypesInputModal from "../../CascadeActions/RelatedEntityTypesInputModal";
import AppIcon from "../../Icons/AppIcon";
import AppModal, { AppModalProps } from "../../Modals/AppModal";
import AppModalTitle from "../../Modals/AppModalTitle";
import EntityAffiliationInput from "./EntityAffiliationInput";

export type CascadeActionFormikValues = CascadeActionInputDto & { submit: string };

export interface UpdateEntityAffiliationCascadeModalProps extends Omit<AppModalProps, "children"> {
  entityType: EntityType;
  entityId?: string;
  onUpdate?: () => void;
}

export default function UpdateEntityAffiliationCascadeModal({
  entityType,
  entityId,
  onUpdate,
  ...modalProps
}: UpdateEntityAffiliationCascadeModalProps) {
  const mounted = useMounted();

  const [isUpdateCascade, setIsUpdateCascade] = useState(false);
  const [isUpdateCascadeSettingsOpen, setIsUpdateCascadeSettingsOpen] = useState(false);

  const cascadeActionSpec = useCascadeActionSpec(
    entityType,
    CascadeActionType.UpdateEntityAffiliation,
  );

  const formik = useFormik<CascadeActionFormikValues>({
    enableReinitialize: true,
    initialValues: {
      baseEntity: {
        entityType: entityType,
        entityId: entityId,
      },
      relatedEntitiesTypes: undefined,
      parameters: {
        updateEntityAffiliation: {
          departmentIds: [],
          locationIds: [],
        },
      },
      submit: "",
    },
    onSubmit: async (values, { setFieldError, setStatus, setSubmitting, setValues }) => {
      try {
        if (mounted.current) {
          setSubmitting(true);
          await apiClient.cascadeActionsApi.apiV1CascadeActionsUpdateAffiliationPost({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            cascadeActionInputDto: {
              ..._.omit(values, "submit"),
              relatedEntitiesTypes: isUpdateCascade ? values.relatedEntitiesTypes : [],
            },
          });

          setStatus({ success: true });
          setSubmitting(false);
          onUpdate && onUpdate();
          // reset to initial values
          setValues({
            baseEntity: {
              entityType: entityType,
              entityId: entityId,
            },
            relatedEntitiesTypes: undefined,
            parameters: {
              updateEntityAffiliation: {
                departmentIds: [],
                locationIds: [],
              },
            },
            submit: "",
          });
          modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown");
        }
      } catch (err: any) {
        if (mounted.current) {
          ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
          setStatus({ success: false });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    values,
    errors,
    touched,
    setFieldError,
    setFieldValue,
    setValues,
    isSubmitting,
    handleSubmit,
  } = formik;

  useEffect(() => {
    if (cascadeActionSpec) {
      setFieldValue(
        "relatedEntitiesTypes",
        isUpdateCascade ? cascadeActionSpec.relatedEntitiesTypes : undefined,
      );
    }
  }, [cascadeActionSpec, isUpdateCascade]);

  const handleClose = useCallback(() => {
    setValues({
      baseEntity: {
        entityType: entityType,
        entityId: entityId,
      },
      relatedEntitiesTypes: undefined,
      parameters: {
        updateEntityAffiliation: {
          departmentIds: [],
          locationIds: [],
        },
      },
      submit: "",
    });
    setIsUpdateCascade(false);
    modalProps.onClose && modalProps.onClose({}, "escapeKeyDown");
  }, [entityType, entityId, setValues]);

  return (
    <AppModal fullWidth keepMounted={false} {...{ ...modalProps, onClose: handleClose }}>
      <AppModalTitle
        title='Update entity affiliation'
        withCloseIcon
        onCloseClicked={() => handleClose()}
      />
      <DialogContent>
        <Stack spacing={2}>
          <EntityAffiliationInput
            department={{
              label: "Department",
              textFieldProps: {
                margin: "dense",
              },
              fullWidth: true,
              departmentId: values.parameters?.updateEntityAffiliation?.departmentIds?.at(0),
              onChange: (d) => {
                setFieldValue(
                  "parameters.updateEntityAffiliation.departmentIds",
                  d?.id ? [d.id] : [],
                );
                setFieldValue("parameters.updateEntityAffiliation.locationIds", []);
              },
              error: getIn(errors, "parameters.updateEntityAffiliation.departmentIds"),
            }}
            location={{
              label: "Location",
              textFieldProps: {
                margin: "dense",
              },
              fullWidth: true,
              locationId: values.parameters?.updateEntityAffiliation?.locationIds?.at(0),
              onChange: (l) => {
                setFieldValue(
                  "parameters.updateEntityAffiliation.locationIds",
                  l?.id ? [l.id] : [],
                );
              },
              searchFilters: {
                departmentId: values.parameters?.updateEntityAffiliation?.departmentIds?.at(0),
              },
              createUpdateProps: {
                defaultValues: {
                  departmentId: values.parameters?.updateEntityAffiliation?.departmentIds?.at(0),
                },
              },
              error: getIn(errors, "parameters.updateEntityAffiliation.locationIds"),
              disabled:
                !values.parameters?.updateEntityAffiliation?.departmentIds ||
                values.parameters?.updateEntityAffiliation?.departmentIds?.length === 0,
            }}
          />

          <Stack direction='row' spacing={1} alignItems='center'>
            <Box>
              <FormControlLabel
                sx={{ mr: 0 }}
                control={
                  <Checkbox
                    checked={isUpdateCascade ?? false}
                    onChange={(e) => {
                      const isCascade2 = e.target.checked;
                      setIsUpdateCascade(isCascade2);
                    }}
                  />
                }
                label='Update affiliation cascade'
              />
            </Box>

            {isUpdateCascade && (
              <IconButton size='small' onClick={() => setIsUpdateCascadeSettingsOpen(true)}>
                <AppIcon of='settings' />
              </IconButton>
            )}
          </Stack>

          <FormHelperText error={true}>{errors.submit}</FormHelperText>

          <RelatedEntityTypesInputModal
            open={isUpdateCascadeSettingsOpen}
            onClose={() => setIsUpdateCascadeSettingsOpen(false)}
            spec={cascadeActionSpec}
            relatedEntitiesTypes={values.relatedEntitiesTypes || undefined}
            onRelatedTypesChange={(newValue) => setFieldValue("relatedEntitiesTypes", newValue)}
          />

          <LoadingButton
            loading={isSubmitting}
            disabled={TypeHelper.isEmpty(values.parameters?.updateEntityAffiliation?.departmentIds)}
            color='primary'
            fullWidth
            type='submit'
            variant='contained'
            onClick={() => handleSubmit()}
            sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
          >
            Save
          </LoadingButton>
        </Stack>
      </DialogContent>
    </AppModal>
  );
}
