import { SxProps, Theme } from "@mui/material";

import { DataGrantDto } from "@/core/api/generated";

import GeneralStrictEntityRelationInline from "../General/Display/GeneralStrictEntityRelationInline";
import { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";

interface Props {
  value: DataGrantDto | null | undefined;
  inlineProps?: BaseEntityInlineInheritableProps;
  sx?: SxProps<Theme>;
}

export default function DataGrantEntityInline({ value, inlineProps, sx }: Props) {
  if (!value) {
    return null;
  }
  return (
    <GeneralStrictEntityRelationInline
      value={{ entityType: value.entityType, entityId: value.entityId }}
      inlineProps={inlineProps}
      sx={sx}
    />
  );
}
