import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout from "@/App/Layouts/ViewLayout";
import CultureAutocomplete from "@/common/components/Entity/ReferenceData/CultureAutocomplete";
import TimezoneAutocomplete from "@/common/components/Entity/ReferenceData/TimezoneAutocomplete";
import { I18nHelper } from "@/common/helpers/i18n";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useDefaultCulture } from "@/common/hooks/i18n/useDefaultCulture";
import { useDefaultTimeZone } from "@/common/hooks/i18n/useDefaultTimeZone";
import useMounted from "@/common/hooks/mount/useMounted";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { UserSettingsInputDto } from "@/core/api/generated";
import { LoadingButton } from "@mui/lab";
import { Box, FormControl, FormHelperText, Stack } from "@mui/material";
import { useFormik } from "formik";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { useHistory } from "react-router";

export default function ProfileSettingsEdit() {
  const mounted = useMounted();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const defaultTimeZone = useDefaultTimeZone();
  const defaultCulture = useDefaultCulture();
  const profileRequest = useApiRequest(apiClient.profileApi.apiV1ProfileGet, {
    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
  });
  const profile = useMemo(() => profileRequest.data, [profileRequest.data]);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    setStatus,
    setSubmitting,
    setFieldError,
    setFieldValue,
  } = useFormik<UserSettingsInputDto & BaseFormikValues>({
    enableReinitialize: true,
    initialValues: {
      tzId: profile?.settings?.tzId || "UTC",
      culture: profile?.settings?.culture || undefined,
      submit: "",
    },
    onSubmit: async () => {
      try {
        const response = await apiClient.profileApi.apiV1ProfileSettingsPut({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          userSettingsInputDto: {
            ..._.omit(values, ["submit"]),
          },
        });
        enqueueSnackbar("Settings updated.", { variant: "success" });
        I18nHelper.saveUserI18nSettingsLocally({
          cultureName: response.data.culture?.name,
          tzId: response.data.tzId,
        });
        history.goBack();

        if (mounted.current) {
          setStatus({ success: true });
          setSubmitting(false);
        }
      } catch (err: any) {
        if (mounted.current) {
          ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
          setStatus({ success: false });
          setSubmitting(false);
        }
      }
    },
  });

  return (
    <ViewLayout header={<SimpleViewPageHeader title={"Profile settings edit"} />}>
      <Stack sx={{ mt: 2 }} direction='column' spacing={1}>
        <Box>
          <FormControl margin='dense' fullWidth>
            <CultureAutocomplete
              required
              entity={values.culture}
              textFieldProps={{
                error: Boolean(errors.culture),
                helperText: ValidationHelper.getFormikErrorsAsString(errors.culture, {
                  isIncludeNested: true,
                }),
              }}
              onChange={(newValue) => setFieldValue("culture", newValue)}
            />
            {defaultCulture && (
              <FormHelperText>
                By default using {defaultCulture.displayName} | {defaultCulture.name}.
              </FormHelperText>
            )}
          </FormControl>

          <FormControl margin='dense' fullWidth>
            <TimezoneAutocomplete
              tzId={values.tzId}
              required
              textFieldProps={{
                error: Boolean(errors.tzId),
                helperText: ValidationHelper.getFormikErrorsAsString(errors.tzId),
              }}
              onChange={(newValue) => setFieldValue("tzId", newValue?.name)}
            />
            {defaultTimeZone && (
              <FormHelperText>By default using {defaultTimeZone.displayName}.</FormHelperText>
            )}
          </FormControl>
        </Box>

        <Stack direction='row' justifyContent='flex-end'>
          <LoadingButton
            type='submit'
            loading={isSubmitting}
            onClick={() => {
              handleSubmit();
            }}
            variant='contained'
            sx={{ minWidth: 100 }}
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    </ViewLayout>
  );
}
