import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogProps,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import _ from "lodash";
import { useSnackbar } from "notistack";
import * as Yup from "yup";

import { NegotiationHelper } from "@/common/helpers/entity/negotiation";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import { ValidationHelper } from "@/common/validation";
import { NegotiationProposalDto, UpdateNegotiationProposalDto } from "@/core/api/generated";
import * as negotiationsSlice from "@/store/communication/negotiationsSlice";

import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import { BaseFormikValues } from "@/common/ts/error";
import AppModalTitle from "../../../Modals/AppModalTitle";

export interface OwnProps {
  negotiationId: string;
  proposal?: NegotiationProposalDto;
  onSave?: () => void;
}

type Props = OwnProps & DialogProps;

export default function EditNegotiationProposalModal({
  negotiationId,
  proposal,
  onSave,
  ...dialogProps
}: Props) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const thunkDispatch = useAppThunkDispatch();

  const allowedActions = useAppSelector(
    (x) =>
      x.communication.negotiations.negotiationAllowedActionsMap[negotiationId || ""] || undefined,
  );
  const canEdit = NegotiationHelper.canEditProposal(proposal, allowedActions);

  return (
    <Box>
      <Dialog {...dialogProps}>
        <AppModalTitle
          onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
        >
          Proposal settings
        </AppModalTitle>
        <DialogContent>
          {!canEdit.value && canEdit.reason && (
            <FormHelperText sx={{ mb: 2 }}>{canEdit.reason}</FormHelperText>
          )}

          <Formik<UpdateNegotiationProposalDto & BaseFormikValues>
            enableReinitialize
            initialValues={{
              statement: proposal?.statement || "",
              settings: proposal?.settings || undefined,
              submit: "",
            }}
            validationSchema={Yup.object().shape({
              // name: Yup.string().required("Name is required"),
            })}
            onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
              try {
                await thunkDispatch(
                  negotiationsSlice.updateNegotiationProposal({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    negotiationId,
                    proposalId: proposal!.id!,
                    updateNegotiationProposalDto: {
                      ..._.omit(values, "submit"),
                    },
                  }),
                );
                enqueueSnackbar("Proposal updated.", { variant: "success" });

                if (mounted.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }
                onSave && onSave();
              } catch (err: any) {
                if (mounted.current) {
                  ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                  setStatus({ success: false });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setErrors,
              setFieldValue,
              setValues,
            }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <Box>
                    <TextField
                      disabled={!canEdit.value}
                      multiline
                      rows={2}
                      error={Boolean(touched.statement && errors.statement)}
                      fullWidth
                      helperText={touched.statement && errors.statement}
                      label='Statement'
                      margin='dense'
                      name='statement'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      value={values.statement}
                      variant='outlined'
                    />
                  </Box>

                  <Divider sx={{ my: 1 }} />

                  {/* Settings */}
                  <Box>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!canEdit.value}
                            checked={values.settings?.allowChangeProposalResponse || false}
                            onBlur={handleBlur}
                            onChange={(e) =>
                              setFieldValue(
                                "settings.allowChangeProposalResponse",
                                e.target.checked,
                              )
                            }
                          />
                        }
                        label='Allow change proposals response'
                      />
                    </FormGroup>
                    <FormHelperText>{`Whether it's allowed to change answer.`}</FormHelperText>

                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!canEdit.value}
                            checked={values.settings?.requireProposalConsensus || false}
                            onBlur={handleBlur}
                            onChange={(e) =>
                              setFieldValue("settings.requireProposalConsensus", e.target.checked)
                            }
                          />
                        }
                        label='Allow reopen negotiation'
                      />
                    </FormGroup>
                    <FormHelperText>
                      Everyone should give the same answer in order to resolve the proposal.
                    </FormHelperText>
                  </Box>

                  <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                  <Stack direction='row' spacing={1} sx={{ justifyContent: "flex-end", mt: 1 }}>
                    <Button
                      variant='text'
                      color='secondary'
                      onClick={(e) =>
                        dialogProps.onClose && dialogProps.onClose(e, "escapeKeyDown")
                      }
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      color='primary'
                      disabled={!proposal || !canEdit.value}
                      loading={isSubmitting}
                      type='submit'
                      variant='text'
                    >
                      Save
                    </LoadingButton>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
