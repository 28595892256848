import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import _ from "lodash";
import { useSnackbar } from "notistack";
import * as Yup from "yup";

import BlockCode from "@/common/components/Code/BlockCode";
import ApiEnumDtoMultiselect from "@/common/components/Enum/ApiEnumDtoMultiselect";
import ApiEnumDtoSelect from "@/common/components/Enum/ApiEnumDtoSelect";
import ApiEnumSelect from "@/common/components/Enum/ApiEnumSelect";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { clipboardService } from "@/common/services/clipboard";
import { BaseFormikValues } from "@/common/ts/error";
import {
  VehicleArea,
  VehicleType,
  VehicleVisualModelSvgPathMetadataDto,
  VehicleVisualModelSvgPathType,
} from "@/core/api/generated";

function getSvgPathMetadataJson(values: VehicleVisualModelSvgPathMetadataDto, space?: number) {
  const resultMetadata = {
    ..._.omit(values, ["submit"]),
  };
  return JSON.stringify(resultMetadata, null, space);
}

type Props = DialogProps;

function VisualModelMetadataHelperModal({ ...dialogProps }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps.onClose({}, "backdropClick")}
      >
        Vehicle visual model metadata helper
      </AppModalTitle>
      <DialogContent>
        <DialogContentText
          sx={{ mb: 1 }}
        >{`This util helps to build metadata quickly.`}</DialogContentText>
        <Box>
          <Typography component='div' fontWeight='bold'>
            Vehicle part metadata builder ({"SVG <path /> metadata"})
          </Typography>
          <Formik<BaseFormikValues & VehicleVisualModelSvgPathMetadataDto>
            initialValues={{
              pathType: VehicleVisualModelSvgPathType.VehiclePart,
              vehicleType: VehicleType.Car,
              vehicleArea: VehicleArea.Exterior,
              vehicleProjection: undefined,
              vehiclePartCategory: undefined,
              vehiclePartTypes: [],
              vehiclePartDescriptors: [],
              submit: "",
            }}
            validationSchema={Yup.object().shape({
              pathType: Yup.string().required("Required"),
              vehicleType: Yup.string().required("Required"),
              vehicleArea: Yup.string().required("Required"),
              vehicleProjection: Yup.string().required("Required"),
              vehiclePartCategory: Yup.string().required("Required"),
              // vehiclePartTypes: Yup.array().min(1).required("Required"),
              // vehiclePartDescriptors: Yup.array().min(1).required("Required"),
            })}
            onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
              try {
                await clipboardService.writeText(getSvgPathMetadataJson(values, 2));
                enqueueSnackbar("Copied to clipboard.", {
                  variant: "success",
                });
              } catch (error) {
                console.error("Clipboard copy error", error);
              }

              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setErrors,
              setFieldValue,
              setValues,
            }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <FormControl
                    sx={{ minWidth: 200 }}
                    fullWidth
                    margin='dense'
                    error={Boolean(errors.vehicleType)}
                  >
                    <InputLabel required>Vehicle type</InputLabel>
                    <ApiEnumSelect
                      type='VehicleType'
                      value={values.vehicleType}
                      onChange={(newValue) => setFieldValue("vehicleType", newValue)}
                      selectProps={{
                        required: true,
                        label: "Vehicle type",
                      }}
                    />
                    <FormHelperText>{touched.vehicleType && errors.vehicleType}</FormHelperText>
                  </FormControl>

                  <Grid container columnSpacing={2}>
                    <Grid item xxs={12} md={6}>
                      <FormControl
                        sx={{ minWidth: 200 }}
                        fullWidth
                        margin='dense'
                        error={Boolean(errors.vehicleArea)}
                      >
                        <InputLabel required>Area</InputLabel>
                        <ApiEnumSelect
                          type='VehicleArea'
                          value={values.vehicleArea}
                          onChange={(newValue) => {
                            setFieldValue("vehicleArea", newValue);
                            setFieldValue("vehicleProjection", undefined);
                          }}
                          selectProps={{
                            required: true,
                            label: "Area",
                          }}
                        />
                        <FormHelperText error={!!errors.vehicleArea}>
                          {errors.vehicleArea}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xxs={12} md={6}>
                      <FormControl
                        sx={{ minWidth: 200 }}
                        fullWidth
                        margin='dense'
                        error={Boolean(errors.vehicleProjection)}
                      >
                        <InputLabel required>Projection</InputLabel>
                        <ApiEnumDtoSelect
                          enumProps={{
                            enumType: "VehicleProjection",
                            area: values.vehicleArea,
                          }}
                          value={values.vehicleProjection}
                          onChange={(newValue, newValueDto) => {
                            setFieldValue("vehicleProjection", newValue);
                          }}
                          required
                          label='Projection'
                        />
                        <FormHelperText error={!!errors.vehicleProjection}>
                          {errors.vehicleProjection}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container columnSpacing={2}>
                    <Grid item xxs={12} md={6}>
                      <FormControl
                        sx={{ minWidth: 200 }}
                        fullWidth
                        margin='dense'
                        error={Boolean(errors.vehiclePartCategory)}
                      >
                        <InputLabel required>Vehicle part category</InputLabel>
                        <ApiEnumDtoSelect
                          enumProps={{
                            enumType: "VehiclePartCategory",
                            area: values.vehicleArea,
                            projection: values.vehicleProjection,
                          }}
                          value={values.vehiclePartCategory}
                          onChange={(newValue, newValueDto) => {
                            setFieldValue("vehiclePartCategory", newValue);
                            setFieldValue("vehiclePartTypes", []);
                          }}
                          required
                          label='Vehicle part category'
                        />
                        <FormHelperText error={!!errors.vehiclePartCategory}>
                          {errors.vehiclePartCategory}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xxs={12} md={6}>
                      <FormControl
                        sx={{ minWidth: 200 }}
                        fullWidth
                        margin='dense'
                        error={Boolean(errors.vehiclePartTypes)}
                      >
                        <InputLabel required>Vehicle part types</InputLabel>
                        <ApiEnumDtoMultiselect
                          enumProps={{
                            enumType: "VehiclePartType",
                            category: values.vehiclePartCategory,
                          }}
                          values={values.vehiclePartTypes}
                          onChange={(newValues, newValueDtos) => {
                            setFieldValue("vehiclePartTypes", newValues);
                          }}
                          required
                          label='Vehicle part types'
                        />
                        <FormHelperText error={!!errors.vehiclePartTypes}>
                          {errors.vehiclePartTypes}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xxs={12} md={6}>
                      <FormControl
                        sx={{ minWidth: 200 }}
                        fullWidth
                        margin='dense'
                        error={Boolean(errors.vehiclePartDescriptors)}
                      >
                        <InputLabel required>Vehicle part descriptors</InputLabel>
                        <ApiEnumDtoMultiselect
                          enumProps={{
                            enumType: "VehiclePartDescriptor",
                            area: values.vehicleArea,
                            projection: values.vehicleProjection,
                            category: values.vehiclePartCategory,
                            types: values.vehiclePartTypes,
                          }}
                          values={values.vehiclePartDescriptors}
                          onChange={(newValues, newValueDtos) => {
                            setFieldValue("vehiclePartDescriptors", newValues);
                          }}
                          label='Vehicle part descriptors'
                        />
                        <FormHelperText error={!!errors.vehiclePartDescriptors}>
                          {errors.vehiclePartDescriptors}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                  <Box>
                    <Typography component='div'>Result:</Typography>
                    <BlockCode sx={{ fontSize: "small" }}>
                      {getSvgPathMetadataJson(values, 2)}
                    </BlockCode>
                    <Button sx={{ mt: 1 }} fullWidth type='submit' variant='outlined'>
                      Copy
                      <ContentCopyIcon sx={{ ml: 1 }} fontSize='inherit' />
                    </Button>
                  </Box>
                </form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={close}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

export default VisualModelMetadataHelperModal;
