import { LoadingButton } from "@mui/lab";
import { FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  EntityType,
  IntegrationApiClientCreateDto,
  IntegrationApiClientDto,
  IntegrationApiClientUpdateDto,
} from "@/core/api/generated";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import GeneralValidationError from "../../Error/GeneralValidationError";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  name?: IntegrationApiClientCreateDto["name"];
};

export interface IntegrationApiClientCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<IntegrationApiClientDto, DefaultValues> {
  integrationApiClientId?: string;
}

export type IntegrationApiClientCreateUpdateProps = IntegrationApiClientCreateUpdateOwnProps;

export default function IntegrationApiClientCreateUpdate({
  integrationApiClientId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: IntegrationApiClientCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const isCreate = !integrationApiClientId;

  const integrationApiClientRequest = useApiRequest(
    apiClient.integrationApiClientsApi.apiV1IntegrationApiClientsIntegrationApiClientIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      integrationApiClientId: integrationApiClientId!,
    },
    {
      skip: !integrationApiClientId,
    },
  );
  const integrationApiClient = integrationApiClientRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: integrationApiClient && {
      idValue: integrationApiClient.id!,
      newTitle: integrationApiClient.localNumber || "",
    },
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.IntegrationApiClient}
      entityId={integrationApiClientId}
      entity={integrationApiClient}
      entityRequest={integrationApiClientRequest}
    >
      <Formik<IntegrationApiClientCreateDto & IntegrationApiClientUpdateDto & BaseFormikValues>
        enableReinitialize={!isCreate}
        initialValues={{
          name: defaultValues?.name || integrationApiClient?.name || undefined,
          description: integrationApiClient?.description || undefined,
          expiresAt: integrationApiClient?.expiresAt || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response =
                await apiClient.integrationApiClientsApi.apiV1IntegrationApiClientsPost({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  integrationApiClientCreateDto: {
                    ...values,
                    expiresAt: values.expiresAt
                      ? moment(values.expiresAt).utc().format()
                      : undefined,
                  },
                });
              enqueueSnackbar("API client created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response =
                await apiClient.integrationApiClientsApi.apiV1IntegrationApiClientsIntegrationApiClientIdPut(
                  {
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    integrationApiClientId,
                    integrationApiClientUpdateDto: {
                      ...values,
                      expiresAt: values.expiresAt
                        ? moment(values.expiresAt).utc().format()
                        : undefined,
                    },
                  },
                );
              enqueueSnackbar("API client updated.", { variant: "success" });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Box>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    required
                    fullWidth
                    helperText={touched.name && errors.name}
                    label='Name'
                    margin='dense'
                    name='name'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                    value={values.name || ""}
                    variant='outlined'
                  />

                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    multiline
                    minRows={2}
                    helperText={touched.description && errors.description}
                    label='Description'
                    margin='dense'
                    name='description'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                    value={values.description || ""}
                    variant='outlined'
                  />

                  <FormControl margin='dense' fullWidth>
                    <MobileDateTimePicker
                      ampm={false}
                      label='Expires at'
                      value={(values.expiresAt && moment(values.expiresAt)) || null}
                      format={DATETIME_FORMATS.DISPLAY_DATETIME}
                      onChange={(newValue: Moment | null) => {
                        setFieldValue("expiresAt", newValue?.format() || null);
                      }}
                      slots={{ textField: (params) => <TextField {...params} fullWidth /> }}
                    />
                    {errors.expiresAt && <FormHelperText error>{errors.expiresAt}</FormHelperText>}
                  </FormControl>
                </Box>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <LoadingButton
                  sx={{ mt: { xs: "auto", md: 2 } }}
                  color='primary'
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </LoadingButton>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
