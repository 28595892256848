import { combineReducers, createSlice } from "@reduxjs/toolkit";
import { History } from "history";

import { adminReducer } from "./admin/index";
import { appCommonReducer } from "./appCommon/slice";
import { authReducer } from "./auth/slice";
import { communicationReducer } from "./communication/index";
import { enumsReducer } from "./enums/slice";
import { managementReducer } from "./management";
import { partiesReducer } from "./parties/slice";
import { profileReducer } from "./profile/slice";
import { repairReducer } from "./repair/index";
import { tenantProfileReducer } from "./tenantProfile/slice";
import { tenantsReducer } from "./tenants/slice";
import { filesReducer } from "./files/slice";
import { tenantToTenantReducer } from "./tenantToTenant/tenantToTenantSlice";
import { operationsReducer } from "./operation/operationsSlice";

const createRootReducer = (history: History) =>
  combineReducers({
    app: appCommonReducer,
    auth: authReducer,
    profile: profileReducer,
    tenantProfile: tenantProfileReducer,
    tenants: tenantsReducer,
    enums: enumsReducer,
    communication: communicationReducer,
    files: filesReducer,
    management: managementReducer,
    repair: repairReducer,
    parties: partiesReducer,
    tenantToTenant: tenantToTenantReducer,
    operations: operationsReducer,

    admin: adminReducer,
  });

export default createRootReducer;
