import { Button, DialogContentText, IconButton, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModalWithTrigger from "@/common/components/Modals/ConfirmationModalWithTrigger";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { apiClient } from "@/core/api/ApiClient";
import { AdminNotificationGetPaginatedDto, AppPermission, EntityType } from "@/core/api/generated";

export default function NotificationsPage() {
  const { enqueueSnackbar } = useSnackbar();
  const commonRequestParams = useCommonRequestParams<AdminNotificationGetPaginatedDto>({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityInAdminArea(EntityType.Notification),
    },
  });

  const paginatedNotificationsRequest = useApiRequest(
    apiClient.adminNotificationsApi.apiV1AdminNotificationsGetPost,
    {
      adminNotificationGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedNotifications = paginatedNotificationsRequest?.data;

  return (
    <ListPageLayout>
      <SimpleViewPageHeader
        title={undefined}
        primaryActions={
          <AuthorizedElement permissions={[AppPermission.AdminNotificationsManage]}>
            <Button
              component={RouterLink}
              to={ROUTE_PATH.ADMIN_NOTIFICATION_CREATE}
              variant='contained'
            >
              Send notification manually
            </Button>
          </AuthorizedElement>
        }
      />

      <DataTabular
        columns={[
          {
            field: "importance",
            title: "Importance",
            flex: 1,
            renderCell: (item) => item.importance,
          },
          {
            field: "channel",
            title: "Channel",
            flex: 1,
            renderCell: (item) => (
              <InlineApiEnumValue type='NotificationChannel' value={item.channel} />
            ),
          },
          {
            field: "deliveryStatus",
            title: "Delivery Status",
            flex: 1,
            renderCell: (item) => (
              <InlineApiEnumValue type='NotificationDeliveryStatus' value={item.deliveryStatus} />
            ),
          },
          {
            field: "createdAt",
            title: "Created At",
            flex: 1,
            renderCell: (item) => <Datetime datetime={item.createdAt} />,
          },
        ]}
        rows={paginatedNotifications?.items}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.ADMIN_NOTIFICATIONS_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <>
            <MenuWithTrigger
              trigger={
                <IconButton>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              {({ handleClose }) => [
                <ConfirmationModalWithTrigger
                  key='delete'
                  trigger={<MenuItem>Resend</MenuItem>}
                  title='Resend notification?'
                  body={({ error }) => {
                    return (
                      <DialogContentText>
                        {`Do you want to resend the notification?`}
                      </DialogContentText>
                    );
                  }}
                  onConfirm={async () => {
                    await apiClient.adminNotificationsApi.apiV1AdminNotificationsNotificationIdResendPost(
                      { notificationId: item.id! },
                    );
                    handleClose();
                    enqueueSnackbar("The notification has been resent.", { variant: "success" });
                  }}
                />,
              ]}
            </MenuWithTrigger>
          </>
        )}
        isLoading={paginatedNotificationsRequest.isLoading}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
      />
    </ListPageLayout>
  );
}
