import { DialogProps } from "@mui/material";

import {
  AssetCandidateEntityInputDto,
  AssetSpecDto,
  AssetSpecInputDto,
} from "@/core/api/generated";
import { AssetCreateUpdateProps } from "./AssetCreateUpdate";
import AssetCreateUpdateModal from "./AssetCreateUpdateModal";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

export interface OwnProps {
  entity: Required<AssetCandidateEntityInputDto>;
  spec?: AssetSpecDto | AssetSpecInputDto | null;
  createUpdateProps: AssetCreateUpdateProps;
}

type Props = OwnProps & DialogProps;

export default function AssetCreateUpdateFromEntityModal({
  entity,
  spec,
  createUpdateProps,
  ...dialogProps
}: Props) {
  const assetRequest = useApiRequest(
    apiClient.assetsApi.apiV1AssetsByEntityGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      entityType: entity.entityType!,
      entityId: entity.entityId!,
    },
    {},
  );
  const asset = assetRequest?.data;

  return (
    <AssetCreateUpdateModal
      createUpdateProps={{
        ...createUpdateProps,
        entityId: asset?.id || createUpdateProps?.entityId,
        defaultValues: {
          entity: entity,
          spec: spec ? { ...spec, entityType: entity.entityType } : undefined,
          ...createUpdateProps?.defaultValues,
        },
      }}
      isLoading={assetRequest.isLoading}
      {...dialogProps}
    />
  );
}
