import { Box, useTheme } from "@mui/material";
import { ReactNode } from "react";
import Nav from "../BaseApp/Nav/Nav";
import Header from "../BaseApp/Header/Header";
import { useAppSelector } from "@/common/hooks/redux";
import { useNavDisplay } from "@/common/contexts/useNavDisplay";

interface MainAppViewProps {
  children: ReactNode;
}

export default function BaseAppLayout({ children }: MainAppViewProps) {
  const theme = useTheme();
  const isNavOpened = useAppSelector((x) => x.app.isNavOpened);
  const { isControlled, navDisplayWidth } = useNavDisplay();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        flex: 1,
        display: "flex",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <Header />

        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "flex-start",
            overflowY: "hidden",
            overflowX: "hidden",
          }}
        >
          {isNavOpened && <Nav />}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "100%",
              width: "100%",
              overflowY: "auto",
              overflowX: "auto",
              flex: 1,
              minWidth: 0,
              ml: isControlled ? 0 : navDisplayWidth.collapsed,
            }}
          >
            <Box sx={{ width: "100%" }}>{children}</Box>
          </Box>
          <Box>{/* <ChatStackContainer /> */}</Box>
        </Box>
      </Box>
    </Box>
  );
}
