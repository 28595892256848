import { Box, Chip, Stack } from "@mui/material";

import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppAvatar from "@/common/components/Avatar/AppAvatar";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import InlineRoleDisplay from "@/common/components/Entity/Role/InlineRoleDisplay";
import UserMenu from "@/common/components/Entity/User/UserMenu";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppPopover from "@/common/components/Popover/AppPopover";
import AppPopoverContent from "@/common/components/Popover/AppPopoverContent";
import AppTypography from "@/common/components/Text/AppTypography";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { apiClient } from "@/core/api/ApiClient";
import { AdminUserGetPaginatedDto, EntityType } from "@/core/api/generated";
import TenantInline from "../components/Entity/Tenant/TenantInline";

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
};
interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function UsersPage({ displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const profile = useUserProfile();
  const commonRequestParams = useCommonRequestParams<AdminUserGetPaginatedDto>({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityInAdminArea(EntityType.User),
    },
  });

  const paginatedUsersRequest = useApiRequest(
    apiClient.adminUsersApi.apiV1AdminUsersGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      adminUserGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const { data: paginatedUsers } = paginatedUsersRequest;

  return (
    <Box>
      <ViewLayout displayProps={displayProps} header={undefined}>
        <DataTabular
          columns={[
            {
              field: "personName.name",
              title: "Name",
              flex: 1,
              renderCell: (item) => (
                <Stack
                  direction='row'
                  spacing={1}
                  sx={{ alignItems: "center", overflow: "hidden" }}
                >
                  <AppAvatar user={item} size='small' />
                  <Stack sx={{ minWidth: 0 }}>
                    <AppTypography ellipsing={{ enabled: true }}>
                      {item.personName?.name}{" "}
                      {profile?.id === item.id && (
                        <Chip
                          size='small'
                          color='primary'
                          variant='filled'
                          label='You'
                          sx={{ ml: 1 }}
                        />
                      )}
                    </AppTypography>
                    <AppTypography ellipsing={{ enabled: true }} component='div' variant='body2'>
                      {item.email}
                    </AppTypography>
                  </Stack>
                </Stack>
              ),
            },
            {
              field: "status",
              title: "Status",
              flex: 1,
              renderCell: (item) => <InlineApiEnumValue type='UserStatus' value={item.status} />,
            },
            {
              field: "phoneNumber",
              title: "Phone",
              flex: 1,
              renderCell: (item) => item.phoneNumber,
            },
            {
              field: "roles",
              title: "Roles",
              flex: 1,
              renderCell: (item) => (
                <AppPopover
                  hoverBehavior={{}}
                  trigger={
                    <Chip
                      variant='outlined'
                      size='medium'
                      color='secondary'
                      label={item.roles?.length ?? 0}
                    />
                  }
                >
                  <AppPopoverContent>
                    <Stack spacing={0.5}>
                      {item.roles?.map((x) => (
                        <InlineRoleDisplay key={x.role!.id!} role={x.role} />
                      ))}
                    </Stack>
                  </AppPopoverContent>
                </AppPopover>
              ),
            },
            {
              field: "companies",
              title: "Tenants",
              flex: 1,
              renderCell: (item) => (
                <AppPopover
                  hoverBehavior={{}}
                  trigger={
                    <Chip
                      variant='outlined'
                      size='medium'
                      color='secondary'
                      label={item.companies?.length ?? 0}
                    />
                  }
                >
                  <AppPopoverContent>
                    <Stack spacing={0.5}>
                      {item.companies?.map((x, i) => (
                        <TenantInline key={i} entity={undefined} entityId={x.id} />
                      ))}
                    </Stack>
                  </AppPopoverContent>
                </AppPopover>
              ),
            },
          ]}
          rows={paginatedUsers?.items}
          getRowId={(item) => item.id!}
          rowTo={(item) => ROUTE_PATH.ADMIN_USER_VIEW(item.id)}
          renderRowAction={({ item }) => (
            <UserMenu
              entity={item}
              onUpdate={() => paginatedUsersRequest.refetch()}
              displayProps={{
                actions: { edit: true, editRole: true, status: true, delete: false },
              }}
            />
          )}
          isLoading={paginatedUsersRequest.isLoading}
          statePersistence={commonRequestParams.dataTabularProps.statePersistence}
          pagination={commonRequestParams.dataTabularProps.pagination}
          sort={commonRequestParams.dataTabularProps.sort}
          quickFilter={commonRequestParams.dataTabularProps.quickFilter}
          filters={commonRequestParams.dataTabularProps.filters}
        />
      </ViewLayout>
    </Box>
  );
}
