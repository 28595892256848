import { Alert, AlertProps, AlertTitle, Box, Button, Stack, Typography } from "@mui/material";
import { GridCheckIcon } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { useState } from "react";

import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import ExpiredAlert from "@/common/components/AppAlerts/ExpiredAlert";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import NoFilesAlert from "@/common/components/AppAlerts/NoFilesAlert";
import InlineCode from "@/common/components/Code/InlineCode";
import ConnectionStatusIndicator from "@/common/components/Connection/ConnectionStatusIndicator";
import { MiscHelper } from "@/common/helpers/misc";
import AppIcon from "@/common/components/Icons/AppIcon";

export default function DevAlerts() {
  const { enqueueSnackbar } = useSnackbar();

  const [isConfirmation1ModalOpen, setIsConfirmation1ModalOpen] = useState(false);

  const successfulActionWithDelay = async () => {
    await MiscHelper.wait(2000);
    enqueueSnackbar("Action completed.", { variant: "success" });
    setIsConfirmation1ModalOpen(false);
  };

  return (
    <Stack spacing={2}>
      <Typography component='div' variant='h2'>
        Alerts
      </Typography>

      <Stack spacing={2}>
        <Typography component='div' variant='h5'>
          <InlineCode>MUI Alerts</InlineCode>
        </Typography>

        <Stack direction='column' spacing={2}>
          <Typography component='div' variant='h6'>
            Variant
          </Typography>
          <Stack direction='row' spacing={2} alignItems='center'>
            {(["standard", "filled", "outlined"] as Array<AlertProps["variant"]>).map(
              (variant, i) => (
                <Stack key={i} spacing={2}>
                  <Alert variant={variant}>{variant}. Lorem ipsum dolor.</Alert>

                  <Alert variant={variant}>
                    <AlertTitle>{variant}. Lorem ipsum dolor.</AlertTitle>
                    {variant}. Lorem ipsum dolor.
                  </Alert>
                </Stack>
              ),
            )}
          </Stack>
        </Stack>

        <Stack spacing={2}>
          <Typography component='div' variant='h6'>
            Severity
          </Typography>
          <Stack direction='row' spacing={2} alignItems='center'>
            {(["success", "info", "warning", "error"] as Array<AlertProps["severity"]>).map(
              (severity, i) => (
                <Stack key={i} spacing={2}>
                  <Alert severity={severity}>{severity}. Lorem ipsum dolor.</Alert>

                  <Alert severity={severity}>
                    <AlertTitle>{severity}. Lorem ipsum dolor.</AlertTitle>
                    {severity}. Lorem ipsum dolor.
                  </Alert>
                </Stack>
              ),
            )}
          </Stack>
        </Stack>

        <Stack spacing={1}>
          <Typography component='div' variant='h6'>
            Actions
          </Typography>

          <Stack spacing={1}>
            <Alert severity='warning' onClose={() => {}}>
              This Alert displays the default close icon.
            </Alert>
            <Alert
              severity='success'
              action={
                <Button color='inherit' size='small'>
                  UNDO
                </Button>
              }
            >
              This Alert uses a Button component for its action.
            </Alert>
          </Stack>
        </Stack>

        <Stack spacing={1}>
          <Typography component='div' variant='h6'>
            Icons
          </Typography>

          <Stack spacing={1}>
            <Alert icon={<AppIcon of='check' fontSize='inherit' />} severity='success'>
              This success Alert has a custom icon.
            </Alert>
            <Alert icon={false} severity='success'>
              This success Alert has no icon.
            </Alert>
            <Alert
              iconMapping={{
                success: <AppIcon of='forceApprove' fontSize='inherit' />,
              }}
            >
              This success Alert uses `iconMapping` to override the default icon.
            </Alert>
          </Stack>
        </Stack>

        <Stack spacing={1}>
          <Typography component='div' variant='h6'>
            Multiline
          </Typography>

          <Stack spacing={1}>
            <Alert>
              <Box>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque voluptate
                repellendus similique soluta laboriosam sequi harum doloremque commodi facilis
                cupiditate fugit perferendis doloribus quisquam nulla rem, in ut est hic.
              </Box>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. In harum, deleniti quod eum
              exercitationem dignissimos quasi unde odio cum sapiente optio deserunt ab quo facere
              eos quia enim, alias quos.
            </Alert>

            <Alert>
              <AlertTitle>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam officia veritatis
                impedit quae sapiente deserunt, ex, maiores soluta dignissimos molestiae nihil amet
                enim commodi eos illum hic nam vel rerum?
              </AlertTitle>
              <Box>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque voluptate
                repellendus similique soluta laboriosam sequi harum doloremque commodi facilis
                cupiditate fugit perferendis doloribus quisquam nulla rem, in ut est hic.
              </Box>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque voluptate repellendus
              similique soluta laboriosam sequi harum doloremque commodi facilis cupiditate fugit
              perferendis doloribus quisquam nulla rem, in ut est hic.
            </Alert>
          </Stack>
        </Stack>
      </Stack>

      <Typography component='div' variant='h5'>
        <InlineCode>EntityNotFoundAlert</InlineCode>
      </Typography>
      <Box>
        <EntityNotFoundAlert />
      </Box>

      <Typography component='div' variant='h5'>
        <InlineCode>NoDataAlert</InlineCode>
      </Typography>
      <Box>
        <NoDataAlert />
      </Box>

      <Typography component='div' variant='h5'>
        <InlineCode>NoFilesAlert</InlineCode>
      </Typography>
      <Box>
        <NoFilesAlert />
      </Box>

      <Typography component='div' variant='h5'>
        <InlineCode>ExpiredAlert</InlineCode>
      </Typography>
      <Box>
        <ExpiredAlert />
      </Box>
      <Box>
        <Typography variant='h5'>
          <InlineCode>ApiConnectionStatusIndicator variant = alert</InlineCode>
        </Typography>
        <Box>
          <Typography variant='h6'>connected = false</Typography>
          <ConnectionStatusIndicator alwaysVisible={true} connected={false} variant='alert' />
        </Box>
        <Box>
          <Typography variant='h6'>connected = true</Typography>
          <ConnectionStatusIndicator alwaysVisible={true} connected={true} variant='alert' />
        </Box>
      </Box>
    </Stack>
  );
}
