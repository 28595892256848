import { Button, Divider, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import GeneralByWhoDisplay from "@/common/components/Entity/General/Display/GeneralByWhoDisplay";
import InlineRoleDisplay from "@/common/components/Entity/Role/InlineRoleDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { apiClient } from "@/core/api/ApiClient";
import { AdminCustomSessionGetPaginatedDto, EntityType } from "@/core/api/generated";

export default function CustomSessionsPage() {
  const commonRequestParams = useCommonRequestParams<AdminCustomSessionGetPaginatedDto>({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityInAdminArea(EntityType.CustomSession),
    },
  });

  const paginatedCustomSessionsRequest = useApiRequest(
    apiClient.adminCustomSessionsApi.apiV1AdminCustomSessionsGetPost,
    {
      adminCustomSessionGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedCustomSessions = paginatedCustomSessionsRequest?.data;

  return (
    <ListPageLayout>
      <SimpleViewPageHeader
        title={undefined}
        primaryActions={
          <Button
            variant='contained'
            color='primary'
            startIcon={<AppIcon of='add' />}
            component={RouterLink}
            to={ROUTE_PATH.ADMIN_CUSTOM_SESSION_CREATE}
          >
            Create new session
          </Button>
        }
      />

      <Divider sx={{ my: 2 }} />

      <Stack direction='column' spacing={1}>
        <DataTabular
          columns={[
            // {
            //   field: "id",
            //   title: "Id & Identifier",
            //   xxs: 12,
            //   md: 2,
            //   renderCell: (item) => (
            //     <Stack direction='column' alignItems='flex-start' spacing={1}>
            //       <InlineCode>{item.id}</InlineCode>
            //       <InlineCode>{item.identifier}</InlineCode>
            //     </Stack>
            //   ),
            // },
            {
              field: "membershipType",
              title: "Membership type",
              flex: 1,
              renderCell: (item) => (
                <InlineApiEnumValue type='UserMembershipType' value={item.membershipType} />
              ),
            },
            {
              field: "issuedBy",
              title: "Issued by",
              flex: 1,
              renderCell: (item) => <GeneralByWhoDisplay who={item.issuedBy} />,
            },
            {
              field: "IssuedAt",
              title: "Issued at",
              flex: 1,
              renderCell: (item) => (
                <Datetime direction='column' datetime={item.issuedAt} withDurationFromNow />
              ),
            },
            {
              field: "expiresAt",
              title: "Expires at",
              flex: 1,
              renderCell: (item) => (
                <Datetime direction='column' datetime={item.expiresAt} withDurationFromNow />
              ),
            },
            {
              field: "roles",
              title: "Roles",
              flex: 1,
              renderCell: (item) => (
                <>{item.roles?.map((x) => <InlineRoleDisplay key={x.id!} role={x} />)}</>
              ),
            },
          ]}
          rows={paginatedCustomSessions?.items}
          isLoading={paginatedCustomSessionsRequest.isLoading}
          getRowId={(item) => item.id!}
          rowTo={(item) => ROUTE_PATH.ADMIN_CUSTOM_SESSION_VIEW(item.id)}
          statePersistence={commonRequestParams.dataTabularProps.statePersistence}
          pagination={commonRequestParams.dataTabularProps.pagination}
          sort={commonRequestParams.dataTabularProps.sort}
          quickFilter={commonRequestParams.dataTabularProps.quickFilter}
          filters={commonRequestParams.dataTabularProps.filters}
        />
      </Stack>
    </ListPageLayout>
  );
}
