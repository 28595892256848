import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import { ReactNode, useState } from "react";

import Datetime from "@/common/components/Datetime/Datetime";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { ROUTE_PATH } from "@/common/constants/routing";
import { NegotiationHelper } from "@/common/helpers/entity/negotiation";
import { useNegotiationAllowedActions } from "@/common/hooks/entity/negotiation/useNegotiationAllowedActions";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  NegotiationDto,
  NegotiationProposalDto,
  NegotiationType,
  TagEntityType,
} from "@/core/api/generated";

import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import { useHistory } from "react-router";
import AuthorizedElement from "../../Auth/AuthorizedElement";
import DataTabular from "../../DataTabular/DataTabular";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import BooleanValue from "../../Form/Display/BooleanValue";
import GeneralScopeLink from "../../Link/GeneralScopeLink";
import MenuWithTrigger from "../../Menu/MenuWithTrigger";
import GeneralScopeDisplayModal from "../General/Display/GeneralScopeDisplayModal";
import GeneralAttachedTagsDisplay from "../General/GeneralTag/GeneralAttachedTagsDisplay";
import PartyInfoModalWithTrigger from "../Party/PartyInfoModalWithTrigger";
import PartyInline from "../Party/PartyInline";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../components/BaseEntityView";
import NegotiationMenu from "./NegotiationMenu";
import NegotiationProposalDetailsModal from "./NegotiationProposal/NegotiationProposalDetailsModal";
import NegotiationProposalValueInline from "./NegotiationProposal/NegotiationProposalValueInline";

const defaultHeaderProps = {
  withLink: false,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  actions: true,
  source: true,
  proposals: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<NegotiationDto> {
  negotiationId?: string | null;
  negotiation?: NegotiationDto | null;
  isLoading?: boolean;
  withRealtimeDataUpdates?: boolean;
  headerProps?: Partial<typeof defaultHeaderProps> & {
    title?: ReactNode;
    titleTypographyProps?: TypographyProps;
  };
  displayProps?: Partial<typeof defaultDisplayProps>;
  onDelete?: () => void;
}

type Props = OwnProps;

export default function NegotiationView({
  negotiationId,
  negotiation,
  isLoading,
  withRealtimeDataUpdates,
  headerProps,
  displayProps = defaultDisplayProps,
  onDelete,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();

  const request = useApiRequest(
    apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      negotiationId: negotiationId!,
    },
    {
      deps: [negotiationId],
      skip: !negotiationId,
    },
  );
  negotiation = request?.data || negotiation;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.Negotiation, negotiationId || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      request.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        negotiation && {
          idValue: negotiation?.id || "",
          newTitle: negotiation?.localNumber || "",
        }) ||
      undefined,
  });

  const allowedActions = useNegotiationAllowedActions(negotiation?.id);

  const canCreateProposal = NegotiationHelper.canCreateProposal(negotiation, allowedActions);

  // menu, dialogs
  const [isProposalModalOpen, setIsProposalModalOpen] = useState(false);
  const [proposalToView, setProposalToView] = useState<NegotiationProposalDto | undefined>(
    undefined,
  );
  const [isViewScopeModalOpen, setIsViewScopeModalOpen] = useState(false);

  return (
    <BaseEntityView
      entityType={EntityType.Negotiation}
      entityId={negotiationId}
      entity={negotiation}
      entityRequest={request}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          <Stack direction='row' spacing={1} sx={{ alignItems: "flex-start" }}>
            <Box>
              {headerProps?.title || (
                <Typography component='div' variant='h1' {...headerProps?.titleTypographyProps}>
                  Negotiation{" "}
                  <AppLink
                    enabled={headerProps?.withLink}
                    to={ROUTE_PATH.NEGOTIATION_VIEW(negotiation?.id)}
                  >
                    <span>{negotiation?.localNumber}</span>
                  </AppLink>
                </Typography>
              )}
            </Box>

            {displayProps?.actions && (
              <NegotiationMenu
                onDelete={() => history.goBack()}
                onUpdate={(newValue) =>
                  newValue ? request.replaceData(newValue) : request.refetch()
                }
                entity={negotiation}
              />
            )}
          </Stack>
        }
      >
        <Stack direction='column' spacing={2}>
          <Grid container columnSpacing={2} rowSpacing={1}>
            <Grid item xxs={12} md minWidth={200}>
              <Stack direction='column' spacing={1}>
                <FieldValue label='Name'>{negotiation?.name}</FieldValue>
                <FieldValue label='Description'>{negotiation?.description}</FieldValue>
                <FieldValue label='Type'>
                  <InlineApiEnumValue
                    type='NegotiationType'
                    value={negotiation?.type}
                    withHelperTooltip
                  />
                </FieldValue>
                {negotiation?.type === NegotiationType.Value && (
                  <FieldValue label='Value type'>
                    <InlineApiEnumValue
                      type='NegotiationValueType'
                      value={negotiation?.valueType}
                      withHelperTooltip
                    />
                  </FieldValue>
                )}
                <FieldValue label='Status'>
                  <InlineApiEnumValue
                    type='NegotiationStatus'
                    value={negotiation?.status}
                    withHelperTooltip
                  />
                </FieldValue>
              </Stack>
            </Grid>
            <Grid item xxs={12} md minWidth={200}>
              <Stack direction='column' spacing={1}>
                <FieldValue
                  label='Moderator parties'
                  helperTooltip={`Parties that can moderate the negotiation, but don't take part in it.`}
                >
                  <Stack direction='row' spacing={1}>
                    {negotiation?.moderatorParties!.map((x) => (
                      <PartyInfoModalWithTrigger
                        key={x.id!}
                        party={x}
                        trigger={<PartyInline entity={x} />}
                      />
                    ))}
                  </Stack>
                </FieldValue>
                <FieldValue
                  label='Initiator party'
                  helperTooltip='Party that initiated the negotiation'
                >
                  <PartyInfoModalWithTrigger
                    party={negotiation?.initiatorParty}
                    trigger={<PartyInline entity={negotiation?.initiatorParty} />}
                  />
                </FieldValue>
                <FieldValue label='Target party' helperTooltip='Respondent party'>
                  <PartyInfoModalWithTrigger
                    party={negotiation?.targetParty}
                    trigger={<PartyInline entity={negotiation?.targetParty} />}
                  />
                </FieldValue>
                <FieldValue
                  label='Scope'
                  helperTooltip='Scope of the negotiation - is an entity to which this negotiation relates. Results of the negotiation can be applied to the scope.'
                >
                  <GeneralScopeLink
                    scope={negotiation?.scope}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsViewScopeModalOpen(true);
                    }}
                  />
                </FieldValue>

                <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                  <FieldValue label='Tags'>
                    {negotiation?.tags && (
                      <GeneralAttachedTagsDisplay
                        tags={negotiation?.tags}
                        defaultIsFolded={false}
                        edit={{
                          entityType: TagEntityType.Negotiation,
                          entityId: negotiation.id,
                          onSaved: (newValue) => {
                            request?.updateData((x) => {
                              x.tags = newValue || undefined;
                            });
                          },
                        }}
                      />
                    )}
                  </FieldValue>
                </AuthorizedElement>
              </Stack>
            </Grid>
          </Grid>

          {/* Proposals */}
          {displayProps?.proposals && (
            <Stack spacing={1}>
              <Typography component='div' variant='subtitle1'>
                Proposals
              </Typography>{" "}
              <DataTabular
                // headerProps={{
                //   variant: "bordered",
                // }}
                // contentProps={{
                //   itemVariant: "bordered",
                // }}
                columns={[
                  {
                    field: "party",
                    title: "Party",
                    flex: 1,
                    renderCell: (item) => <PartyInline entity={item.party} />,
                  },
                  {
                    field: "statement",
                    title: "Statement",
                    flex: 1,
                    renderCell: (item) => item.statement,
                  },
                  {
                    field: "value",
                    title: "Value",
                    flex: 1,
                    renderCell: (item) => (
                      <>
                        {negotiation?.type === NegotiationType.Value ? (
                          <Stack>
                            <Typography component='span' variant='body2' color='text.secondary'>
                              <InlineApiEnumValue
                                type='NegotiationValueType'
                                value={negotiation?.valueType}
                              />
                              :{" "}
                            </Typography>
                            <NegotiationProposalValueInline
                              negotiation={negotiation}
                              proposal={item}
                            />
                          </Stack>
                        ) : (
                          "-"
                        )}
                      </>
                    ),
                  },
                  {
                    field: "status",
                    title: "Status",
                    flex: 1,
                    renderCell: (item) => (
                      <InlineApiEnumValue type='ProposalStatus' value={item.status} />
                    ),
                  },
                  {
                    field: "consensusType",
                    title: "Consensus / Approval",
                    flex: 1,
                    renderCell: (item) => (
                      <>
                        <InlineApiEnumValue type='ConsensusType' value={item.consensusType} /> /{" "}
                        <InlineApiEnumValue type='ApprovalStatus' value={item.approvalStatus} />
                      </>
                    ),
                  },
                  {
                    field: "createdAt",
                    title: "Created at",
                    flex: 1,
                    renderCell: (item) => <Datetime datetime={item.createdAt} />,
                  },
                ]}
                rows={negotiation?.proposals}
                getRowId={(item) => item.id!}
                renderRowAction={(actionParams) => (
                  <MenuWithTrigger
                    withAuthCloseOnClick
                    trigger={
                      <IconButton>
                        <AppIcon of='moreVert' />
                      </IconButton>
                    }
                  >
                    <MenuItem
                      dense
                      onClick={() => {
                        setProposalToView(actionParams.item);
                        setIsProposalModalOpen(true);
                      }}
                    >
                      <ListItemIcon>
                        <AppIcon of='view' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>View details</ListItemText>
                    </MenuItem>
                  </MenuWithTrigger>
                )}
              />
            </Stack>
          )}

          <Stack spacing={1}>
            <Typography component='div' variant='subtitle1'>
              Result
            </Typography>
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Grid item xxs={12} md minWidth={200}>
                <Stack direction='column' spacing={1}>
                  <FieldValue label='Result type'>
                    <InlineApiEnumValue
                      type='ApprovalStatus'
                      value={negotiation?.result?.approvalStatus}
                    />
                  </FieldValue>
                </Stack>
              </Grid>
              <Grid item xxs={12} md minWidth={200}>
                <Stack direction='column' spacing={1}>
                  <FieldValue label='Is auto'>
                    <BooleanValue value={negotiation?.result?.isAuto} />
                  </FieldValue>
                  <FieldValue label='Is forced'>
                    <BooleanValue value={negotiation?.result?.isForced} />
                  </FieldValue>
                </Stack>
              </Grid>
            </Grid>
          </Stack>

          {/* Proposal details */}
          {negotiation && allowedActions && proposalToView && (
            <NegotiationProposalDetailsModal
              open={isProposalModalOpen}
              onClose={() => setIsProposalModalOpen(false)}
              negotiation={negotiation}
              allowedActions={allowedActions}
              proposal={proposalToView}
            />
          )}

          {/* GeneralScope */}
          {negotiation?.scope && (
            <GeneralScopeDisplayModal
              open={isViewScopeModalOpen}
              onClose={() => {
                setIsViewScopeModalOpen(false);
              }}
              scope={negotiation?.scope}
              title='Negotiation scope'
              description='This is the scope of the negotiation'
            />
          )}
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}
