import { Box } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import VehicleHistoryEmbeddedView from "@/common/components/Entity/VehicleHistory/VehicleHistoryEmbeddedView";
import { VehicleDto } from "@/core/api/generated";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";

interface Props {
  vehicle: VehicleDto;
}
export default function VehicleHistoryTabContent({ vehicle }: Props) {
  return (
    <PageTabContent>
      <Box>
        <VehicleHistoryEmbeddedView
          vehicleId={vehicle.id!}
          displayProps={{ filters: false }}
          headerProps={{
            withLink: false,
            title: <PageTabHeader title={"History"} />,
          }}
        />
      </Box>
    </PageTabContent>
  );
}
