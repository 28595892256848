import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import EntityPoolsInfoDisplay from "@/common/components/EntityInfo/EntityPoolsInfoDisplay";
import EntityTagsInfoDisplay from "@/common/components/EntityInfo/EntityTagsInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import TableCellContentOfAttachments from "@/common/components/Table/TableCell/TableCellContentOfAttachments";
import TableCellContentOfCount from "@/common/components/Table/TableCell/TableCellContentOfCount";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { EntityHelper } from "@/common/helpers/entity";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  AssetDto,
  AssetGetPaginatedDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  PoolItemEntityType,
  TagEntityType,
} from "@/core/api/generated";
import { Button, Stack, SxProps, Theme } from "@mui/material";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import AffiliationInfoDisplay from "../../EntityAffiliation/AffiliationInfoDisplay";
import GeneralStrictEntityRelationInline from "../../General/Display/GeneralStrictEntityRelationInline";
import GeneralAttachedTagsOfEntitiesEditModal from "../../General/GeneralTag/GeneralAttachedTagsOfEntitiesEditModal";
import TenantStructureMembershipOfEntityEditModal from "../../Membership/TenantStructureMembershipOfEntityEditModal";
import PoolItemCreateManyFromEntitiesModal from "../../PoolItem/PoolItemCreateManyFromEntitiesModal";
import AssetMenu from "../AssetMenu";
import AssetsDeleteModal from "../AssetsDeleteModal";

enum BulkActionFlags {
  UpdateTenantStructureMembership = "UpdateTenantStructureMembership",
  UpdateTags = "UpdateTags",
  AddToPool = "AddToPool",
  Delete = "Delete",
}
const defaultDisplayProps = {
  filters: true,
  create: true,
  edit: true,
  delete: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type AssetPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

export interface AssetPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  propagatedDeps?: PropagatedDeps<AssetPaginatedListPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}

export type AssetPaginatedListProps = AssetPaginatedListOwnProps;

export default function AssetPaginatedList({
  displayProps,
  propagatedDeps,
  sx,
}: AssetPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<AssetGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.Asset,
    },
  });

  const paginatedAssetsRequest = useApiRequest(
    apiClient.assetsApi.apiV1AssetsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps, propagatedDeps?.depsMap["refetch"]],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedAssets = paginatedAssetsRequest.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Asset)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedAssetsRequest.handleEntityChanged(data);
    },
  });

  return (
    <Stack spacing={2}>
      <ViewLayout
        displayProps={displayProps}
        header={
          <SimpleViewPageHeader
            title={undefined}
            primaryActions={
              displayProps?.create && (
                <AuthorizedElement
                  permissions={[AppPermission.FleetAppAccess, AppPermission.AssetManage]}
                >
                  {displayProps?.create && (
                    <Button
                      variant='contained'
                      color='primary'
                      startIcon={<AppIcon of='add' />}
                      component={RouterLink}
                      to={ROUTE_PATH.ASSET_CREATE}
                    >
                      Create new asset
                    </Button>
                  )}
                </AuthorizedElement>
              )
            }
          />
        }
      >
        <Stack direction='column' spacing={1}>
          <DataTabular
            columns={[
              {
                field: getTypedPath<AssetDto>().id.$path,
                title: "ID",
                width: 250,
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.id,
                filters: {
                  fieldType: FilterFieldType.Id,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<AssetDto>().localNumber.$path,
                title: "Number",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <Stack direction='row' spacing={0.5}>
                    <span>{item.localNumber}</span>{" "}
                    <EntityChipList entity={item} variant='compact' />
                  </Stack>
                ),
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<AssetDto>().name.$path,
                title: "Name",
                width: 200,
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.name || "-",
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<AssetDto>().description.$path,
                title: "Description",
                width: 200,
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => item.description || "-",
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<AssetDto>().entityType.$path,
                title: "Entity type",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <InlineApiEnumValue type='AssetEntityType' value={item.entityType} />
                ),
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.AssetEntityType,
                    },
                  },
                },
              },
              {
                field: getTypedPath<AssetDto>().entity.entityId.$path,
                title: "Entity",
                width: 400,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => <GeneralStrictEntityRelationInline value={item.entity} />,
              },
              {
                field: getTypedPath<AssetDto>().createdAt.$path,
                title: "Created at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<AssetDto>().updatedAt.$path,
                title: "Updated at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<AssetDto>().createdBy.$path,
                title: "Created by",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                },
              },
              {
                field: getTypedPath<AssetDto>().updatedBy.$path,
                title: "Updated by",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                },
              },
              {
                field: getTypedPath<AssetDto>().accessories.$path,
                title: "Accessories",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => <TableCellContentOfCount count={item.accessories?.length} />,
              },
              {
                field: getTypedPath<AssetDto>().attachments.$path,
                title: "Attachments",
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => (
                  <TableCellContentOfAttachments attachments={item.attachments} />
                ),
              },
              {
                field: getTypedPath<AssetDto>().poolsMeta.$path,
                title: "Pools",
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => (
                  <EntityPoolsInfoDisplay
                    entityType={PoolItemEntityType.Asset}
                    entity={item}
                    noDataPlaceholder='-'
                  />
                ),
              },
              {
                field: "affiliation",
                title: "Affiliation",
                description: "Affiliation to departments & locations",
                width: 120,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                isToDisabled: true,
                renderCell: (item) => (
                  <AffiliationInfoDisplay
                    variant='compact'
                    tenantId={item.tenantId}
                    departmentIds={item.departmentIds}
                    locationIds={item.locationIds}
                  />
                ),
              },
              {
                field: getTypedPath<AssetDto>().tags.$path,
                title: "Tags",
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                isColumnMenuDisabled: false,
                isToDisabled: true,
                renderCell: (item) => (
                  <EntityTagsInfoDisplay
                    entityType={TagEntityType.Asset}
                    entity={item}
                    noDataPlaceholder='-'
                    edit={{
                      onSaved: (newValue) =>
                        paginatedAssetsRequest.updateData((data) => {
                          data.items?.forEach((item2) => {
                            if (item2.id === item.id) {
                              item2.tags = newValue || undefined;
                            }
                          });
                        }),
                    }}
                  />
                ),
              },
            ]}
            isLoading={paginatedAssetsRequest.isLoading}
            rows={paginatedAssets?.items}
            getRowId={(item) => item.id!}
            rowTo={(item) => ROUTE_PATH.ASSET_VIEW(item.id)}
            renderRowAction={({ item }) => (
              <AssetMenu
                entity={item}
                onDelete={() => paginatedAssetsRequest.refetch()}
                onUpdate={() => paginatedAssetsRequest.refetch()}
                displayProps={{
                  actions: {
                    edit: displayProps?.edit || false,
                    delete: displayProps?.delete || false,
                    addToPool: true,
                  },
                }}
              />
            )}
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
            bulkActions={{
              enabled: true,
              definition: BulkActionFlags,
              actionTriggers: ({ currentAction, startAction, selectedIds }) => (
                <>
                  <AppIconButton
                    tooltipProps={{ title: "Update affiliation" }}
                    onClick={() => startAction(BulkActionFlags.UpdateTenantStructureMembership)}
                  >
                    <AppIcon of='department' />
                  </AppIconButton>
                  <AppIconButton
                    tooltipProps={{ title: "Update tags" }}
                    onClick={() => startAction(BulkActionFlags.UpdateTags)}
                  >
                    <AppIcon of='tag' />
                  </AppIconButton>
                  <AppIconButton
                    tooltipProps={{ title: "Add to pool" }}
                    onClick={() => startAction(BulkActionFlags.AddToPool)}
                  >
                    <AppIcon of='pool' />
                  </AppIconButton>

                  <AppIconButton
                    tooltipProps={{ title: "Delete" }}
                    onClick={() => startAction(BulkActionFlags.Delete)}
                  >
                    <AppIcon of='delete' />
                  </AppIconButton>
                </>
              ),
              actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => {
                const selectedEntities = EntityHelper.filterEntitiesByIds(
                  paginatedAssets?.items || [],
                  selectedIds as string[],
                );
                return (
                  <>
                    <AssetsDeleteModal
                      entities={selectedEntities}
                      open={currentAction === BulkActionFlags.Delete}
                      onClose={() => cancelAction()}
                      onDelete={() => {
                        completeAction();
                        paginatedAssetsRequest.refetch();
                      }}
                    />
                    <PoolItemCreateManyFromEntitiesModal
                      open={currentAction === BulkActionFlags.AddToPool}
                      onClose={() => cancelAction()}
                      createProps={{
                        entityType: PoolItemEntityType.Asset,
                        entities: selectedEntities,
                        onSave: (newValue) => {
                          completeAction();
                          paginatedAssetsRequest.refetch();
                        },
                      }}
                    />
                    <TenantStructureMembershipOfEntityEditModal
                      open={currentAction === BulkActionFlags.UpdateTenantStructureMembership}
                      onClose={() => cancelAction()}
                      tenantStructureMembershipOfEntityEditProps={{
                        entityType: EntityType.Asset,
                        entities: selectedEntities,
                        onSave: () => {
                          completeAction();
                          paginatedAssetsRequest.refetch();
                        },
                      }}
                    />
                    <GeneralAttachedTagsOfEntitiesEditModal
                      tagEntityType={TagEntityType.Asset}
                      entities={selectedEntities}
                      open={currentAction === BulkActionFlags.UpdateTags}
                      onClose={() => cancelAction()}
                      onSaved={() => {
                        completeAction();
                        paginatedAssetsRequest.refetch();
                      }}
                    />
                  </>
                );
              },
            }}
          />
        </Stack>
      </ViewLayout>
    </Stack>
  );
}
