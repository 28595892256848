import { HubConnection } from "@microsoft/signalr";

import { apiClient } from "@/core/api/ApiClient";
import {
  AccessoryCheckCreatedInAppDataDto,
  BaseInAppNotificationDataDto,
  DamageCostEvaluationAggregateUpdatedInAppDataDto,
  DamageCostEvaluationCompletedInAppDataDto,
  DamageCostEvaluationStartedInAppDataDto,
  DamageDetectionCreatedInAppDataDto,
  InAppNotificationReceivedDto,
  InAppNotificationType,
  NotificationDto,
  NotificationHubClientMethodName,
  NotificationHubServerMethodName,
} from "@/core/api/generated";
// import { inAppNotificationReceived } from "@/store/notifications/slice";

import { ROUTE_PATH } from "../constants/routing";
import { notificationService } from "../services/notification";
import { BaseHubService } from "./baseHubService";

export interface IInAppNotificationAction {
  hasAction: boolean;
  routeUrl?: string;
  executeAction?: (notification: NotificationDto) => void;
}

export { NotificationHubClientMethodName, NotificationHubServerMethodName };

/** Client methods that can be called by the server. */
const clientMethods = NotificationHubClientMethodName;

/** Server methods that can be called by the client. */
const serverMethods = NotificationHubServerMethodName;

export class NotificationHubService extends BaseHubService {
  constructor() {
    super({
      hubName: "NotificationHub",
      showLogs: true,
      logIncomingMessages: true,
      logOutcomingMessages: true,
    });

    this.on("initialized", ({ connection }) => {
      this.registerMethodHandlers(connection);
    });

    this.on("connected", ({ connectionId }) => {
      apiClient.updateSignalConnectionIds({
        notificationHubConnectionId: connectionId,
      });

      // this.echo("Hello");
    });
  }

  public async connect(): Promise<void> {
    await super.connectToHub("/signalr/notification");
  }

  public async disconnect(): Promise<void> {
    await super.disconnectFromHub();
  }

  private registerMethodHandlers(connection: HubConnection) {
    this.registerMethodHandler(clientMethods.TestNotify, this.onTestNotify.bind(this));
    this.registerMethodHandler(clientMethods.Echo, this.onEcho.bind(this));
    this.registerMethodHandler(
      clientMethods.ServerErrorOccurred,
      this.onServerErrorOccurred.bind(this),
    );

    this.registerMethodHandler(
      clientMethods.InAppNotificationReceived,
      this.onInAppNotificationReceived.bind(this),
    );
  }

  //#region Method handlers

  private onTestNotify(data: any) {}

  private onEcho(data: any) {}

  private onServerErrorOccurred(data: any) {
    // always log error response from the BE
    console.error(this.logPrefix, "BE notified about server error:", data);
  }

  private onInAppNotificationReceived(data: InAppNotificationReceivedDto) {
    notificationService.handleNewInAppNotification(data);
  }

  //#endregion

  //#region Server methods

  public echo(message: string) {
    this.send(serverMethods.Echo, message);
  }

  //#endregion

  //#region Helper methods

  /** Returns an action according to InApp notification data. */
  public getInAppNotificationAction(notification: NotificationDto): IInAppNotificationAction {
    const result: IInAppNotificationAction = {
      hasAction: false,
      routeUrl: undefined,
      executeAction: undefined,
    };

    const baseData = notification!.inApp?.data as BaseInAppNotificationDataDto | undefined;
    if (!notification!.inApp || !baseData || !baseData.type) {
      return result;
    }

    if (baseData.type === InAppNotificationType.DamageDetectionCreated) {
      const data = baseData as DamageDetectionCreatedInAppDataDto;
      result.routeUrl = ROUTE_PATH.DAMAGE_DETECTION_VIEW(data.damageDetectionId);
    }

    if (baseData.type === InAppNotificationType.DamageCostEvaluationStarted) {
      const data = baseData as DamageCostEvaluationStartedInAppDataDto;
      result.routeUrl = ROUTE_PATH.DAMAGE_COST_EVALUATION_VIEW(data.damageCostEvaluationId);
    }
    if (baseData.type === InAppNotificationType.DamageCostEvaluationCompleted) {
      const data = baseData as DamageCostEvaluationCompletedInAppDataDto;
      result.routeUrl = ROUTE_PATH.DAMAGE_COST_EVALUATION_VIEW(data.damageCostEvaluationId);
    }

    if (baseData.type === InAppNotificationType.DamageCostEvaluationAggregateUpdated) {
      const data = baseData as DamageCostEvaluationAggregateUpdatedInAppDataDto;
      result.routeUrl = ROUTE_PATH.DAMAGE_COST_EVALUATION_AGGREGATE_VIEW(
        data.damageCostEvaluationAggregateId,
      );
    }

    if (baseData.type === InAppNotificationType.AccessoryCheckCreated) {
      const data = baseData as AccessoryCheckCreatedInAppDataDto;
      result.routeUrl = ROUTE_PATH.ACCESSORY_CHECK_VIEW(data.accessoryCheckId);
    }

    const openChatTypes: InAppNotificationType[] = [
      InAppNotificationType.ChatResolved,
      InAppNotificationType.ChatReopened,
      InAppNotificationType.ChatAcknowledged,

      InAppNotificationType.ChatParticipantAdded,
      InAppNotificationType.ChatParticipantRemoved,
      InAppNotificationType.ChatParticipantMentioned,

      InAppNotificationType.ChatMessageAcknowledged,

      InAppNotificationType.ChatNegotiationProposalCreated,
      InAppNotificationType.ChatNegotiationProposalResponseGiven,
    ];

    if (openChatTypes.includes(baseData.type!)) {
      const data = baseData as { chatId?: string; chat?: { id?: string } };
      result.routeUrl = ROUTE_PATH.CHAT_VIEW(data.chatId || data.chat?.id);

      // const data = baseData as ChatResolvedInAppDataDto;
      // result.executeAction = () => {
      //   store.dispatch(
      //     chatsSlice.openChat({
      //       placement: "stack",
      //       chatType: data.chatType!,
      //       chatId: data.chatId,
      //       scope: undefined,
      //     }) as unknown as AnyAction,
      //   );
      // };
    }

    result.hasAction = !!result.routeUrl || !!result.executeAction;
    return result;
  }

  //#endregion
}

export const notificationHubService = new NotificationHubService();
