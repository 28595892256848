import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosRequestConfig } from "axios";

import { FileItem } from "@/common/fileItem";
import { FileApiApiV1FilesUploadPostRequest, UploadFilesResultDto } from "@/core/api/generated";

import { AppThunk } from "..";

export type UploadFilesFuncRequestParameters = FileApiApiV1FilesUploadPostRequest;

export type UploadFilesFunc = (
  requestParameters: UploadFilesFuncRequestParameters,
  options?: AxiosRequestConfig<any> | undefined,
) => Promise<UploadFilesResultDto>;

export interface FullScreenFilesState {
  files: FileItem[];
}

export interface FilesState {
  fullScreen: FullScreenFilesState;
}

const initialState: FilesState = {
  fullScreen: {
    files: [],
  },
};

export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    _fullScreenStateChanged: (state, action: PayloadAction<{ files: FileItem[] }>) => {
      state.fullScreen = {
        files: action.payload.files,
      };
    },
  },
});

export const { _fullScreenStateChanged } = filesSlice.actions;

export const setFullScreenImages =
  (params: { files: FileItem[] }): AppThunk<void> =>
  (dispatch) => {
    dispatch(_fullScreenStateChanged(params));
  };

export const filesReducer = filesSlice.reducer;
