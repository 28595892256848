import _ from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";

import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import { ChatProps } from "@/common/components/Entity/Chat/Chat";
import Chat from "@/common/components/Entity/Chat/Chat";
import { GeneralScopeHelper } from "@/common/helpers/generalScope";
import { useUnmountEffect } from "@/common/hooks/mount/useUnmountEffect";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import { ValidationHelper } from "@/common/validation";
import { ChatType } from "@/core/api/generated";
import * as chatsSlice from "@/store/communication/chatsSlice";
import { OpenChatInfo } from "@/store/communication/chatsSlice";

interface OwnProps {
  chatId?: string | null;
  chatType?: ChatType | null;
  chatScopeStr?: string | null;
  chatProps?: Partial<ChatProps>;
}

type Props = OwnProps;

export default function EmbeddedChat({ chatId, chatType, chatScopeStr, chatProps }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const thunkDispatch = useAppThunkDispatch();

  const [openedChat, setOpenedChat] = useState<OpenChatInfo | undefined>(undefined);

  const chatScope = useMemo(
    () => GeneralScopeHelper.deserializeScopeFromString(chatScopeStr),
    [chatScopeStr],
  );

  const canShowChat = !!chatId || !!chatScope;

  const handleOpenChat = useCallback(async () => {
    if (canShowChat) {
      try {
        const newOpenedChat = await thunkDispatch(
          chatsSlice.openChat({
            placement: "page",
            chatType: chatType,
            chatId: chatId,
            scope: chatScope,
          }),
        );
        setOpenedChat(newOpenedChat);
        return newOpenedChat;
      } catch (err) {
        const newValidation = ValidationHelper.handleApiErrorResponse(err);
        enqueueSnackbar(newValidation.generalError, { variant: "error" });
      }
    }
  }, [chatType, chatId, chatScope]);

  const handleOpenChatThrottle = useCallback(
    _.throttle(handleOpenChat, 500, {
      leading: true,
      trailing: false,
    }),
    [handleOpenChat],
  );

  const handleCloseChat = useCallback(async () => {
    try {
      await thunkDispatch(
        chatsSlice.closeChat({
          openedChat,
          chat: undefined,
        }),
      );
    } catch (err) {
      const newValidation = ValidationHelper.handleApiErrorResponse(err);
      enqueueSnackbar(newValidation.generalError, { variant: "error" });
    }
  }, [openedChat]);

  const handleCloseChatThrottle = useCallback(
    _.throttle(handleCloseChat, 500, {
      leading: true,
      trailing: false,
    }),
    [],
  );

  useEffect(() => {
    handleOpenChatThrottle();
  }, []);

  // ensure closeChat is called only once on unmount
  useUnmountEffect(() => {
    if (openedChat) {
      handleCloseChatThrottle();
    }
  }, [openedChat]);

  if (!canShowChat) {
    return <EntityNotFoundAlert />;
  }

  return (
    <Chat
      chatType={chatType!}
      chatId={chatId || undefined}
      scope={chatScope || undefined}
      allowClose={false}
      allowPin={false}
      allowLeave
      fullWidth
      // fullHeight
      maxHeight='100%'
      chatHistoryProps={{
        autoScrollToHistoryEnd: false,
      }}
      onClose={(chat) => {
        handleCloseChat();
      }}
      {...chatProps}
    />
  );
}
