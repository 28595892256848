import { LoadingButton } from "@mui/lab";
import { FormControl, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Formik, getIn } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";

import { FileItem } from "@/common/fileItem";
import { renderIf } from "@/common/helpers/render/renderIf";
import useMounted from "@/common/hooks/mount/useMounted";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  GeneralAttachmentDto,
  TenantRequestDto,
  TenantRequestOperationType,
  TenantRequestResultInputDto,
  TenantRequestType,
} from "@/core/api/generated";
import _ from "lodash";
import { useState } from "react";
import FoldableBlock from "../../Display/FoldableBlock";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import FileUploader from "../../Files/FileUploader";
import AccessoryCheckAutocompleteOrCreate from "../AccessoryCheck/AccessoryCheckAutocompleteOrCreate";
import DamageCostEvaluationAutocompleteOrCreate from "../DamageCostEvaluation/DamageCostEvaluationAutocompleteOrCreate";
import DamageDetectionAutocompleteOrCreate from "../DamageDetection/DamageDetectionAutocompleteOrCreate";
import RepairOperationAutocompleteOrCreate from "../RepairOperation/RepairOperationAutocompleteOrCreate";
import VisualInspectionAutocompleteOrCreate from "../VisualInspection/VisualInspectionAutocompleteOrCreate";
import GeneralValidationError from "../../Error/GeneralValidationError";
import { BaseFormikValues } from "@/common/ts/error";

export interface TenantRequestUpdateResultOwnProps {
  tenantRequest: TenantRequestDto;
  // defaultValues?: {
  //   receiverTenantId?: TenantRequestResultInputDto["receiverTenantId"];
  // };
  onSave?: (newValue: TenantRequestDto) => void;
}

export type TenantRequestUpdateResultProps = TenantRequestUpdateResultOwnProps;

export default function TenantRequestUpdateResult({
  tenantRequest,
  // defaultValues,
  onSave,
}: TenantRequestUpdateResultProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();

  const [isUploadingFiles, setIsUploadingFiles] = useState(false);

  return (
    <Stack spacing={1}>
      <Formik<
        BaseFormikValues &
          TenantRequestResultInputDto & {
            initialAttachments?: GeneralAttachmentDto[] | null;
            uploadedAttachments?: FileItem[];
          }
      >
        enableReinitialize
        initialValues={{
          type: tenantRequest?.result?.type || tenantRequest?.content?.type || undefined,
          operation: tenantRequest?.result?.operation || {
            operationType:
              tenantRequest?.result?.operation?.operationType ||
              tenantRequest?.content?.operation?.operationType,
          },
          attachments: tenantRequest?.result?.attachments || undefined,

          initialAttachments: tenantRequest?.result?.attachments || undefined,

          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            const response =
              await apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdResultPut(
                {
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  tenantRequestId: tenantRequest.id || "",
                  tenantRequestResultInputDto: {
                    ...values,
                  },
                },
              );
            enqueueSnackbar("Result saved.", {
              variant: "success",
            });
            onSave && onSave(response.data);

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Stack spacing={2}>
                  {/* Result */}
                  <FoldableBlock
                    defaultIsFolded={false}
                    trigger={{
                      label: (
                        <Typography component='span' variant='subtitle1'>
                          Result for &apos;
                          <InlineApiEnumValue type='TenantRequestType' value={values.type} />
                          &apos; *
                        </Typography>
                      ),
                    }}
                  >
                    <Stack spacing={1}>
                      {/* Operation type inputs */}
                      {values.type === TenantRequestType.Operation && (
                        <Box>
                          {/* Specific operation inputs */}
                          {values.operation?.operationType && (
                            <Box>
                              {renderIf()
                                .if(
                                  values.operation?.operationType ===
                                    TenantRequestOperationType.VisualInspection,
                                )
                                .then(
                                  <Box>
                                    <FormControl margin='dense' fullWidth>
                                      <VisualInspectionAutocompleteOrCreate
                                        autocompleteProps={{
                                          required: true,
                                          entityId:
                                            values.operation?.visualInspection?.visualInspectionId,
                                          isPreload: true,
                                          textFieldProps: {
                                            error: Boolean(
                                              getIn(
                                                errors,
                                                "operation.visualInspection.visualInspectionId",
                                              ),
                                            ),
                                            helperText: ValidationHelper.getErrorsAsString(
                                              getIn(
                                                errors,
                                                "operation.visualInspection.visualInspectionId",
                                              ),
                                            ),
                                          },
                                          onChange: (newValue) => {
                                            setFieldValue(
                                              "operation.visualInspection.visualInspectionId",
                                              newValue?.id,
                                            );
                                          },
                                        }}
                                        createUpdateProps={{
                                          defaultValues: {
                                            vehicleId:
                                              tenantRequest.content?.operation?.visualInspection
                                                ?.vehicleId,
                                            tenantRequestsMeta: {
                                              tenantRequestIds: [tenantRequest.id!],
                                            },
                                          },
                                        }}
                                        createFormPlacement='modal'
                                        onCreate={(newValue) => {
                                          setFieldValue(
                                            "operation.visualInspection.visualInspectionId",
                                            newValue?.id,
                                          );
                                        }}
                                      />
                                    </FormControl>

                                    {_.isString(getIn(errors, "operation.visualInspection")) && (
                                      <FormHelperText error>
                                        {getIn(errors, "operation.visualInspection")}
                                      </FormHelperText>
                                    )}
                                  </Box>,
                                )
                                .elseif(
                                  values.operation?.operationType ===
                                    TenantRequestOperationType.DamageDetection,
                                )
                                .then(
                                  <Box>
                                    <FormControl margin='dense' fullWidth>
                                      <DamageDetectionAutocompleteOrCreate
                                        autocompleteProps={{
                                          required: true,
                                          entityId:
                                            values.operation?.damageDetection?.damageDetectionId,
                                          isPreload: true,
                                          textFieldProps: {
                                            error: Boolean(
                                              getIn(
                                                errors,
                                                "operation.damageDetection.damageDetectionId",
                                              ),
                                            ),
                                            helperText: ValidationHelper.getErrorsAsString(
                                              getIn(
                                                errors,
                                                "operation.damageDetection.damageDetectionId",
                                              ),
                                            ),
                                          },
                                          onChange: (newValue) => {
                                            setFieldValue(
                                              "operation.damageDetection.damageDetectionId",
                                              newValue?.id,
                                            );
                                          },
                                        }}
                                        createUpdateProps={{
                                          defaultValues: {
                                            vehicleId:
                                              tenantRequest.content?.operation?.damageDetection
                                                ?.vehicleId,
                                            tenantRequestsMeta: {
                                              tenantRequestIds: [tenantRequest.id!],
                                            },
                                          },
                                        }}
                                        createFormPlacement='modal'
                                        onCreate={(newValue) => {
                                          setFieldValue(
                                            "operation.damageDetection.damageDetectionId",
                                            newValue?.id,
                                          );
                                        }}
                                      />
                                    </FormControl>

                                    {_.isString(getIn(errors, "operation.damageDetection")) && (
                                      <FormHelperText error>
                                        {getIn(errors, "operation.damageDetection")}
                                      </FormHelperText>
                                    )}
                                  </Box>,
                                )
                                .elseif(
                                  values.operation?.operationType ===
                                    TenantRequestOperationType.DamageCostEvaluation,
                                )
                                .then(
                                  <Box>
                                    <FormControl margin='dense' fullWidth>
                                      <DamageCostEvaluationAutocompleteOrCreate
                                        autocompleteProps={{
                                          required: true,
                                          entityId:
                                            values.operation?.damageCostEvaluation
                                              ?.damageCostEvaluationId,
                                          isPreload: true,
                                          textFieldProps: {
                                            error: Boolean(
                                              getIn(
                                                errors,
                                                "operation.damageCostEvaluation.damageCostEvaluationId",
                                              ),
                                            ),
                                            helperText: ValidationHelper.getErrorsAsString(
                                              getIn(
                                                errors,
                                                "operation.damageCostEvaluation.damageCostEvaluationId",
                                              ),
                                            ),
                                          },
                                          onChange: (newValue) => {
                                            setFieldValue(
                                              "operation.damageCostEvaluation.damageCostEvaluationId",
                                              newValue?.id,
                                            );
                                          },
                                        }}
                                        createUpdateProps={{
                                          defaultValues: {
                                            vehicleId:
                                              tenantRequest.content?.operation?.damageCostEvaluation
                                                ?.vehicleId,
                                            vehicleDamageIds:
                                              tenantRequest.content?.operation?.damageCostEvaluation
                                                ?.vehicleDamageIds,
                                            currency:
                                              tenantRequest.content?.operation?.damageCostEvaluation
                                                ?.currency,
                                            tenantRequestsMeta: {
                                              tenantRequestIds: [tenantRequest.id!],
                                            },
                                          },
                                        }}
                                        createFormPlacement='modal'
                                        onCreate={(newValue) => {
                                          setFieldValue(
                                            "operation.damageCostEvaluation.damageCostEvaluationId",
                                            newValue?.id,
                                          );
                                        }}
                                      />
                                    </FormControl>

                                    {_.isString(
                                      getIn(errors, "operation.damageCostEvaluation"),
                                    ) && (
                                      <FormHelperText error>
                                        {getIn(errors, "operation.damageCostEvaluation")}
                                      </FormHelperText>
                                    )}
                                  </Box>,
                                )
                                .elseif(
                                  values.operation?.operationType ===
                                    TenantRequestOperationType.AccessoryCheck,
                                )
                                .then(
                                  <Box>
                                    <FormControl margin='dense' fullWidth>
                                      <AccessoryCheckAutocompleteOrCreate
                                        autocompleteProps={{
                                          required: true,
                                          entityId:
                                            values.operation?.accessoryCheck?.accessoryCheckId,
                                          isPreload: true,
                                          textFieldProps: {
                                            error: Boolean(
                                              getIn(
                                                errors,
                                                "operation.accessoryCheck.accessoryCheckId",
                                              ),
                                            ),
                                            helperText: ValidationHelper.getErrorsAsString(
                                              getIn(
                                                errors,
                                                "operation.accessoryCheck.accessoryCheckId",
                                              ),
                                            ),
                                          },
                                          onChange: (newValue) => {
                                            setFieldValue(
                                              "operation.accessoryCheck.accessoryCheckId",
                                              newValue?.id,
                                            );
                                          },
                                        }}
                                        createUpdateProps={{
                                          defaultValues: {
                                            vehicleId:
                                              tenantRequest.content?.operation?.accessoryCheck
                                                ?.vehicleId,
                                            tenantRequestsMeta: {
                                              tenantRequestIds: [tenantRequest.id!],
                                            },
                                          },
                                        }}
                                        createFormPlacement='modal'
                                        onCreate={(newValue) => {
                                          setFieldValue(
                                            "operation.accessoryCheck.accessoryCheckId",
                                            newValue?.id,
                                          );
                                        }}
                                      />
                                    </FormControl>

                                    {_.isString(getIn(errors, "operation.accessoryCheck")) && (
                                      <FormHelperText error>
                                        {getIn(errors, "operation.accessoryCheck")}
                                      </FormHelperText>
                                    )}
                                  </Box>,
                                )
                                .elseif(
                                  values.operation?.operationType ===
                                    TenantRequestOperationType.RepairOperation,
                                )
                                .then(
                                  <Box>
                                    <FormControl margin='dense' fullWidth>
                                      <RepairOperationAutocompleteOrCreate
                                        autocompleteProps={{
                                          required: true,
                                          entityId:
                                            values.operation?.repairOperation?.repairOperationId,

                                          isPreload: true,
                                          textFieldProps: {
                                            error: Boolean(
                                              getIn(
                                                errors,
                                                "operation.repairOperation.repairOperationId",
                                              ),
                                            ),
                                            helperText: ValidationHelper.getErrorsAsString(
                                              getIn(
                                                errors,
                                                "operation.repairOperation.repairOperationId",
                                              ),
                                            ),
                                          },
                                          onChange: (newValue) => {
                                            setFieldValue(
                                              "operation.repairOperation.repairOperationId",
                                              newValue?.id,
                                            );
                                          },
                                        }}
                                        createUpdateProps={{
                                          defaultValues: {
                                            vehicleId:
                                              tenantRequest.content?.operation?.repairOperation
                                                ?.vehicleId,
                                            vehicleDamageIds:
                                              tenantRequest.content?.operation?.repairOperation
                                                ?.vehicleDamageIds,
                                            currency:
                                              tenantRequest.content?.operation?.repairOperation
                                                ?.currency,
                                            tenantRequestsMeta: {
                                              tenantRequestIds: [tenantRequest.id!],
                                            },
                                          },
                                        }}
                                        createFormPlacement='modal'
                                        onCreate={(newValue) => {
                                          setFieldValue(
                                            "operation.repairOperation.repairOperationId",
                                            newValue?.id,
                                          );
                                        }}
                                      />
                                    </FormControl>

                                    {_.isString(getIn(errors, "operation.repairOperation")) && (
                                      <FormHelperText error>
                                        {getIn(errors, "operation.repairOperation")}
                                      </FormHelperText>
                                    )}
                                  </Box>,
                                )
                                .else(
                                  <Box>
                                    <InlineApiEnumValue
                                      type='TenantRequestType'
                                      value={values.type}
                                    />{" "}
                                    not supported yet!
                                  </Box>,
                                )
                                .render()}
                            </Box>
                          )}

                          {_.isString(getIn(errors, "content.operation")) && (
                            <FormHelperText error>
                              {getIn(errors, "content.operation")}
                            </FormHelperText>
                          )}
                        </Box>
                      )}
                    </Stack>
                  </FoldableBlock>

                  <FoldableBlock
                    defaultIsFolded={false}
                    trigger={{
                      label: (
                        <Typography component='span' variant='subtitle1'>
                          Other
                        </Typography>
                      ),
                    }}
                  >
                    <TextField
                      error={Boolean(getIn(errors, "notes"))}
                      fullWidth
                      multiline
                      minRows={2}
                      helperText={getIn(errors, "notes")}
                      label='Notes'
                      margin='dense'
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      value={values.notes || ""}
                      variant='outlined'
                    />

                    <FormControl fullWidth margin='none'>
                      <Typography component='div' variant='subtitle1'>
                        Attachments
                      </Typography>

                      <FileUploader
                        multiple
                        maxFiles={100}
                        defaultFiles={FileItem.createManyFrom(
                          values.uploadedAttachments ||
                            values.attachments ||
                            values.initialAttachments,
                        )}
                        onChange={(newFiles) => {
                          setFieldValue(
                            "attachments",
                            FileItem.toManyGeneralAttachmentInputDto(newFiles),
                          );
                          setFieldValue("uploadedAttachments", newFiles);
                        }}
                        onUploadStarted={() => {
                          setIsUploadingFiles(true);
                        }}
                        onUploadFinished={() => {
                          setIsUploadingFiles(false);
                        }}
                      />

                      {errors.attachments && (
                        <FormHelperText error>
                          {ValidationHelper.getFormikErrorsAsString(errors.attachments)}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </FoldableBlock>
                </Stack>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <LoadingButton
                  sx={{ mt: { xs: "auto", md: 2 } }}
                  color='primary'
                  loading={isSubmitting}
                  disabled={isUploadingFiles}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </LoadingButton>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </Stack>
  );
}
