import { FilterDefinitionDto, FilterType } from "@/core/api/generated";
import { ArrayHelper } from "../helpers/array";
import { FilterHelper } from "../helpers/filter";
import { TypeHelper } from "../helpers/type";
import { FilterDefinitionItem } from "./filterDefinitionItem";

/** Definition of final filters that can used for data filtering.
 * NB: must be serializable to/from JSON.
 */
export class FilterDefinition {
  public items: FilterDefinitionItem[];

  constructor(params: { items: FilterDefinitionItem[] }) {
    this.items = params.items.map((x) => new FilterDefinitionItem(x));
  }

  // #region Static

  public static newEmpty(): FilterDefinition {
    return new FilterDefinition({ items: [] });
  }

  // #endregion

  public get isValid(): boolean {
    return !TypeHelper.isEmpty(this.items) && this.items.every((x) => x.isValid);
  }

  public getItemById(itemId: string): FilterDefinitionItem | undefined {
    return this.items.find((x) => x.id === itemId);
  }

  public addItem(newItem: FilterDefinitionItem): void {
    this.items.push(newItem);
  }

  public replaceItem(newItem: FilterDefinitionItem): void {
    ArrayHelper.replaceByPredicate(this.items, (x) => x.id === newItem.id, newItem);
  }

  public replaceItemById(itemId: string, newItem: FilterDefinitionItem): void {
    ArrayHelper.replaceByPredicate(this.items, (x) => x.id === itemId, newItem);
  }

  public removeItem(item: FilterDefinitionItem): void {
    ArrayHelper.removeByPredicate(this.items, (x) => x.id === item.id);
  }

  public removeItemById(itemId: string): void {
    ArrayHelper.removeByPredicate(this.items, (x) => x.id === itemId);
  }

  /** Removes empty items to make sure the definition is relevant and valid. */
  public cleanup(): void {
    const invalidItems = this.items.filter((x) => !x.isValid);
    if (!TypeHelper.isEmpty(invalidItems)) {
      console.warn(
        `Found ${invalidItems.length} invalid items during cleanup. Invalid items:`,
        invalidItems,
      );
    }

    this.items = this.items.filter((x) => x.isValid);
  }

  public clone(): FilterDefinition {
    return new FilterDefinition({ items: this.items.map((x) => x.clone()) });
  }

  /** Converts internal values to valid format before serialization to JSON. */
  public formatBeforeJsonSerialization(): void {
    this.items.forEach((x) => x.formatBeforeJsonSerialization());
  }

  public mapToDto(options?: { filterType?: FilterType }): FilterDefinitionDto | undefined {
    return FilterHelper.mapFilterDefinitionToFilterDefinitionDto(this, options);
  }

  public serializeIntoString(options?: { filterType?: FilterType }): string | undefined {
    return FilterHelper.serializeFilterDefinitionIntoString(this, options);
  }
}
