import { Box, FormHelperText, Stack, Typography } from "@mui/material";

import { useSuggestedVehicleVisualModel } from "@/common/hooks/entity/vehicleVisualModel/useSuggestedVehicleVisualModel";
import { VehicleSpecInputDto, VehicleVisualModelDto } from "@/core/api/generated";
import VehicleVisualModelAutocomplete, {
  VehicleVisualModelAutocompleteProps,
} from "../../VehicleVisualModel/VehicleVisualModelAutocomplete";
import VehicleVisualModelLink from "../../VehicleVisualModel/VehicleVisualModelLink";
import AppLink from "@/common/components/Link/AppLink";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { useState } from "react";
import FieldValue from "@/common/components/Form/Display/FieldValue";

interface Props {
  defaultVisualModelId: string | undefined | null;
  isDefaultVisualModelSelectedManually: boolean | undefined | null;
  spec: VehicleSpecInputDto | undefined | null;
  vehicleVisualModelAutocompleteProps?: Partial<VehicleVisualModelAutocompleteProps>;
  onChange: (newValue: {
    defaultVisualModel: VehicleVisualModelDto | undefined | null;
    isDefaultVisualModelSelectedManually: boolean;
  }) => void;
}

export default function DefaultVehicleVisualModelInput({
  defaultVisualModelId,
  isDefaultVisualModelSelectedManually,
  spec,
  vehicleVisualModelAutocompleteProps,
  onChange,
}: Props) {
  const [isUseNewModelConfirmationModalOpen, setIsUseNewModelConfirmationModalOpen] =
    useState(false);

  // fetch and set suggested VehicleVisualModel when user fills out vehicle info
  const suggestedVehicleVisualModel = useSuggestedVehicleVisualModel({
    spec: spec,
    onChange: (newValue) => {
      if (
        !isDefaultVisualModelSelectedManually &&
        newValue &&
        newValue.id !== defaultVisualModelId
      ) {
        onChange({
          defaultVisualModel: newValue,
          isDefaultVisualModelSelectedManually: false,
        });
      }
    },
  });

  return (
    <Box>
      <VehicleVisualModelAutocomplete
        {...vehicleVisualModelAutocompleteProps}
        entity={undefined}
        entityId={defaultVisualModelId}
        label='Default visual model'
        onChange={(newValue) => {
          onChange({
            defaultVisualModel: newValue,
            isDefaultVisualModelSelectedManually: true,
          });
        }}
      />
      {suggestedVehicleVisualModel &&
        defaultVisualModelId &&
        suggestedVehicleVisualModel.id !== defaultVisualModelId && (
          <FormHelperText>
            Found more suitable visual model for this vehicle{" "}
            <VehicleVisualModelLink entity={suggestedVehicleVisualModel} />.{" "}
            <AppLink
              to='#'
              isInheritStyles
              onClick={(e) => {
                e.preventDefault();
                setIsUseNewModelConfirmationModalOpen(true);
              }}
            >
              Use new visual model.
            </AppLink>
          </FormHelperText>
        )}

      {/* Use new visual model confirmation */}
      <ConfirmationModal
        maxWidth='md'
        fullWidth
        title='Update default visual model?'
        body={
          <>
            <Stack spacing={1}>
              <Typography>
                {" "}
                {`You're going to update the default visual model. Please keep in mind:`}
                <ol>
                  <li>
                    The new visual model will be applied only to new damage detections and damages.
                  </li>
                  <li>
                    The old damage detections and damages still reference the old visual model.
                  </li>
                  <li>
                    You can migrate manually old damage detections and damages to new visual model
                    manually if needed.
                  </li>
                </ol>
              </Typography>

              <FieldValue label='Old visual model'>
                <VehicleVisualModelLink entity={undefined} entityId={defaultVisualModelId} />
              </FieldValue>

              <FieldValue label='New visual model'>
                <VehicleVisualModelLink entity={suggestedVehicleVisualModel} />
              </FieldValue>
            </Stack>
          </>
        }
        open={isUseNewModelConfirmationModalOpen}
        onClose={() => setIsUseNewModelConfirmationModalOpen(false)}
        onCancel={() => setIsUseNewModelConfirmationModalOpen(false)}
        onConfirm={() => {
          onChange({
            defaultVisualModel: suggestedVehicleVisualModel,
            isDefaultVisualModelSelectedManually: true,
          });
          setIsUseNewModelConfirmationModalOpen(false);
        }}
      />
    </Box>
  );
}
