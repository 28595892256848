import { Box, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AppAvatar from "@/common/components/Avatar/AppAvatar";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { clipboardService } from "@/common/services/clipboard";
import { apiClient } from "@/core/api/ApiClient";
import { TenantInviteDto } from "@/core/api/generated";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import CustomerBlock from "@/common/components/Entity/Customer/CustomerBlock";
import RoleLink from "@/common/components/Entity/Role/RoleLink";
import AppIcon from "@/common/components/Icons/AppIcon";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
};
interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function InviteView({ displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { inviteId } = useParams<{ inviteId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [inviteInfo, setInviteInfo] = useState<TenantInviteDto | null>(null);

  const customerRequest = useApiRequest(
    apiClient.customersApi.apiV1CustomersCustomerIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      customerId: inviteInfo?.customerId || "",
    },
    {
      deps: [inviteInfo?.customerId],
      skip: !inviteInfo?.customerId,
    },
  );
  const customer = customerRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: inviteInfo && {
      idValue: inviteInfo.id!,
      newTitle: `${inviteInfo.type} invite` || "",
    },
  });

  const getInvitesRequest = useApiRequest(apiClient.tenantInvitesApi.apiV1TenantInvitesGetPost, {
    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    tenantInviteGetPaginatedDto: {
      inviteId: inviteId,
    },
  });
  const invites = getInvitesRequest.data;

  useEffect(() => {
    const item = invites?.items ? invites.items[0] : null;
    setInviteInfo(item);
  }, [inviteId, invites]);

  const getRegisteredUsersRequest = useApiRequest(
    apiClient.usersApi.apiV1UsersByIdsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      requestBody: inviteInfo?.registeredUserIds || undefined, // userIds
    },
    {
      skip: !inviteInfo?.registeredUserIds?.length,
    },
  );
  const registeredUsers = getRegisteredUsersRequest.data;

  const getRejectedUsersRequest = useApiRequest(
    apiClient.usersApi.apiV1UsersByIdsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      requestBody: inviteInfo?.rejectedUserIds || undefined, // userIds
    },
    {
      skip: !inviteInfo?.rejectedUserIds?.length,
    },
  );
  const rejectedUsers = getRejectedUsersRequest.data;

  const getAcceptedUsersRequest = useApiRequest(
    apiClient.usersApi.apiV1UsersByIdsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      requestBody: inviteInfo?.acceptedUserIds || undefined, // userIds
    },
    {
      skip: !inviteInfo?.acceptedUserIds?.length,
    },
  );
  const acceptedUsers = getAcceptedUsersRequest.data;

  return (
    <ViewLayout displayProps={displayProps} header={<SimpleViewPageHeader title={`Invite`} />}>
      {customer && (
        <CustomerBlock
          sx={{ maxWidth: { md: "400px" } }}
          variant='reference'
          customer={customer}
          withDetailsToggle
          withLink
        />
      )}

      <Grid sx={{ mb: 2, mt: 2 }} container columnSpacing={2} rowSpacing={1}>
        <Grid item xxs={12} md minWidth={200}>
          <Stack direction='column' spacing={1}>
            <FieldValue label='Receiver'>{inviteInfo?.userInfo?.email}</FieldValue>
            <FieldValue label='Receiver name'>{inviteInfo?.userInfo?.personName?.name}</FieldValue>
            <FieldValue label='Receiver phone number'>
              {inviteInfo?.userInfo?.phoneNumber}
            </FieldValue>
            <FieldValue label='Expires at'>
              <Datetime datetime={inviteInfo?.expiresAt} direction='column' withDurationFromNow />
            </FieldValue>
            <FieldValue label='Sent at'>
              <Datetime datetime={inviteInfo?.sentAt} direction='column' withDurationFromNow />
            </FieldValue>
          </Stack>
        </Grid>
        <Grid item xxs={12} md minWidth={200}>
          <Stack direction='column' spacing={1}>
            <FieldValue label='Type'>{inviteInfo?.type}</FieldValue>
            <FieldValue label='Current/Max users'>
              {inviteInfo?.currentUsers}/{inviteInfo?.maxUsers}
            </FieldValue>
            <FieldValue label='Roles'>
              <Stack>
                {inviteInfo?.roleIds?.map((id, i) => (
                  <RoleLink key={i} entity={undefined} entityId={id} />
                ))}
              </Stack>
            </FieldValue>
            <FieldValue label='is Expired'>
              {inviteInfo?.isExpired ? "Expired" : "Actual"}
            </FieldValue>
          </Stack>
        </Grid>
        <TextField
          sx={{ mt: 1, px: 2 }}
          value={inviteInfo?.inviteUrl}
          fullWidth
          disabled={inviteInfo?.isExpired}
          size='small'
          margin='none'
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position='start'>
                <IconButton
                  onClick={async () => {
                    try {
                      await clipboardService.writeText(inviteInfo?.inviteUrl as string);
                      enqueueSnackbar("Link copied to clipboard.", {
                        variant: "success",
                      });
                    } catch (error) {
                      console.error("Clipboard copy error", error);
                    }
                  }}
                >
                  <AppIcon of='link' />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      {acceptedUsers && (
        <Stack sx={{ mt: 2 }}>
          <Typography component='div' sx={{ mb: 2 }} variant='h3'>
            Accepted users
          </Typography>
          <Stack spacing={1}>
            <DataTabular
              columns={[
                {
                  field: "personName.name",
                  title: "Name",
                  flex: 1,
                  renderCell: (item) => (
                    <Stack direction='row' spacing={1}>
                      <AppAvatar user={item} size='small' />
                      <Stack>
                        <Box>{item.personName?.name}</Box>
                        <Typography component='div' variant='body2'>
                          {item.email}
                        </Typography>
                      </Stack>
                    </Stack>
                  ),
                },
                {
                  field: "status",
                  title: "Status",
                  flex: 1,
                  renderCell: (item) => (
                    <InlineApiEnumValue type='UserStatus' value={item.status} />
                  ),
                },
                {
                  field: "phoneNumber",
                  title: "Phone",
                  flex: 1,
                  renderCell: (item) => item.phoneNumber,
                },
                {
                  field: "roles",
                  title: "Roles",
                  flex: 1,
                  renderCell: (item) => item.roles?.map((x) => x.role!.name)?.join(", "),
                },
              ]}
              rows={acceptedUsers}
              getRowId={(item) => item.id!}
              rowTo={(item) => ROUTE_PATH.TENANT_USER_VIEW(item.id)}
            />
          </Stack>
        </Stack>
      )}

      {registeredUsers && (
        <Stack sx={{ mt: 2 }}>
          <Typography component='div' sx={{ mb: 2 }} variant='h3'>
            Registered users
          </Typography>
          <Stack spacing={1}>
            <DataTabular
              columns={[
                {
                  field: "personName.name",
                  title: "Name",
                  flex: 1,
                  renderCell: (item) => (
                    <Stack direction='row' spacing={1}>
                      <AppAvatar user={item} size='small' />
                      <Stack>
                        <Box>{item.personName?.name}</Box>
                        <Typography component='div' variant='body2'>
                          {item.email}
                        </Typography>
                      </Stack>
                    </Stack>
                  ),
                },
                {
                  field: "status",
                  title: "Status",
                  flex: 1,
                  renderCell: (item) => (
                    <InlineApiEnumValue type='UserStatus' value={item.status} />
                  ),
                },
                {
                  field: "phoneNumber",
                  title: "Phone",
                  flex: 1,
                  renderCell: (item) => item.phoneNumber,
                },
                {
                  field: "roles",
                  title: "Roles",
                  flex: 1,
                  renderCell: (item) => item.roles?.map((x) => x.role!.name)?.join(", "),
                },
              ]}
              rows={registeredUsers}
              getRowId={(item) => item.id!}
              rowTo={(item) => ROUTE_PATH.TENANT_USER_VIEW(item.id)}
            />
          </Stack>
        </Stack>
      )}

      {rejectedUsers && (
        <Stack sx={{ mt: 2 }}>
          <Typography component='div' sx={{ mb: 2 }} variant='h3'>
            Rejected users
          </Typography>
          <DataTabular
            columns={[
              {
                field: "personName.name",
                title: "Name",
                flex: 1,
                renderCell: (item) => (
                  <Stack direction='row' spacing={1}>
                    <AppAvatar user={item} size='small' />
                    <Stack>
                      <Box>{item.personName?.name}</Box>
                      <Typography component='div' variant='body2'>
                        {item.email}
                      </Typography>
                    </Stack>
                  </Stack>
                ),
              },
              {
                field: "status",
                title: "Status",
                flex: 1,
                renderCell: (item) => <InlineApiEnumValue type='UserStatus' value={item.status} />,
              },
              {
                field: "phoneNumber",
                title: "Phone",
                flex: 1,
                renderCell: (item) => item.phoneNumber,
              },
              {
                field: "roles",
                title: "Roles",
                flex: 1,
                renderCell: (item) => item.roles?.map((x) => x.role!.name)?.join(", "),
              },
            ]}
            rows={rejectedUsers}
            getRowId={(item) => item.id!}
            rowTo={(item) => ROUTE_PATH.TENANT_USER_VIEW(item.id)}
          />
        </Stack>
      )}
    </ViewLayout>
  );
}
